/*GENERAL*/
.risk-micro .microsite-rm .mainpage-wrapper {
  max-width: 1200px;
}
/*CAROUSEL*/
.risk-micro .microsite-rm .carousel .carousel-control {
  position: absolute;
  top: unset;
  width: 60px;
  opacity: .3;
  background-image: none;
  transition: all 0.9s ease-in-out;
  align-items: center;
  display: flex;
  height: 100%;
  padding-top: 60px;
}
.risk-micro .microsite-rm .carousel .playPause {
  position: absolute;
  width: 100%;
  left: unset;
  right:  unset;
  top: unset;
  text-align: right;
  height: 40px;
  padding: 0px 10%;
  bottom: 7%;
  z-index: 25;
}
.risk-micro .microsite-rm .carousel .playPause .fa {
  line-height: 40px;
  position: absolute;
  top: unset;
  right: unset;
}
.risk-micro .microsite-rm .carousel:hover .playPause .fa {
  position: relative;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}
.risk-micro .microsite-rm .carousel:hover .playPause .fa-pause {
  opacity: .5;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
}
.risk-micro .carousel .arrow-container .carousel-control .glyphicon {
  top: unset;
}
.risk-micro .carousel .carousel-inner .slide {
  height: fit-content;
  height: -moz-fit-content;
  display: grid;
  align-items: center;
  max-width: 1800px;
  margin: 0px auto;
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite-rm .carousel-control .glyphicon-chevron-right {
    margin-right: -15px;
    margin-top: 20px;
  }
.risk-micro   .microsite-rm .carousel-control .glyphicon-chevron-left {
    margin-left: -15px;
    margin-top: 20px;
  }
}
/*CAROUSEL SLIDER*/
.risk-micro .carousel--rm.carousel {
  height: fit-content;
  height: -moz-fit-content;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  z-index: 180;
  padding-bottom: 30px;
}
.risk-micro .carousel--rm.carousel .carousel-indicators {
  height: fit-content;
  height: -moz-fit-content;
  bottom: -60px;
  width: auto;
  text-align: center;
  padding: 0;
  max-width: 960px;
  opacity: 1;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: inline-block;
}
.risk-micro .carousel--rm.carousel .carousel-indicators li {
  background-color: #c6c9cb;
}
.risk-micro .carousel--rm.carousel .carousel-indicators li.active {
  background-color: #1978b4;
}
.risk-micro .carousel--rm.carousel .carousel-inner {
  height: fit-content;
  height: -moz-fit-content;
}
.risk-micro .carousel .carousel-inner .slide:before {
  transition: height 1s;
  display: none;
}
.risk-micro .carousel--rm.carousel .carousel-inner:before {
  display: none;
}
.risk-micro .carousel--rm.carousel .carousel-inner .slider-text-wrapper {
  height: fit-content;
  height: -moz-fit-content;
  padding-right: 0px;
  padding-left: 0px;
  display: block;
  width: 55%;
}
.risk-micro body .carousel--rm .title-wrapper {
  float: right;
  margin-right: 120px;
}
.risk-micro .microsite-rm .carousel-inner .slider-text {
  max-width: 100%;
  display: inline-flex;
  position: absolute;
  height: 100%;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 40px;
  align-items: center;
}
.risk-micro .microsite-rm .carousel-inner .slider-text.title {
  max-width: 100%;
  display: block;
  position: absolute;
  height: 100%;
  flex-direction: row;
  top: 8%;
  width: 100%;
}
.risk-micro .microsite-rm .carousel--rm .first-paragraph {
  margin-left: 31%;
}
.risk-micro .carousel--rm.carousel .carousel-inner .slider-text h4 {
  font-size: 31px;
  margin-top: 0px;
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 0px;
  color: #00549a;
}
.risk-micro .carousel--rm.carousel .carousel-inner .item .slide img {
  margin: 0px auto;
  width: 100%;
}
.risk-micro .microsite-rm .carousel--rm img.image-large {
  display: block;
}
.risk-micro .microsite-rm .carousel--rm img.image-small,
.risk-micro .microsite-rm .carousel.carousel--rm .information-mobile img.image-mobile,
.risk-micro .microsite-rm .carousel.carousel--rm .information-mobile img.image-mobile-portrait,
.risk-micro .microsite-rm .carousel.carousel--rm .information-mobile.slide {
  display: none;
}
@media screen and (max-width: 320px) and (orientation: portrait) {
  .risk-micro .microsite.microsite-rm .carousel--rm.carousel.slide .carousel-inner {
    max-height: 100vh;
    min-height: unset;
  }
.risk-micro   .microste.microsite-rm .carousel--rm.carousel .carousel-inner .slide {
    min-height: 100vh;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile img.image-mobile-portrait {
    margin-top: 60%;
  }
}
@media screen and (max-width: 360px) and (orientation: portrait) {
  .risk-micro .microsite.microsite-rm .slide.carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile img.image-mobile {
    display: none;
  }
.risk-micro   .microsite-rm .Carousel .carousel-inner .slide.information-mobile img.image-mobile-portrait {
    display: block;
    align-items: flex-end;
    margin-top: 70%;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel-fade.carousel .carousel-inner .slider-text-wrapper {
    top: 18%;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .carousel-fade.carousel--rm.carousel .carousel-inner .slider-text-wrapper {
    top: 18%;
  }
}
@media screen and (min-width: 300px) and (max-width: 640px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .carousel--rm.carousel.slide .carousel-inner {
    min-height: unset;
    max-height: 350px;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile img.image-mobile {
    margin-top: 80px;
  }
.risk-micro   .microsite.microsite-rm .carousel.carousel--rm .carousel-inner .title-wrapper {
    margin-right: 0px;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel .carousel-inner {
    min-height: 500px;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile {
    margin-top: -100px;
    float: right;
  }
}
@media screen and (max-width: 360px) and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
  .risk-micro .microsite.microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile img {
    margin-top: 460px;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel.Carousel {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 640px) {
  .risk-micro .microsite-rm .carousel--rm.carousel.carousel .carousel-inner .slider-text .row h4 {
    font-size: 1.2em;
  }
.risk-micro   .microsite.microsite-rm .site-title .mainpage-wrapper h1 {
    font-size: 1.4em;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .title.slider-text {
    top: 5%;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm.carousel .carousel-inner .slider-text-wrapper {
    top: 25%;
    padding-top: 0px;
    position: absolute;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .slide.information-mobile img.image-mobile {
    align-items: flex-end;
    margin-top: 340px;
    display: block;
  }
.risk-micro   .microsite.microsite-rm .carousel.carousel--rm .information-mobile.slide {
    display: inline-flex;
  }
.risk-micro   .microsite-rm .Carousel.carousel.carousel--rm .carousel-inner .title-wrapper {
    margin-right: 0px;
  }
.risk-micro   .microsite.microsite-rm .Carousel.carousel--rm.carousel.slide .indicatorWrap,
.risk-micro   .microsite.microsite-rm .carousel .playPause,
.risk-micro   .microsite.microsite-rm .carousel .arrow-container,
.risk-micro   .microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .item .slide img.image-small,
.risk-micro   .microsite-rm .carousel--rm.carousel.Carousel .carousel-inner .item .slide img.image-large {
    display: none;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .slide-1,
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .slide-2,
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .slide-3,
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .slide-4,
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .item {
    display: none;
  }
.risk-micro   .microsite-rm.carousel .carousel-inner .slide:before {
    transition: none;
    display: none;
  }
}
@media screen and (min-width: 641px) and (max-width: 765px) {
  .risk-micro .microsite-rm .carousel.carousel--rm .carousel-inner .title-wrapper {
    margin-right: 0px;
    max-width: 70%;
    float: none;
    margin-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .risk-micro body.carousel--rm.carousel .carousel-inner .item .slide-background {
    height: fit-content;
    height: -moz-fit-content;
  }
.risk-micro   .microsite-rm .carousel .carousel-inner .item {
    height: fit-content;
    height: -moz-fit-content;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel {
    padding-top: 0px;
    padding-bottom: 0px;
  }
.risk-micro   .microsite-rm .carousel--rm .slide img.image-large {
    display: none;
  }
.risk-micro   .microsite-rm .carousel--rm .slide img.image-small {
    display: block;
  }
.risk-micro   .carousel .carousel-inner .slide {
    height: 350px;
    position: relative;
  }
}
@media screen and (min-width: 761px) and (max-width: 992px) {
  .risk-micro .microsite-rm .carousel .carousel-inner .slide {
    height: fit-content;
    height: -moz-fit-content;
    min-height: 400px;
    display: inline-flex;
    align-items: center;
  }
.risk-micro   .microsite-rm.carousel--rm.carousel .carousel-inner {
    min-height: 300px;
  }
}
@media screen and (max-width: 840px) {
  .risk-micro .microsite-rm .carousel.carousel--rm .carousel-inner .slider-text.title h1 {
    font-size: 2.7em;
  }
}
@media screen and (max-width: 992px) {
  .risk-micro .microsite-rm .carousel--rm .first-paragraph,
.risk-micro   .microsite.microsite-rm .Carousel.carousel--rm.carousel.slide .indicatorWrap,
.risk-micro   .microsite.microsite-rm .carousel .playPause,
.risk-micro   .microsite.microsite-rm .carousel .arrow-container {
    display: none;
  }
.risk-micro   .microsite-rm .carousel-inner .slider-text.title {
    display: flex;
  }
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite-rm .carousel--rm.carousel .carousel-inner .slide {
    max-height: 400px;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel.slide .carousel-inner {
    max-height: 400px;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner .title.slider-text {
    top: 0px;
  }
.risk-micro 
  .microsite-rm .carousel--rm.carousel .carousel-inner .slider-text-wrapper {
    padding-top: 50px;
  }
.risk-micro   .microsite.microsite-rm .carousel--rm .first-paragraph {
    margin-left: 21%;
    margin-top: 16%;
  }
}
@media screen and (max-width: 1300px) {
  .risk-micro .microsite-rm .carousel .carousel-inner .slide {
    height: fit-content;
    height: -moz-fit-content;
    min-height: 600px;
    display: inline-flex;
    align-items: center;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel .carousel-inner {
    min-height: 600px;
  }
.risk-micro   .microsite-rm .carousel.carousel--rm .slider-text-wrapper .row.first-paragraph {
    margin-top: 20%;
  }
.risk-micro   body .carousel--rm.carousel .carousel-indicators {
    top: -40px;
    bottom: unset;
  }
.risk-micro   .microsite-rm .carousel--rm.carousel .indicatorWrap {
    top: -50px;
  }
.risk-micro   .microsite.microsite-rm .Carousel.carousel--rm.carousel.slide.carousel--fade .indicatorWrap {
    top: 590px;
  }
.risk-micro   .microsite-rm .carousel .carousel-inner .slider-text .slider-text-wrapper h4 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 1405px) {
  .risk-micro .microsite-rm .carousel .carousel-inner .slider-text.title h1 {
    font-size: 3em;
  }
.risk-micro   .microsite-rm .carousel.carousel--rm .title-wrapper {
    padding: 0px;
  }
.risk-micro   .microsite.microsite-rm .Carousel.carousel--rm.carousel.slide .indicatorWrap {
    top: 610px;
  }
}
@media screen and (max-width: 1500px) {
  .risk-micro .microsite-rm .carousel--rm .title-wrapper {
   padding: 0px;
  }
.risk-micro   .microsite-rm .carousel .carousel-inner .slider-text h4 {
    font-size: 1.9em;
  }
.risk-micro   .microsite.microsite-rm .Carousel.carousel--rm.carousel .indicatorWrap {
    top: 660px;
  }
}
@media screen and (max-width: 1600px) {
  .risk-micro .microsite.microsite-rm .carousel--rm.carousel .indicatorWrap {
    top: 680px;
  }
}
@media screen and (max-width: 1700px) {
  .risk-micro .microsite-rm .carousel.carousel--rm .row.first-paragraph {
    margin-top: 20%;
  }
.risk-micro   .microsite .carousel--rm.carousel .indicatorWrap {
    top: 730px;
  }
}
@media screen and (min-width: 1800px) {
  .risk-micro .microsite.microsite-rm .carousel--rm .first-paragraph {
    margin-left: 400px;
  }
}
@media screen and (max-width: 1800px) {
  .risk-micro body .carousel--rm.carousel .indicatorWrap {
    top: 760px;
  }
}
@media screen and (max-width: 2100px) {
  .risk-micro .microsite-rm .carousel.carousel--rm .first-paragraph {
    margin-top: 10%;
  }
}
@media screen and (min-width: 2400px) {
  .risk-micro .microsite.microsite-rm .carousel--rm .slider-text-wrapper .first-paragraph {
    margin-left: 500px;
  }
}
@media screen and (min-width: 2800px) {
  .risk-micro .microsite.microsite-rm .carousel.carousel--rm .slider-text-wrapper .first-paragraph {
    margin-left: 800px;
  }
.risk-micro   .microsite-rm .carousel--rm .title-wrapper {
    margin-right: 0px;
  }
}
@media screen and (min-width: 3300px) {
  .risk-micro .microsite.microsite-rm .carousel.carousel--rm .slider-text-wrapper .first-paragraph {
    margin-left: 1000px;
  }
.risk-micro   .microsite-rm .carousel--rm .title-wrapper {
    margin-right: -100px;
  }
}
@media screen and (min-width: 1px) {
  .risk-micro .microsite-rm .carousel .indicatorWrap .carousel-indicators li.active {
    margin: 3px;
  }
}
/*GENERAL STYLING*/
.risk-micro .microsite-rm h5 {
  font-size: 22px;
  color: #6d7377;
  line-height: 1.7em;
}
.risk-micro .microsite.microsite-rm h6 {
  font-size: 1.2rem;
  margin-top: 50px;
  margin-bottom: 100px;
  font-weight: bold;
  line-height: 1.4em;
}
.risk-micro .microsite.microsite-rm h6,
.risk-micro .microsite-rm .page-section--intro p,
.risk-micro .microsite-rm .page-section--intro-second-section p,
.risk-micro .microsite-rm .page-section--intro-third-section .text-wrapper p,
.risk-micro .microsite-rm .page-section--intro-second-section .text-wrapper p,
.risk-micro .microsite-rm .page-section--program-features .header p,
.risk-micro .microsite-rm .page-section--program-attributes .attribute-container .text-tile h6,
.risk-micro .microsite-rm .page-section--functions .banner-text-container h4 {
  color: #193f6f;
}
.risk-micro .microsite.microsite-rm a:hover {
  cursor: pointer;
}
.risk-micro .microsite.microsite-rm h3 {
  margin-bottom: 0px;
  line-height: 1.7em;
  display: inline-block;
}
/*STICKY HEADERS*/
.risk-micro .microsite-new .page-section--tools .site-layout-title .graphic-wrap .graphic,
.risk-micro .microsite-new .page-section--programs .site-layout-title .graphic-wrap .graphic {
  width: 50px;
}
@media screen and (max-width: 992px) {
  .risk-micro .microsite-new.microsite .page-section--tools .site-layout-title .site-layout--graphic .graphic-wrap .graphic,
.risk-micro   .microsite-new.microsite .page-section--programs .site-layout-title .site-layout--graphic .graphic-wrap .graphic {
    width: 45px;
  }
}
/*PROGRAMS SECTION*/
.risk-micro .microsite-rm .page-section--programs {
  height: fit-content;
  margin-bottom: -30px;
}
.risk-micro .page-section--programs .page-section--intro {
  height: fit-content;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(207,224,227,1) 46%, rgba(177,205,210,1) 74%, rgba(110,162,172,1) 100%);
}
.risk-micro .microsite-rm .page-section--programs .page-section--intro {
  height: fit-content;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(207,224,227,1) 46%, rgba(177,205,210,1) 74%, rgba(110,162,172,1) 100%);
  padding-top: 150px;
  padding-bottom: 150px;
}
.risk-micro .microsite-rm .cliff-background {
  height: 120%;
  background-image: url(images/images/microsite/rm/rm-programs/cliff.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right 0px;
  display: block;
  padding: 0px;
  position: absolute;
  z-index: 10;
}
.risk-micro .microsite-rm .page-section--intro .mainpage-wrapper {
  z-index: 20;
}
.risk-micro .microsite-rm .page-section--programs .page-section--intro-second-section {
  height: fit-content;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(207,224,227,1) 46%, rgba(177,205,210,1) 74%, rgba(110,162,172,1) 100%);
  padding-left: 0px;
  padding-right: 0px;
  margin-top: -10px;
}
.risk-micro .microsite-rm .page-section--intro-second-section .umbrella-graphic img {
  margin-top: 430px;
  display: block;
}
.risk-micro .microsite-rm .page-section--intro-second-section .umbrella-graphic img.small-screen {
  display: none;
}
.risk-micro .microsite.microsite-rm .page-section--intro .text-wrapper {
  display: block;
  float: left;
  position: relatve;
  top: 0px;
  height: 100%;
  z-index: 20;
}
.risk-micro .microsite-rm .page-section--intro-second-section .text-wrapper {
  display: block;
  position: absolute;
  top: -60px;
  height: 100%;
  z-index: 100;
}
.risk-micro .microsite-rm h4 {
  font-size: 2.3em;
  margin-top: 100px;
  margin-bottom: 100px;
  font-weight: 500;
  line-height: 1.5em;
  color: #4b97c6;
}
.risk-micro .microsite-rm p {
  max-width: 700px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4em;
  color: #73787A;
}
.risk-micro .microsite-rm .page-section--intro p,
.risk-micro .microsite-rm .page-section--intro-second-section p,
.risk-micro .microsite-rm .page-section--intro-third-section .text-wrapper p {
  font-size: 1.4em;
  margin-top: 50px;
  margin-bottom: 100px;
  font-weight: 500;
  line-height: 1.4em;
}
.risk-micro .microsite-rm .page-section--intro-second-section .text-wrapper p {
  margin-top: 30px;
  margin-bottom: 100px;
  line-height: 1.4em;
  clear: both;
}
.risk-micro .microsite-rm .page-section--intro-second-section p.second-paragraph {
  float: right;
  margin-top: 700px;
}
.risk-micro .microsite-rm .page-section--intro-second-section p.first-paragraph {
  margin-left: 130px;
}
.risk-micro .microsite-rm .page-section--intro p.first-paragraph,
.risk-micro .microsite-rm .page-section--intro p.third-paragraph {
  margin-left: 5%;
}
.risk-micro .microsite-rm .page-section--intro .cloud-paragraph:nth-of-type(1) {
  padding-left: 0px;
  margin-left: -20px;
}
.risk-micro .microsite-rm .page-section--intro .cloud-paragraph:nth-of-type(1) p {
  max-width: 60%;
  padding-left: 0px;
}
.risk-micro .microsite-rm .page-section--intro .cloud-paragraph .cloud {
  padding-left: 0px;
}
.risk-micro .ocean {
  height: 500px;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: rgb(1,64,81);
  background: linear-gradient(0deg, rgba(1,64,81,1) 0%, rgba(2,66,85,1) 0%, rgba(2,66,85,1) 19%, rgba(2,66,85,0.8701855742296919) 42%, rgba(5,74,100,0.604079131652661) 60%, rgba(3,70,92,0.0802696078431373) 78%, rgba(2,66,85,0) 100%);
  overflow-x: hidden;
}
.risk-micro .wave {
  background: url(images/images/microsite/rm/rm-programs/wave.svg) repeat-x;
  position: absolute;
  top: 18px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.risk-micro .wave:nth-of-type(2) {
  top: 35px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
   50% {
    transform: translate3d(0,5px,0);
  }
}
.risk-micro .microsite-rm .page-section--intro-third-section {
  height: fit-content;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}
.risk-micro .microsite-rm .page-section--intro-third-section .ocean-gradient-overlay {
  float: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background: rgb(73,154,201);
  background: linear-gradient(0deg, rgba(73,154,201,0.9346113445378151) 0%, rgba(53,130,169,0.923406862745098) 32%, rgba(20,90,115,0.9514180672268907) 72%, rgba(0,65,81,0.99) 100%);
  padding-bottom: 100px;
  margin-top: -1px;
}
.risk-micro .microsite-rm .page-section--intro-third-section h4 {
  margin-top: 0px;
}
.risk-micro .microsite-rm .page-section--intro-third-section h4,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section p {
  color: #ffffff;
  float: none;
}
.risk-micro .microsite-rm .page-section--intro-third-section .submarine-graphic {
  position: absolute;
  top: 6%;
}
.risk-micro .submarine-graphic img {
  position: absolute;
  left: 100%;
  animation: horizontalMove 20s infinite;
  -webkit-animation: horizontalMove 20s infinite; /** Chrome & Safari **/
  -moz-animation: horizontalMove 20s infinite; /** Firefox **/
  -o-animation: horizontalMove 20s infinite;
}
@keyframes horizontalMove
{
  0% {
    left: 100%;
    animation-timing-function: ease-in;
  }
  50% {
    left: 40%;
    animation-timing-function: ease-in-out;
  }
   100% {
    left: -50%;
    animation-timing-function: ease-out;
  }
}
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .paragraph-container {
  float: none;
}
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .paragraph-container p {
  display: block;
}
.risk-micro .microsite-rm .page-section--intro-third-section .ocean-gradient-overlay-2 {
  background: rgb(69,149,194);
  background: linear-gradient(0deg, rgba(69,149,194,0.9206057422969187) 5%, rgba(73,154,201,0.9346113445378151) 100%);
  height: fit-content;
  padding-bottom: 200px;
}
.risk-micro .microsite-rm .radar-container {
  margin: 0px;
  padding: 0px;
  float: none;
  display: block;
  height: 800px;
}
.risk-micro .microsite-rm .radar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0px;
  padding: 0px;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  border: 10px solid #ffffff;
  box-shadow: 0 1px 0 #ffffff, inset 0 0 100px #ffffff;
  overflow: hidden;
}
.risk-micro ul.radar li:nth-child(1),
.risk-micro ul.radar li:nth-child(2),
.risk-micro ul.radar li:nth-child(3),
.risk-micro ul.radar li:nth-child(4) {
  list-style: none;
  position: absolute;
  top: 49%;
  height: 1px;
  width: 100%;
  background: #ffffff;
  border-radius: 0;
}
.risk-micro ul.radar li:nth-child(2)
{
  transform: rotate(90deg);
}
.risk-micro ul.radar li:nth-child(3)
{
  transform: rotate(45deg);
}
.risk-micro ul.radar li:nth-child(4)
{
  transform: rotate(-45deg);
}
.risk-micro ul.radar li:nth-child(5),
.risk-micro ul.radar li:nth-child(6),
.risk-micro ul.radar li:nth-child(7)
{
  list-style: none;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
}
.risk-micro ul.radar li:nth-child(5)
{
  width: 200px;
  height: 200px;
}
.risk-micro ul.radar li:nth-child(6)
{
  width: 400px;
  height: 400px;
}
.risk-micro ul.radar li:nth-child(7)
{
  width: 600px;
  height: 600px;
}
.risk-micro ul.radar li:nth-child(8)
{
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 50%;
  transform-origin: top left;
  background: linear-gradient(45deg, #ffffff 0%, rgba(255, 255, 255, 0) 60%);
  animation: animate 5s linear infinite;
}
@keyframes animate
{
  0%
  {
    transform: rotate(0deg);
  }
  100%
  {
    transform: rotate(360deg);
  }
}
.risk-micro ul.radar li:nth-child(9),
.risk-micro ul.radar li:nth-child(10),
.risk-micro ul.radar li:nth-child(11)
{
  list-style: none;
  background-color: #ffffff;
  position: absolute;
  top: 67%;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  line-height: 180px;
}
.risk-micro ul.radar li:nth-child(9) {
  top: 10%;
  left: 37%;
}
.risk-micro ul.radar li:nth-child(10) {
  top: 58%;
  left: 16%;
}
.risk-micro ul.radar li:nth-child(11) {
  top: 58%;
  left: 59%;
}
.risk-micro ul.radar li.circle {
  padding: 10px;
  text-align: center;
}
.risk-micro .microsite-rm ul.radar li.circle:before {
  content: '';
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border-radius:50%;
  background: #ffffff;
  transition: .5s;
  animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
  z-index: -1;
}
@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
    box-shadow: 0 0 15px #2b8ade;
    filter: blur(3px);
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
    box-shadow: 0 0 15px #2b8ade;
  }
}
.risk-micro .microsite-rm ul.radar li.circle p {
  animation: textGlow 4s infinite alternate;
}
@keyframes textGlow {
  from {
    text-shadow: none;
  }
   to {
    text-shadow: 0 0 15px #2b8ade;
  }
}
.risk-micro ul.radar li.circle span {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  position: relative;
}
.risk-micro .microsite.microsite-rm ul.radar li.circle span p {
  font-size: 19px;
  color: #000000;
  line-height: normal;
  margin: 0px;
  vertical-align: middle;
  padding-top: 0px;
}
@media screen and (min-width: 320px) and (max-width: 480px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--programs {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 330px) {
  .risk-micro .microsite.microsite-rm .page-section--intro .mainpage-wrapper .text-wrapper h4,
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .mainpage-wrapper .text-wrapper h4,
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .mainpage-wrapper .text-wrapper h4 {
    margin-bottom: 10px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro .text-wrapper :nth-child(5) {
   margin-top: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro .mainpage-wrapper p.second-paragraph {
    max-width: 50%;
  }
.risk-micro   .microsite.microsite-rm .page-section .page-section--intro-second-section .mainpage-wrapper .text-wrapper {
    top: 30px;
  }
}
@media screen and (max-width: 480px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .ocean .wave {
    height: 118px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 360px;
  }
}
@media screen and (max-width: 400px) {
  .risk-micro .microsite.microsite-rm .page-section--intro .mainpage-wrapper h4,
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .mainpage-wrapper h4,
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .mainpage-wrapper h4 {
    font-size: .9em;
    margin-bottom: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro .mainpage-wrapper p,
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .mainpage-wrapper p,
.risk-micro   .microsite.microsite-rm .page-section .page-section--intro-third-section .mainpage-wrapper p {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: .7em;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .mainpage-wrapper .text-wrapper {
    top: 60px;
  }
.risk-micro   .microsite-rm .page-section .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 300px;
  }
.risk-micro   .microsite.microsite-rm .ocean-gradient .ocean {
    height: 100px;
  }
.risk-micro   .microsite-rm .ocean .wave {
    height: 75px;
    animation: wave 21s cubic-bezier( 0.6, 0.45, 0.63, 0.53) infinite;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .mainpage-wrapper .paragraph-container {
    padding-bottom: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--programs .page-section--intro {
    padding-top: 30px;
  }
.risk-micro   .microsite.microsite-rm .page-section .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 300px;
  }
}
@media screen and (max-width: 640px) and  (min-aspect-ratio: 4/3), (max-width: 568px) and (min-aspect-ratio: 4/3) {
.risk-micro .microsite.microsite-rm .page-section .page-section--intro .mainpage-wrapper h4,
.risk-micro .microsite.microsite-rm .page-section--intro-second-section h4,
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-third-section .mainpage-wrapper h4 {
    margin-bottom: 20px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .mainpage-wrapper p {
    margin: 15px 0px 15px 0px;
  }
.risk-micro .microsite-rm .page-section .page-section--intro-second-section p.first-paragraph {
    margin-bottom: 20px;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-second-section .text-wrapper {
    top: 50px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 320px;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-third-section .text-wrapper {
    padding-top: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-third-section .paragraph-container {
    padding-bottom: 30px;
  }
}
@media  screen and (max-width: 580px) {
  .risk-micro div.microsite.microsite-rm .page-section--intro h4,
  .risk-micro div.microsite.microsite-rm .page-section--intro-second-section h4, 
  .risk-micro div.microsite.microsite-rm .page-section--intro-third-section h4 {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  .risk-micro div.microsite.microsite-rm div.page-section--intro p, 
  .risk-micro div.microsite.microsite-rm div.page-section--intro-second-section p, 
  .risk-micro div.microsite.microsite-rm div.page-section--intro-third-section .text-wrapper p {
        font-size: .8em;
  }
  .risk-micro .microsite.microsite-rm div.page-section--intro .text-wrapper :nth-child(5) {
    margin-top: 10px;
  }
  .risk-micro div.microsite.microsite-rm div.page-section--intro-second-section .text-wrapper {
    top: 50px;
  }
  
}
@media screen and (max-width: 600px) {
  .risk-micro .microsite.microsite-rm .page-section .page-section--intro-second-section .first-paragraph,
.risk-micro   .microsite.microsite-rm .page-section .page-section--intro-second-section .mainpage-wrapper .text-wrapper p.second-paragraph {
    margin-left: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--programs .page-section.page-section--intro .cliff-background {
    background-size: auto 68%;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--intro .fifth-paragraph {
    margin-left: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .radar-container {
    height: 400px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .radar {
    height: 350px;
    width: 350px;
    border: 6px solid #ffffff;
  }
.risk-micro   .microsite-rm ul.radar li:nth-child(1),
.risk-micro   .microsite-rm ul.radar li:nth-child(2),
.risk-micro   .microsite-rm ul.radar li:nth-child(3),
.risk-micro   .microsite-rm ul.radar li:nth-child(4) {
  width: 103%;
  }
.risk-micro   .microsite-rm ul.radar li:nth-child(9) {
   top: 4%;
    left: 35%;
  }
.risk-micro   .microsite-rm ul.radar li:nth-child(10) {
   top: 56%;
    left: 14%;
  }
.risk-micro   .microsite-rm ul.radar li:nth-child(11) {
   top: 56%;
    left: 56%;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section ul.radar li:nth-child(9),
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section ul.radar li:nth-child(10),
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section ul.radar li:nth-child(11) {
   height: 110px;
    width: 110px;
    line-height: 110px;
  }
.risk-micro   .microsite-rm .page-section--intro-third-section .radar-container ul.radar li.circle span p {
    font-size: 10px;
    padding-bottom: 5px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .ocean-gradient-overlay {
    padding-bottom: 10px;
  }
.risk-micro   .microsite.microsite-rm p {
    font-size: .8em;
  }
}
@media screen and (max-width: 640px) and  (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm ul.radar li.circle span p {
    font-size: 14px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .ocean-gradient-overlay {
    padding-bottom: 50px;
  }
.risk-micro .microsite.microsite-rm .page-section--program-features .header h4 {
    margin-bottom: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--program-features .list-container ul {
    padding: 30px 0px;
  }
.risk-micro  .microsite.microsite-rm .page-section .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 260px;
  }
}
@media screen and (max-width: 640px) {
.risk-micro .microsite.microsite-rm .page-section--intro p,
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p,
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-third-section p {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 1.1em;
  }
.risk-micro .microsite.microsite-rm .page-section--program-features .header h4 {
    font-size: 1.6em;
  }
.risk-micro .microsite.microsite-rm .page-section--intro h4,
.risk-micro .microsite.microsite-rm .page-section--intro-second-section h4,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section h4 {
    margin-bottom: 40px;
  }
.risk-micro   .microsite.microsite-rm .wave {
    width: 2400px;
  }
.risk-micro   .microsite.microsite-rm .wave:nth-of-type(2) {
   top: unset;
    bottom: 0px;
  }
.risk-micro   .microsite.microsite-rm .wave:nth-of-type(1) {
   top: unset;
    bottom: 5px;
  }
.risk-micro   .microsite.microsite-rm .ocean {
    height: 200px;
  }
.risk-micro   .ocean .wave {
    height: 168px;
  }
.risk-micro   .microsite-rm .page-section.page-section--programs .page-section--intro-second-section {
    padding-top: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-second-section .text-wrapper {
    top: 90px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .paragraph-container {
    padding-bottom: 60px;
  }
.risk-micro   .microsite.microsite-rm .page-section--intro-third-section .submarine-graphic img {
    animation: horizontalMove 20s infinite;
  }
  .risk-micro div.microsite.microsite-rm .page-section--intro-second-section .text-wrapper {
    top: 100px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section.page-section--intro div.cliff-background {
    height: 100%;
    background-size: auto 65%;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section h4,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section h4 {
    margin-bottom: 60px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro h4 {
    margin-bottom: 40px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .text-wrapper :nth-child(5) {
   margin-top: 40px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .fifth-paragraph {
    margin-left: 20%;
    float: none;
    text-align: left;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .paragraph-container p {
    font-size: 1.3em;
    margin-bottom: 50px;
    margin-top: 50px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p {
    font-size: 1.3em;
    margin-bottom: 30px;
    margin-top: 30px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p.third-paragraph {
    margin-bottom: 50px;
  }
.risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section--intro {
    padding-bottom: 320px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .text-wrapper {
    top: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .submarine-graphic {
    top: 3%;
  }
.risk-micro .microsite.microsite-rm .radar-container {
    height: 600px;
  }
.risk-micro .microsite.microsite-rm .radar {
    width: 600px;
    height: 600px;
  }
.risk-micro .microsite-rm ul.radar li.circle {
    padding: 5px;
  }
.risk-micro .microsite-rm ul.radar li:nth-child(9),
.risk-micro .microsite-rm ul.radar li:nth-child(10),
.risk-micro .microsite-rm ul.radar li:nth-child(11) {
   height: 150px;
    width: 150px;
    line-height: 150px;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section.page-section--intro div.cliff-background {
    height: 100%;
    background-size: auto 65%;
  }
.risk-micro .microsite.microsite-rm .page-section--intro h4,
.risk-micro .microsite-rm .page-section .page-section--intro-second-section h4,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section h4 {
    font-size: 1.5em;
    margin-bottom: 40px;
  }
  .risk-micro div.microsite.microsite-rm .page-section--intro .fifth-paragraph {
float: none;
text-align: left;
margin-left: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .text-wrapper :nth-child(5) {
   margin-top: 40px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .fifth-paragraph {
    margin-left: 20%;
    float: none;
    text-align: left;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p,
.risk-micro .microsite.microsite-rm .page-section--intro-third-section p {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .risk-micro div.microsite.microsite-rm .page-section--intro p, 
  .risk-micro div.microsite.microsite-rm .page-section--intro-second-section p, 
  .risk-micro div.microsite.microsite-rm .page-section--intro-third-section .text-wrapper p {
    font-size: .9em;
        margin-bottom: 10px;
    margin-top: 10px;
  }
  .risk-micro .microsite-rm div.page-section .page-section--intro-second-section p.first-paragraph {
    margin-bottom: 30px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p.third-paragraph {
    margin-bottom: 50px;
  }
.risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section--intro {
    padding-bottom: 320px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .text-wrapper {
    top: 0px;
  }
.risk-micro div.microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 300px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .text-wrapper {
    padding-top: 40px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .submarine-graphic {
    top: 16%;
  }
.risk-micro .microsite.microsite-rm .radar-container {
    height: 600px;
  }
.risk-micro .microsite.microsite-rm .radar {
    width: 600px;
    height: 600px;
  }
.risk-micro .microsite-rm ul.radar li.circle {
    padding: 5px;
  }
.risk-micro .microsite-rm ul.radar li:nth-child(9),
.risk-micro .microsite-rm ul.radar li:nth-child(10),
.risk-micro .microsite-rm ul.radar li:nth-child(11) {
   height: 150px;
    width: 150px;
    line-height: 150px;
  }
.risk-micro .microsite.microsite-rm ul.radar li.circle span p {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
.risk-micro div.microsite.microsite-rm h4 {
  font-size: 1.7em;
}
.risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section.page-section--intro .cliff-background {
  height: 78%;
}
.risk-micro .microsite-rm .page-section--intro h4:nth-of-type(1) {
  margin-top: 50px;
}
.risk-micro .microsite.microsite-rm .page-section--intro .fifth-paragraph {
    float: right;
    text-align: right;
  }
.risk-micro .microsite-rm .page-section .page-section--intro-second-section p.first-paragraph {
    margin-top: 0px;
    margin-bottom: 60px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .text-wrapper {
    padding-top: 100px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .cliff-graphic img.small-screen {
    display: block;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .cliff-graphic img.large-screen {
    display: none;
  }
.risk-micro .microsite.microsite-rm .wave:nth-of-type(1) {
   bottom: 20px;
  }
.risk-micro .microsite.microsite-rm .wave:nth-of-type(2) {
   bottom: 15px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p, 
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p, 
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .text-wrapper p {
      font-size: 1.3em;
}
}
@media screen and (max-width: 1000px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--programs .page-section .cliff-background {
    height: 84%;
  }
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite-rm .page-section--programs .cliff-background {
    height: 90%;
    background-image: url(images/images/microsite/rm/rm-programs/cliff-small.png);
    background-position: right 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img.small-screen {
    display: block;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img.large-screen {
    display: none;
  }
.risk-micro .microsite.microsite-rm .page-section--programs .page-section.page-section--intro {
    padding-bottom: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .submarine-graphic img {
    animation: horizontalMove 30s infinite;
  }
.risk-micro .microsite-rm .page-section--programs .page-section--intro-second-section {
    padding-top: 100px;
  }
.risk-micro .microsite-rm .cloud-paragraph {
    padding: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p.first-paragraph,
.risk-micro .microsite.microsite-rm .page-section--intro p.cloud-paragraph,
.risk-micro .microsite.microsite-rm .page-section--intro p.third-paragraph {
    margin-left: 0%;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-first-section .cloud-paragraph .cloud {
    display: none;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .cloud-paragraph {
    margin-left: 0%;
    padding-left: 15px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro .cloud-paragraph:nth-of-type(1) p {
    max-width: none;
  }
.risk-micro .microsite-rm .page-section--intro-first-section .cloud-paragraph .cloud {
    margin-right: 0px;
    margin-bottom: 0px;
  }
.risk-micro .microsite-rm .page-section--intro .cloud-paragraph .cloud {
    display: none;
  }
.risk-micro .microsite-rm .cloud img {
    display: none;
  }
.risk-micro .microsite-rm .page-section--intro .cloud-paragraph.two {
    margin-left: 0%;
    padding-left: 0px;
  }
.risk-micro .microsite-rm .page-section--intro-second-section .cloud-paragraph p.fourth-paragraph {
    padding-left: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro p.first-paragraph,
.risk-micro .microsite.microsite-rm .page-section--intro p.third-paragraph {
    margin-left: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .umbrella-graphic img {
    margin-top: 550px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .text-wrapper p {
    margin-top: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-second-section .text-wrapper p.second-paragraph {
    margin-top: 0px;
    float: none;
    display: block;
  }
.risk-micro .microsite-rm .wave:nth-of-type(1) {
   top: unset;
    bottom: 35px;
  }
.risk-micro .microsite-rm .wave:nth-of-type(2) {
   top: unset;
    bottom: 30px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-third-section .submarine-graphic {
    top: 16%;
  }
.risk-micro .microsite.microsite-rm .ocean {
    height: 300px;
  }
}
@media screen and (max-width: 1400px) {
  .risk-micro .microsite.microsite-rm .page-section--programs .page-section .cliff-background {
    height: 90%;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-second-section h4 {
    margin-top: 0px;
  }
.risk-micro .microsite-rm .ocean {
    height: 400px;
  }
.risk-micro .microsite.microsite-rm h4 {
    font-size: 2em;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section .text-wrapper {
    top: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section .page-section--intro-second-section p.second-paragraph {
    margin-top: 200px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p.first-paragraph {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1500px) {
  .risk-micro .microsite.microsite-rm .page-section--programs .cliff-background {
    height: 95%;
    background-position: right 0px;
  }

}
@media screen and (max-width: 1600px) {
  .risk-micro .microsite-rm .page-section--intro-first-section .cloud-paragraph {
    margin-left: 0px;
  }
.risk-micro .microsite-rm .ocean {
    height: 400px;
  }
}
@media screen and (max-width: 1650px) {
  .risk-micro .microsite.microsite-rm .page-section--intro .cloud-paragraph:nth-of-type(1) p {
    max-width: 50%;
  }
}
@media screen and (max-width: 1800px) {
.risk-micro .microsite-rm .page-section--intro-first-section .cloud-paragraph {
    margin-left: 0px;
  }
.risk-micro .microsite-rm .ocean {
    height: 400px;
  }
.risk-micro .microsite.microsite-rm div.page-section--intro-second-section p.second-paragraph {
    margin-top: 300px;
  }
.risk-micro .microsite.microsite-rm .page-section--programs .cliff-background {
    height: 105%;
    background-position: right -20px;
  }
}
@media screen and (max-width: 2300px) {
  .risk-micro .microsite-rm .page-section--programs .cliff-background {
    height: 110%;
    background-position: right -100px;
  }
.risk-micro .microsite.microsite-rm .page-section--intro-second-section p.second-paragraph {
    margin-bottom: 0px;
    margin-top: 500px;
  }
}
.risk-micro .microsite-rm .white-banner {
  width: 100%;
  padding: 50px 0px;
  vertical-align: center;
  z-index: 20;
  background-color: #ffffff;
  float: none;
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
}
.risk-micro .microsite-rm .page-section--program-features,
.risk-micro .microsite-rm .page-section--program-attributes {
  padding: 0px;
  height: fit-content;
}
.risk-micro .microsite-rm .page-section--program-features .header h4 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.risk-micro .microsite-rm .page-section--program-features .header .mainpage-wrapper {
  text-align: center;
}
.risk-micro .microsite-rm .page-section--program-features .header p {
  float: none;
}
.risk-micro .microsite-rm .inset {
  background-color: #73787A;
  height: fit-content;
  padding: 20px 30px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.risk-micro .microsite-rm .inset p {
  color: #ffffff;
}
.risk-micro .microsite-rm .page-section--program-features .inset {
  background-color: #73787A;
  height: fit-content;
  padding: 20px 30px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.risk-micro .microsite-rm .page-section--program-features .inset p {
  color: #ffffff;
}
.risk-micro .microsite-rm .page-section--program-features .list-container {
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  list-style: none;
  float: none;
  right: 0px;
  padding-right: 0px;
  margin-right: 0px;
  z-index: 12;
}
.risk-micro .microsite-rm .page-section--program-features .list-container ul {
  padding: 50px 0px 80px 0px;
  list-style: none;
  float: right;
}
.risk-micro .microsite-rm .page-section--program-features .list-container li {
  border-bottom: 6px solid #1e90bf;
  width: 100%;
  color:  #73787A;
  font-weight: bold;
  list-style: none;
  padding: 35px 0px 5px 0px;
  display: flex;
  align-items: flex-end;
  height: 90px;
}
.risk-micro .microsite-rm .page-section--program-features .list-container li p {
  margin-bottom: 0px;
  padding-left: 40px;
  margin-top: 0px;
  padding-top: 0px;
}
.risk-micro .microsite-rm .page-section--program-features .list-container li::before {
  content: "";
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-roles-blue.png);
  height: 60px;
  width: 60px;
  display: inline-block;
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: absolute;
  left: -30px;
  bottom: -6px;
}
.risk-micro .page-section--program-features .list-container li:nth-child(2)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-evolve-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(3)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-empower-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(4)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-engage-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(5)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-metrics-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(6)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-support-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(7)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-leadership-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(8)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-align-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(9)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-sustain-blue.png);
}
.risk-micro .page-section--program-features .list-container li:nth-child(10)::before {
  background-image: url(images/images/microsite/rm/rm-programs/icons/icon-integrate-blue.png);
}


@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .white-banner {
    padding: 50px 20px;
  }
.risk-micro .microsite.microsite-rm .page-section--program-features .list-container li p {
    font-size: .8rem;
  }
  .risk-micro .microsite.microsite-rm .page-section--program-features .list-container li::before {
    height: 50px;
    width: 50px;
    background-size: 50px 50px;
    left: -25px;
  }
  .risk-micro .microsite.microsite-rm .page-section--program-features .list-container ul {
    padding-top: 10px;
  }
}
.risk-micro .microsite-rm .page-section--program-attributes .attribute-container {
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  float: none;
  padding: 0px;
  margin: 0px;
}
.risk-micro .page-section--program-attributes .attribute-container .mainpage-wrapper {
  max-width: 1000px;
  width: 1000px;
}
.risk-micro .page-section--program-attributes .attribute-container .text-tile {
  padding: 40px 80px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  line-height: 370px;
  height: 450px;
  width: 50%;
  z-index: 20;
  position: relative;
}
.risk-micro .page-section--program-attributes .attribute-container .text-tile .text-wrapper {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
}
.risk-micro .microsite-rm .page-section--program-attributes .attribute-container .text-tile h6 {
  margin-bottom: 10px;
  margin-top: 0px;
  vertical-align: middle;
  align-items: center;
}
.risk-micro .page-section--program-attributes .attribute-container .text-tile p {
  margin-bottom: 0px;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile {
  display: inline-block;
  position: relative;
  height: auto;
  width: 50%;
  vertical-align: top;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile.small {
  display: none;
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  vertical-align: top;
}
.risk-micro .page-section--program-attributes .attribute-container .text-tile.left {
  margin-right: -5px;
}
.risk-micro .page-section--program-attributes .attribute-container .text-tile.right {
  margin-left: -5px;
  box-shadow: outset 0.2em 0.2em .2em 0 rgba(255,255,255,0.5), outset -0.2em -0.2em .2em 0 rgba(0,0,0,0.5);
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile.large .graphic {
  background: url(images/images/microsite/rm/rm-programs/tiles/simple.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  margin: 0px;
  padding: 0px;
  float: none;
}
.risk-micro .microsite-rm .page-section--program-attributes .attribute-container .img-tile.small img {
  display: none;
}
.risk-micro .microsite-rm .page-section--program-attributes .attribute-container .img-tile.small {
  display: none;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.start-simple.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/simple.png);
  background-size: cover;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.agility.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/agility.png);
  background-size: cover;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.sustainability.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/sustainability.png);
  background-size: cover;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.support.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/support.png);
  background-size: cover;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.inclusive.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/inclusive.png);
  background-size: cover;
}
.risk-micro .page-section--program-attributes .attribute-container .img-tile .graphic.efficiency.large {
  background: url(images/images/microsite/rm/rm-programs/tiles/efficiency.png);
  background-size: cover;
}
@media screen and (max-width: 400px) {
  .risk-micro .microsite-rm.microsite .page-section--program-attributes .attribute-container .text-tile h6 {
    font-size: 1.2em;
  }
.risk-micro   .page-section--program-attributes .attribute-container .text-tile p {
    font-size: .8em;
  }
.risk-micro   .microsite.microsite-rm .page-section--program-attributes .attribute-container {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 640px) {
  .risk-micro .microsite-rm .page-section .page-section--program-attributes .attribute-container .text-tile {
    padding: 50px 30px;
  }
.risk-micro   .microsite-rm.microsite .page-section--program-attributes .attribute-container .text-tile h6 {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section--program-attributes .attribute-container .text-tile {
    width: 100%;
    height: fit-content;
    padding: 80px 80px;
    line-height: normal;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .text-tile .text-wrapper {
    max-width: 500px;
    display: block;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .text-tile.left .text-wrapper {
    max-width: 500px;
    float: right;
    display: block;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .text-tile.left {
    text-align: right;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .img-tile.large,
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .img-tile.large .graphic.large {
    display: none;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .img-tile.small {
    display: block;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .img-tile img.small {
    height: auto;
    margin: 0px;
    padding: 0px;
    float: none;
    display: block;
  }
}
@media screen and (max-width: 1000px) {
  .risk-micro .microsite-rm .page-section--program-attributes .attribute-container .mainpage-wrapper {
    width: 100%;
    max-width: 100%;
  }
.risk-micro   .microsite-rm .page-section--program-attributes .attribute-container .text-tile {
    padding: 40px 50px;
  }
}
/*BENEFITS SECTION*/
.risk-micro .microsite.microsite-rm .gradient {
  width: 100%;
  height: 100%;
  text-align: right;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 28%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
  position: absolute;
  top: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
}
.risk-micro .microsite.microsite-rm .page-section--benefits .parallax p {
  max-width: none;
  font-size: 20px;
  line-height: 1.6em;
  font-weight: normal;
  float: none;
}
.risk-micro .microsite.microsite-rm .page-section--benefits h3 {
  color: #555B8A;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5em;
  float: none;
  margin-bottom: 80px;
}
.risk-micro .microsite.microsite-rm .page-section--benefits.page-section .page-section {
  padding: unset;
}
.risk-micro .microsite.microsite-rm .page-section--benefits .text-container {
  display: block;
  float: none;
}
.risk-micro .microsite.microsite-rm .page-section--benefits .parallax.evolve .text-container {
  display: block;
  text-align: right;
  clear: both;
  float: right;
}
.risk-micro .microsite-rm .page-section--benefits .parallax.evolve .text-container p {
  padding-bottom: 50px;
}
.risk-micro .microsite-rm .page-section--benefits .parallax.evolve .text-container:nth-child(2) {
  padding-bottom: 300px;
}
.risk-micro .microsite-rm .parallax {
  background-image: url(images/images/microsite/rm/rm-benefits/parallax-dynamic.png);
  height: fit-content;
  background-attachment: fixed;
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: 1200px auto;
  overflow-x: hidden;
  padding: 0px;
}
.risk-micro .microsite-rm .parallax.page-section .text-wrapper {
  height: fit-content;
  padding-top: 25%;
  padding-bottom: 35%;
}
.risk-micro .microsite-rm .parallax .section-one {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.7021183473389356) 0%, rgba(255,255,255,0.7497373949579832) 42%, rgba(255,255,255,0.562062324929972) 65%, rgba(255,255,255,0.3127626050420168) 100%);
  height: fit-content;
}
.risk-micro .microsite-rm .parallax .section-two {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 28%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
  height: fit-content;
}
.risk-micro .microsite-rm .parallax.dynamic {
  background-image: url(images/images/microsite/rm/rm-benefits/parallax-dynamic.png);
  background-position: 95% 90%;
  background-size: 1000px auto;
}
.risk-micro .microsite-rm .parallax.evolve {
  background-image: url(images/images/microsite/rm/rm-benefits/parallax-evolves.png);
  background-position: left 20%;
  background-size: 1200px auto;
}
@media screen and (max-width: 580px) and (min-aspect-ratio: 4/3),
(max-width: 640px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .parallax.page-section .text-wrapper {
    padding: 7% 0;
  }
}
@media screen and (max-width: 640px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--benefits h3 {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
.risk-micro   .microsite-rm .page-section.page-section--benefits .parallax .text-container p {
    font-size: 1em;
  }
.risk-micro   .microsite.microsite-rm .page-section--benefits .parallax.evolve .text-container p {
    padding-bottom: 10px;
  }
.risk-micro   .microsite.microsite-rm .parallax.page-section .text-wrapper {
       max-width: 80%;
     }
.risk-micro   .microsite.microsite-rm .parallax.page-section .section-two .text-wrapper {
    float: right;
    clear: both;
  }
.risk-micro   .microsite-rm.microsite .page-section--benefits .parallax.evolve .text-container:nth-child(2) {
  padding-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section--benefits h3 {
    font-size: 1.7em;
  }
.risk-micro   .microsite-rm .page-section--benefits .parallax .text-container p {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 992px) {
  .risk-micro .microsite.microsite-rm .page-section--benefits .parallax.dynamic {
    background-size: 400px auto;
    background-position: 60% 90%;
  }
.risk-micro   .microsite-rm .page-section--benefits .parallax.evolve {
    background-size: 600px auto;
    background-position: left 17%;
  }
.risk-micro   .microsite.microsite-rm .page-section--benefits .parallax.page-section .section-two {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.654499299719888) 0%, rgba(255,255,255,0.8421743697478992) 67%, rgba(255,255,255,1) 85%);
  }
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite-rm .page-section--benefits .parallax.dynamic {
    background-size: 600px auto;
    background-position: 95% 95%;
  }
.risk-micro   .microsite.microsite-rm .parallax.page-section .text-wrapper {
    padding-top: 15%;
    padding-bottom: 15%;
  }
.risk-micro   .microsite-rm .page-section--benefits .parallax.evolve {
    background-size: 800px auto;
    background-position: left 32%;
  }
.risk-micro   .microsite.microsite-rm .parallax.page-section .section-one {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8421743697478992) 35%, rgba(255,255,255,0.654499299719888) 100%);
  }
.risk-micro   .microsite.microsite-rm .parallax.page-section .section-two {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8421743697478992) 29%, rgba(255,255,255,0.654499299719888) 100%);
  }
.risk-micro   .microsite-rm .page-section--benefits .parallax.evolve .text-container:nth-child(2) {
  padding-bottom: 100px;
  }
}
.risk-micro   /*IMPORTAL SECTION*/
.risk-micro .microsite.microsite-rm .page-section--rmportal {
  padding-top: 70px;
  height: fit-content;
  height: -moz-fit-content;
}
.risk-micro .microsite.microsite-rm .page-section--rmportal .text-wrapper {
  float: right;
  padding-right: 40px;
  padding-top: 80px;
}
.risk-micro .microsite.microsite-rm .page-section--rmportal .importal-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
}
.risk-micro .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper {
  display: block;
  text-align: right;
  margin-bottom: 100px;
}
.risk-micro .microsite-rm .page-section--rmportal .mainpage-wrapper h4 {
  margin-top: 10px;
  display: inline-block;
  padding-right: 0px;
  float: right;
  color: #6D7377;
  font-size: 28px;
 }
.risk-micro .microsite-rm .page-section--rmportal .mainpage-wrapper h4 .smaller {
  font-size: .8em;
  color: #6D7377;
  font-weight: normal;
  line-height: 33px;
  padding-top: 10px;
}
.risk-micro .microsite.microsite-rm .page-section--rmportal .imp-devices {
  position: relative;
  left: 0px;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-top: 40px;
  float: none;
  padding: 0px;
}
.risk-micro .microsite.microsite-rm .page-section--rmportal .imp-devices img {
  margin: 0px auto;
}
@media screen and (max-width: 400px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--rmportal .text-wrapper {
    padding-right: 15px;
  }
.risk-micro   .microsite.microsite-rm .page-section .page-section--rmportal {
    padding-top: 50px;
  }
}
@media screen and (max-width: 480px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--rmportal .mainpage-wrapper .text-wrapper {
    padding-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .risk-micro .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper {
    padding-right: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper h4 {
    font-size: 1.1em;
  }
.risk-micro   .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper h4 .smaller {
    line-height: 20px;
  }
}
@media screen and (max-width: 648px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--rmportal {
    padding-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper h4 {
    font-size: 22px;
  }
.risk-micro   .microsite-rm .page-section--rmportal .mainpage-wrapper h4 .smaller {
    line-height: 27px;
  }
.risk-micro   .microsite.microsite-rm .page-section .page-section--rmportal .text-wrapper {
    padding-top: 0px;
  }
}
/*ORCHESTRATION SECTION*/
.risk-micro .microsite.microsite-rm .page-section--orchestration {
  height: fit-content;
  height: -moz-fit-content;
  clear: both;
  content:'';
  display: block;
  padding: 0px 0px 10px 0px;
}
.risk-micro .microsite.microsite-rm .page-section--orchestration .orchestration-wrapper {
  text-align: right;
  margin-left: 7%;
  margin-bottom: 20px;
  display: block;
  clear: right;
  position: relative;
}
.risk-micro .microsite.microsite-rm .page-section--orchestration .text-wrapper {
  float: right;
  display: block;
  position: relative;
}
.risk-micro .microsite.microsite-rm .page-section--orchestration .banner {
  background-color: rgba(88, 173, 173, 0.91);
  width: 100%;
  height: fit-content;
  padding: 100px 0px;
  margin-bottom: 100px;
  margin-top: 0px;
}
.risk-micro .microsite.microsite-rm .page-section--orchestration .banner:before {
  height: 0px;
}
.risk-micro .microsite-rm .page-section--orchestration .banner-text p {
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  max-width: none;
}
.risk-micro .microsite.microsite-rm .banner-text p {
  color: #ffffff;
}
.risk-micro .microsite-rm .page-section--tools .photos {
  height: fit-content;
  padding-bottom: 100px;
}
.risk-micro .microsite-rm .page-section--tools .photos ul {
  list-style: none;
  display: block;
  clear: both;
}
.risk-micro .microsite-rm .page-section--tools .photos li {
  list-style: none;
  padding: 40px 0px;
  display: block;
  max-height: 500px;
  vertical-align: top;
}
.risk-micro .microsite-rm .page-section--tools .photo-container {
  display: inline-block;
  position: relative;
  height: auto;
  width: 50%;
}
.risk-micro .microsite-rm .page-section--tools .photo-container .photo {
  background: url(images/images/microsite/rm/rm-tools/orchestration-first.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 350px;
  margin: 0px;
  padding: 0px;
  float: none;
}
.risk-micro .microsite-rm .page-section--tools .photo-container .first.photo {
  background: url(images/images/microsite/rm/rm-tools/orchestration-first.png);
 }
.risk-micro .microsite-rm .page-section--tools .photo-container .second.photo {
  background: url(images/images/microsite/rm/rm-tools/orchestration-second.png);
}
.risk-micro .microsite-rm .page-section--tools .photo-container .third.photo {
  background: url(images/images/microsite/rm/rm-tools/orchestration-third.png);
}
.risk-micro .microsite-rm .page-section--tools .text-container {
   padding: 60px 30px;
   float: none;
   display: inline-block;
   width: 40%;
   vertical-align: top;
   max-height: 350px;
   line-height: 230px;
 }
.risk-micro .microsite-rm .page-section--tools .text-container.mobile {
  display: none;
}
.risk-micro .microsite-rm .page-section--tools .third .text-container {
  padding: 30px 30px;
  max-width: 49%;
  width: auto;
}
.risk-micro .microsite-rm .page-section--tools ul li p {
  font-weight: normal;
  padding-top: 0px;
  vertical-align: middle;
  display: inline-block;
}
.risk-micro .microsite-rm .page-section--tools ul li.first,
.risk-micro .microsite-rm .page-section--tools ul li.third {
  margin-left: 0px;
  text-align: left;
  float: right;
  clear: both;
}
.risk-micro .microsite-rm .page-section--tools ul li.second {
  text-align: right;
}
.risk-micro .microsite-rm .page-section--tools ul li .photo-container {
  margin: 0px;
  padding: 0px;
}
@media screen and (max-width: 330px) {
  .risk-micro .microsite.microsite-rm .page-section .page-section--orchestration .mainpage-wrapper .banner-text p {
    padding: 0px 5px;
  }
}
@media screen and (max-width: 640px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--rmportal .text-wrapper {
    padding-top: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .page-section--orchestration .photos ul .text-container {
    width: 100%;
    height: fit-content;
    max-height: none;
    line-height: normal;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools ul li.second {
    text-align: left;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .third .text-container {
    max-width: 100%;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .first .text-container,
.risk-micro   .microsite.microsite-rm .page-section--tools .second .text-container,
.risk-micro   .microsite.microsite-rm .page-section--tools .third .text-container {
    padding: 5px 5px 5px 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .text-container.mobile {
    display: inline-block;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .text-container.desktop {
    display: none;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .photos ul li.second p {
    padding-left: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools ul .photo-container .first.photo,
.risk-micro   .microsite.microsite-rm .page-section--tools ul .photo-container .second.photo,
.risk-micro   .microsite.microsite-rm .page-section--tools ul .photo-container .third.photo {
    height: 300px;
    width: 100%;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .photo-container {
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--rmportal .mainpage-wrapper h4 {
    margin-bottom: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--tools .page-section--orchestration .banner-text p {
    font-size: 1.4em;
  }
.risk-micro   .microsite.microsite-rm .page-section--orchestration .banner {
    padding: 50px 20px;
    margin-bottom: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--tools .photos ul li {
    padding: 0px;
  }
.risk-micro   .microsite-rm .page-section--tools .photos ul {
    margin-left: 0px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px), (min-device-width: 600px) and
(max-device-width: 960px) and (orientation : landscape) {
  .risk-micro .microsite-rm .page-section--tools ul li .text-container p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section .page-section--orchestration .banner-text p {
    font-size: 1.8em;
    padding: 0px 40px;
  }
.risk-micro   .microsite-rm .page-section--tools ul .text-container {
    max-height: 200px;
    height: 200px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 5px;
    line-height: 170px;
  }
.risk-micro   .microsite-rm .page-section--tools ul .third .text-container {
    line-height: 160px;
    padding-top: 0px;
  }
.risk-micro   .microsite-rm .page-section--tools ul .second .text-container {
    padding-left: 0px;
    padding-right: 20px;
  }
.risk-micro   .microsite-rm .page-section--tools ul li .text-container p {
    font-size: 14px;
  }
.risk-micro   .microsite-rm .page-section--tools .photos ul li.first p,
.risk-micro   .microsite-rm .page-section--tools .photos  ul li.third p {
    padding-right: 10px;
  }
.risk-micro   .microsite-rm .page-section--tools .photos  ul li.second p {
    padding-left: 10px;
  }
.risk-micro   .microsite-rm .page-section--tools ul .photo-container .first.photo {
    background: url(images/images/microsite/rm/rm-tools/orchestration-first.png);
    background-size: cover;
    height: 200px;
    width: auto;
  }
.risk-micro   .microsite-rm .page-section--tools ul .photo-container .second.photo {
    background: url(images/images/microsite/rm/rm-tools/orchestration-second.png);
    background-size: cover;
    height: 200px;
    width: auto;
  }
.risk-micro   .microsite-rm .page-section--tools ul .photo-container .third.photo {
    background: url(images/images/microsite/rm/rm-tools/orchestration-third.png);
    background-size: cover;
    height: 200px;
    width: auto;
  }
.risk-micro   .microsite-rm .page-section--tools ul .photo-container .photo {
    height: 200px;
    background-size: 366px, 250px;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite-rm .page-section--tools .photos ul li.first,
.risk-micro   .microsite-rm .page-section--tools .photos  ul li.third {
    float: left;
  }
.risk-micro   .microsite-rm .page-section--tools ul {
    padding-left: 0px;
  }
.risk-micro   .microsite-rm .page-section--tools .photos .text-container {
    width: 48%;
  }
}
/*FUNCTIONS SECTION*/
.risk-micro .microsite.microsite-rm .page-section.page-section--functions {
  height: fit-content;
  padding-top: 0px;
}
.risk-micro .microsite-rm .page-section--functions .banner {
  width: 100%;
  padding: 100px 0px;
  background-color: #ffffff;
  height: fit-content;
  z-index: 20;
  displaY: block;
  float: none;
}
.risk-micro .microsite-rm .page-section--functions .banner:before {
  background-color: #ffffff;
}
.risk-micro .microsite-rm .page-section--functions .banner-text-container {
  vertical-align: top;
  float: none;
}
.risk-micro .microsite-rm .page-section--functions .banner-text-container h4 {
  margin: 0px;
}
.risk-micro .microsite-rm .page-section--functions .cloud-devices {
  padding: 0px;
  margin: 0px;
  z-index: 30;
  position: absolute;
  top: 60px;
  right: 0px;
}
.risk-micro .microsite-rm .page-section--functions .inset {
  z-index: 12;
  background-color: #58ADAD;
  text-align: right;
  padding: 90px 0px;
  height: fit-content;
  position: relative;
  display: block;
}
.risk-micro .microsite-rm .page-section--functions .inset .inset-text-container {
  float: none;
}
.risk-micro .microsite-rm .page-section--functions .inset p {
  padding-top: 0px;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
}
.risk-micro .microsite-rm .page-section--functions .half-banner {
  height: fit-content;
  background-color: #ffffff;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 0px;
  padding-right: 50px;
  -webkit-box-shadow: 1px 3px 15px 3px rgba(0,0,0,0.35);
  box-shadow: 1px 3px 15px 3px rgba(0,0,0,0.25);
  position: relative;
  margin-bottom: 60px;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.right {
  float: right;
  padding-left: 50px;
  padding-right: 0px;
}
.risk-micro .microsite-rm .page-section--functions .half-banner .vector-graphic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.risk-micro .microsite-rm .page-section--functions .half-banner .vector-graphic.mobile {
  display: none;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.right .vector-graphic {
  right: 0px;
}
.risk-micro .microsite-rm .page-section--functions .half-banner .vector-graphic img {
  float: right;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.right .vector-graphic img {
  float: left;
}
.risk-micro .microsite-rm .page-section--functions .half-banner .text-wrapper {
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
  max-width: 50%;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.left .text-wrapper {
  float: right;
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
  max-width: 40%;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.right .text-wrapper {
  text-align: left;
}
.risk-micro .microsite-rm .page-section--functions .half-banner h6 {
  display: inline-block;
  color: #58ADAD;
  margin: 0px;
  margin-top: 20px;
  text-align: left;
  max-width: 90%;
}
.risk-micro .microsite-rm .page-section--functions .manage-change.half-banner h6 {
  width: 75%;
}
.risk-micro .microsite-rm .page-section--functions .half-banner ul {
  max-width: 75%;
  list-style: unset;
  margin: 30px 0px;
  text-align: left;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.left ul {
  float: right;
}
.risk-micro .microsite-rm .page-section--functions .half-banner.right ul {
  margin-left: 20px;
}
@media screen and (max-width: 480px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--functions .mainpage-wrapper div.banner-text-container {
    margin-top: 230px;
  }
}
@media screen and (max-width: 480px) and (min-aspect-ratio: 4/3),
(max-width: 640px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite-rm .page-section--functions .cloud-devices {
    top: 12px;
  }
}
@media screen and (max-width: 400px) {
  .risk-micro .microsite.microsite-rm .page-section--functions .banner-text-container h4 {
    font-size: 1.2em;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions .mainpage-wrapper .banner-text-container {
    margin-top: 150px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .cloud-devices {
    top: 10px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .inset {
    padding: 30px 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .inset p {
    font-size: .9em;
  }
.risk-micro   .microsite-rm .page-section.page-section--functions .half-banner h6 {
    font-size: 1.2em;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner .vector-graphic {
    position: relative;
    transform: none;
    padding-bottom: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions .half-banner div.text-wrapper {
    max-width: 80%;
  }
.risk-micro   .microsite-rm .page-section--functions .half-banner {
    padding-top: 30px;
    margin-bottom: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner .vector-graphic.mobile {
    display: block;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner .vector-graphic.large {
    display: none;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner ul li {
    font-size: 13px;
  }
}
@media screen and (max-width: 568px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section--functions .half-banner {
    margin-bottom: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions div.banner-text-container {
    margin-top: 300px;
  }
}
@media screen and (max-width: 500px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--functions .half-banner.left .vector-graphic img {
    margin-right: 0px;
  }
}
@media screen and (max-width: 640px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--functions .banner-text-container {
    margin-top: 360px;
    padding-bottom: 10px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .banner {
    padding-bottom: 20px;
    margin-top: 10px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .inset {
    padding: 50px 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner ul {
    margin: 10px 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner h6 {
    margin-top: 0px;
  }
.risk-micro   .microsite-rm .page-section.page-section--functions .half-banner.left h6 {
    text-align: right;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px), (min-device-width: 600px) and
(max-device-width: 960px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section--functions .banner-text-container h4 {
    padding: 0px 20px 0px 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner {
    margin-bottom: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
.microsite.microsite-rm .page-section--functions .banner-text-container {
  margin-top: 500px;
}
.risk-micro .microsite.microsite-rm .page-section--functions .banner-text-container h4 {
  font-size: 1.8em;
  padding: 0px 20px;
}
.risk-micro .microsite.microsite-rm .page-section.page-section--functions .half-banner .text-wrapper {
  max-width: 70%;
}
.risk-micro .microsite.microsite-rm .page-section--functions .half-banner.left .vector-graphic img {
  margin-right: 0px;
}
.risk-micro .microsite.microsite-rm .page-section--functions .half-banner.right .vector-graphic {
  right: 20px;
}
.risk-micro .microsite.microsite-rm .page-section--functions .half-banner.right .vector-graphic img {
  margin-left: 0px;
  }
}
@media screen and (max-width: 992px) {
  .risk-micro .microsite.microsite-rm .page-section--functions .banner-text-container {
    margin-top: 550px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .banner-text-container h4 {
    font-size: 1.8em;
    padding: 0px 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions .half-banner .text-wrapper {
    max-width: 70%;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner.left .vector-graphic {
    padding-right: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner.left .vector-graphic img {
    margin-right: -20px;
  }
}
@media screen and (max-width: 1200px) {
  .risk-micro .microsite.microsite-rm .page-section--functions .half-banner.right .vector-graphic img {
    float: left;
    margin-left: -30px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--functions .half-banner .text-wrapper {
    max-width: 60%;
  }
}
@media screen and (max-width: 1800px) {
  .risk-micro .microsite.microsite-rm .page-section--functions .half-banner h6 {
    max-width: none;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .half-banner ul {
    max-width: 85%;
  }
}
@media screen and (max-width: 2000px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--functions .manage-change.half-banner h6 {
    padding-right: 0px;
  }
}
@media screen and (max-width: 2500px) {
  .risk-micro .microsite.microsite-rm .page-section--functions .half-banner.left .text-wrapper {
    max-width: 50%;
  }
.risk-micro   .microsite-rm .page-section--functions .cloud-devices img {
    float: right;
  }
}
@media screen and (min-width: 2500px) {
  .risk-micro .microsite-rm .page-section--functions .cloud-devices img {
    float: right;
  }
}
@media screen and (min-width: 2700px) {
  .risk-micro .microsite-rm .page-section--functions .cloud-devices img {
    float: right;
    width: 100%;
    max-width: 1622px;
  }
.risk-micro   .microsite.microsite-rm .page-section--functions .banner {
    padding: 140px;
  }
}
/*ASSESSMENTS SECTION*/
.risk-micro .microsite-rm .page-section--assessments .intro {
  margin-top: -20px;
  background-color: #1978B4;
}
.risk-micro .page-section--assessments .intro.banner {
  background-color: #ffffff;
}
.risk-micro .microsite-rm .page-section--assessments .banner {
  width: 100%;
  padding: 100px 0px;
  background-color: #ffffff;
  height: fit-content;
  z-index: 20;
  displaY: block;
  float: none;
}
.risk-micro .microsite-rm .page-section--assessments .banner .banner-content {
  display: table;
  margin-left: 80px;
  padding-top: 30px;
}
.risk-micro .microsite-rm .page-section--assessments .intro .graphic.mobile {
  display: none;
}
.risk-micro .microsite-rm .page-section--assessments .intro .graphic {
  background: url(images/images/microsite/rm/rm-assessments/assessments-intro.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 200px;
  height: 200px;
  margin: 0px;
  padding: 0px;
  float: none;
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
}
.risk-micro .microsite-rm .page-section--assessments .banner .graphic {
  background: url(images/images/microsite/rm/rm-assessments/assessments-intro.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 200px;
  height: 200px;
  margin: 0px;
  padding: 0px;
  float: none;
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
}
.risk-micro .microsite-rm .page-section--assessments .banner:before {
  background-color: #ffffff;
}
.risk-micro .microsite-rm .page-section--assessments h4 {
  color: #1978B4;
  margin: 0px;
  display: block;
  margin-bottom: 30px;
}
.risk-micro .microsite-rm .page-section--assessments .banner .banner-text-container,
.risk-micro .microsite-rm .page-section--assessments .body-content .container .text-container {
  margin-left: 30px;
  position: relative;
  padding-right: 0px;
  display: table-cell;
  vertical-align: middle;
  height: fit-content;
  padding-left: 40px;
}
.risk-micro .microsite-rm .page-section--assessments .banner-text-container p {
  font-size: 24px;
  margin: 0px;
  padding: 0px;
  float: right;
  display: table-cell;
  vertical-align: middle;
}
.risk-micro .microsite-rm .page-section--assessments p {
  max-width: none;
  padding-top: 0px;
}
.risk-micro .microsite-rm .page-section--assessments .banner .mainpage-wrapper {
  padding-left: 80px;
}
.risk-micro .microsite-rm .page-section--assessments .inset {
  background-color: #1978B4;
  padding: 40px 20px;
}
.risk-micro .microsite-rm .page-section--assessments .inset h5  {
  color: #ffffff;
  font-size: 28px;
  margin: 0px;
  font-weight: normal;
}
.risk-micro .microsite-rm .page-section--assessments .body-content {
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  padding: 70px 0px;
  background-color: #ffffff;
}
.risk-micro .microsite-rm .page-section--assessments .first-paragraph {
  float: none;
  display: block;
  margin: 0px auto;
  margin-top: 20px;
  padding-left: 60px;
}
.risk-micro .microsite-rm .page-section--assessments .body-content .container p {
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  float: right;
}
.risk-micro .microsite-rm .page-section--assessments .body-content .container {
  float: none;
  display: table;
  margin: 50px 0px 50px 0px;
  padding-left: 80px;
  width: 100%;
}
.risk-micro .microsite-rm .page-section--assessments .assessment-process .graphic {
  background: url(images/images/microsite/rm/rm-assessments/assessment-process.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.risk-micro .microsite-rm .page-section--assessments .assessment-findings .graphic {
  background: url(images/images/microsite/rm/rm-assessments/assessment-findings.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.risk-micro .microsite-rm .page-section--assessments .body-content .container .text-container {
  display: table-cell;
  vertical-align: middle;
  height: fit-content;
  float: none;
  padding-right: 50px;
}
@media screen and (max-width: 400px), (max-width: 568px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section--assessments .banner .mainpage-wrapper {
    padding-left: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--assessments h4 {
    font-size: 1.2em;
    padding-left: 0px;
    text-align: center;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner .graphic.mobile,
.risk-micro   .microsite.microsite-rm .page-section--assessments .intro .graphic {
    display: block;
    padding: 20px 20px;
    margin: 0px auto;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments p {
    text-align: center;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner .banner-text-container p {
   font-size: .9em;
    display: block;
    margin: 0px auto;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner .banner-content {
    margin: 0px auto;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--assessments .banner .banner-text-container {
    padding: 30px 30px;
    text-align: center;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner {
    padding: 70px 10px 10px 10px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .inset {
    padding: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .inset h5 {
    font-size: 1.6em;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .first-paragraph {
    margin-top: 0px;
  }
.risk-micro   .microsite-rm .page-section--assessments .intro .graphic.large{
    display: none;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--assessments .body-content .text-container {
    width: 100%;
    display: block;
    padding: 20px 10px;
    margin: 0px auto;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--assessments .body-content .text-container p {
    font-size: .8em;
    padding: 0px 20px;
  }
}
@media screen and (max-width: 640px) and (min-aspect-ratio: 4/3) {
  .risk-micro .microsite.microsite-rm .page-section--assessments .banner .banner-text-container p {
    float: none;
    font-size: 1.2em;
    max-width: 380px;
  }
}
@media screen and (max-width: 640px) {
 .risk-micro .microsite.microsite-rm .page-section.page-section--assessments {
  padding-top: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner .banner-content {
    padding-top: 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .banner {
    padding: 70px 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .body-content {
    padding: 30px 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .body-content .container {
    margin: 30px 0px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .body-content .container p {
    font-size: 1em;
  }
}
@media screen and (max-width: 768px) {
  .risk-micro .microsite.microsite-rm .page-section.page-section--assessments h4 {
    font-size: 2em;
  }
.risk-micro   .microsite.microsite-rm .page-section.page-section--assessments .banner .banner-text-container {
    padding-left: 30px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .first-paragraph {
    padding-left: 20px;
    padding-right: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .body-content .container .text-container {
    padding-left: 10px;
    padding-right: 20px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .body-content .container {
    padding-left: 20px;
  }
.risk-micro .microsite.microsite-rm .page-section--assessments .assessment-process .graphic {
    background: url(images/images/microsite/rm/rm-assessments/assessment-process.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 150px;
  }
.risk-micro   .microsite.microsite-rm .page-section--assessments .assessment-findings .graphic {
    background: url(images/images/microsite/rm/rm-assessments/assessment-findings.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 150px;
  }
}
@media screen and (max-width: 992px) {
.risk-micro .microsite.microsite-rm .page-section--assessments .banner .graphic {
    background: url(images/images/microsite/rm/rm-assessments/assessments-intro.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 150px;
    height: 150px;
    padding-left: 10px;
  }
.risk-micro .microsite.microsite-rm .page-section--assessments .banner .banner-text-container {
    padding-left: 40px;
    margin-left: 0px;
  }
.risk-micro .microsite.microsite-rm .page-section--assessments .banner .banner-text-container p {
    font-size: 1.4em;
  }
.risk-micro .microsite.microsite-rm .page-section--assessments .banner .banner-content {
    margin: 0px auto;
    margin-left: unset;
  }
  .risk-micro .microsite-rm .page-section--assessments .banner .mainpage-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
.risk-micro .microsite.microsite-rm .page-section--assessments .body-content .text-container {
    width: 50%;
  }
}