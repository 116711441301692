/*GENERAL STYLING*/
.cmmc-micro html,
.cmmc-micro .cmmc body {
  margin: 0;
}
.cmmc-micro .cmmc {
  color: #6C757D;
}
.cmmc-micro .cmmc.microsite .primary-color {
  color: #164C93;
}
.cmmc-micro .cmmc.microsite .secondary-color {
  color: #4091CE;
}
.cmmc-micro .cmmc .white {
  color: #ffffff;
}
.cmmc-micro .cmmc .heavy {
  font-weight: 600;
}
.cmmc-micro .microsite.cmmc p {
  font-size: 1.05em;
  letter-spacing: .08em;
  line-height: 1.875em;
}
.cmmc-micro .microsite.cmmc ul li p,
.cmmc-micro .microsite.cmmc ul li {
  font-size: 14.7px;
  font-weight: normal;
}
.cmmc-micro .microsite.cmmc .light {
  font-weight: lighter;
}
.cmmc-micro .cmmc p.large {
  font-size: 24px;
}
.cmmc-micro .microsite.cmmc h1,
.cmmc-micro .microsite.cmmc h4 {
  text-transform: capitalize;
}
.cmmc-micro .microsite.cmmc h1 {
  font-weight: 600;
  font-size: 30px;
}
.cmmc-micro .microsite.cmmc h2 {
  font-weight: 600;
  font-size: 40px;
  margin: 0px;
}
.cmmc-micro .new-dod-process p.large,
.cmmc-micro body .service-areas .tile-title h3 {
  font-size: 20px;
}
.cmmc-micro .microsite.cmmc h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}
.cmmc-micro .cmmc .capitalize {
  text-transform: capitalize;
}
.cmmc-micro .cmmc .float-right {
  float: right;
}
.cmmc-micro .cmmc .float-none {
  float: none;
  align-self: center;
}
.cmmc-micro .shadow-top {
  -webkit-box-shadow: 0px -4px 6px 2px rgba(0,0,0,0.16);
  box-shadow: 0px -4px 6px 2px rgba(0,0,0,0.16);
}
.cmmc-micro .shadow-bottom {
  -webkit-box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.16);
  box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.16);
}
.cmmc-micro .shadow-top-inset {
  -webkit-box-shadow: inset 0px 5px 6px 2px rgba(0,0,0,0.16);
  box-shadow: inset 0px 5px 6px 2px rgba(0,0,0,0.16);
}
.cmmc-micro .shadow-bottom-inset {
  -webkit-box-shadow: inset 0px -4px 6px 2px rgba(0,0,0,0.16);
  box-shadow: inset 0px -4px 6px 2px rgba(0,0,0,0.16);
}
.cmmc-micro .gradient-overlay {
  position: relative;
  height: 100%;
  width: 100%;
}
.cmmc-micro .gradient-overlay.left-to-right.white {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 45%, rgba(255,255,255,0) 100%);
  width: inherit;
}
.cmmc-micro .gradient-overlay.right-to-left.white {
  background: rgb(255,255,255);
  background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8813900560224089) 53%, rgba(255,255,255,0) 100%);
}
.cmmc-micro .gradient-overlay.left-to-right.blue {
  background: rgb(16,63,124);
  background: linear-gradient(90deg, rgba(16,63,124,1) 0%, rgba(37,71,114,0.9738270308123249) 41%, rgba(48,77,114,0.9626225490196079) 53%, rgba(75,86,100,0) 100%);
}
.cmmc-micro .gradient-overlay.right-to-left.blue {
  background: rgb(16,63,124);
  background: linear-gradient(-90deg, rgba(16,63,124,1) 0%, rgba(37,71,114,0.9738270308123249) 41%, rgba(48,77,114,0.9626225490196079) 53%, rgba(75,86,100,0) 100%);
}
.cmmc-micro .intro-splash .gradient-overlay {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8813900560224089) 53%, rgba(255,255,255,0) 100%);
}
.cmmc-micro .clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.cmmc-micro .microsite-cmmc .page-section {
  padding: 0px 0px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc.microsite .implement-doc-support .gradient-overlay.right-to-left.white {
    background:linear-gradient(-90deg,rgba(255,255,255,.9) 0,rgba(255,255,255,.8) 48%,rgba(255,255,255,0.7) 70%,rgba(255,255,255,0) 100%)
  }
.cmmc-micro   .cmmc .gradient-overlay.right-to-left.blue {
    background: linear-gradient(-90deg,rgba(16,63,124,.9) 0,rgba(37,71,114,0.8) 31%,rgba(48,77,114,0.9) 35%,rgba(75,86,100,0) 100%)
  }
  .cmmc-micro .cmmc .certification-needs {
    box-shadow: none;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .ncg-training .training-session-card .card-wrapper {
    margin-top: 120px;
  }
.cmmc-micro body.microsite.cmmc p {
    font-size: .8em;
  }
.cmmc-micro .microsite.cmmc.microsite-cmmc .light {
    font-weight: normal;
  }
.cmmc-micro   .microsite.cmmc.microsite-cmmc h3 {
    font-size: 16px;
    line-height: 20px;
  }
.cmmc-micro   .gradient-overlay.right-to-left.blue {
    background: linear-gradient(-90deg,rgba(16,63,124,1) 0,rgba(37,71,114,0.8) 31%,rgba(48,77,114,0.9) 35%,rgba(75,86,100,0) 100%);
  }
.cmmc-micro   .gradient-overlay.left-to-right.blue {
    background: linear-gradient(90deg,rgba(16,63,124,.8) 0,rgba(37,71,114,0.8) 31%,rgba(48,77,114,0.9) 35%,rgba(75,86,100,0) 100%);
  }
.cmmc-micro   .cmmc .implement-doc-support .gradient-overlay.right-to-left.white {
    background:linear-gradient(-90deg,rgba(255,255,255,1) 0,rgba(255,255,255,.9) 38%,rgba(255,255,255,0.7) 50%,rgba(255,255,255,0) 100%)
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .microsite.cmmc.microsite-cmmc p.large {
    font-size: 16px;
  }
.cmmc-micro   .microsite.cmmc p {
    line-height: 1.8em;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .microsite.cmmc h2 {
    font-size: 30px;
  }
.cmmc-micro   .tile-title {
    max-width: 300px;
    margin: 0px auto;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .microsite.cmmc h3 {
    font-size: 18px;
  }
.cmmc-micro   .cmmc .button-container p,
.cmmc-micro   .cmmc .tile-area p {
    font-size: 12px;
  }
.cmmc-micro   .new-dod-process p.large,
.cmmc-micro   body .service-areas .tile-title h3 {
    font-size: 16px;
    line-height: 24px;
  }
.cmmc-micro   .microsite.cmmc p.large {
    font-size: 20px;
  }
.cmmc-micro   .microsite.cmmc p {
    font-size: .9em;
  }
.cmmc-micro   .microsite.cmmc ul li p,
.cmmc-micro   .microsite.cmmc ul li {
    font-size: 12.6px;
    font-weight: normal;
  }
}
/*BUTTONS*/
.cmmc-micro .btn-primary.gradient {
  background: -moz-linear-gradient(top,  #164C93 50%, #11396f 50%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#164C93), color-stop(50%,#11396f)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #164C93 50%, #11396f 50%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #164C93 50%, #11396f 50%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #164C93 50%, #11396f 50%); /* IE10+ */
  background: linear-gradient(to bottom,  #164C93 50%, #11396f 50%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#164C93', endColorstr='#11396f',GradientType=0 ); /* IE6-9 */
}
.cmmc-micro .btn-primary.gradient:hover, .btn-primary.gradient:focus, .btn-primary.gradient:active, .btn-primary.gradient.active, .open > .dropdown-toggle.btn-primary {
  background: -moz-linear-gradient(top,  #25599d 50%, #164C93 50%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#25599d), color-stop(50%,#164C93)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #25599d 50%,#164C93 50%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #25599d 50%,#164C93 50%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #25599d 50%,#164C93 50%); /* IE10+ */
  background: linear-gradient(to bottom,  #25599d 50%,#164C93 50%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25599d', endColorstr='#164C93',GradientType=0 ); /* IE6-9 */
}
.cmmc-micro .btn-primary.gradient:active, .btn-primary.gradient.active {
  background: -moz-linear-gradient(top,  #1e4980 50%, #183a67 50%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#1e4980), color-stop(50%,#183a67)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #1e4980 50%,#183a67 50%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #1e4980 50%,#183a67 50%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #1e4980 50%,#183a67 50%); /* IE10+ */
  background: linear-gradient(to bottom,  #1e4980 50%,#183a67 50%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e4980', endColorstr='#183a67',GradientType=0 ); /* IE6-9 */
}
/***********************
  CUSTON BTN VALUES
************************/
.cmmc .btn {
  border: 0 none;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 12px;
  background-color: #164C93;
  -webkit-box-shadow: inset -1px 0px 3px 3px #1957a9;
  box-shadow: inset -1px 0px 3px 3px #1957a9;
  color: #ffffff;
  padding: 1rem 3rem;
  font-size: 20px;
  text-transform: capitalize;
  font-family: verdana, serif;
}
.cmmc-micro .btn:focus, .btn:active:focus, .btn.active:focus {
  outline: 0 none;
}
.cmmc-micro .btn-primary {
  background: #164C93;
  color: #ffffff;
}
.cmmc-micro .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  background: #164C93;
}
.cmmc-micro .btn-primary:active, .btn-primary.active {
  background: #183a67;
  box-shadow: none;
}
.cmmc-micro .button-container p {
  display: block;
  text-align: center;
  font-weight: 600;
  padding-top: 20px;
}
.cmmc-micro .cmmc .btn-navy:hover {
  background-color: #164C93;
  color: #ffffff;
  -webkit-box-shadow: inset -1px -4px 3px 3px #113C73;
  box-shadow: inset -1px -4px 3px 3px #113C73;
}
/*INTRO SPLASH*/
.cmmc .intro-splash {
  background-image: url(images/images/microsite/cmmc/cmmc-splash.jpg);
  background-size: auto 100%;
  background-position: right center;
  margin-top: 50px;
  height: fit-content;
  height: -moz-fit-content;
  background-repeat: no-repeat;
  position: relative;
}
.cmmc-micro .splash-content-container {
  max-width: 1350px;
  display: flex;
  height: 850px;
  align-items: flex-end;
  margin: 0px auto;
}
.cmmc-micro .main-content {
  display: inline-block;
}
.cmmc-micro .badges {
  max-width: 500px;
  position: relative;
  margin-left: auto;
  display: inline-block;
  margin-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .cmmc .badges {
    display: flex;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .cmmc-micro body.cmmc .badges {
    justify-content: space-between;
    padding: 0px 0px 30px 0px;
    margin: 0px auto;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro body.cmmc .badges {
    flex-direction: row;
    max-width: 100%;
    margin-top: auto;
  }
    .cmmc-micro .badge-wrapper {
    max-width: 200px;
  }
  .cmmc-micro .cmmc.microsite .float-none {
    align-self: flex-start;
    padding-top: 120px;
  }
  .cmmc-micro .splash-content-container {
  display: flex;
  flex-direction: column;
}
}
.cmmc-micro .origin-services.cmmc .intro-splash {
  margin-top: 75px;
}
.cmmc-micro .intro-splash-wrapper {
  text-align: right;
  padding: 0px 30px 60px 30px;
}
.cmmc-micro .intro-splash-wrapper img {

  max-width: 500px;
}
.cmmc-micro .intro-splash-wrapper h1 {
  display: inline-block;
  float: none;
  text-align: left;
  max-width: 587px;
  padding: 20px 0px;
}
.cmmc-micro .intro-splash-wrapper .main-content-wrapper {
  display: block;
  float: none;
  padding: 0px;
  margin-left: 100px;
  max-width: 587px;
}
.cmmc-micro .intro-splash-wrapper .button-container {
  text-align: center;
  max-width: 310px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .intro-splash-wrapper img {
    max-width: 270px;
  }
.cmmc-micro   .cmmc .intro-splash-wrapper .main-content-wrapper {
    margin: 0px 10px;
    width: 100%;
    max-width: 300px;
  }
.cmmc-micro   .cmmc .intro-splash-wrapper .button-container p {
    max-width: 200px;
  }
.cmmc-micro   .microsite-cmmc.microsite.cmmc .sub-text {
    width: 90%;
    padding: 10px 0px 10px 0px;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .intro-splash-wrapper {
    padding: 60px 0px;
  }
  .cmmc-micro .cmmc .splash-content-container {
    height: 700px;
    max-width: inherit;
  }
  .cmmc-micro .cmmc.microsite .float-none.main-content {
    padding-top: 60px;
  }
.cmmc-micro   .cmmc .intro-splash-wrapper img {
    max-width: 300px;
    margin-right: unset;
  }
.cmmc-micro   .microsite.cmmc .intro-splash-wrapper h1 {
    font-size: 20px;
  }
.cmmc-micro   .cmmc .btn {
    font-size: 16px;
  }
.cmmc-micro   .intro-splash-wrapper .button-container p {
    max-width: 260px;
    margin: 0px auto;
  }
  .cmmc-micro .badges .badge-wrapper {
    max-width: 150px;
  }
.cmmc-micro .microsite.cmmc .tile-section {
    padding: 60px 0px;
  }
.cmmc-micro   .microsite .service-areas .tile-section p {
    top: -10px;
  }
.cmmc-micro   .microsite.cmmc h2 {
    font-size: 24px;
    line-height: 34px;
  }
.cmmc-micro   .microsite.cmmc .background-left h2 {
    text-align: right;
  }
.cmmc-micro   .microsite.cmmc p.large {
    font-size: 16px;
  }
.cmmc-micro   .microsite.cmmc .sub-text {
    display: block;
    float: none;
    margin: 0px auto;
    width: 75%;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .intro-splash-wrapper img {
    margin-right: unset;
  }
    .cmmc-micro .cmmc .intro-splash {
    margin-top: 35px;
  }
.cmmc-micro   .microsite h1 {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .intro-splash-wrapper .main-content-wrapper {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .cmmc .intro-splash {
    background-position: center center;
  }
.cmmc-micro   .intro-splash-wrapper img {
    margin-left: 0px;
    max-width: 400px;
    margin-right: auto;
  }
.cmmc-micro   .intro-splash-wrapper {
    text-align: left;
  }
.cmmc-micro   .microsite.cmmc h1 {
    font-size: 24px;
  }
}
/*TILES/SERVICE AREAS*/
.cmmc .tile-section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.cmmc-micro .tile-area {
  padding: 20px;
  text-align: center;
}
.cmmc-micro .tile-title {
  display: block;
}
.cmmc-micro body .service-areas .tile-title h3 {
  text-align: left;
  display: inline-block;
  max-width: 200px;
  float: right;
}
.cmmc-micro .tile-title:before {
  content: '';
  background-image: url(images/images/microsite/cmmc/need-to-do.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 100px;
  display: inline-block;
  left: 0px;
}
.cmmc-micro .needs-to-do .tile-title:before {
  background-image: url(images/images/microsite/cmmc/need-to-do.png);
}
.cmmc-micro .establish-certification .tile-title:before {
  background-image: url(images/images/microsite/cmmc/establish-certification.png);
}
.cmmc-micro .implementation-and-support .tile-title:before {
  background-image: url(images/images/microsite/cmmc/implementation-and-support.png);
}
.cmmc-micro .service-areas .tile-section p {
  padding: 0px 20px;
  max-width: 320px;
  top: -10px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro body.cmmc.microsite .service-areas .tile-title h3 {
    text-align: center;
    float: none;
    margin-top: 0px;
  }
.cmmc-micro .microsite .service-areas .tile-section p {
    top: 0px;
  }
.cmmc-micro .microsite.cmmc .tile-area {
    width: 90%;
  }
  .cmmc-micro .tile-title {
    padding-top: 77px;
  }
  .cmmc-micro .microsite .tile-title:before {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .cmmc .tile-area {
    display: block;
    margin: 0px auto;
    float: none;
  }
.cmmc-micro body.cmmc .service-areas .tile-title h3 {
    text-align: left;
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
.cmmc-micro .cmmc .tile-section {
    padding-bottom: 30px;
  }
.cmmc-micro .cmmc .service-areas .tile-section {
    padding-bottom: 60px;
  }
.cmmc-micro body .service-areas .tile-title h3 {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 10px;
  }
.cmmc-micro .service-areas .tile-section p {
    top: 0px;
  }
}
@media screen and (max-width: 1200px) {
.cmmc-micro .tile-title:before {
    height: 100px;
    width: 60px;
  }
.cmmc-micro .implementation-and-support .tile-title:before,
.cmmc-micro .establish-certification .tile-title:before,
.cmmc-micro .needs-to-do .tile-title:before {
    margin-top: 10px;
  }
.cmmc-micro .implementation-and-support .tile-title:before {
    background-position: center 10px;
  }
}
/*BANNERS WITH BACKGROUNDS*/
.background-left {
  content: '';
  background-position: left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.cmmc-micro .background-right {
  content: '';
  background-position: right center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.cmmc-micro .ncg-cybersecurity .background-left {
  background-image: url(images/images/microsite/cmmc/ncg-proven-track-record.png);
}
.cmmc-micro .gradient-overlay .container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.cmmc-micro .sub-info.container {
  padding: 40px 80px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .gradient-overlay .container {
    padding: 50px 0px;
  }
.cmmc-micro .cmmc .background-left .text-wrapper {
    width: 70%;
  }
.cmmc-micro .cmmc .background-right .text-wrapper {
    padding: 50px 10px;
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .sub-info.container {
    padding: 40px;
  }
}
@media screen and (min-width: 1200px) {
   .background-right {
    background-size: 60% auto ;
  }
}
@media screen and (min-width: 1700px) {
  .cmmc-micro .background-left {
    background-size: 60% auto;
    background-position: left top;
  }
.cmmc-micro   .implement-doc-support .background-left {
    background-position: left center;
  }
}
@media screen and (min-width: 2000px) {
  .cmmc-micro .background-right {
    background-size: 60% auto;
    background-position: right top;
  }
}
.cmmc-micro .cmmc .sub-text {
  padding: 20px;
}
.cmmc-micro .ncg-cybersecurity .shadow-top {
  -webkit-box-shadow: 0px -4px 6px 2px rgba(0,0,0,0.96);
  box-shadow: 0px -4px 6px 2px rgba(0,0,0,0.96);
}
.cmmc-micro .ncg-cybersecurity .sub-text {
  max-width: 500px;
}
.cmmc-micro .ncg-cybersecurity .sub-text.float-right {
  max-width: 460px;
}
.cmmc-micro .ncg-cybersecurity .sub-text.float-right ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
}
.cmmc-micro .microsite li.bullet-wrap p {
  line-height: normal;
}
.cmmc-micro .sub-text.float-right li:before {
  content: "\2022";
  font-family: "arial", sans-serif;
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 23px;
  line-height: normal;
  left: 0px;
}
.cmmc-micro .ncg-cybersecurity ul {
  margin-left: 30px;
}
.cmmc-micro .new-dod-process .background-right {
  float: right;
  width: 100%;
  background-image: url(images/images/microsite/cmmc/new-dod-process.png);
}
.cmmc-micro .new-dod-process .text-wrapper {
  padding: 100px 60px;
  max-width: 560px;
  margin-left: auto;
}
.cmmc-micro .new-dod-process .text-box {
  background-color: white;
}
.cmmc-micro .new-dod-process p.large {
  padding: 25px 0px;
  letter-spacing: normal;
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .sub-text.float-right li:before {
    line-height: normal;
    font-size: 20px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .sub-text.float-right li.bullet-wrap:before {
    line-height: 13px;
  }
}
@media screen and (min-width: 1200px) {
  .cmmc-micro .new-dod-process .background-right {
    background-size: auto 100%;
  }
}
@media screen and (min-width: 2000px) {
  div.new-dod-process .background-right {
    background-size: 55% auto;
  }
}
.cmmc-micro .cmmc .certification-needs h3 {
  font-size: 26px;
  display: block;
  margin: 0px auto;
  padding-bottom: 10px;
  text-align: right;
}
.cmmc-micro .cmmc .certification-needs p.light {
  text-align: right;
  display: block;
  margin-left: auto;
  right: 0px;
  max-width: 630px;
}
.cmmc-micro .microsite .page-section.certification-needs {
  padding: 100px 0px;
}
.cmmc-micro .certification-needs .graphic-aid {
  margin: 0px auto;
  float: none;
  padding-top: 30px;
  padding-bottom: 20px;
}
@media screen and (max-width: 568px) {
  .cmmc-micro .certification-needs .graphic-aid {
  padding-left: 0px;
  padding-right: 0px;
}


}
.cmmc-micro .reference {
  padding-top: 20px;
  max-width: 500px;
}
.cmmc-micro .cmmc .reference p,
.cmmc-micro .cmmc .reference a {
  font-size: 12px;
  display: block;
}
.cmmc-micro .cmmc .reference a {
  padding-top: 10px;
}
.cmmc-micro .ncg-arms-around .background-right {
  background-image: url(images/images/microsite/cmmc/get-your-arms-around-opacity.png);
}
.cmmc-micro .cmmc .ncg-arms-around .tile-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.cmmc-micro .ncg-arms-around .tile-area {
  text-align: center;
}
.cmmc-micro .ncg-arms-around .tile-area h3 {
  margin-bottom: 20px;
}
.cmmc-micro .ncg-arms-around .tile-area .graphic {
  margin: 0px auto;
  float: none;
  padding-bottom: 15px;
  height: 170px;
}
.cmmc-micro .ncg-arms-around .tile-area.plan-the-work .graphic .img-responsive  {
  max-height: 100%;
  width: auto;
  max-width: 200px;
}
.cmmc-micro .ncg-arms-around .tile-area.starting .graphic .img-responsive {
  max-height: 100%;
  width: auto;
  max-width: 170px;
}
.cmmc-micro .ncg-arms-around .tile-area ul {
  display: block;
  margin: 0px auto;
  max-width: 270px;
  width: fit-content;
  width: -moz-fit-content;
}
.cmmc-micro .ncg-arms-around .tile-area ul li {
  text-align: left;
  list-style-position: outside;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .ncg-arms-around .tile-area {
    width: 80%;
  }
}
@media screen and (max-width: 580px) {
  .cmmc-micro .cmmc .ncg-arms-around .tile-area ul li {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .cmmc .ncg-arms-around .tile-area {
    padding: 20px 10px 20px 10px;
  }
.cmmc-micro   .cmmc .ncg-arms-around .tile-area ul li {
    font-size: 12px;
  }
.cmmc-micro   .cmmc .certification-needs .graphic-aid {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .cmmc .ncg-arms-around .tile-section {
    width: 100%;
    padding: 30px 30px;
  }
.cmmc-micro   .ncg-arms-around .tile-area h3 {
    margin-bottom: 10px;
    line-height: normal;
  }
.cmmc-micro   .ncg-arms-around .tile-area.work-the-plan .graphic .img-responsive {
    max-width: 200px;
  }
.cmmc-micro   .ncg-arms-around .tile-area .graphic {
    padding: 0px;
    height: 120px;
  }
.cmmc-micro   .ncg-arms-around .tile-area ul {
    padding-top: 15px;
  }
.cmmc-micro   .ncg-arms-around .graphic img {
    padding-top: 10px;
    margin: 0px auto;
  }
.cmmc-micro   .ncg-arms-around .tile-area {
    padding: 5px;
  }
.cmmc-micro   .cmmc .ncg-arms-around .work-the-plan .graphic img {
    padding-top: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .ncg-arms-around .work-the-plan .graphic img {
    padding-top: 20px;
  }
.cmmc-micro   .ncg-arms-around .tile-area ul li {
    font-size: .9em;
  }
.cmmc-micro   .ncg-arms-around .tile-area ul {
    padding-left: 30px;
  }
}
.cmmc-micro .header-banner {
  background-color: #4091CE;
  padding: 20px;
}
.cmmc-micro .implement-doc-support.page-section {
  padding-top: 5rem;
}
.cmmc-micro .implement-doc-support div.header-banner h2 {
  padding-bottom: 0px;
  margin-left: unset;
  max-width: unset;
}
.cmmc-micro .implement-doc-support .shadow-top {
  padding: 0px;
  float: none;
}
.cmmc-micro .implement-doc-support .background-left {
  background-image: url(images/images/microsite/cmmc/implementation-doc-and-support-opacity.png);
}
.cmmc-micro .implement-doc-support .gradient-overlay.right-to-left.white {
  background: rgb(255,255,255);
  background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 38%, rgba(255,255,255,0.8897934173669468) 52%, rgba(255,255,255,0) 100%);
}
.cmmc-micro .cmmc .implement-doc-support h2 {
  max-width: 360px;
  padding-bottom: 20px;
  margin-left: auto;
}
.cmmc-micro .implement-doc-support p.light {
  max-width: 430px;
  margin-left: auto;
  display: block;
}
.cmmc-micro .implement-doc-support div.border-cell:last-of-type {
  border-left: 2px solid #4091CE;
  border-top: 2px solid #4091CE;
}
.cmmc-micro .implement-doc-support div.border-cell:nth-of-type(2) {
  border-left: 2px solid #4091CE;
}
.cmmc-micro .implement-doc-support div.border-cell:nth-of-type(3) {
  border-top: 2px solid #4091CE;
}
.cmmc-micro .implement-doc-support .shadow-top-inset {
  padding: 0px;
}
.cmmc-micro .implement-doc-support .cell-wrapper {
  padding: 20px;
  min-height: 500px;
  width: fit-content;
  width: -moz-fit-content;
}
.cmmc-micro .cell-wrapper .cell-title {
  display: flex;
  align-items: flex-end;
}
.cmmc-micro .cell-wrapper .cell-title h3 {
  max-width: 230px;
  display: inline-block;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 20px;
}
.cmmc-micro .cell-title:before {
  content: '';
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 200px;
  display: inline-block;
  left: 0px;
}
.cmmc-micro .process-design-implement .cell-title:before {
  background-image: url(images/images/microsite/cmmc/process-design-implement.png);
}
.cmmc-micro .team-audit-preparation .cell-title:before {
  background-image: url(images/images/microsite/cmmc/team-audit-prep.png);
  background-size: auto 120px;
}
.cmmc-micro .practice-implement-perform .cell-title:before {
  background-image: url(images/images/microsite/cmmc/practice-implement-perform.png);
  background-size: auto 130px;
}
.cmmc-micro .agile-program-approach .cell-title:before {
  background-image: url(images/images/microsite/cmmc/sustainable-agile-approach.png);
  background-size: auto 140px;
}
.cmmc-micro .implement-doc-support .cell-wrapper p.light {
  padding-left: 90px;
  padding-top: 10px;
  max-width: none;
  margin-left: 0px;
}
.cmmc-micro .border-cell.force {
  float: left !important;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .new-dod-process .text-box {
    width: 100%;
    margin-top: 200px;
  }
.cmmc-micro   .cmmc .agile-program-approach .cell-title:before {
    background-size: auto 110px;
  }
.cmmc-micro   .practice-implement-perform .cell-title:before,
.cmmc-micro   .team-audit-preparation .cell-title:before {
    background-size: auto 100px;
  }
.cmmc-micro   .cell-title:before {
    height: 120px;
  }
.cmmc-micro   .microsite.cmmc.microsite-cmmc .implement-doc-support .cell-wrapper {
    padding: 20px 0px 80px 0px;
    width: unset;
  }
.cmmc-micro   .microsite .implement-doc-support .cell-wrapper p.light {
    margin: 0px auto;
    padding-left: 30px;
    max-width: 300px;
  }
.cmmc-micro   .cell-wrapper .cell-title h3 {
    margin-left: 10px;
  }
.cmmc-micro   .implement-doc-support.page-section .text-wrapper {
    width: 70%;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .certification-needs .reference p,
.cmmc-micro   .cmmc .certification-needs .reference a {
    font-size: 8px;
  }
.cmmc-micro   .microsite.cmmc .implement-doc-support .cell-wrapper {
    padding: 20px 40px 80px 0px;
  }
  .cmmc-micro .cmmc.microsite .certification-needs p.light {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .microsite .page-section.certification-needs {
    padding: 70px 0px;
  }
.cmmc-micro   .cmmc .certification-needs h3 {
    font-size: 22px;
  }
.cmmc-micro   .cmmc .certification-needs h3,
.cmmc-micro   .cmmc .certification-needs p.light {
    text-align: center;
  }
.cmmc-micro   .cmmc .certification-needs p.light {
    padding-right: 40px;
    padding-left: 40px;
    margin: 0px auto;
  }
.cmmc-micro   .implement-doc-support p.light.large {
    max-width: 330px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .new-dod-process .background-right {
    background-position: right top;
    background-size: 100% auto;
  }
.cmmc-micro   .new-dod-process .text-box {
    width: 100%;
    margin-top: 300px;
  }
.cmmc-micro   .new-dod-process .text-wrapper {
    margin: 0px auto;
    padding: 60px 40px;
  }
.cmmc-micro   .new-dod-process p.large {
    padding: 15px 0px;
  }
.cmmc-micro   .new-dod-process h2.secondary-color {
    margin-bottom: 10px;
  }
.cmmc-micro   .cmmc .implement-doc-support .cell-wrapper {
    min-height: unset;
    padding: 20px 40px 80px 40px;
    margin: 0px auto;
    float: none;
  }
.cmmc-micro   .implement-doc-support .cell-wrapper p.light {
    max-width: 500px;
  }
.cmmc-micro   .implement-doc-support div.border-cell:nth-of-type(2) {
    border-left: 0px;
  }
.cmmc-micro   .implement-doc-support div.border-cell {
    border-top: 2px solid #4091ce
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .implement-doc-support .cell-wrapper {
    min-height: 460px;
  }
}
@media screen and (min-width: 1200px) {
  .cmmc-micro .implement-doc-support .cell-wrapper p.light {
    max-width: 530px;
  }
.cmmc-micro   .implement-doc-support div.border-cell:nth-of-type(odd) .cell-wrapper {
    float: right;
    margin-right: 50px;
  }
}
.cmmc-micro .cmmc-programmatic-approach p.light,
.cmmc-micro .cmmc .cmmc-programmatic-approach h2 {
  margin: 0px auto;
  text-align: center;
}
.cmmc-micro .cmmc-programmatic-approach p.light {
  padding-top: 15px;
}
.cmmc-micro .cmmc .cmmc-programmatic-approach h2 {
  width: fit-content;
  width: -moz-fit-content;
}
.cmmc-micro .cmmc-programmatic-approach p,
.cmmc-micro .cmmc-programmatic-approach h2 {
  display: block;
}
.cmmc-micro .cmmc-programmatic-approach .image-container {
  padding-top: 50px;
  float: none;
  margin: 0px auto;
}
.cmmc-micro .cmmc-programmatic-approach .image-container img {
  margin: 0px auto;
}
.cmmc-micro .cmmc-programmatic-approach.page-section {
  padding: 80px 0px;
}
@media screen and (max-width: 480px) {
.cmmc-micro .cmmc-programmatic-approach .image-container img {
  height: inherit;
  width: auto;
  max-width: unset;

}
.cmmc-micro .cmmc  .cmmc-programmatic-approach .image-container {
  height: 300px;
  overflow-x: scroll;
  padding-top: 15px;
}
}
@media screen and (max-width: 768px) {
  .cmmc-micro .cmmc .cmmc-programmatic-approach .container p.light {
    font-size: 14px;
    max-width: 400px;
  }
.cmmc-micro   .cmmc-programmatic-approach p {
    padding: 0px 30px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .cmmc-programmatic-approach .container p.light {
    font-size: 16px;
    max-width: 500px;
  }
.cmmc-micro   .cmmc-programmatic-approach .image-container {
    padding-top: 30px;
  }
}
.cmmc-micro .cmmc-orchestration-portal .container {
  padding: 80px 0px;
}
.cmmc-micro .cmmc-orchestration-portal .clearfix div {
  float: none;
  display: block;
  margin: 0px auto;
  max-width: 630px;
  padding-bottom: 20px;
}
.cmmc-micro .cmmc-orchestration-portal .portal-demo {
  padding-top: 40px;
}
.cmmc-micro .cmmc-orchestration-portal .demo-tile {
  padding: 30px;
  text-align: center;
}
.cmmc-micro .demo-tile .graphic {
  display: block;
  float: none;
  margin: 15px 0px;
  margin-left: auto;
  margin-right: auto;
  right: 0px;
  left: 0px;
}
.cmmc-micro .demo-tile .graphic img {
  -webkit-box-shadow: 7px 6px 6px -1px rgba(0,0,0,0.17);
  box-shadow: 7px 6px 6px -1px rgba(0,0,0,0.17);
}
.cmmc-micro .demo-tile h3 {
  max-width: 200px;
  margin: 0px auto;
}
.cmmc-micro .demo-tile p.light {
  max-width: 230px;
}
.cmmc-micro .cmmc .page-section.baseline-questionnaire {
  padding: 90px 0px;
}
.cmmc-micro .baseline-questionnaire h2 {
  padding-bottom: 30px;
}
.cmmc-micro .baseline-questionnaire p.large {
  position: relative;
  margin-left: 200px;
}
.cmmc-micro .baseline-questionnaire .container h2,
.cmmc-micro .baseline-questionnaire .content-container,
.cmmc-micro .baseline-questionnaire .button-container {
  width: fit-content;
  width: -moz-fit-content;
  margin: 0px auto;
  display: block;
}
.cmmc-micro .baseline-questionnaire .content-container:before {
  content: '';
  background-image: url(images/images/microsite/cmmc/where-do-you-stand.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  width: 150px;
  display: inline-block;
  position: absolute;
  top: 45%;
  transform: translateY(-45%);
}
.cmmc-micro .baseline-questionnaire .button-container {
  padding: 60px 0px 0px 0px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .baseline-questionnaire h2 {
    text-align: center;
  }
.cmmc-micro   .microsite.cmmc.microsite-cmmc .baseline-questionnaire p.large {
    max-width: unset;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .baseline-questionnaire .content-container:before {
    margin: 0px auto;
    display: block;
    top: unset;
    transform: unset;
    position: relative;
  }
.cmmc-micro   .microsite.cmmc .baseline-questionnaire p.large {
    margin-top: 30px;
    margin-left: 0px;
    padding: 0px 15px;
  }
.cmmc-micro   .baseline-questionnaire .button-container {
    padding-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .microsite.cmmc .baseline-questionnaire p.large {
    max-width: 400px;
  }
.cmmc-micro   .cmmc .baseline-questionnaire .content-container:before {
    height: 100px;
    width: 100px;
  }
.cmmc-micro   .baseline-questionnaire p.large {
    margin-left: 160px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .cmmc .baseline-questionnaire p.large {
    max-width: 500px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .baseline-questionnaire .content-container:before {
    height: 130px;
    width: 130px;
  }
}
/*CONTACT FORM*/
.cmmc input.btn-default {
  border: 1px solid #bfced9;
  color: #42a1ec;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  transition: background-color 0.5s, color 0.5s;
  letter-spacing: normal;
  box-shadow: none;
  padding: 10px 12px;
  border-radius: 5px;
}
.cmmc-micro .cmmc input.btn-default:hover,
.cmmc-micro .cmmc input.btn-default:focus {
  border-color: #c2d9f0;
  color: #2773bd;
  background-color: #fff;
  transition: background-color 0.5s, color 0.5s;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc-orchestration-portal .container {
    padding: 60px 0px;
  }
.cmmc-micro   .cmmc .cmmc-orchestration-portal .orchestration-logo {
    width: 100%;
    margin: 0px auto;
  }
.cmmc-micro  .cmmc .cmmc-orchestration-portal .clearfix div {
    padding-bottom: 40px;
    text-align: left;
    margin-right: 10px;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cell-wrapper .cell-title h3 {
    line-height: 10px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .cmmc-orchestration-portal .orchestration-logo {
    float: none;
    margin-left: 20px;
  }
.cmmc-micro   .cmmc-orchestration-portal .clearfix div {
    float: right;
    margin-right: 50px;
    text-align: right;
  }
.cmmc-micro   .cmmc-orchestration-portal .demo-tile:nth-of-type(even) {
    float: right;
  }
.cmmc-micro   .cmmc-orchestration-portal .demo-tile {
    padding: 10px 30px 0px 30px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .cmmc-orchestration-portal .portal-demo {
    padding-top: 0px;
  }
.cmmc-micro 
  .cmmc-orchestration-portal .portal-demo img {
    max-height: 116px;
  }
.cmmc-micro 
  .demo-tile p.light {
    max-width: 200px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .demo-tile h3 {
    max-width: 180px;
  }
}
.cmmc-micro .video {
  padding-top: 60px;
  padding-bottom: 60px;
  align-content: center;
  display: flex;
}
.cmmc-micro .video.cmmc-getting-started.container {
  padding-top: 0px;
  padding-bottom: 80px;
}
.cmmc-micro .video.ncg-cmmc-services.container {
  padding-bottom: 80px;
}
.cmmc-micro .video iframe {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.cmmc-micro .video .text-container {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}
.cmmc-micro .video .text-container p {
  max-width: 480px;
  text-align: left;
}
.cmmc-micro .video .text-container h3 {
  margin-top: 0px;
}
.cmmc-micro .video.cmmc-getting-started .text-container h3,
.cmmc-micro .video.cmmc-getting-started .text-container p {
  text-align: left;
}
.cmmc-micro .video.cmmc-getting-started .text-container p {
  text-align: left;
  max-width: 450px;
  margin-left: 0px;
}
.cmmc-micro .video.ncg-cmmc-services .text-container p {
  margin-left: 0px;
}
.cmmc-micro .video a {
  display: block;
  padding-top: 15px;
  max-width: 500px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .video .text-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .cmmc-micro .video.what-is-cmmc .text-container {
    padding-top: 0px;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .video a {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .microsite.cmmc .video.cmmc-getting-started {
    padding: 0px 0px 80px 0px;
  }
.cmmc-micro   .video.cmmc-getting-started .text-container p,
.cmmc-micro   .cmmc .video.ncg-cmmc-services .text-container p {
    margin: 0px auto;
    text-align: center;
    max-width: 400px;
  }
.cmmc-micro   .cmmc .video.ncg-cmmc-services .text-container p {
    padding-left: 20px;
    padding-right: 20px;
  }
.cmmc-micro   .cmmc .video.ncg-cmmc-services {
    flex-flow: column;
  }
.cmmc-micro   .microsite.cmmc .video {
    padding: 60px 0px 60px 0px;
  }
.cmmc-micro   .cmmc .video.what-is-cmmc {
    flex-flow: column;
  }
.cmmc-micro   .video .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
.cmmc-micro   .video .video-wrapper iframe {
    width: 100%;
    height: 100%;
  }
.cmmc-micro   .video .text-container p,
.cmmc-micro   .video .text-container h3,
.cmmc-micro   .video .text-container a {
    text-align: center;
    display: block;
    width: fit-content;
    width: -moz-fit-content;
    margin: 0px auto;
  }
}
@media screen and (max-width: 1199px) {
  .cmmc-micro .video .text-container,
.cmmc-micro   .video .video-wrapper {
    display: block;
  }
.cmmc-micro  .cmmc .video {
    flex-flow: column-reverse;
    padding-bottom: 20px;
  }
.cmmc-micro   .video .text-container {
    padding-bottom: 40px;
    max-width: fit-content;
    padding-top: 40px;
    margin: 0px auto;
  }
.cmmc-micro   .cmmc .video iframe {
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%);
  }
}
/*PRESS RELEASE*/
/* .cmmc .press-release {
  border-top: 1px solid #d5e1eb;
  border-bottom: 1px solid #d5e1eb;
  padding: 50px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  z-index: 0;
} */
.cmmc-micro .cmmc .press-release p.date .cmmc #registerForm .intro li p {
  padding: 2px 0px 0px 0px;
  line-height: normal;
}
.cmmc-micro .cmmc .press-release h3 {
  color: #4091ce;
}
.cmmc-micro .press-release p.date {
  line-height: normal;
}
.cmmc-micro .cmmc .press-release a {
  max-width: 400px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .press-release {
    margin-top: 0px;
  }
.cmmc-micro body .press-release h3:before {
    display: block;
    position: relative;
    margin: 0px auto;
    margin-bottom: 10px;
    left: unset;
  }
.cmmc-micro body.cmmc .press-release-wrapper {
    display: block;
    text-align: center;
  }
}
/*TRAINING SECTION*/
.page-section.ncg-training {
  margin-bottom: 60px;
}
.cmmc-micro .ncg-training .background-right {
  background-image: url(images/images/microsite/cmmc/cmmc-training.png);
  background-position: right center;
  background-color: #0b3e6f;
}
.cmmc-micro .ncg-training .gradient-overlay .container {
  padding-top: 160px;
  padding-bottom: 160px;
}
.cmmc-micro .cmmc .ncg-training .container.intro {
  padding: 40px 70px;
}
.cmmc-micro .cmmc .ncg-training h3 {
  font-size: 28px;
}
.cmmc-micro .cmmc .ncg-training p.light {
  max-width: 1000px;
}
.cmmc-micro .cmmc .ncg-training .button-container {
  margin-top: 30px;
  text-align: right;
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .ncg-training .button-container {
    text-align: center;
  }
}
.cmmc-micro .cmmc .button-container a.download {
  line-height: 43px;
  vertical-align: center;
}
.cmmc-micro .cmmc .button-container a.download:after {
  display: none;
}
.cmmc-micro .cmmc .button-container a p {
  font-size: 12px;
  text-transform: uppercase;
  line-height: normal;
  font-weight: 600;
  color: #ffffff;
  padding-top: 0px;
  vertical-align: middle;
  position: relative;
}
.cmmc-micro .button-container a p:before {
  content:'';
  background-image: url(images/images/microsite/cmmc/download-icon.png);
  height: 15px;
  width: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 6px;
  margin-bottom: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.cmmc-micro .cmmc .ncg-training .container.sessions {
  padding-bottom: 40px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .ncg-training .container.sessions {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 568px) {
  div.ncg-training .gradient-overlay .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .ncg-training .gradient-overlay.left-to-right.blue {
    background: linear-gradient(90deg,rgba(16,63,124,1) 0,rgba(37,71,114,0.9) 40%,rgba(48,77,114,0.8) 55%,rgba(75,86,100,0) 100%);
  }
}
/*Training Session Cards*/
.training-session-card {
  display: block;
  margin: 0px auto;
  width: 100%;
  max-width: 1000px;
  -webkit-box-shadow: 6px 6px 10px 3px rgba(0,0,0,0.11);
  box-shadow: 6px 6px 10px 3px rgba(0,0,0,0.11);
  margin-bottom: 40px;
  position: relative;
  padding: 50px 70px 40px 50px;
}
.cmmc-micro .card-title-container {
  display: block;
  height: fit-content;
  width: 100%;
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background-color: rgba(158, 166, 179, 0.6);
  -webkit-box-shadow: 5px 3px 6px 2px rgba(22,76,147,0.33);
  box-shadow: 5px 3px 6px 2px rgba(22,76,147,0.33);
  position: absolute;
  left: 0px;
  top: 0px;
}
.cmmc-micro .training-session-card h4 {
  font-size: 22px;
  max-width: 640px;
  line-height: 30px;
  font-weight: bold;
  padding: 20px 10px 120px 50px;
}
.cmmc-micro .training-session-card .card-wrapper {
  display: block;
  margin-left: auto;
  max-width: 700px;
  padding: 20px;
  margin-top: 100px;
}
.cmmc-micro .training-session-card .card-wrapper .row {
  margin-left: 0px;
  margin-right: 0px;
}
.cmmc-micro .training-session-card p.card-info-title {
  text-transform: capitalize;
  text-align: right;
  padding-right: 0px;
}
.cmmc-micro .training-session-card .row.duration p.card-info-text,
.cmmc-micro .training-session-card .row.audience p.card-info-text,
.cmmc-micro .training-session-card .row.format p.card-info-text,
.cmmc-micro .training-session-card .row.cost p.card-info-text {
  text-transform: capitalize;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .training-session-card .card-title-container {
    clip-path: polygon(0 0, 100% 0, 100% 20%, 0 45%);
  }
.cmmc-micro   .cmmc .ncg-training .training-session-card .card-title-container h4 {
    max-width: 200px;
    padding: 10px 10px 300px 20px;
  }
.cmmc-micro   .microsite.cmmc .ncg-training .training-session-card .card-wrapper {
    padding: 0px;
    margin-top: 150px;
  }
.cmmc-micro   .cmmc .ncg-training .training-session-card.cmmc-100  .card-wrapper {
    margin-top: 160px;
  }
.cmmc-micro   .training-session-card .card-wrapper .card-info-text {
    padding-right: 0px;
  }
.cmmc-micro   .training-session-card .card-wrapper .card-info-title {
    padding-left: 0px;
  }
.cmmc-micro   .cmmc .ncg-training .container.intro {
    padding: 40px;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .ncg-training .training-session-card h4 {
    max-width: 320px;
  }
.cmmc-micro   body.microsite.cmmc h4 {
    font-size: 16px;
    line-height: 24px;
  }
.cmmc-micro   .ncg-training .training-session-card .card-title-container h4 {
    padding: 10px 10px 120px 30px;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .ncg-training .training-session-card .card-wrapper {
    margin-top: 150px;
  }
.cmmc-micro   .ncg-training .training-session-card h4 {
    max-width: 380px;
    padding: 20px 10px 150px 50px;
  }
.cmmc-micro   .microsite.cmmc h4 {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 992px){
  .training-session-card .card-wrapper {
    max-width: 600px;
    margin-top: 130px;
  }
.cmmc-micro   .cmmc .ncg-training h3 {
    font-size: 24px;
  }
.cmmc-micro   .cmmc h4 {
    font-size: 20px;
    line-height: 28px;
  }
.cmmc-micro   .training-session-card h4 {
    max-width: 400px;
  }
.cmmc-micro   .training-session-card {
    padding: 50px 20px 30px 30px;
  }
}
@media screen and (max-width: 1200px){
  .training-session-card .card-wrapper {
    max-width: 600px;
  }
}
/*Learning Tracks*/
.learning-tracks.container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.cmmc-micro .ncg-training .header-banner h3 {
  max-width: 800px;
  line-height: 1.25em;
}
.cmmc-micro .learning-track-tile {
  padding: 0px;
  -webkit-box-shadow: 4px 3px 8px 1px rgba(145,153,160,0.45);
  box-shadow: 4px 3px 8px 1px rgba(145,153,160,0.45);
  justify-content: space-between;
  margin-right: 20px;
  max-width: 350px;
  margin-top: 40px;
}
.cmmc-micro .learning-track-tile:last-of-type {
  margin-right: 0px;
}
.cmmc-micro .learning-track-tile h4 {
  padding: 10px 20px;
  background-color: rgba(22, 76, 146, 0.89);
  font-size: 20px;
  margin: 0px;
  text-align: center;
}
.cmmc-micro .learning-track-tile:before {
  content: '';
  display: block;
  height: 120px;
  width: 100%;
  clip-path: polygon(0 0, 0 100%, 100% 0);
  background-color: rgba(159, 167, 180, 1);
  -webkit-box-shadow: 3px 3px 10px 1px rgba(145,153,160,0.50);
  box-shadow: 3px 3px 10px 1px rgba(145,153,160,0.50);
  position: absolute;
  left: 0px;
  top: 0px;
}
.cmmc-micro .learning-track-tile p {
  padding-top: 50px;
  padding-left: 80px;
  padding-right: 20px;
  padding-bottom: 50px;
}
@media screen and (max-width: 568px) {
  .cmmc-micro .microsite.cmmc .learning-track-tile h4 {
    font-size: 14px;
  }
.cmmc-micro .microsite.cmmc .learning-track-tile {
    min-width: 250px;
    margin: 15px auto;
    float: none;
  }
  .learning-tracks.container {
    text-align: center;
  }
  .cmmc-micro .header-banner {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .cmmc-micro .cmmc .learning-track-tile p {
    padding-left: 60px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .learning-track-tile:before {
    height: 100px;
  }
.cmmc-micro   .learning-track-tile h4 {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .cmmc .learning-track-tile {
    display: block;
    max-width: unset;
    margin-right: 20px;
    margin-left: 20px;
  }
.cmmc-micro   .learning-track-tile.cybersecurity-it {
    float: right;
  }
}
/*Series Curriculum*/
.series-curriculum.container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.cmmc-micro .curriculum-wrapper {
  display: block;
  padding-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cmmc-micro .curriculum-wrapper h4 {
  font-size: 20px;
  text-transform: capitalize;
}
.cmmc-micro .curriculum-wrapper .row {
  margin-left: 0px;
  margin-right: 0px;
}
.cmmc-micro .series-curriculum p.duration,
.cmmc-micro .series-curriculum p.applicable-tracks,
.cmmc-micro .series-curriculum p.info {
  text-transform: capitalize;
}
.cmmc-micro .series-curriculum p.info {
  padding-left: 5px;
}
@media screen and (max-width: 480px) {
  .cmmc-micro .cmmc .curriculum-wrapper {
    padding-left: 30px;
  }
}
@media screen and (max-width: 568px) {
  .cmmc-micro .cmmc .series-curriculum h3 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .cmmc .curriculum-wrapper {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .series-curriculum h3 {
    margin-bottom: 50px;
  }
}
/*REGISTRATION FORM*/
.cmmc .modal-dialog {
  max-width: 1200px;
}
.cmmc-micro .cmmc .modal-dialog p {
  padding-top: 0px;
}
.cmmc-micro .cmmc .modal-dialog .modal-content {
  border: none;
}
.cmmc-micro .cmmc #registerForm .modal-header,
.cmmc-micro .cmmc .modal-dialog .modal-header {
  background-color: #0b3e6f;
  border-bottom: 1px solid #0b3e6f;
  padding: 20px;
}
.cmmc-micro .cmmc #registerForm h4,
.cmmc-micro .cmmc .modal-dialog h4 {
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin-top: 0px;
  display: inline-block;
}
.cmmc-micro .cmmc #registerForm .modal-body {
  padding: 0px;
  width: 100%;
  display: flex;
}
.cmmc-micro .cmmc .modal-dialog .modal-body {
  width: fit-content;
  width: -moz-fit-content;
  display: block;
  margin: 0px auto;
}
.cmmc-micro .cmmc #registerForm .left-column,
.cmmc-micro .cmmc #registerForm .right-column {
  padding: 0px;
}
.cmmc-micro .cmmc #registerForm .left-column {
  content: '';
  background-image: url(images/images/microsite/cmmc/registration-modal.jpg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  flex: 1;
  display: flex;
  align-items: center;
}
.cmmc-micro .cmmc #registerForm .left-column .text-wrapper {
  background-color: rgba(64, 145, 206, 0.75);
  padding: 50px 30px;
}
.cmmc-micro .cmmc #registerForm .left-column p {
  text-align: left;
}
.cmmc-micro .cmmc #registerForm .intro {
  padding: 40px;
  text-align: left;
  max-width: 510px;
  display: block;
  margin: 0px auto;
}
.cmmc-micro .cmmc #registerForm .intro ul {
  display: block;
  margin: 0px auto;
  width: fit-content;
  width: -moz-fit-content;
}
.cmmc-micro .cmmc #registerForm .intro li {
  padding-top: 5px;
}
.cmmc-micro .cmmc #registerForm .intro li p {
  width: fit-content;
  font-size: 18px;
}
.cmmc-micro .cmmc #registerForm h6 {
  font-size: 20px
}
/*FORM FIELDS*/
.cmmc #registerForm .form-wrapper {
  width: fit-content;
  width: -moz-fit-content;
  margin: 0px auto;
}
.cmmc-micro .cmmc #registerForm .form-element {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  display: block;
}
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms {
  padding-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cmmc-micro .cmmc #registerForm label {
  padding-right: 5px;
  font-size: 14px;
  position: relative;
}
.cmmc-micro .cmmc #registerForm .required label:before {
  content: '*';
  color: #bd2e37;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  padding-right: 5px;
  font-weight: bold;
  top: 1px;
  position: absolute;
  left: -10px;
  display: inline-block;
}
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms label {
  margin-bottom: 0px;
}
.cmmc-micro .cmmc #registerForm input {
  box-shadow: 0 1px 19px 3px rgba(0,0,0,0.05);
  border-radius: 3px;
  height: 20px;
  float: none;
  width: 220px;
  padding: 5px;
}
.cmmc-micro .cmmc #registerForm input:focus {
  border: 2px solid #4091CE !important;
  box-shadow: 0 0 3px #4091CE !important;
  -moz-box-shadow: 0 0 3px #1c84f2 !important;
  -webkit-box-shadow: 0 0 3px #1c84f2 !important;
}
.cmmc-micro input:focus, textarea:focus, select:focus {
  outline-offset: 0px;
  outline: none;
}
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms input {
  display: inline-block;
  width: 15px;
  margin-top: 0px;
  height: 15px;
  vertical-align: middle;
  margin-bottom: 3px;
}
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms input:focus {
  outline: 2px solid #4091CE;
}
.cmmc-micro .cmmc #registerForm .form-element.error input {
  color: black;
}
.cmmc-micro .cmmc #registerForm .form-element .errormessage {
  color: #bd2e37;
  padding-right: 5px;
  max-width: 220px;
  margin-left: auto;
  font-size: 10px;
}
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms .errormessage {
  max-width: 100%;
  padding-top: 10px;
  padding-right: 0px;
}
/*CAPTCHA*/
.cmmc #registerForm .form-element.captcha-container {
  margin-bottom: 20px;
}
.cmmc-micro .cmmc #registerForm .captcha {
  padding-bottom: 0px;
  padding-top: 10px;
}
.cmmc-micro .cmmc #registerForm .captcha > div > div {
  transform: scale(.9);
  transform-origin: 100% 0%;
}
/*REQUIRED FIELD KEY*/
.cmmc #registerForm .required-field-key {
  display: block;
  text-align: right;
}
.cmmc-micro .cmmc #registerForm .required-field-key p {
  font-size: 8px;
  font-style: italic;
  text-align: right;
  color: #bd2e37;
  padding-right: 5px;
}
/*AGREE TO TERMS*/
.cmmc #registerForm .agree-to-terms a.terms-of-use {
  display: block;
  font-size: 10px;
  text-decoration: underline;
  font-weight: bold;
  padding-top: 5px;
}
.cmmc-micro .cmmc #registerForm .agree-to-terms a.terms-of-use,
.cmmc-micro .cmmc #registerForm .form-element.agree-to-terms .errormessage {
  padding-right: 25px;
}
.cmmc-micro .cmmc #registerForm .modal-footer {
  text-align: center;
}
/*REGISTER BUTTON*/
.cmmc #registerForm input.btn.register {
  font-size: 12px;
  padding: 1rem 3rem;
  height: auto;
  width: auto;
}
.cmmc-micro .cmmc #registerForm .button-wrapper {
  text-align: center;
  padding-bottom: 40px;
}
.cmmc-micro .cmmc .modal-content.confirmation-message .modal-body {
  padding: 50px 40px;
  text-align: center;
  line-height: 2em;
  min-height: 100px;
}
@media screen and (max-width: 480px) {
  body.cmmc #registerForm .modal-body .intro,
.cmmc-micro   body.cmmc #registerForm .left-column p {
    max-width: 300px;
  }
}
@media screen and (max-width: 568px) {
  body.cmmc #registerForm h4,
.cmmc-micro   body.cmmc .modal-dialog h4 {
    font-size: 18px;
    max-width: 350px;
  }
.cmmc-micro   body.cmmc #registerForm .left-column {
    background-size: cover;
  }
.cmmc-micro   body.cmmc #registerForm .left-column .text-wrapper {
    padding: 40px 30px;
  }
.cmmc-micro   .cmmc #registerForm .left-column p {
    margin: 0px auto;
    max-width: 400px;
  }
.cmmc-micro   body.cmmc #registerForm .intro {
    padding: 20px;
    max-width: 400px;
  }
.cmmc-micro   body.cmmc #registerForm h6 {
    font-size: 14px;
  }
.cmmc-micro   body.cmmc #registerForm .intro li p {
    font-size: 12px;
  }
.cmmc-micro   .cmmc #registerForm input {
    width: 200px;
  }
.cmmc-micro   .cmmc #registerForm label {
    padding-right: 2px;
    display: block;
  }
.cmmc-micro   .cmmc #registerForm .required label:before {
    position: relative;
    font-size: 10px;
    padding-right: 3px;
    left: unset;
  }
.cmmc-micro   .cmmc #registerForm .form-element {
    text-align: center;
  }
.cmmc-micro   .cmmc #registerForm .agree-to-terms a.terms-of-use,
.cmmc-micro   .cmmc #registerForm .form-element.agree-to-terms .errormessage {
    padding-right: 0px;
  }
.cmmc-micro   .cmmc #registerForm .form-element .errormessage {
    padding-right: 0px;
    margin: 0px auto;
  }
.cmmc-micro   .cmmc #registerForm .required-field-key p {
    text-align: center;
  }
.cmmc-micro   .cmmc #registerForm .captcha>div>div {
    transform: scale(.8);
    transform-origin: 50% 0%;
  }
}
@media screen and (max-width: 768px) {
  body.microsite.cmmc .modal-dialog {
    max-width: 100%;
  }
.cmmc-micro   .cmmc #registerForm .modal-body {
    flex-direction: column;
  }
.cmmc-micro   .cmmc #registerForm .left-column,
.cmmc-micro   .cmmc #registerForm .right-column {
    display: block;
    float: none;
    flex: unset;
    height: fit-content;
  }
.cmmc-micro   .cmmc #registerForm .left-column {
    height: fit-content;
    padding: 0px;
    background-size: 100% auto;
  }
.cmmc-micro   .cmmc #registerForm .left-column .text-wrapper {
    background-color: rgba(64,145,206,0.55);
    padding: 40px 70px;
  }
.cmmc-micro   .cmmc #registerForm h4,
.cmmc-micro   .cmmc .modal-dialog h4 {
    font-size: 22px;
  }
.cmmc-micro   .cmmc #registerForm .intro {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 992px) {
  .cmmc-micro .microsite.cmmc .modal-dialog {
    max-width: 768px;
  }
.cmmc-micro   .cmmc #registerForm h6 {
    font-size: 18px;
  }
.cmmc-micro   .cmmc #registerForm .intro li p {
    font-size: 14px;
  }
.cmmc-micro   .cmmc #registerForm label {
    font-size: 12px;
  }
.cmmc-micro   .cmmc #registerForm .form-element .errormessage {
    font-size: 8px;
  }
}
@media screen and (max-width: 1200px) {
  .cmmc-micro .cmmc .modal-dialog {
    max-width: 992px;
  }
.cmmc-micro   .cmmc #registerForm .left-column {
    background-position: center center;
  }
}