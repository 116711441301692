/*GENERAL STYLING*/
.micro-new .float-right {
  float: right;
}
.micro-new .float-none {
  float: none;
}
.micro-new .microsite-new .page-section {
  padding: 0px 0px;
}
.micro-new .microsite-new .secondary-color-1 {
  color: #4b97c6;
}
.micro-new .microsite-new .secondary-color-2 {
  color: #555b8a;
}
.micro-new .microsite-new .secondary-color-3 {
  color: #58adad;
}
.micro-new .microsite-new .secondary-color-4 {
  color: #1978b4;
}
.micro-new .microsite.microsite-new .heavy {
  font-weight: 600;
}
.micro-new .microsite.microsite-new .light {
  font-weight: lighter;
}
.micro-new .microsite.microsite-new p.large {
  font-size: 18px;
}
/*NAV HEADER*/
.micro-new header.microsite {
  background: #2773bd;
  background: -moz-linear-gradient(right, #092c58 0%, #2773bd 100%);
  background: -webkit-linear-gradient(right, #092c58 0%, #2773bd 100%);
  background: -o-linear-gradient(right, #092c58 0%, #2773bd 100%);
  background: -ms-linear-gradient(right, #092c58 0%, #2773bd 100%);
  background: linear-gradient(to right, #092c58 0%, #2773bd 100%);
  border: none;
  z-index: 600;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(1).active,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(1).active {
  border-bottom: 5px solid #4b97c6;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(2).active,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(2).active {
  border-bottom: 5px solid #555b8a;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(3).active,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(3).active {
  border-bottom: 5px solid #58adad;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(4).active,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(4).active {
  border-bottom: 5px solid #1978b4;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li.active,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li.active {
  background-color: #092c58;
  text-decoration: none;
  cursor: pointer;
}
.micro-new header.microsite-new .navbar-inverse .navbar-nav li.active a,
.micro-new header.microsite-new.smaller .navbar-inverse .navbar-nav li.active a {
  background-color: transparent;
  color: #fff;
}
@media (max-width: 768px) {
  .micro-new body.microsite.microsite-new header .navbar-header {
    height: auto;
    min-height: 60px;
    margin: 0;
    width: 100%;
  }
.micro-new   header .navbar-header .navbar-toggle {
    top: 12px;
  }
.micro-new   .microsite.microsite-new header.microsite-new .navbar-inverse .navbar-nav li.active {
    border-bottom: 0;
  }
.micro-new   header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(1).active,
  header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(1).active {
    background-image: linear-gradient(to right,#4b97c6,#a2c7eb);
  }
.micro-new   header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(2).active,
  header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(2).active {
    background-image: linear-gradient(to right,#555b8a,#a2c7eb);
  }
.micro-new   header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(3).active,
  header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(3).active {
    background-image: linear-gradient(to right,#58adad,#a2c7eb);
  }
.micro-new   header.microsite-new .navbar-inverse .navbar-nav li:nth-of-type(4).active,
  header.microsite-new.smaller .navbar-inverse .navbar-nav li:nth-of-type(4).active {
    background-image: linear-gradient(to right,#1978b4,#a2c7eb);
  }
.micro-new   header.microsite .navbar.navbar-inverse .navbar-nav li a {
    padding: 20px;
  }
 }
/*XL NAV TITLES*/
/*Styling for Header with Long Navigational Titles of 2+ Lines*/
.micro-new header.microsite.microsite-new.xl-nav-titles {
  height: 80px;
}
.micro-new header.microsite.xl-nav-titles .mainpage-wrapper {
  height: 100%;
  align-items: center;
}
.micro-new header.microsite.xl-nav-titles .navbar .navbar-collapse .navbar-nav {
  overflow: visible;
}
.micro-new header.microsite-new.xl-nav-titles .logoWrapper {
  top: 1px;
  left: 10px;
}
.micro-new header.microsite.xl-nav-titles .navbar-collapse {
  width: fit-content;
  padding-right: 20px;
}
.micro-new header.microsite.xl-nav-titles .navbar-inverse .navbar-nav li {
  height: 85px;
  top: 12px;
}
.micro-new header.microsite.xl-nav-titles .navbar.navbar-inverse li a {
  padding: 20px;
  width: fit-content;
  height: 85px;
}
.micro-new header.microsite.xl-nav-titles li a p {
  line-height: 1.3em;
  width: fit-content;
  color: #9d9d9d;
  font-size: 14px;
}
.micro-new header.microsite.xl-nav-titles li.active a p {
  color: white;
}
@media screen and (max-width: 480px) {
  .micro-new header.microsite.xl-nav-titles .navbar.navbar-inverse .navbar-nav li a p {
    line-height: 1.3em;
  }
 }
@media screen and (max-width: 768px) {
  .micro-new body header.microsite.microsite-new.xl-nav-titles {
    height: 60px;
  }
.micro-new   header.microsite.xl-nav-titles .navbar.navbar-inverse ul.navbar-nav li {
    top: unset;
    max-width: unset;
    height: auto;
  }
.micro-new   header.microsite.xl-nav-titles .navbar.navbar-inverse .navbar-collapse ul.navbar-nav li a {
    padding: 20px;
    width: 100%;
    max-width: unset;
    height: auto;
  }
.micro-new   header.microsite.xl-nav-titles .navbar .navbar-nav li a p {
    font-size: 14px;
    color: #5e85a1;
    line-height: 1.8em;
  }
.micro-new   header.microsite.xl-nav-titles .navbar .navbar-nav li.active a p {
    color: white;
  }
 }
@media screen and (max-width: 992px) {
  .micro-new header.microsite.xl-nav-titles .navbar .navbar-collapse {
    padding-right: 0px;
  }
.micro-new   header.microsite.xl-nav-titles .navbar.navbar-inverse .navbar-collapse .navbar-nav li a {
    max-width: 120px;
    padding: 12px;
  }
 }
@media screen and (max-width: 1200px) {
  .micro-new header.microsite.xl-nav-titles .navbar.navbar-inverse .navbar-nav li a {
    text-align: left;
    padding: 12px 20px 10px 20px;
    max-width: 140px;
  }
.micro-new   header.microsite.xl-nav-titles .navbar-nav li a p {
    font-size: 12px;
  }
 }
/*WHITE NAV*/
.micro-new header.microsite.white-nav {
  background: white;
  -webkit-box-shadow: 0px 3px 6px 2px rgba(0,0,0,0.16);
  box-shadow: 0px 3px 6px 2px rgba(0,0,0,0.16);
  height: 70px;
}
.micro-new header.microsite.white-nav .logoWrapper {
  top: 8px;
}
.micro-new header.white-nav .navbar-inverse .navbar-nav > li > a:hover,
.micro-new header.white-nav .navbar-inverse .navbar-nav > li > a:focus {
  color: #164C93;
}
.micro-new header.white-nav .navbar-inverse .navbar-nav > .active > a,
.micro-new header.white-nav .navbar-inverse .navbar-nav > .active > a:hover,
.micro-new header.white-nav .navbar-inverse .navbar-nav > .active > a:focus {
  color: white;
}
.micro-new header.microsite.white-nav .navbar.navbar-inverse li a {
  padding: 25px 20px 20px 20px;
}
.micro-new header.microsite-new.white-nav .navbar-inverse .navbar-nav li.active,
.micro-new header.microsite-new.smaller.white-nav .navbar-inverse .navbar-nav li.active {
  border-bottom: 0px;
}
.micro-new header.white-nav .navbar-nav li:nth-of-type(1).active,
.micro-new header.smaller.white-nav .navbar-nav li:nth-of-type(1).active {
  background-color: #4b97c6;
}
.micro-new header.white-nav .navbar-nav li:nth-of-type(2).active,
.micro-new header.smaller.white-nav .navbar-nav li:nth-of-type(2).active {
  background-color: #555b8a;
}
.micro-new header.white-nav .navbar-nav li:nth-of-type(3).active,
.micro-new header.smaller.white-nav .navbar-nav li:nth-of-type(3).active {
  background-color: #58adad;
}
.micro-new header.white-nav .navbar-nav li:nth-of-type(4).active,
.micro-new header.smaller.white-nav .navbar-nav li:nth-of-type(4).active {
  background-color: #1978b4;
}
.micro-new header.microsite.microsite-new.white-nav .navbar-toggle .icon-bar {
  background-color: #7c818c;
}
.micro-new header.microsite.microsite-new.white-nav .navbar-inverse .navbar-toggle {
  border: 2px solid white;
}
@media screen and (max-width: 768px) {
  .micro-new body header.microsite.white-nav {
    height: 60px;
  }
.micro-new   header.microsite.white-nav .navbar.navbar-inverse .navbar-nav li a {
    padding: 20px;
    line-height: 1.8em;
  }
.micro-new   header.microsite.white-nav .navbar.navbar-inverse .navbar-nav li {
    max-width: 100%;
  }
.micro-new   header.microsite.white-nav .navbar-collapse {
    margin-top: 5px;
  }
.micro-new   header.microsite.white-nav .navbar-collapse .navbar-nav li {
    background: rgba(224, 228, 235, 1);
  }
.micro-new   header.microsite.white-nav .navbar-inverse .navbar-nav li.active,
  header.microsite.white-nav .navbar-inverse .navbar-nav li.active {
    border-bottom: 0px;
    background-image: linear-gradient(to right,#164C93,#ffffff);
  }
 }
@media screen and (min-width: 769px) {
  .micro-new header.microsite.white-nav .navbar.navbar-inverse .navbar-nav li {
    border-bottom: 0px;
    height: 70px;
  }
 }
@media screen and (max-width: 992px) {
  .micro-new header.microsite.white-nav .navbar .navbar-nav li {
    max-width: 150px;
  }
.micro-new   header.microsite.white-nav .navbar.navbar-inverse li a {
    padding: 20px 20px 15px 20px;
  }
.micro-new   header.microsite.white-nav .navbar .navbar-nav li a {
    line-height: 1.3em;
  }
 }
/*WHITE NAV WITH BLUE HIGHLIGHT*/
.micro-new header.microsite.white-nav.secondary-highlight .navbar-inverse .navbar-nav li.active,
.micro-new header.microsite.white-nav.secondary-highlight.smaller .navbar-inverse .navbar-nav li.active {
  background-color: #13407c;
  -webkit-box-shadow: inset 0px 3px 17px 0px #0B2544;
  box-shadow: inset 0px 3px 17px 0px #0B2544;
  background-image: none;
}
/*DOUBLE LOGO NAV*/
.micro-new header.microsite.double-logo .logoWrapper.secondary-logo {
  margin-left: 40px;
  margin-top: 6px;
  left: unset;
  height: fit-content;
}
@media screen and (max-width: 768px) {
  .micro-new header.microsite.double-logo .navbar.navbar-inverse .navbar-nav {
    right: unset;
  }
 }
@media screen and (max-width: 1200px) {
  .micro-new header.microsite.double-logo .logoWrapper.secondary-logo {
    display: none;
  }
.micro-new   header.microsite.double-logo .navbar .navbar-nav {
    right: 10px;
  }
 }
/*EXTRA LONG*/
.micro-new header.microsite.extra-long {
  width: 100vw;
  padding: 0px 20px;
}
@media screen and (max-width: 768px) {
  .micro-new header.microsite.extra-long .navbar.navbar-inverse .navbar-collapse {
    margin-right: 0px;
    width: 100%;
  }
 }
@media screen and (max-width: 1200px) {
  .micro-new header.microsite.extra-long .mainpage-wrapper {
    max-width: 100%;
  }
.micro-new   header.microsite.extra-long {
    padding: 0px;
  }
.micro-new   header.microsite.extra-long .navbar .navbar-collapse {
    margin-right: 10px;
    width: fit-content;
  }
 }
@media screen and (min-width: 1200px) {
  .micro-new header.microsite.extra-long .mainpage-wrapper {
    max-width: 1700px;
    margin: 0px auto;
  }
 }
/*STICKY HEADERS*/
.micro-new .microsite-new .site-layout-title {
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #4b97c6;
  position: sticky;
  top: 80px;
  z-index: 500;
  border-top: 20px solid white;
  -webkit-box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.68);
  box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.68);
  scroll-snap-align: start;
  scroll-snap-type: y mandatory;
  background-clip: padding-box;
  /* scroll-snap-padding: 80px 0px; */
}
.micro-new .microsite-new .page-section .mainpage-wrapper .site-layout-title-inner {
  display: flex;
  align-items: center;
}
.micro-new .microsite-new .page-section--programs .site-layout-title,
.micro-new .microsite-new .page-section--prepare .site-layout-title {
  background-color: #4b97c6;
}
.micro-new .microsite-new .page-section--manage .site-layout-title,
.micro-new .microsite-new .page-section--benefits .site-layout-title {
  background-color: #555b8a;
}
.micro-new .microsite-new .page-section--tools .site-layout-title {
  background-color: #58adad;
}
.micro-new .microsite-new .page-section--assessments .site-layout-title,
.micro-new .microsite-new .page-section--bcp-dr .site-layout-title {
  background-color: #1978b4;
}
.micro-new .microsite-new .site-layout-title .site-layout--graphic.circle {
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.47);
  -moz-box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.47);
  box-shadow: 1px 2px 6px 0px rgba(0,0,0,0.47);
}
.micro-new .microsite-new .site-layout-title .graphic-wrap .graphic {
  width: 60px;
}
.micro-new .microsite-new .site-layout-title .site-layout--graphic {
  height: auto;
  width: 55px;
  display: inline-block;
  margin-right: 10px;
}
.micro-new .microsite-new .site-layout-title .graphic-wrap {
  margin: 0;
  line-height: 80px;
  text-align: center;
}
.micro-new .microsite-new .page-section .site-layout-title h2 {
  margin: 0;
  color: #ffffff;
  font-weight: 400;
  padding-left: 15px;
  font-size: 1.9em;
  padding-top: 5px;
  height: fit-content;
}
.micro-new .microsite-new span h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block;
}
@media screen and (max-width: 480px) {
  .micro-new .microsite.microsite-new .page-section .site-layout-title {
    height: 45px;
    border: none;
    top: 65px;
  }
.micro-new .microsite-new .page-section .site-layout-title .site-layout--graphic {
    margin-right: 0px;
  }
.micro-new .microsite-new .page-section .site-layout-title .mainpage-wrapper .site-layout-title-inner h2 {
    max-width: 70%;
    font-size: 1.4em;
    padding-top: 0px;
  }
.micro-new .microsite.microsite-new .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 60px;
  }
.micro-new .microsite-new .site-layout-title .mainpage-wrapper .site-layout-title-inner .site-layout--graphic.circle {
    height: 60px;
    width: 60px;
    margin-left: 10px;
  }
.micro-new .microsite-new .site-layout-title .mainpage-wrapper .site-layout-title-inner div.site-layout--graphic .graphic-wrap .graphic {
    width: 35px;
  }
 }
@media screen and (max-width: 580px) {
.micro-new .microsite.microsite-new .site-layout-title {
    top: 45px;
  }
 }
@media screen and (max-width: 640px) {
  .micro-new body.microsite.microsite-new .page-section .site-layout-title .mainpage-wrapper .site-layout-title-inner .header-tagline {
    display: none;
  }
 }
@media screen and (max-width: 768px) {
  .micro-new .nav-up.microsite-new .site-layout-title {
  top: 0px;
  }
.micro-new .microsite-new .site-layout-title {
    border-top: 20px solid transparent;
    top: 55px;
  }
 }
@media screen and (max-width: 992px) {
  .micro-new .microsite-new .page-section .site-layout-title .mainpage-wrapper h2 {
    font-size: 1.6em;
  }
.micro-new   .microsite-new .mainpage-wrapper .site-layout-title-inner .site-layout--graphic.circle {
    height: 70px;
    width: 70px;
    margin-right: 0px;
    margin-left: 10px;
  }
.micro-new   .microsite-new .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 70px;
  }
.micro-new   .microsite-new .mainpage-wrapper .site-layout-title-inner .site-layout--graphic .graphic-wrap .graphic {
    width: 50px;
  }
 }
@media screen and (max-width: 1200px) {
  .micro-new .microsite-new .site-layout-title .site-layout--graphic.circle {
    margin-right: 0px;
    margin-left: 10px;
  }
 }
.micro-new .microsite-new .banner {
  display: inline-block;
  width: 100%;
  margin-top: 60px;
  height: 550px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  z-index: 40;
}
/*TILE STYLING*/
.micro-new .microsite-new .tile-collection-wrap .tile-collection {
  display: inline-flex;
  justify-content: center;
}
.micro-new .microsite-new .tile-collection .tile-wrap {
  position: relative;
  display: inline-block;
  float: left;
  height: 100%;
  border: 2px solid #b0bbcb;
  margin: 5px 0;
  padding: 15px;
  background-color: #fff;
  z-index: 5;
  width: 280px;
}

.micro-new .microsite-new .tile-collection-wrap .tile-collection .tile--graphic {
  height: 140px;
  vertical-align: middle;
  line-height: 140px;
}
.micro-new .microsite-new .tile-collection-wrap .tile-collection .tile--body {
  padding: 0px;
}
.micro-new .microsite-new .tile-collection-wrap .tile-collection p {
  padding-left: 15px;
  padding-right: 15px;
}
.micro-new .microsite-new .tile-collection-wrap .tile-collection .tile {
  margin-bottom: 15px;
  padding: 0 10px;
  text-align: center;
}
.micro-new .microsite-new .tile-collection-wrap .tile-collection .tile--graphic img {
  width: auto;
  max-width: 120px;
  max-height: 139px;
  margin-bottom: 10px;
  display: inline-block;
}
.micro-new .microsite-new .tile-collection-wrap .tile-collection h4 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 10px auto;
  margin-bottom: 20px;
  color: #6d7377;
  line-height: 1.5em;
}
@media screen and (max-width: 640px) {
  .micro-new .microsite-new .tile-collection-wrap .tile-collection .tile--graphic {
    height: 100px;
    line-height: 100px;
  }
.micro-new   .microsite-new .page-section--site-layout .tile-wrap h5,
.micro-new .microsite-new .page-section--site-layout .tile-wrap h4 {
    line-height: normal;
    font-size: 18px;
    max-width: 200px;
    margin: 0px auto;
  }
.micro-new   .microsite-new .tile-collection-wrap .tile-collection p {
    margin-top: 0px;
    max-width: 240px;
  }
.micro-new   .microsite-new .tile-collection-wrap .tile-collection .tile--graphic img {
    margin-bottom: 0px;    
    max-width: 80px;
    max-height: 90px;
  }
 }
/*SITE LAYOUT SECTION*/
.micro-new .microsite-new .page-section--site-layout .tile-wrap {
  height: 420px;
  padding: 20px 30px;
}
.micro-new .microsite-new .page-section--site-layout .tile:nth-of-type(1) .tile-wrap {
  border-top: 5px solid #4b97c6;
}
.micro-new .microsite-new .page-section--site-layout .tile:nth-of-type(2) .tile-wrap {
  border-top: 5px solid #555b8a;
}
.micro-new .microsite-new .page-section--site-layout .tile:nth-of-type(3) .tile-wrap {
  border-top: 5px solid #58adad;
}
.micro-new .microsite-new .page-section--site-layout .tile:nth-of-type(4) .tile-wrap {
  border-top: 5px solid #1978b4;
}
.micro-new .microsite-new .page-section--site-layout .tile-wrap h5,
.micro-new .microsite-new .page-section--site-layout .tile-wrap h4 {
  max-width: 300px;
  display: block;
  margin: 0px auto;
  padding-top: 20px;
}
@media screen and (max-width: 480px) {
  .micro-new .microsite-new .tile-collection-wrap .tile-collection h4 {
    font-size: 1.3em;
    margin: 10px auto;
  }
.micro-new   .microsite-new .page-section.page-section--site-layout {
    padding-bottom: 40px;
  }
 }
@media screen and (max-width: 640px) {
  .micro-new .microsite-new .page-section.page-section--site-layout {
    padding: 0px;
  }
.micro-new   .microsite-new .page-section.page-section--site-layout .tile-collection-wrap .tile-collection .tile-wrap {
    width: 100%;
    height: fit-content;
    box-shadow: none;
  }
.micro-new   .microsite-new .page-section--site-layout .tile-collection-wrap .tile-collection .tile {
    display: block;
    margin: 0px auto;
    width: 100%;
    padding: 0px;
  }
.micro-new   .microsite-new .tile-collection-wrap {
    margin: 0px;
  }
.micro-new   .tile-collection-wrap .tile-collection .tile.tile--shadow .tile-wrap {
    box-shadow: none;
  }
.micro-new   .page-section--site-layout .tile-collection .tile .tile-wrap:last-of-type {
    border-bottom: 0px;
  }
 }
@media screen and (max-width: 768px) {
  .micro-new .microsite-new .tile-collection-wrap .tile-collection p {
    font-size: 1em;
  }
 }
@media screen and (max-width: 840px) {
  .micro-new .microsite-new .page-section--site-layout .tile-collection-wrap .tile-collection .tile-wrap {
    width: 300px;
  }
 }
@media screen and (max-width: 1200px) {
  .micro-new .microsite-new .page-section--site-layout .tile-collection .tile-wrap {
    width: 400px;
  }
.micro-new   .microsite-new .tile-collection-wrap .tile-collection p {
    margin-bottom: 15px;
    max-width: 340px;
  }
 }

 /* PRESS RELEASE */

.micro-new .microsite .press-release h4 {
  text-transform: uppercase;
  line-height: 23px;
}