body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
html {
  height: 100%;
}
ul {
  padding-left: 20px;
  margin-top: 0px;
  width: fit-content;
}
ul li {
  padding-top: 10px;
  line-height: 1.875em;
  letter-spacing: .08em;
  font-size: 1.05em;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
