/* NCG Main Page CSS */

body .stars2  {
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	text-align: center;
	background-color: #FFFFFF;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	line-height:120%;
	/*background-image: url('images/microsite/stars2/bkg.gif');
	background-repeat: repeat-y;
	background-position: center;*/
}
.stars2 #container {
	width:862px;
	margin-right:auto;
	margin-left:auto;
	margin-top:10px;
	padding:0px;
	text-align:left;
	background-color:#FFFFFF;
}

.stars2 #banner {
	float:left;
	margin-top: 0px;
	width:870px;
	/*background-image:url(images/microsite/stars2/TopNav_back_blue.jpg);*/
}	

.stars2 #contentbanner {
	float:left;
	width:680px;
	margin-top:12px;
	margin-left:12px;
}	

.stars2 #navbar {
	float:left;
	margin-top: 20px;
	width:860px;
/*	height:75px;*/
	background: #CDCF91;
}

.stars2 #splashcontent {
	/*margin-top: 160px;*/
	width:400px;
	height:300px;
	background: #91AA9D;
}

.stars2 #rtSplash {
	/*margin-top: 160px;*/
	float:left;
	width:200px;
	height:300px;
	background: #3E606F;
}

.stars2 #topnavbar {
	float:left;
	/*margin-top: 45px;*/
	width:900px;
	text-align:right;
	background: #FFFFFF;
}

.stars2 #leftspacer {
	float:left;
	margin-top:10px;
	width:50px;
	background:#FFFFFF;
}

.stars2 #splashmedia {
	/*margin-top:30px;*/
	margin-left:50;
	text-align:center;
	width:810px;
}

.stars2 #leftbar {
	width:160px;
	/*padding:0px;*/
	padding-right:2px;
	float:left;
	margin-top:22px;
	/* background-image:url(images/microsite/stars2/left_Nav_back.gif); */
	background-repeat:repeat-y;
	/*background:#EBEACC;*/
}

.stars2 #srv_leftbar {
	width:165px;
	/*padding:0px;*/
	padding-right:4px;
	float:left;
	margin-top:12px;
	/*background-image:url(images/microsite/stars2/left_Nav_back_blue.jpg);*/
	/* background-image:url(images/microsite/stars2/ridge.jpg); */
	border-left:1px solid #999;
	border-top:1px solid #999;
	background-repeat:repeat-y;
	background-position: 10px;
	/*background:#EBEACC;*/
}

.stars2 #left_list {
	width:140px;
	margin-left:10px;
	border-left:solid 3px #116C9B;
	border-bottom:solid 1px #116C9B;
	padding-left:4px;
	padding-top:12px;
	padding-bottom:10px;
}

.stars2 #right_list {
	width:160px;
	margin-left:10px;
	border-bottom:solid 1px #116C9B;
}

.stars2 #contents {
	/*margin-left:210px; 
	margin-right:210px;
	margin-top:22px;*/
	width:480px;
	padding:0px;
	float:left;
	margin-top:12px;
	margin-left:12px;
	border:1px solid #666;
	background:#FFFFFF;
	margin-bottom: 50px;
}

.stars2 #rightbar {
	width:180px;
	padding-top:2px;
	float:left;
	margin-top:12px;
	margin-left:12px;
	border-top:3px solid #666;
	border-bottom:1px groove #666;
	background:#FFFFFF;
}


.stars2 a.nav:link {color: #41545E}
.stars2 a.nav:visited {color: #41545E}
.stars2 a.nav:hover {color: #6D7D80}
.stars2 a.nav:active {color: #0000FF}
.stars2 a.nav {
	font-size: 10px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #EBEACC;
}

.stars2 a.leftnav:link {color: #41545E}
.stars2 a.leftnav:visited {color: #41545E}
.stars2 a.leftnav:hover {color: #6D7D80}
.stars2 a.leftnav:active {color: #0000FF}
.stars2 a.leftnav {
	font-size: 13px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #EBEACC;
	line-height:15px;
}

.stars2 a.srv_leftnav:link {color: #122550}
.stars2 a.srv_leftnav:visited {color: #41545E}
.stars2 a.srv_leftnav:hover {color: #6D7D80}
.stars2 a.srv_leftnav:active {color: #0000FF}
.stars2 a.srv_leftnav {
	font-size: 13px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #EBEACC;
}

.stars2 p {
	font-size:10px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #41545E;
	margin-top:20px;
	margin-left:10px;
	margin-right:10px;
}

	
.stars2 h1 {
font-size:16px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #41545E;
	margin-top:20px;
	margin-left:10px;
	margin-right:10px;
}

.stars2 h2 {
	font-size:12px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #41545E;
	margin-top:20px;
	margin-left:10px;
	margin-right:10px;
}

.stars2 td {
	font-family: Arial, Helvetica, sans-serif;
}

.stars2 th {
	font-family: Arial, Helvetica, sans-serif;
}

.stars2 ul.square {
	list-style-type: square;
	font-size:10px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	color: #41545E;
}

.stars2 p.footer_style {
color: #000000;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 65%;
	font-weight: bold;
	text-align: center;
}