@charset 'UTF-8';



/* Arrows */
.slick-slider .slick-prev,
.slick-slider .slick-next
{
    width: 50px;
    height: 50px;
    display: none;
    opacity: 0;
    z-index: 1000;
}
.slick-slider:hover .slick-arrow.slick-prev,
.slick-slider:hover .slick-arrow.slick-next {
    opacity: .5;
    transition: color 1s;
}
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    font-size: 45px;
    top: 50%;
    transform: translateY(-50%);
    color: #7a8da2;
}
@media screen and (max-width: 960px) {
    .slick-slider .slick-arrow.slick-prev:before,
    .slick-slider .slick-arrow.slick-next:before {
        font-size: 32px;
    }
    .slick-slider .slick-arrow.slick-prev {
        left: 15px;
        bottom: 5px;
        top: unset;
        transform: unset;
    }
    .slick-slider .slick-arrow.slick-next {
        right: 15px;
        top: unset;
        bottom: 5px;
        transform: unset;
    }
}
.slick-slider.invert .slick-prev:before,
.slick-slider.invert .slick-next:before
{
    color: #00549a;
    transition: color 1s;
}
.slick-slider .slick-prev
{
    left: 25px;
}
.slick-slider .slick-next
{
    right: 25px;
}
.slick-slider .slick-dots
{
    bottom: 25px;
    height: fit-content;
}
.slick-slider .slick-dots li button:before
{
    font-size: 12px;

    top: 50%;
    left: 50%;

    color: #7a8da2;
    transform: translate(-50%, -50%);

    transition: opacity 1s;
}
.slick-slider.invert .slick-dots li button:before {
    color: #00549a;
    transition: background-color 1s;
}
.slick-slider .slick-dots li.slick-active button:before
{
    color: #7a8da2;
    font-size: 16px;
    transition: opacity 1s;
}
.slick-slider .invert .slick-dots li.slick-active button:before {
    color: #00549a;
    transition: background-color 1s;
}