/*GENERAL*/
.privacy-services .microsite.privacy .mainpage-wrapper {
  max-width: 1200px;
}
.privacy-services .microsite.privacy .page-section .page-section {
  padding: 100px 0px;
}
.privacy-services .privacy.microsite h3 {
  font-size: 30px;
}
.privacy-services .extra-margin-small {
  margin-bottom: 75px;
}
.privacy-services .extra-margin {
  margin-bottom: 150px;
}
.privacy-services .privacy.microsite  .page-section--intro h3 {
  font-weight: bold;
}
.privacy-services .privacy.microsite .page-section--privacy-assessments {
  padding-bottom: 100px;
}
.privacy-services .privacy.microsite .page-section--privacy-breach-im,
.privacy.microsite .page-section--privacy-data-management-engineering,
.privacy.microsite .page-section--privacy-assessments {
  padding-top: 100px;
}
@media screen and (max-width: 480px) {

.privacy-services body.privacy p {
    font-size: .8em;
  }
}
@media screen and (max-width: 640px) {
  .privacy-services .microsite-new.microsite.privacy .page-section .page-section {
    padding: 80px 0px;
    text-align: center;
  }
.privacy-services .microsite-new.privacy.microsite .page-section--privacy-assessments {
    padding-bottom: 80px;
  }
.privacy-services .microsite-new.privacy.microsite .page-section--privacy-breach-im,
  .privacy-services .microsite-new.privacy.microsite .page-section--privacy-data-management-engineering,
  .privacy-services .microsite-new.privacy.microsite .page-section--privacy-assessments {
    padding-top: 80px;
  }
  .privacy-services body.privacy.microsite .page-section h3 {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-services .privacy.microsite .page-wrap {
    overflow-x: unset;
  }
.privacy-services body.privacy.microsite h3 {
    margin-bottom: 0px;
    font-size: 20px;
  }
.privacy-services  .privacy p {
    font-size: .9em;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .privacy .extra-margin,
  .privacy-services .privacy .extra-margin-small {
    margin-bottom: 0px;
  }
  .privacy-services .privacy.microsite h3 {
    font-size: 26px;
  }
}
@media screen and (min-width: 1200px) {
  .privacy-services .microsite.privacy .page-section .page-section {
    padding: 150px 0px;
  }
  .privacy-services .privacy.microsite .page-section--privacy-breach-im,
  .privacy-services .privacy.microsite .page-section--privacy-data-management-engineering,
  .privacy-services .privacy.microsite .page-section--privacy-assessments {
    padding-top: 150px;
  }
}
/*SECTION HEADERS*/
.privacy-services .microsite-new.privacy .site-layout-title {
  top: 80px;
}
.privacy-services .microsite-new .page-section--program-development .site-layout-title {
  background-color: #4b97c6;
}
.privacy-services .microsite-new .page-section--privacy-breach-im .site-layout-title {
  background-color: #555b8a;
}
.privacy-services .microsite-new .page-section--privacy-data-management-engineering .site-layout-title {
  background-color: #58adad;
}
.privacy-services .microsite-new .page-section--privacy-assessments .site-layout-title {
  background-color: #1978b4;
}
.privacy-services .privacy .page-section--privacy-breach-im .site-layout-title .graphic-wrap .graphic {
  width: 55px;
}
.privacy-services .privacy .page-section--privacy-breach-im .site-layout-title .graphic-wrap {
  line-height: 70px;
}
@media screen and (max-width: 480px) {
  .privacy-services .privacy.microsite .page-section--privacy-breach-im .site-layout-title .site-layout--graphic .graphic-wrap .graphic {
    width: 30px;
  }
.privacy-services   .privacy.microsite .page-section--privacy-breach-im .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 50px;
  }
  .privacy-services body.microsite-new.privacy div.site-layout-title {
    top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-services .microsite.privacy .page-section .site-layout-title h2 {
    max-width: 80%;
  }
.privacy-services body.microsite-new.privacy .site-layout-title {
    top: 80px;
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .microsite.microsite-new.privacy .page-section .site-layout-title h2 {
    font-size: 1.2em;
  }
.privacy-services   .privacy .page-section--privacy-breach-im .site-layout-title .site-layout--graphic .graphic-wrap .graphic {
    width: 50px;
  }
.privacy-services   .privacy .page-section--privacy-breach-im .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .microsite-new.privacy .page-section .site-layout-title h2 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 520px) {
  .privacy-services .microsite.microsite-new.privacy .page-section .site-layout-title h2 {
    font-size: 0.8em;
  }
}
/*CAROUSEL*/
.privacy-services .privacy .carousel {
  height: 1000px;
}
.privacy-services .carousel .item {
  height: 100%;
  width: 100%;
  position: relative;
}
.privacy-services .carousel .item:after {
  content: '';
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  opacity: .7;
  display: block;
}
/* .carousel .item.item-1:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-1.png');
}
.privacy-services .carousel .item.item-2:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-2.png');
}
.privacy-services .carousel .item.item-3:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-3.png');
}
.privacy-services .carousel .item.item-4:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-4.png');
}
.privacy-services .carousel .item.item-5:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-5.png');
  opacity: .8;
}
.privacy-services .carousel .item.item-6:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-6.png');
}
.privacy-services .carousel .item.item-7:after {
  background-image: url('images/images/microsite/privacy-services/pp-slider-7.png');
} */
.privacy-services .privacy .carousel .carousel-inner {
  height: 100%;
  width: 100%;
}
.privacy-services .privacy .carousel .carousel-control {
  top: 50%;
  transform: translateY(-50%);
}
.privacy-services .privacy .carousel-indicators li {
  padding: unset;
}
.privacy-services .privacy .carousel .carousel-control .glyphicon {
  color: #ffffff;
  top: unset;
}
.privacy-services 
.carousel .carousel-text {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
}
.privacy-services .carousel .title-wrapper,
.carousel .sub-text-wrapper {
  padding: 50px;
  float: none;
}
.privacy-services .carousel .item-1 .title-wrapper,
.carousel .item-1 .sub-text-wrapper {
  background-color:rgba(80, 93, 149, 0.75);
}
.privacy-services .carousel .item-2 .title-wrapper,
.carousel .item-2 .sub-text-wrapper {
  background-color: rgba(89, 147, 155, 0.8);
}
.privacy-services .carousel .item-3 .title-wrapper,
.carousel .item-3 .sub-text-wrapper {
  background-color: rgba(72, 136, 173, 0.82);
}
.privacy-services .carousel .item-4 .title-wrapper,
.carousel .item-4 .sub-text-wrapper {
  background-color: rgba(8, 66, 125, 0.75);
}
.privacy-services .carousel .item-5 .title-wrapper,
.carousel .item-5 .sub-text-wrapper {
  background-color: rgba(68, 145, 228, 0.75);
}
.privacy-services .carousel .item-6 .title-wrapper,
.carousel .item-6 .sub-text-wrapper {
  background-color: rgba(115, 142, 176, 0.75);
}
.privacy-services .carousel .item-7 .title-wrapper,
.carousel .item-7 .sub-text-wrapper {
  background-color: rgba(74, 96, 140, 0.75);
}
.privacy-services .carousel-text .title-wrapper {
  padding-left: 60px;
  padding-right: 60px;
}
.privacy-services .carousel-text .title-wrapper.float-right {
  margin-left: auto;
}
.privacy-services .carousel-text .title-wrapper.float-right h1 {
  margin-left: unset;
  margin-right: auto;
  text-align: left;
}
.privacy-services .carousel-text h1,
.carousel-text h3,
.carousel-text p {
  color: white;
}
.privacy-services .slick-slider {
  height: 1000px;
}
.micro .privacy-services .carousel {
  height: 1000px;
}
.privacy-services .slick-list {
  height: inherit;
}
.privacy-services .slick-slider .slick-track {
  height: inherit;
}
.privacy-services .carousel-text .title-wrapper h1 {
  max-width: 650px;
  margin-bottom: 0px;
  margin-left: auto;
  text-align: right;
  font-size: 50px;
  font-weight: bold;
  margin-top: 0px;
}
.privacy-services .carousel-text.long-text .title-wrapper h1 {
  max-width: 800px;
}
.privacy-services .carousel-text .sub-text-wrapper {
  margin-top: 60px;
  margin-left: auto;
  padding-right: 60px;
  padding-left: 60px;
}
.privacy-services .carousel-text .sub-text-wrapper h3 {
  margin-top: 0px;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 0px;
}
.privacy-services .carousel-text .sub-text-wrapper p {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .micro .privacy-services .carousel-text .title-wrapper h1 {
    font-size: 30px;
  }
  .micro .privacy-services .carousel-text .sub-text-wrapper h3 {
  font-size: 18px;
}
.micro .privacy-services .carousel-text .sub-text-wrapper p {
  font-size: 12px;
}
.privacy-services .slick-slider {
  height: 800px;
}
.micro .privacy-services .carousel {
  height: 800px;
}
.micro .privacy-services .carousel .carousel-text {
  top: 40%;
  transform: translateY(-40%);
}
.micro .privacy-services .slick-slider .slick-dots li button:before {
  border: 1px solid #fff;
  height: 10px;
  width: 10px;
}
.micro .privacy-services .slick-slider .slick-dots li.slick-active button:before {
    height: 14px;
  width: 14px;
}
}
.micro .slick-dots li {
  margin-left: 8px;
  margin-right: 8px;
}
.micro .slick-slider .slick-dots {
  bottom: 60px;
}
.micro .slick-slider .slick-dots li button:before {
  border: 2px solid #fff;
  color: rgba(0, 0, 0, 0);
  border-radius: 12px;
  opacity: .9;
  height: 15px;
  width: 15px;
    content: '';
  display: inline-block;
}
.micro .slick-slider .slick-dots li.slick-active button:before {
  background-color: #ffffff;
    height: 20px;
  width: 20px;
}
@media screen and (max-width: 480px) {
  .privacy-services .carousel-text .title-wrapper,
  .privacy-services .carousel-text .sub-text-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
.privacy-services   .carousel .title-wrapper,
  .privacy-services .carousel .sub-text-wrapper {
    padding: 30px;
  }
.privacy-services   body.microsite .carousel .playPause {
    display: flex;
    right: 0px;
    width: 100%;
    justify-content: center;
  }
.privacy-services   body .carousel .playPause .fa {
    opacity: .4;
    height: 30px;
    width: 30px;
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-services .privacy .carousel {
    height: 800px;
  }
.privacy-services .privacy .carousel .carousel-inner .item {
    height: 100%;
  }
.privacy-services .carousel-indicators li,
  .privacy-services .carousel-indicators .active {
    margin: 5px;
  }
.privacy-services body.microsite.privacy h1 {
    font-size: 30px;
  }
.privacy-services   .privacy.microsite .carousel-text .sub-text-wrapper h3 {
    font-size: 18px;
  }
.privacy-services   .privacy.microsite.microsite-new p.large {
    font-size: 12px;
  }
.privacy-services   body.microsite .carousel-control .glyphicon.glyphicon-chevron-left {
    margin-top: 0px;
    margin-left: 0px;
    bottom: 40px;
  }
.privacy-services   body.microsite .carousel-control .glyphicon.glyphicon-chevron-right {
    margin-top: 0px;
    margin-right: 0px;
    bottom: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .microsite.privacy h1 {
    font-size: 40px;
  }
.privacy-services   .privacy .carousel-text .sub-text-wrapper h3 {
    font-size: 24px;
  }
}
@media screen and (min-width: 2000px) {
  .privacy-services .privacy .carousel-text .title-wrapper {
    margin-left: auto;
  }
.privacy-services   .privacy .carousel-text .title-wrapper h1 {
    margin-left: unset;
    margin-right: auto;
    text-align: left;
  }
}
@media screen and (min-width: 1800px) {
  .privacy-services .carousel .carousel-text .title-wrapper h1 {
    max-width: 800px;
  }
}
/*SITE LAYOUT*/
.privacy-services .microsite.privacy .page-section--site-layout {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.privacy-services .microsite.privacy .page-section--site-layout:after {
  background-image: none;
}
.privacy-services .microsite.privacy .page-section--site-layout .tile-wrap h5 {
  padding-bottom: 10px;
}
.privacy-services .privacy.microsite-new .tile-collection-wrap .tile-collection p {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.privacy-services .privacy .tile-collection-wrap .tile-collection .tile .tile-wrap {
  padding: 30px 10px 60px 10px;
}
.privacy-services .microsite.privacy .page-section--site-layout #tilePrivacyAssessments .tile-wrap h5 {
  max-width: 180px;
}
.privacy-services .microsite-new .tile-collection-wrap .tile-collection #tilePrivacyDataManagement img,
.microsite-new .tile-collection-wrap .tile-collection #tilePrivacyProgramDevelopment img {
  max-width: 140px;
}
.privacy-services .privacy .tile-collection-wrap .tile-collection .tile--learn-more {
  margin: 0px auto;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .privacy-services .microsite.privacy .page-section--site-layout {
    padding-top: 0px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .tile-collection-wrap .tile-collection .tile--learn-more {
    bottom: 30px;
  }
.privacy-services .microsite.privacy .page-section--site-layout #tilePrivacyProgramDevelopment .tile-wrap h5 {
    max-width: 180px;
  }
}
/*PROGRAM DEVELOPMENT*/
.privacy-services .page-section .page-section.left .image-wrapper {
  position: absolute;
  right: 0px;
}
.privacy-services .page-section .page-section.right .text-wrapper {
  text-align: right;
}
.privacy-services .page-section .page-section.right .image-wrapper {
  position: absolute;
  left: 0px;
}
@media screen and (max-width: 480px) {
  .privacy-services .microsite.privacy .page-section .page-section.left .image-wrapper,
  .privacy-services .microsite.privacy .page-section .page-section.right .image-wrapper,
  .privacy-services .microsite.privacy .page-section .page-section.stripe .image-wrapper  {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .privacy-services .microsite.privacy .page-section .page-section.left .image-wrapper,
  .privacy-services .microsite.privacy .page-section .page-section.right .image-wrapper {
    top: 0px;
    transform: unset;
    float: none;
    margin: 0px auto;
    right: unset;
    left: unset;
    position: relative;
  }
.privacy-services .privacy .page-section .page-section.right .text-wrapper {
    text-align: center;
  }
.privacy-services .page-section .page-section .image-wrapper img {
    float: none;
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .privacy .page-section .page-section.left .image-wrapper,
  .privacy-services .privacy .page-section .page-section.right .image-wrapper {
    top: 55%;
    transform: translateY(-55%);
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .page-section .page-section.left .image-wrapper {
    top: 70%;
    transform: translateY(-70%);
  }
}
/*Intro*/
.privacy-services .privacy.microsite .page-section--risk-based-approach-pd h3 {
  padding-bottom: 0px;
}
.privacy-services .page-section--program-development p,
.privacy-services .page-section--privacy-breach-im p,
.privacy-services .page-section--privacy-data-management-engineering p,
.privacy-services .page-section--privacy-assessments p {
  padding-top: 30px;
  padding-left: 0px;
  padding-right: 0px;
}
.privacy-services .page-section--program-development .page-section--intro .image-wrapper {
  bottom: 80px;
}
.privacy-services .page-section--program-development .page-section--intro img {
  max-width: 700px;
}
@media screen and (max-width: 480px) {
  .privacy-services body .page-section .mainpage-wrapper .text-wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 640px) {
  .privacy-services .page-section .mainpage-wrapper .text-wrapper {
    float: none;
    margin: 0px auto;
  }
.privacy-services   .page-section--program-development .page-section--intro img {
    float: none;
  }
.privacy-services   .page-section--program-development p,
  .privacy-services .page-section--privacy-breach-im p,
  .privacy-services .page-section--privacy-data-management-engineering p,
  .privacy-services .page-section--privacy-assessments p {
    width: 100%;
      padding-top: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .privacy.microsite .page-section--program-development .page-section--intro .image-wrapper {
    top: 60%;
    transform: translateY(-60%);
  }
.privacy-services   .left.page-section--program-development p,
  .privacy-services .left.page-section--privacy-breach-im p,
  .privacy-services .left.page-section--privacy-data-management-engineering p,
  .privacy-services .left.page-section--privacy-assessments p {
    display: block;
    float: none;
  }
.privacy-services   .right.page-section--program-development p,
  .privacy-services .right.page-section--privacy-breach-im p,
  .privacy-services .right.page-section--privacy-data-management-engineering p,
  .privacy-services .right.page-section--privacy-assessments p {
    display: block;
  }
}
/*Risk Based Approach*/
.privacy-services .page-section--risk-based-approach-pd .text-wrapper {
  padding-bottom: 60px;
}
.privacy-services .page-section--risk-based-approach-pd .large-mobile-image-wrapper {
  float: none;
}
@media screen and (max-width: 576px) {
.privacy-services div.page-section--risk-based-approach-pd img {
    height: 370px;
  }
}
@media screen and (max-width: 640px) {
.privacy-services body .page-section--risk-based-approach-pd .text-wrapper {
    padding-bottom: 20px;
  }
.privacy-services .page-section--risk-based-approach-pd img {
    height: 450px;
    max-width: unset;
  }
.privacy-services .page-section--risk-based-approach-pd .large-mobile-image-wrapper {
  overflow-x: scroll;
  padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-services div.page-section--risk-based-approach-pd .text-wrapper {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .page-section--risk-based-approach-pd .text-wrapper p {
    max-width: unset;
    padding-top: 10px;
  }
.privacy-services   .page-section--risk-based-approach-pd .text-wrapper {
    padding-bottom: 40px;
  }
}
/*Tailoring Privacy*/
.image-mobile {
    display: none;
  }
.privacy-services .microsite.privacy .page-section .page-section--tailoring-privacy {
  padding-bottom: 330px;
}
.privacy-services .page-section--tailoring-privacy .image-wrapper {
  bottom: 0px;
}
.privacy-services .page-section--tailoring-privacy img {
  max-width: 1500px;
}
@media screen and (max-width: 480px) {
  body.microsite.privacy.microsite-new .page-section .page-section.page-section--tailoring-privacy {
    padding-bottom: 150px;
  }
  .privacy-services body.microsite.privacy .page-section .page-section.page-section--tailoring-privacy .image-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 640px) {
.privacy-services body.microsite.privacy .page-section .page-section.page-section--tailoring-privacy .image-wrapper {
    top: 0px;
    position: relative;
  }
  .privacy-services .image-mobile {
    display: block;
  }
  .image-desktop {
    display: none;
  }
.privacy-services body.microsite.privacy.microsite-new .page-section .page-section.page-section--tailoring-privacy {
      margin-bottom: 0px;
    }
}
@media screen and (max-width: 992px) {
  body.microsite.privacy.microsite-new .page-section .page-section--tailoring-privacy {
    padding-bottom: 200px;
  }
.privacy-services .microsite.privacy .page-section .page-section--tailoring-privacy .image-wrapper {
    top: unset;
    transform: none;
    bottom: 0px;
  }
}
@media screen and (max-width: 1200px) {
  body.microsite.privacy.microsite-new .page-section .page-section--tailoring-privacy {
    margin-bottom: 100px;
  }
.privacy-services   .privacy .page-section .page-section--tailoring-privacy .image-wrapper {
    top: unset;
    transform: translateY(-5%);
    bottom: 5%;
  }
}
@media screen and (max-width: 1500px) {
  body.microsite.privacy .page-section .page-section--tailoring-privacy {
    padding-bottom: 300px;
  }
}
@media screen and (min-width: 2000px) {
  .privacy-services .page-section--tailoring-privacy img {
    float: none;
    margin: 0px auto;
  }
}
/*Data Driven Process*/
.privacy-services .page-section--data-driven-process .image-wrapper {
  top: 160px;
}
.privacy-services .page-section--data-driven-process .image-wrapper img {
  max-width: 500px;
}
/*Ongoing Support*/
.privacy-servicesction--ongoing-support .image-wrapper {
  top: 150px;
}
.privacy-services .page-section--ongoing-support .image-wrapper img {
  max-width: 700px;
}
@media screen and (max-width: 1200px) {
  .privacy-services .privacy .page-section .page-section.page-section--ongoing-support .image-wrapper {
    top: 60%;
    transform: translateY(-60%);
  }
  .privacy-services body.microsite.privacy .page-section.page-section--ongoing-support {
    padding-bottom: 100px;
  }
}
/*PRIVACY BREACH & INCIDENT MANAGEMENT*/
/*Intro*/
.privacy-services .page-section--privacy-breach-im .page-section--intro .image-wrapper {
  top: 370px;
}
.privacy-services .page-section--privacy-breach-im .page-section--intro img {
  max-width: 700px;
}
@media screen and (max-width: 400px) {
  .privacy-services .privacy .page-section--privacy-breach-im .text-wrapper {
    width: 66%;
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .microsite.privacy .page-section--privacy-breach-im .page-section--intro .image-wrapper {
    top: 30%;
    transform: translateY(-30%);
  }
}
/*IM Capabilities*/
.privacy-services .page-section--im-capabilities .image-wrapper {
  top: 50%;
  transform: translateY(-50%);
}
.privacy-services .page-section--im-capabilities img {
  max-width: 600px;
}
/*Breach Response*/
.privacy-services .page-section--breach-response .image-wrapper {
  top: 290px;
}
.privacy-services .page-section--breach-response img {
  max-width: 600px;
}
/*PRIVACY DATA MANAGEMENT & ENGINEERING*/
.privacy-services/*Intro*/
.privacy-services .page-section--privacy-data-management-engineering .page-section--intro .image-wrapper {
  top: 250px;
}
.privacy-services .page-section--privacy-data-management-engineering .page-section--intro img {
  max-width: 650px;
}
/*PARALLELOGRAM*/
.privacy-services .privacy.microsite .page-section.stripe {
  margin-top: 100px;
}
.privacy-services .parallelogram {
  clip-path: polygon(0 50%, 0 0, 100% 50%, 100% 100%);
  position: absolute;
  top: 0px;
  height: 900px;
}
.privacy-services .parallelogram.teal {
  background: rgb(88,173,173);
  background: linear-gradient(114deg, rgba(88,173,173,1) 0%, rgba(88,173,173,0.5046393557422969) 50%, rgba(88,173,173,0) 100%);
}
.privacy-services .parallelogram.light-blue {
  background: rgb(75,151,198);
  background: linear-gradient(114deg, rgba(75,151,198,1) 0%, rgba(75,151,198,0.5018382352941176) 50%, rgba(75,151,198,0) 100%);
}
.privacy-services .parallelogram.med-blue {
  background: rgb(25,120,180);
  background: linear-gradient(114deg, rgba(25,120,180,1) 0%, rgba(25,120,180,0.5018382352941176) 50%, rgba(25,120,180,0) 100%);
}
.privacy-services .parallelogram.purple {
  background: rgb(85,91,138);
  background: linear-gradient(114deg, rgba(85,91,138,1) 0%, rgba(85,91,138,0.5018382352941176) 50%, rgba(85,91,138,0) 100%);
}
.privacy-services .page-section.stripe .text-wrapper {
  background-color: rgba(255, 255, 255, 0.72);
  padding: 50px 50px 70px 70px;
  -webkit-box-shadow: 2px 3px 3px 1px rgba(0,0,0,0.36);
  box-shadow: 2px 3px 3px 1px rgba(0,0,0,0.36);
  margin-top: 40px;
}
.privacy-services .parallelogram .image-wrapper {
  top: 200px;
}
.privacy-services .parallelogram img {
  max-width: 550px;
  float: right;
}
@media screen and (max-width: 640px) {
  .privacy-services body.privacy.microsite .page-section.stripe {
    margin-top: 0px;
  }
.privacy-services .privacy.microsite .parallelogram {
    clip-path: none;
    background: none;
    height: fit-content;
    position: relative;
    float: none;
  }
  .privacy-services body .page-section .parallelogram img {
  max-width: unset;
}
.privacy-services .page-section.stripe .text-wrapper {
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    background: none;
  }
.privacy-services .microsite.privacy .page-section.stripe .image-wrapper {
    float: none;
    top: 0px;
    transform: unset;
    margin: 0px auto;
    right: unset;
    left: unset;
    position: relative;
    padding-right: 0px;
  }
.privacy-services .page-section.stripe .image-wrapper img {
    margin: 0px auto;
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .page-section.stripe .text-wrapper {
    margin-top: 0px;
  }
.privacy-services   .privacy .parallelogram {
    height: 100%;
  }
.privacy-services   .microsite.privacy .parallelogram .image-wrapper {
    top: 30%;
    transform: translateY(-30%);
    padding-left: 0px;
  }
.privacy-services   .microsite.privacy .parallelogram .image-wrapper img {
    padding: 0px;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .privacy .parallelogram .image-wrapper {
    top: 170px;
  }
}
/*Privacy Data Management*/
.privacy-services .page-section--privacy-data-management .image-wrapper {
  top: 150px;
}
.privacy-services .page-section--privacy-data-management img {
  max-width: 520px;
}
/*Privacy Engineering*/
.privacy-services .page-section--privacy-engineering .parallelogram .image-wrapper {
  top: 130px;
}
.privacy-services .page-section--privacy-engineering .parallelogram img {
  max-width: 500px;
}
@media screen and (max-width: 640px) {
  body.microsite.privacy .page-section .page-section--privacy-engineering .parallelogram .image-wrapper {
    float: none;
    top: 0px;
    transform: unset;
    margin: 0px auto;
    right: unset;
    left: unset;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  body.microsite.privacy .page-section--privacy-engineering .parallelogram .image-wrapper {
    top: 30%;
    transform: translateY(-30%);
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .microsite.privacy .page-section--privacy-engineering .parallelogram .image-wrapper {
    top: 20%;
    transform: translateY(-20%);
  }
}
@media screen and (max-width: 1600px) {
  .privacy-services .page-section--privacy-engineering .parallelogram .image-wrapper {
    top: 100px;
  }
}
/*PRIVACY ASSESSMENTS*/
.privacy-services/*Intro*/
.privacy-services .page-section--privacy-assessments .page-section--intro .image-wrapper {
  bottom: 0px;
}
.privacy-services .page-section--privacy-assessments .page-section--intro img {
  max-width: 580px;
}
@media screen and (max-width: 768px) {
  body.privacy.microsite .page-section--privacy-assessments .page-section--intro .image-wrapper {
    top: 60%;
    transform: translateY(-60%);
  }
}
@media screen and (max-width: 992px) {
  .privacy-services .privacy.microsite .page-section--privacy-assessments .page-section--intro .image-wrapper {
    top: 70%;
    transform: translateY(-70%);
  }
}
@media screen and (max-width: 1200px) {
  .privacy-services .page-section--privacy-assessments .page-section--intro img {
    max-width: 500px;
  }
}
/*Detailed Insight*/
.privacy-services .page-section--detailed-insight .image-wrapper {
  top: 200px;
}
.privacy-services .page-section--detailed-insight img {
  max-width: 580px;
}