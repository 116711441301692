.crunch .homepage_layout {
  position: relative;
  width: 100%;
  margin: 0 auto;
  float: none;
  display: inline-block;
  background-color: #fff;
  z-index: 5;
  border-top: 6px solid #1593cd;
}
@media (max-width: 768px) {
  .crunch .homepage_layout {
    display: block;
  }
}
.crunch .homepage_layout .featured {
  background-color: #fff;
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 0;
  padding-left: 15px;
  border-bottom: 1px solid #d5e1eb;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured {
    width: 100%;
    max-width: none;
    padding: 0 15px;
  }
}
.crunch .homepage_layout .featured .feature-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .feature-wrapper {
    margin-top: 50px;
    overflow: hidden;
    display: inline-block;
  }
}
.crunch .homepage_layout .featured .featured-spotlight {
  height: 150px;
  width: 20%;
  z-index: 10;
  padding-right: 15px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-spotlight {
    margin: 0 auto;
    width: auto;
    padding: 0;
    flex-direction: column;
  }
}
.crunch .homepage_layout .featured .featured-spotlight .graphic-wrap {
  margin: 0;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-spotlight .graphic-wrap {
    display: block;
    width: 100px;
    margin: 0 auto;
    padding: 0;
  }
}
.crunch .homepage_layout .featured .featured-spotlight h4 {
  margin: 0;
  font-size: 20px;
  width: auto;
  display: inline-block;
  line-height: 1.3em;
  color: #3daedc;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-spotlight h4 {
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
}
.crunch .homepage_layout .featured .featured-slider {
  height: fit-content;
  width: 80%;
  border: 1px solid #d0e2eb;
  border-bottom: 0;
  border-top: 0;
  margin: 0;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-slider {
    height: auto;
    border: none;
    width: 100%;
    padding-top: 40px;
  }
}
.crunch .homepage_layout .featured .slick-dots {
  width: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .crunch body .homepage_layout .featured .slick-dots {
    top: 195px;
    left: 50%;
    right: 0px;
    width: fit-content;
    width: -moz-fit-content;
    transform: translateX(-50%);
    padding: 0px;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
.crunch   body .homepage_layout .featured .slick-dots li {
    margin-left: 3px;
    margin-right: 3px;
  }
}
.crunch .homepage_layout .featured .slick-dots li {
  width: 20px;
  height: 20px;
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 2px 0px;
}
.crunch .homepage_layout .featured .slick-dots li.slick-active button {
  border: none;
  height: 100%;
  color: transparent;
  background-color: #d7dbde;
}
.crunch .homepage_layout .featured .slick-dots li button {
  border: none;
  height: 100%;
  width: 100%;
  color: transparent;
  background-color: #eaedef;
  overflow: hidden;
}
.crunch .homepage_layout .featured .featured-tile {
  position: relative;
  float: right;
  padding: 10px;
  display: inline-block;
}
.crunch .homepage_layout .featured .featured-image {
  display: inline-block;
  height: 130px;
  width: 24%;
  top: 0;
  vertical-align: top;
  position: relative;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .mainpage-wrapper  .featured-tile {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
.crunch   .homepage_layout .featured .mainpage-wrapper .featured-image img {
    position: relative;
    top: unset;
    transform: unset;
  }
.crunch   .homepage_layout .featured .mainpage-wrapper .featured-image {
    height: auto;
    margin-top: 10px;
    display: block;
    width: 100%;
  }
}
.crunch .homepage_layout .featured .featured-image img {
  width: 100%;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-image img {
    width: 100%;
    height: auto;
  }
}
.crunch .homepage_layout .featured .featured-body {
  width: 75%;
  display: inline-block;
  padding: 10px 15px 0 20px;
  padding-top: 0;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .crunch .homepage_layout .featured .featured-body {
    padding: 10px 0;
    padding-bottom: 30px;
    width: 100%;
  }
}
.crunch .homepage_layout .featured .featured-body .featured-body--title {
  position: relative;
  bottom: 0;
  padding-bottom: 5px;
  margin: 0;
  font-size: 15px;
  width: 100%;
  font-weight: 200;
}
.crunch .homepage_layout .featured .featured-body .featured-body--title h5 {
  margin: 0;
  color: #0a72a3;
  font-weight: bold;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0.03em;
  padding-top: 5px;
}
.crunch .homepage_layout .featured .featured-body p {
  display: inline-block;
  padding: 0;
  line-height: 1.6em;
  font-size: 15px;
  color: #698998;
  max-width: 450px;
}
.crunch .homepage_layout .featured .featured-body .btn-default {
  letter-spacing: 0.03em;
  margin-top: 15px;
  padding: 7px 15px;
}
.crunch .homepage_layout .page-section:nth-of-type(even) {
  background-color: #f8fafc;
}
.crunch .homepage_layout #intro.page-section {
  z-index: 10;
  padding-top: 100px;
}
.crunch .homepage_layout #intro.page-section .learn-animate {
  color: #1091cc;
  opacity: 0.3;
  transition: opacity 1s;
}
@media (max-width: 768px) {
  .crunch .homepage_layout #intro.page-section .learn-animate {
    opacity: 1;
  }
}
.crunch .homepage_layout #intro.page-section .learn-animate.visible {
  opacity: 1;
  transition: opacity 1s;
}
.crunch .homepage_layout #uniting:before {
  content: '';
  background-image: url(images/images/index/page/capabilities.jpg);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 10%;
  margin-left: 50px;
  margin-top: 90px;
  width: 800px;
  height: 500px;
  border-radius: 5px;
  z-index: 0;
  opacity: 0.8;
}
@media (max-width: 992px) {
  .crunch .homepage_layout #uniting:before {
    background-image: none;
  }
}
.crunch .homepage_layout #uniting:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 0.08) 80%);
}
.crunch .homepage_layout #compliance .graphic-wrap {
  width: 40%;
}
@media (max-width: 768px) {
  .crunch .homepage_layout #compliance .graphic-wrap {
    margin: 0;
    width: 100%;
  }
}
.crunch .primary_layout {
  position: relative;
  display: inline-block;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
}
.crunch .primary_layout .sideBar,
.crunch .primary_layout .sideBar.relative {
  float: left;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .crunch .primary_layout .sideBar,
.crunch .primary_layout .sideBar.relative {
    /* IE10+ CSS styles go here */
    display: block;
  }
}
@media (max-width: 991px) {
  .crunch .primary_layout .sideBar,
.crunch .primary_layout .sideBar.relative {
    width: 100% !important;
    max-width: 100%;
  }
}
.crunch .primary_layout .page-section {
  width: 68%;
}
.crunch .primary_layout .blogPage .page-section,
.crunch .primary_layout .pressRelease .page-section {
  width: 85%;
  padding: 30px 60px 20px 30px;
}
@media screen and (max-width: 992px) {
  .crunch .primary_layout .blogPage .page-section,
.crunch .primary_layout .pressRelease .page-section {
    float: none;
    margin: 0px auto;
    display: block;
    padding: 30px 20px 30px 20px;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .primary_layout .page-section {
    padding: 25px 15px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .crunch .primary_layout .page-section {
    width: 100%;
    padding: 5px 0;
  }
}
@media screen and (max-width: 768px) {
  .crunch .primary_layout .page-section .page-section {
    padding: 0;
  }
}
.crunch .primary_layout .page-section,
.crunch .primary_layout .page-section .page-section {
  float: right;
}
.crunch .primary_layout .page-section--striped,
.crunch .primary_layout .page-section .page-section--striped {
  padding: 30px;
}
@media (max-width: 768px) {
  .crunch .primary_layout .page-section--striped,
.crunch .primary_layout .page-section .page-section--striped {
    padding: 15px;
  }
}
.crunch .primary_layout .page-section--striped .mainpage-wrapper,
.crunch .primary_layout .page-section .page-section--striped .mainpage-wrapper {
  background-color: transparent;
}
.crunch .primary_layout .page-section--striped:last-of-type,
.crunch .primary_layout .page-section .page-section--striped:last-of-type {
  padding-bottom: 50px;
  border: none;
}
.crunch .primary_layout--full .page-section {
  width: 100%;
}
.crunch .primary_layout--full .page-section--striped {
  padding: 60px 0;
}
@media (max-width: 768px) {
  .crunch .primary_layout--full .page-section--striped {
    padding: 15px 0;
  }
}
.crunch .secondary_layout {
  position: relative;
  display: inline-block;
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 768px) {
  .crunch .secondary_layout {
    display: table;
  }
}
.crunch .secondary_layout #intro.page-section {
  padding-top: 0;
}
.crunch .secondary_layout #intro.page-section .col-lg-8 {
  padding-top: 50px;
}
@media (max-width: 768px) {
  .crunch .secondary_layout #intro.page-section .col-lg-8 {
    padding: 20px 15px;
  }
}
.crunch .secondary_layout .sideBar {
  float: right;
}
.crunch .secondary_layout .page-section {
  display: inline-block;
  float: left;
  width: 65%;
}
@media screen and (max-width: 768px) {
  .crunch .secondary_layout .page-section {
    width: 100%;
  }
}
.crunch .secondary_layout .page-section--full {
  width: 100%;
}
.crunch .secondary_layout--full .page-section {
  width: 100%;
}
.crunch .secondary_layout--full .page-section--striped {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.crunch html {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100%;
}
.crunch *,
*:before,
*:after {
  box-sizing: inherit;
}
.crunch body {
  position: relative;
  margin: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.08em;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
}
.crunch .page-wrap {
  background-color: #fff;
  display: inline-block;
  height: auto;
  position: relative;
  width: 100%;
  padding: 0;
  padding-bottom: 100px;
  z-index: 5;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .crunch .page-wrap {
    /* IE10+ CSS styles go here */
    display: table;
    background-color: #fff;
    position: relative;
    width: 100%;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .page-wrap {
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .crunch .page-wrap {
    padding: 0;
    z-index: 0;
    overflow-x: hidden;
  }
}
.crunch .page-wrap--no-pad {
  padding-bottom: 0;
}
.crunch .page-section {
  position: relative;
  display: inline-block;
  padding: 5rem 0;
  width: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .crunch .page-section {
    padding: 10px 0;
  }
}
.crunch .page-section .page-section {
  padding: 30px 0 0 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .crunch .page-section .page-section {
    padding: 25px 0;
  }
}
.crunch .page-section--mini.page-section {
  padding: 20px 0;
}
.crunch .page-section--striped {
  display: inline-block;
  width: 100%;
}
.crunch .page-section--striped:nth-of-type(odd) {
  background-color: #f7f9fc;
  border-bottom: 1px solid rgba(221, 221, 221, 0.62);
}
.crunch #intro {
  padding-top: 0px;
}
.crunch .page-section--striped:nth-of-type(even) {
  background-color: #fff;
  border-bottom: 1px solid rgba(221, 221, 221, 0.62);
}
.crunch .page-section--bordered {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #b0bbcb;
}
.crunch .page-section--bordered:last-of-type {
  border: none;
}
.crunch .page-section.containTop {
  padding-top: 0;
  padding-bottom: 0;
}
.crunch .page-section .wrapper {
  padding: 0;
}
@media (max-width: 768px) {
  .crunch .page-section .wrapper {
    padding: 10px 0;
  }
}
.crunch .page-section .content-left {
  padding-right: 55px;
}
@media screen and (max-width: 768px) {
  .crunch .page-section .content-left {
    padding-right: 15px;
  }
}
.crunch .page-section .content-right {
  padding-left: 55px;
}
@media screen and (max-width: 768px) {
  .crunch .page-section .content-right {
    padding-left: 15px;
  }
}
.crunch .page-section .white-box {
  position: relative;
  display: inline-block;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #b8bdc6;
}
.crunch .page-section .white-box--trans {
  background-color: rgba(255, 255, 255, 0.85);
  border: none;
  padding: 20px;
}
.crunch .mainpage-wrapper {
  position: relative;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  float: none;
  display: block;
}
.securityPortal-img {
  display: none;
}
@media (max-width: 768px) {
  .securityPortal-img {
    display: block;
    float: right;
    padding-right: 20px;
    max-width: 60%;
    padding-top: 75px;
  }
}
@media (max-width: 768px) {
  .crunch .mainpage-wrapper {
    max-width: 100%;
    width: 100%;
  }
}
.crunch .pageIntro {
  color: #7a96b3;
  font-style: italic;
  margin-bottom: 40px;
}
.crunch .flex-wrap {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .crunch .flex-wrap {
    flex-direction: column;
  }
}
.crunch .flex-wrap .flex-item {
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .crunch .flex-wrap .flex-item {
    width: 100%;
    margin: 30px auto;
  }
  .crunch .flex-wrap .flex-item div.graphic-wrap {
  justify-content: flex-end;
}
}
.crunch .flex-wrap .flex-item .graphic-wrap {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.crunch .flex-wrap .flex-item .graphic-wrap img {
  display: block;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .crunch .flex-wrap .flex-item .graphic-wrap img {
    width: 100%;
    max-height: none;
    max-width: 250px;
  }
  .topictext {
  max-width: 70%;
  text-align: center;
  margin: 0px auto;
}
}
.topictext {
  margin-top: auto;
}
.crunch .mobile {
  display: none;
}
@media (max-width: 768px) {
  .crunch .mobile {
    display: block;
  }
}
.crunch .mobile--inline {
  display: none;
}
@media (max-width: 768px) {
  .crunch .mobile--inline {
    display: inline;
  }
}
.crunch a {
  cursor: pointer;
  color: #2773bd;
}
.crunch a:hover {
  color: #2d8adf;
  text-decoration: underline;
}
.crunch .custom-tooltip + .tooltip > .tooltip-inner {
  background-color: #144d91;
  letter-spacing: 0.06em;
}
.crunch .custom-tooltip + .tooltip > .tooltip-arrow {
  border-bottom-color: #144d91;
}
.crunch .tooltips {
  display: none;
}
@media screen and (max-width: 992px) {
  .crunch .tooltips {
    display: block;
  }
}
.crunch .imgDetails {
  line-height: 1.5em;
  font-size: 13px;
  margin: 0 0 25px 0;
  font-weight: 400;
  color: #778997;
  padding: 0px 8px 8px 10px;
  font-style: italic;
}
@media (max-width: 768px) {
  .crunch .imgDetails {
    padding: 0;
  }
}
.crunch body:after {
  content: "";
  display: block;
  position: initial;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(3, 12, 30, 0);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.crunch body.overlay:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(3, 12, 30, 0.61);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.crunch hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
  width: 100%;
}
.crunch #adobe {
  position: relative;
  display: inline-flex;
  width: 100%;
  margin: 0px auto;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}
.crunch #adobe h5 {
  display: inline-block;
  font-size: 12px;
  color: #2773bd;
  font-weight: normal;
  line-height: normal;
  margin: 0px;
  text-align: left;
}
#adobe:hover,
#adobe:focus {
  text-decoration: none;
}
.crunch .adobeDownload {
  top: 0;
  position: absolute;
  right: 15px;
  background-color: white;
  border-radius: 300px;
  height: 90px;
  width: 90px;
  font-size: 12px;
  text-align: center;
  line-height: 1.2em;
  color: #7d8c99;
  padding-top: 2px;
}
.crunch .adobeDownload a {
  color: #7d8c99;
}
.crunch .adobeDownload a:hover {
  color: #1091cc;
  text-decoration: none;
}
.crunch .adobeDownload .fa {
  font-size: 30px;
  color: #db7575;
  display: block;
  padding: 10px 0 7px 0;
}
@media (max-width: 768px) {
  .crunch .adobeDownload {
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    height: auto;
    border-radius: 0;
    padding-bottom: 3px;
    background-color: transparent;
  }
.crunch   .adobeDownload .fa {
    display: inline-block;
    padding-right: 10px;
    vertical-align: -6px;
  }
}
.crunch .download:after {
  font-family: "FontAwesome", serif;
  font-size: 23px;
  padding-left: 10px;
  vertical-align: -3px;
  color: #019fa1;
  content: '\f019';
}
.crunch header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border-top: 1px solid #e9edf2;
  z-index: 50;
  overflow-y: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 2px 2px 2px rgba(86, 106, 153, 0.49);
  background: #ffffff;
  background: -moz-linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, #ffffff), color-stop(22%, #ffffff), color-stop(100%, rgba(255, 255, 255, 0.86)));
  background: -webkit-linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%);
  background: -o-linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%);
  background: -ms-linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%);
  background: linear-gradient(45deg, #ffffff 0%, #ffffff 22%, rgba(255, 255, 255, 0.86) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}
.crunch header .mainpage-wrapper {
  display: table;
  align-items: center;
  height: 100%;
  padding-bottom: 42px;
}
.crunch .microsite header.microsite .mainpage-wrapper {
  height: 60px;
  padding-bottom: 0px;
}
@media screen and (min-width: 992px) {
  .crunch header .navbar-nav .logo {
    display: none;
  }
.crunch   header .mainpage-wrapper .logoWrapper {
    width: 50%;
    display: inline-block;
    position: absolute;
    z-index: 100;
  }
.crunch   header .mainpage-wrapper .logoWrapper img {
    height: auto;
  }
.crunch   header .mainpage-wrapper .logoWrapper .logoLink {
    display: inline-block;
    padding-top: 15px;
  }
.crunch   header .mainpage-wrapper .contactWrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    opacity: 1;
    transition: opacity 1s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo {
    padding: 0px;
    text-align: left;
    width: auto;
    float: right;
    color: #5E8293;
    letter-spacing: 0.08em;
    margin-bottom: 0px;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mobile-call {
    display: none;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .phone:before {
    font-family: "FontAwesome", serif;
    content: '\f095';
    position: absolute;
    left: -25px;
    vertical-align: middle;
    color: #82b9e1;
    font-size: 18px;
    padding-top: 2px;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mail:before {
    font-family: "FontAwesome", serif;
    content: '\f003';
    position: absolute;
    left: -25px;
    color: rgba(144, 191, 208, 0.72);
    font-size: 16px;
    vertical-align: middle;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mail {
    color: #5fa7c1;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mail:hover {
    cursor: pointer;
    color: #2773bd;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mail:hover:before {
    color: #66bde5;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .mail,
  header .mainpage-wrapper .contactWrapper .contactInfo .phone {
    opacity: 1;
    -webkit-transition: opacity 1.5s;
    -moz-transition: opacity 1.5s;
    -ms-transition: opacity 1.5s;
    -o-transition: opacity 1.5s;
    transition: opacity 1.5s;
    transition-delay: 0.3s;
    margin-left: 15px;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo li {
    display: block;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.8em;
    position: relative;
    border-right: 1px solid rgba(241, 244, 245, 0.43);
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo li span {
    color: #6fb3ce;
    font-size: 12px;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo li a {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #3c8fb6;
    font-weight: 400;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo li a:hover {
    text-decoration: none;
  }
.crunch   header .mainpage-wrapper .contactWrapper .contactInfo .active a {
    color: #108ec9;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper {
    height: 80px;
    width: 220px;
    display: inline-block;
    position: absolute;
    z-index: 0;
    background-color: transparent;
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper .NCGlogo {
    margin: 15px auto 0 auto;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper img {
    -webkit-transition: height 5s, opacity 1s;
    -moz-transition: height 5s, opacity 1s;
    -ms-transition: height 5s, opacity 1s;
    -o-transition: height 5s, opacity 1s;
    transition: height 5s, opacity 1s;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper #desktop.NCGlogo {
    display: block;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper #mid.NCGlogo {
    display: none;
  }
.crunch   header .mainpage-wrapper .contactWrapper .logoWrapper #mobile.NCGlogo {
    display: none;
  }
.crunch   header .smTrigger {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .crunch header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-top: 1px solid #e9edf2;
    z-index: 50;
    height: 56px;
  }
.crunch   header .logoWrapper {
    background-color: transparent;
  }
.crunch   header .logoWrapper .NCGlogo {
    margin: 0 auto;
  }
.crunch   header .contactWrapper {
    position: absolute;
  }
.crunch   header .contactWrapper .contactInfo {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .crunch header {
    overflow-y: auto;
    top: 0;
    height: auto;
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
  }
.crunch   header.nav-up {
    top: -55px;
    height: auto;
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
  }
.crunch   header.nav-down {
    top: 0;
    height: auto;
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
  }
.crunch   header .logoWrapper {
    left: 0;
    top: 0;
    position: absolute;
    width: 180px;
    z-index: 2000;
  }
.crunch   header .logoWrapper .NCGlogo {
    height: auto;
    position: relative;
  }
.crunch   header .contactWrapper {
    position: absolute;
  }
.crunch   header .contactInfo {
    padding: 0;
    margin: 0;
    margin-top: 3px;
  }
.crunch   header .mail {
    display: none;
  }
.crunch   header.main .mainpage-wrapper {
    display: none;
  }
}
.crunch header.main .logoWrapper {
  opacity: 1;
  height: auto;
  transition: all 0.5s;
  transition-delay: 0.5s;
}
@media screen and (max-width: 768px) {
  .crunch header.main .logoWrapper {
    height: 50px;
    padding: 3px 7px;
    width: 180px;
  }
.crunch   header.main .logoWrapper img {
    width: 130px;
    margin-left: 0;
    background-color: #fff;
  }
}
.crunch header.main .logoWrapper img {
  -webkit-transition: height 5s;
  -moz-transition: height 5s;
  -ms-transition: height 5s;
  -o-transition: height 5s;
  transition: height 5s;
}
@media screen and (min-width: 992px) {
  .crunch header.main.smaller {
    overflow-y: hidden;
    height: 56px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
.crunch   header.main.smaller .contactWrapper {
    opacity: 0;
    transition: opacity 0.3s;
  }
.crunch   header.main.smaller .smTrigger {
    display: block;
  }
.crunch   header.main.smaller .logoWrapper {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
  }
.crunch   header.main.smaller .logoWrapper img {
    -webkit-transition: height 5s;
    -moz-transition: height 5s;
    -ms-transition: height 5s;
    -o-transition: height 5s;
    transition: height 5s;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav {
    right: 0;
    top: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li {
    width: 15.9%;
    align-items: center;
    position: relative;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.logo {
    display: block;
    min-width: 190px;
    background-color: #fff;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.logo:hover .logoLink {
    background-color: #fff;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.logo .logoLink {
    width: 100%;
    padding: 0;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.logo .logoLink .linkWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.logo .logoLink .linkWrapper img {
    width: 160px;
    margin: 5px 10px;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li:nth-of-type(2) {
  border-left: 0;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.phone,
  header.main.smaller .navbar.navbar-inverse .navbar-nav li.mail {
    display: flex;
    width: 7%;
    height: 63px;
    align-items: center;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.mail .fa {
    font-size: 20px;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li.mail:hover,
  header.main.smaller .navbar.navbar-inverse .navbar-nav li.phone:hover {
    cursor: pointer;
    background-color: transparent;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li .fa {
    text-align: center;
    position: absolute;
    top: 27px;
    right: 0;
    left: 0;
    color: #fff;
    width: 100%;
    font-size: 26px;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav li a {
    text-align: left;
    line-height: 0.7em;
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 0;
    padding-top: 13px;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav .hideNCG {
    display: none;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav .smTrigger {
    display: block;
  }
.crunch   header.main.smaller .navbar.navbar-inverse .navbar-nav .contactHide {
    display: block;
    width: 5%;
    padding: 0;
  }
}
@media screen and (min-width: 992px) and (max-width: 768px) {
  .crunch header.main.smaller .logoWrapper {
    opacity: 1;
    height: auto;
  }
}
.crunch header.main .navbar-header a.logoLink {
  display: none;
}
@media (max-width: 768px) {
  .crunch header.main .logoLink img {
    max-width: 140px;
  }
.crunch   header.main .navbar-header a.logoLink {
    display: inline-block;
  }
.crunch   header.main .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #0b3e6f;
  }
.crunch   header.main .navbar-header {
    border: none;
    border-top: 2px solid #fff;
    border-bottom: 3px solid #40aada;
    box-shadow: 2px 2px 2px rgba(3, 68, 110, 0.47);
    background: white;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
.crunch   header.main .navbar-header .navbar-toggle {
    border: none;
  }
}
.crunch header.main .navbar-inverse {
  box-shadow: 2px 2px 2px rgba(3, 68, 110, 0.47);
}
.crunch header.main .navbar-collapse {
  height: auto;
  border-top: none;
  border-bottom: 2px solid #fff;
  box-shadow: 2px 2px 2px rgba(3, 68, 110, 0.47);
  background: #40aada;
  background: -moz-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #40aada), color-stop(100%, #1091cc));
  background: -webkit-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -o-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -ms-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: linear-gradient(to right, #40aada 0%, #1091cc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7cacd0', endColorstr='#47739d', GradientType=1);
}
@media (max-width: 992px) {
  .crunch header.main .navbar-collapse {
    border-top: 1px solid #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}
@media (max-width: 768px) {
  .crunch header.main .navbar-collapse {
    padding: 0;
    box-shadow: 3px 3px 5px rgba(118, 143, 154, 0.51);
    border: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom: 3px solid #40aada;
  }
}
.crunch header.main .navbar-nav li {
  border-right: 1px solid #8bdafe;
  width: 25%;
}
.crunch header.main .navbar-nav li:nth-of-type(2) {
  border-left: 1px solid #8bdafe;
  border-right: 1px solid #8bdafe;
}
.crunch header.main .navbar-nav li a {
  padding: 10px;
  color: #eee;
  text-align: center;
  font-weight: 400;
}
@media (max-width: 768px) {
  .crunch header.main .navbar-nav li a {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .crunch header .navbar-header {
    height: auto;
    min-height: 55px;
    margin: 0;
    width: 100%;
  }
.crunch   header .navbar-header .navbar-toggle {
    position: absolute;
    margin: 0;
    right: 10px;
    top: 8px;
    padding: 10px;
    background-image: none;
    border-radius: 4px;
    display: block;
  }
}
.crunch header .navbar.navbar-inverse {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  min-height: 20px;
  border: none;
  margin: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.crunch header .navbar.navbar-inverse .mobile {
  display: none;
}
@media (max-width: 992px) {
  .crunch header .navbar.navbar-inverse {
    right: 0;
    bottom: 0;
    height: auto;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}
@media (max-width: 768px) {
  .crunch header .navbar.navbar-inverse {
    right: 0;
    top: 0;
    height: auto;
    box-shadow: none;
    overflow: visible;
    position: relative;
  }
.crunch   header .navbar.navbar-inverse .mobile {
    display: inline-block;
  }
}
.crunch header .navbar.navbar-inverse .navbar-collapse {
  width: 100%;
  padding: 0;
}
.crunch header .navbar.navbar-inverse .navbar-collapse.in {
  overflow-y: auto;
  height: auto;
}
.crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  height: auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  float: none;
}
@media (max-width: 992px) and (min-width: 769px) {
  .crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
  }
}
@media (max-width: 768px) {
  .crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav {
    flex-direction: column;
    padding-left: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav li {
  padding: 0;
  text-align: center;
}
.crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.08em;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav li {
    padding-top: 10px;
    width: 16%;
    height: 63px;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li:nth-of-type(2) {
  border-left: 0;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo {
    display: block;
    width: 200px;
    background-color: #fff;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo:hover .logoLink {
    background-color: #fff;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo .logoLink {
    width: 100%;
    padding: 0;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo .logoLink .linkWrapper {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo .logoLink .linkWrapper img {
    width: 160px;
    margin: 2px auto;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li .fa {
    font-size: 26px;
    text-align: center;
    color: #fff;
    width: 100%;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a {
    text-align: left;
    line-height: 0.7em;
    padding-left: 10px;
    padding-top: 14px;
    min-height: 55px;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a .smTrigger {
    display: block;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a .contactHide {
    display: block;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.phone,
  header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.mail {
    display: flex;
    width: 7%;
    height: 63px;
    align-items: center;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.mail .fa {
    font-size: 20px;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.mail:hover,
  header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.phone:hover {
    cursor: pointer;
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav li {
    width: 100%;
    height: auto;
    background: #eceeed;
    padding-top: 0;
    border: none;
    border-top: 1px solid #fff;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.active {
    background-image: linear-gradient(to right, #3c8dc2, #a2c7eb);
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.active a {
    color: #fff;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.phone,
  .crunch header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.mail {
    display: none;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li.logo {
    display: none;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li .contactHide {
    display: none;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a {
    color: #5e85a1;
    text-transform: uppercase;
    letter-spacing: 0.13em;
    text-align: left;
    background-color: transparent;
    padding: 10px 15px;
    border-bottom: 1px solid #fff;
    line-height: normal;
    height: auto;
    min-height: 0;
    font-size: 13px;
  }
.crunch   header .navbar.navbar-inverse .navbar-collapse .navbar-nav li a .smTrigger {
    display: none;
  }
}
.crunch .banner {
  position: relative;
  display: block;
  height: 350px;
  min-width: 170px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  align-items: center;
  border-bottom: 2px solid #9ab9ce;
  -webkit-transition: height 0.35s ease-in-out;
  -moz-transition: height 0.35s ease-in-out;
  -o-transition: height 0.35s ease-in-out;
  transition: height 0.35s ease-in-out;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .banner {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .crunch .banner {
    height: 180px;
    background-size: cover;
    background-position: left top;
    min-width: 100%;
    background-attachment: scroll;
  }
}
.crunch .banner:before {
  background-color: rgba(230, 249, 255, 0.6);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 350px;
  z-index: 0;
  -webkit-transition: height 0.35s ease-in-out;
  -moz-transition: height 0.35s ease-in-out;
  -o-transition: height 0.35s ease-in-out;
  transition: height 0.35s ease-in-out;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .banner:before {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .crunch .banner:before {
    height: 180px;
    background-color: rgba(230, 249, 255, 0.8);
  }
}
.crunch .banner .bannerTitle {
  position: fixed;
  padding: 0 15px;
  width: 992px;
  top: 275px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: none;
  color: #00549a;
  font-weight: 500;
  font-size: 55px;
  text-shadow: 1px 1px 1px #fff;
  -webkit-transition: top 0.35s ease-in-out;
  -moz-transition: top 0.35s ease-in-out;
  -o-transition: top 0.35s ease-in-out;
  transition: top 0.35s ease-in-out;
}
@media (max-width: 992px) {
  .crunch .banner .bannerTitle {
    top: 120px;
    right: 0;
    font-size: 45px;
    width: 90%;
    margin: 0;
    padding: 15px 15px 10px 35px;
  }
.crunch .banner.banner--blog h1.bannerTitle,
.crunch .banner.banner--press-release h1.bannerTitle {
    font-size: 30px;
    bottom: 0px;
  }
}
@media (max-width: 768px) {
  .crunch .banner .bannerTitle {
    position: absolute;
    padding: 15px 10px 10px 15px;
    width: 100%;
    top: 120px;
    font-size: 30px;
    right: 0;
    margin: 0;
  }
  .crunch div.banner.banner--blog h1.bannerTitle,
.crunch div.banner.banner--press-release h1.bannerTitle {
    font-size: 26px;
  }
}
@media (max-width: 390px) {
  .crunch div.banner.banner--blog h1.bannerTitle,
.crunch div.banner.banner--press-release h1.bannerTitle {
    position: absolute;
    width: 100%;
    font-size: 24px;
  }
}
.crunch .banner--markets {
  background-image: url('images/images/banners/market.jpg');
  background-position: center -100px;
}
@media (max-width: 768px) {
  .crunch .banner--markets {
    background-position: center top;
  }
}
.crunch .banner--markets:before {
  content: '';
  background-image: linear-gradient(to right, rgba(209, 237, 255, 0.68) 30%, rgba(23, 133, 210, 0) 100%);
  background-color: transparent;
}
@media (max-width: 768px) {
  .crunch .banner--markets:before {
    background-image: linear-gradient(to right, rgba(209, 237, 255, 0.55) 30%, rgba(110, 195, 255, 0.72) 100%);
  }
}
.crunch .banner--markets .bannerTitle {
  top: 240px;
  line-height: 0.8em;
}
@media (max-width: 992px) {
  .crunch .banner--markets .bannerTitle {
    top: 75px;
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .crunch .banner--markets .bannerTitle {
    font-size: 30px;
  }
}
.crunch .banner--markets .bannerTitle small {
  font-size: 27px;
  color: #1a6aa7;
}
@media (max-width: 992px) {
  .crunch .banner--markets .bannerTitle small {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .crunch .banner--markets .bannerTitle small {
    font-size: 20px;
  }
}
.crunch .banner--capabilities {
  background-image: url('images/images/banners/process.jpg');
}
@media (max-width: 768px) {
  .crunch .banner--capabilities {
    background-image: url('images/images/banners/mobile/process.jpg');
  }
}
.crunch .banner--performance {
  background-image: url('images/images/banners/performance.jpg');
  background-position: right top;
  background-size: cover;
}
@media (max-width: 768px) {
  .crunch .banner--performance {
    background-image: url('images/images/banners/mobile/performance.jpg');
  }
}
.crunch .banner--performance-sub {
  border: none;
  background-image: url('images/images/banners/perform-subpage.jpg');
  background-position: left center ;
}
.crunch .banner--performance-sub:before {
  content: '';
  background-color: transparent;
}
@media (max-width: 768px) {
  .crunch .banner--performance-sub .bannerTitle {
    top: 90px;
    font-size: 24px;
  }
}
.crunch .banner--cyber-security {
  border: none;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgb(255 255 255 / 73%)),url('images/images/banners/cyberPortal.jpg');
}
.crunch .banner--cyber-security:before {
  background-color: rgba(255, 255, 255, 0.44);
}

.crunch .banner--blog,
.crunch .banner--press-release {
  background-image: url('images/images/banners/blog.jpg');
  background-position: center 100%;
}
.crunch .banner--press {
  background-image: url('images/images/banners/Press-Banner.jpg');
}
.crunch .banner--privacy,
.crunch .banner--terms-of-use,
.crunch .banner--cookiePolicy {
  background-image: url('images/images/banners/banner-privacy.jpg');
}
.crunch .banner--about {
  background-image: url('images/images/banners/about.jpg');
}
@media (max-width: 768px) {
  .crunch .banner--about {
    background-image: url('images/images/banners/mobile/about.jpg');
  }
}
.crunch .banner--careers {
  background-image: url('images/images/banners/career.jpg');
}
@media (max-width: 768px) {
  .crunch .banner--careers {
    background-image: url('images/images/banners/mobile/careers.jpg');
  }
}
@media (max-width: 768px) {
  .crunch .banner--careers .bannerTitle {
    top: 80px;
  }
}
.crunch .banner--mq-careers {
  background-image: url('images/images/banners/marquette-careers-background.jpg');
}
@media (max-width: 768px) {
  .crunch .banner--mq-careers {
    background-image: url('images/images/banners/mobile/marquette-careers-background.jpg');
  }
}
.crunch .banner--mq-internships {
  background-image: url('images/images/banners/marquette-internships-background.jpg');
}
@media (max-width: 768px) {
  .crunch .banner--mq-internships {
    background-image: url('images/images/banners/mobile/marquette-internships-background.jpg');
  }
}
.crunch .banner--corporate {
  background-image: url('images/images/banners/corp.jpg');
  background-position: top right;
}
@media (max-width: 768px) {
  .crunch .banner--management {
    background-image: url('images/images/banners/mobile/management.jpg');
  }
}
.crunch .banner--contact {
  background-image: url('images/images/banners/contact.jpg');
  background-color: rgba(187, 239, 255, 0.84);
}
@media (max-width: 768px) {
  .crunch .banner--contact {
    background-image: url('images/images/banners/mobile/contact.jpg');
    background-position: top right;
  }
}
.crunch .banner--commercial {
  background-image: url('images/images/banners/commercial.jpg');
  background-position: top right;
}
.crunch .banner--commercial:before {
  content: '';
  background-color: rgba(230, 249, 255, 0.55);
}
@media (max-width: 768px) {
  .crunch .banner--commercial {
    background-position: left 0;
  }
.crunch   .banner--commercial .bannerTitle {
    top: 85px;
  }
}
.crunch .banner--federal {
  background-image: url('images/images/banners/federal.jpg');
  background-position: left -400px;
}
@media (max-width: 992px) {
  .crunch .banner--federal {
    background-position: left -210px;
  }
}
@media (max-width: 768px) {
  .crunch .banner--federal {
    background-position: right top;
  }
}
.crunch .banner--cyber-industries.banner {
  border: none;
  background-image: url('images/images/banners/cyberIndustries.jpg');
}
.crunch .banner--cyber-industries.banner:before {
  content: '';
  background-color: rgba(230, 249, 255, 0.79);
}
.crunch .banner--cyber-industries.banner .bannerTitle {
  top: 220px;
}
@media (max-width: 992px) {
  .crunch .banner--cyber-industries.banner .bannerTitle {
    top: 80px;
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .crunch .banner--cyber-industries.banner .bannerTitle {
    top: 50px;
    font-size: 31px;
  }
}
.crunch .banner--drupal {
  background-image: url('images/images/banners/development.jpg');
  background-position: left top;
}
@media (max-width: 768px) {
  .crunch .banner--drupal .bannerTitle {
    top: 85px;
  }
}
.crunch .banner--consulting {
  background-image: url('images/images/banners/devConsult.jpg');
  background-position: left top;
}
.crunch .banner--consulting:before {
  content: '';
  background-color: rgba(230, 249, 255, 0.59);
}
.crunch .banner--consulting .bannerTitle {
  top: 220px;
  line-height: 1.1em;
}
@media (max-width: 992px) {
  .crunch .banner--consulting .bannerTitle {
    top: 80px;
  }
}
@media (max-width: 768px) {
  .crunch .banner--consulting .bannerTitle {
    top: 70px;
    font-size: 26px;
  }
}
.crunch .banner--integration {
  background-image: url('images/images/banners/integration.jpg');
}
.crunch .banner--integration:before {
  content: '';
  background-color: rgba(230, 249, 255, 0.89);
}
@media (max-width: 768px) {
  .crunch .banner--integration .bannerTitle {
    top: 90px;
    font-size: 26px;
  }
}
.crunch .banner--vendor {
  background-image: url('images/images/banners/vendor.jpg');
  background-position: left -100px;
}
@media (max-width: 768px) {
  .crunch .banner--vendor {
    background-position: left top;
  }
}
.crunch .banner--vendor:before {
  content: '';
  background-color: rgba(230, 249, 255, 0.85);
}
@media (max-width: 768px) {
  .crunch .banner--vendor .bannerTitle {
    top: 55px;
  }
}
.crunch .banner--analytics {
  background-image: url('images/images/banners/analytics.jpg');
  background-position: center top;
}
@media (max-width: 768px) {
  .crunch .banner--analytics {
    background-image: url('images/images/banners/mobile/analytics.jpg');
    background-position: right top;
  }
}
.crunch body .footer,
.crunchbody.microsite .footer,
.crunch body .page-wrap:after,
.crunch body.microsite .page-wrap:after {
  height: 80px;
}
@media (max-width: 480px) {
  .crunch body .footer,
  .crunch body.microsite .footer,
  .crunch body .page-wrap:after,
  .crunch body.microsite .page-wrap:after {
    height: 160px;
  }
}
.crunch body .footer,
.crunch body.microsite .footer {
  position: relative;
  height: auto;
  width: 100%;
  display: inline-block;
  background-color: #eceeed;
  padding: 0;
  border-top: 2px solid #fff;
  overflow: hidden;
  z-index: 5;
  clear: both;
}
@media screen and (max-width: 768px) {
  .crunch body .footer,
  .crunch body.microsite .footer {
    z-index: initial;
  }
}
.crunch body .footer .mainpage-wrapper,
.crunch body.microsite .footer .mainpage-wrapper {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  padding: 10px 0;
  display: block;
}
@media (max-width: 768px) {
  .crunch body .footer .mainpage-wrapper,
  body.microsite .footer .mainpage-wrapper {
    display: inline-block;
  }
}
.crunch body .footer .midBlock,
.crunch body.microsite .footer .midBlock {
  margin-left: 35px;
}
.crunch body .footer .flex-container,
.crunch body.microsite .footer .flex-container {
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 992px;
  padding: 15px;
}
.crunch .footer .row-bottom {
  padding-top: 10px;
}
@media (min-width: 992px) {
  .crunch body .footer .flex-container,
  body.microsite .footer .flex-container {
    padding: 15px 0;
    flex-wrap: nowrap;
  }
}
@media (max-width: 992px) {
  .crunch body .footer .flex-container,
  body.microsite .footer .flex-container {
    padding: 20px;
  }
.crunch   .footer div.row-bottom {
    padding-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .crunch body .footer .flex-container,
  body.microsite .footer .flex-container {
    margin: 0px;
  }
.crunch   body .footer .site-link.wrapper ul {
    min-height: unset;
  }
.crunch   .icons .icon-wrapper {
    padding: 0px;
  }
.crunch   .icons .icon-wrapper img {
    width: 100%;
    height: auto;
    display: inline-block;
    max-width: 30px;
  }
.crunch   .footer .foot-panel {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .crunch body .footer .addresses {
    flex-wrap: wrap;
  }
.crunch   .footer .site-link ul li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
.crunch   .footer .site-link ul li:first-of-type {
    display: block;
    width: 100%;
  }
.crunch   .footer .foot-panel {
    padding-top: 20px;
  }
.crunch   body .footer .flex-container,
  body.microsite .footer .flex-container {
    padding: 0 15px;
  }
.crunch   body .footer div.mobile,
  body.microsite .footer div.mobile {
    display: flex;
    float: none;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-around;
  }
.crunch   body .footer .copyRight ul.legal-docs {
    padding-left: 10px;
    padding-right: 10px;
  }
.crunch   body .footer .copyRight ul.legal-docs a {
    padding-right: 10px;
    font-size: 9px;
  }
.crunch   .footer #social-media {
    order: -1;
    flex: 1 0 100%;
    margin-bottom: 20px;
  }
.crunch   body .footer li.maps,
  body.microsite .footer li.maps {
    bottom: 10px;
  }
.crunch   body .footer .addresses div.va-address ul,
  body .footer .addresses div.mi-address ul,
  body .footer .addresses div.main-address ul {
    padding-right: 5px;
  }
}
@media screen and (min-width: 768px) {
  .crunch body .footer .mobile,
  body.microsite .footer .mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .crunch body .footer .mobile,
  body.microsite .footer .mobile {
    display: inline-block;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
.crunch   body .footer .mobile .footer-title a,
  body.microsite .footer .mobile .footer-title a {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
}
.crunch body .footer .desktop,
.crunch body.microsite .footer .desktop {
  padding: 0;
}
body .footer .footer-title,
.crunch body.microsite .footer .footer-title {
  font-weight: bold;
  font-size: 13px;
  display: block;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .crunch body .footer .addresses,
  body.microsite .footer .addresses {
    padding-left: 20px;
  }
}
@media (max-width: 768px) {
  .crunch body .footer .addresses,
  body.microsite .footer .addresses {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
.crunch   body .footer .addresses div,
  body.microsite .footer .addresses div {
    padding: 0;
  }
.crunch   body .footer .mobile .footer-title {
    padding-bottom: 10px;
  }
}
.crunch body .footer .contact,
.crunch body.microsite .footer .contact {
  padding: 0;
}
.crunch body .footer .contact h5,
.crunch body.microsite .footer .contact h5 {
  margin: 0;
  font-size: 13px;
}
.crunch body .footer .contact .icons,
.crunch body.microsite .footer .contact .icons {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  max-width: 300px;
  align-content: center;
  justify-content: space-around;
}
.crunch body .footer .map-marker{
  font-family: "FontAwesome", serif;
  font-size: 16px;
  color: #49c0a4;
  padding-left: 5px;
}
.crunch body .footer .contact .phoneIcon,
.crunch body.microsite .footer .contact .phoneIcon,
.crunch body .footer .contact .emailIcon,
.crunch body.microsite .footer .contact .emailIcon,
.crunch body .footer .contact .careers,
.crunch body.microsite .footer .contact .careers {
  position: relative;
}
@media (max-width: 768px) {
  .crunch body .footer .contact .phoneIcon,
  body.microsite .footer .contact .phoneIcon,
  body .footer .contact .emailIcon,
  body.microsite .footer .contact .emailIcon,
  body .footer .contact .careers,
  body.microsite .footer .contact .careers {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
  }
.crunch   body .footer .contact .phoneIcon,
  body.microsite .footer .contact .phoneIcon,
  body .footer .contact .careers,
  body.microsite .footer .contact .careers {
    text-align: right;
  }
.crunch   body .footer .contact .emailIcon,
  body.microsite .footer .contact .emailIcon {
    text-align: left;
  }
}
.crunch body .footer .contact .phoneIcon a,
.crunch body.microsite .footer .contact .phoneIcon a,
.crunch body .footer .contact .emailIcon a,
.crunch body.microsite .footer .contact .emailIcon a,
.crunch body .footer .contact .careers a,
.crunch body.microsite .footer .contact .careers a {
  content: '';
  background-position: center center;
  background-size: 55% auto;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  display: inline-block;
  border: 2px solid rgba(115,179,212,0.7);
  border-radius: 100px;
}
.crunch body .footer .contact .phoneIcon a,
.crunch body.microsite .footer .contact .phoneIcon a {
  background-image: url("images/images/footer/phone-icon.png");
  background-size: 50% auto;
}
.crunch body .footer .contact .emailIcon,
.crunch body.microsite .footer .contact .emailIcon {
  margin-left: 10px;
}
.crunch body .footer .contact .careers,
.crunch body.microsite .footer .contact .careers,
.crunch body .footer .contact .phoneIcon,
.crunch body.microsite .footer .contact .phoneIcon {
  margin-right: 10px;
}
.crunch body .footer .contact .careers a,
.crunch body.microsite .footer .contact .careers a {
  background-image: url("images/images/footer/briefcase-icon.png");
}
.crunch body .footer .contact .emailIcon a,
.crunch body.microsite .footer .contact .emailIcon a {
  background-image: url("images/images/footer/mail-icon.png");
}
.crunch body .footer .contact #contact-mobile, body .footer .contact #contact-desktop,
.crunch body.microsite .footer .contact #contact-mobile, body.microsite .footer .contact #contact-desktop,
.crunch body .footer .contact #careerOps-mobile, body .footer .contact #careerOps-desktop,
.crunch body.microsite .footer .contact #careerOps-mobile, body.microsite .footer .contact #careerOps-desktop {
  margin-bottom: 5px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 768px) {
  .crunch body .footer .contact #contact-mobile, body .footer .contact #contact-desktop,
  body.microsite .footer .contact #contact-mobile, body.microsite .footer .contact #contact-desktop,
  body .footer .contact #careerOps-mobile, body .footer .contact #careerOps-desktop,
  body.microsite .footer .contact #careerOps-mobile, body.microsite .footer .contact #careerOps-desktop {
    margin-right: 0;
    padding: 0;
  }
}
.crunch body .footer h5,
.crunch body.microsite .footer h5 {
  margin: 0;
  font-size: 13px;
}
@media screen and (min-width: 768px) {
  .crunch body .footer .maps,
  body.microsite .footer .maps {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .crunch body .footer .maps,
  body.microsite .footer .maps {
    position: absolute;
    margin: 10px 0;
    height: 10px;
  }
.crunch   body .footer .maps a,
  body.microsite .footer .maps a {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    font-size: 15px;
    border: 1px solid rgba(63, 89, 128, 0.28);
    padding: 11px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    width: fit-content;
    width: -moz-fit-content;
    margin: 0;
  }
.crunch   body .footer .maps a:focus,
  body.microsite .footer .maps a:focus,
  body .footer .maps a:hover,
  body.microsite .footer .maps a:hover {
    text-decoration: none;
  }
.crunch   body .footer .maps a:after,
  body.microsite .footer .maps a:after {
    font-family: "FontAwesome", serif;

    content: '\f041';
    font-size: 25px;
    color: #49c0a4;
    padding-left: 5px;
    vertical-align: -4px;
  }
}
@media screen and (max-width: 480px) {
  .crunch body .footer .btn-info,
  body.microsite .footer .btn-info {
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 50px;
    margin: 0;
    position: initial;
  }
}
@media screen and (max-width: 480px) {
  .crunch body .footer .buttons,
  body.microsite .footer .buttons {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    padding: 0 15px 15px 10px;
    width: 100%;
    position: initial;
  }
.crunch   body .footer .buttons .btn-primary,
  body.microsite .footer .buttons .btn-primary {
    text-transform: uppercase;
    font-size: 12px;
    min-width: 100%;
    display: block;
    height: 50px;
    margin: 1em 0;
  }
.crunch   body .footer .buttons .btn-info,
  body.microsite .footer .buttons .btn-info {
    font-size: 12px;
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    background-color: #fff;
    height: 50px;
    color: #fff;
    margin: 0;
  }
.crunch   body .footer .buttons #contact-mobile.btn-info, body .footer .buttons #contact-desktop.btn-info,
  body.microsite .footer .buttons #contact-mobile.btn-info, body.microsite .footer .buttons #contact-desktop.btn-info  {
    color: #2773bd;
  }
.crunch   body .footer .buttons .btn-info:hover,
  body.microsite .footer .buttons .btn-info:hover,
  body .footer .buttons .btn-info:focus,
  body.microsite .footer .buttons .btn-info:focus {
    color: #fff;
  }
}
.crunch body .footer ul,
.crunch body.microsite .footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .crunch body .footer ul,
  body.microsite .footer ul {
    position: relative;
  }
.crunch   body .footer .addresses div.va-address ul,
  body .footer .addresses div.main-address ul {
    padding-bottom: 50px;
    min-height: fit-content;
  }
}
.crunch body .footer ul li.categLink a,
.crunch body.microsite .footer ul li.categLink a {
  color: #2773bd;
  font-size: 10px;
  line-height: .8em;
}
.crunch body .footer ul li,
.crunch body.microsite .footer ul li {
  font-size: 12px;
  padding: 0;
  color: #7a8da2;
  line-height: 1.5em;
}
.crunch body .footer ul li a,
.crunch body.microsite .footer ul li a {
  font-weight: 500;
  font-size: 12px;
}
@media screen and (max-width: 992px) {
  .crunch body .footer ul li a,
  body.microsite .footer ul li a {
    font-size: 12px;
    line-height: 1em;
  }
}
.crunch body .footer a,
.crunch body.microsite .footer a {
  line-height: 2em;
  font-weight: 400;
  font-size: 12px;
  color: #2773bd;
}
.crunch body .footer p,
.crunch body.microsite .footer p {
  margin: 0;
  font-size: 12px;
  color: #5e85a1;
  letter-spacing: 0.08em;
  display: block;
  line-height: 1.55em;
  font-family: Helvetica, sans-serif;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .crunch body .footer p,
  body.microsite .footer p {
    font-size: 13px;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
.crunch   body .footer .addresses ul li {
    font-size: 10px;
  }
}
.crunch body .footer p strong,
.crunch body.microsite .footer p strong {
  font-weight: 500;
  color: #5e85a1;
  font-size: 13px;
}
.crunch body .footer .imgBlock,
.crunch body.microsite .footer .imgBlock,
.crunch body .footer .midBlock,
.crunch body.microsite .footer .midBlock {
  margin-right: 15px;
  min-height: 93px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 992px) and (min-width: 480px) {
  .crunch body .footer .imgBlock,
  body.microsite .footer .imgBlock,
  body .footer .midBlock,
  body.microsite .footer .midBlock {
    width: auto;
    display: flex;
    align-items: center;
    margin-right: 35px;
  }
}
@media screen and (max-width: 768px) {
  .crunch body .footer .imgBlock,
  body.microsite .footer .imgBlock,
  body .footer .midBlock,
  body.microsite .footer .midBlock {
    width: 100%;
    margin-top: 20px;
    align-items: baseline;
    min-height: 0;
    height: auto;
  }
.crunch   body .footer .imgBlock p,
  body.microsite .footer .imgBlock p,
  body .footer .midBlock p,
  body.microsite .footer .midBlock p {
    padding: 0;
  }
}
.crunch body .footer .imgBlock img,
.crunch body.microsite .footer .imgBlock img,
.crunch body .footer .midBlock img,
.crunch body.microsite .footer .midBlock img {
  width: 190px;
  margin-left: -5px;
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .crunch body .footer .imgBlock img,
  body.microsite .footer .imgBlock img,
  body .footer .midBlock img,
  body.microsite .footer .midBlock img {
    width: 210px;
    margin-left: -10px;
    margin-top: 15px;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .crunch body .footer .imgBlock img,
  body.microsite .footer .imgBlock img,
  body .footer .midBlock img,
  body.microsite .footer .midBlock img {
    margin: 0;
    float: none;
    display: block;
    width: 200px;
  }
}
.crunch body .footer .copyRight,
.crunch body.microsite .footer .copyRight {
  display: block;
  background-color: #006298;
  width: 100%;
  height: auto;
  padding: 10px 0;
}
.crunch body .footer .copyRight p,
.crunch body.microsite .footer .copyRight p {
  padding: 0 15px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .crunch body .footer .copyRight p,
  body.microsite .footer .copyRight p {
    padding: 5px 15px;
  }
.crunch   body .footer .copyRight p .rights,
  body.microsite .footer .copyRight p .rights {
    display: block;
  }
}
.crunch body .footer .phoneNum,
.crunch body.microsite .footer .phoneNum {
  font-weight: bold;
  font-size: 12px;
  color: #5e85a1;
  display: inline;
}
.crunch body .footer .emailLink:before,
.crunch body.microsite .footer .emailLink:before {
  font-family: 'FontAwesome', sans-serif;
  content: '\f003';
  position: initial;
  padding-right: 5px;
  font-size: 15px;
  vertical-align: 0;
  display: inline;
  color: #67b2ed;
}
.crunch body .footer .emailLink > a,
.crunch body.microsite .footer .emailLink > a {
  margin: 0;
  letter-spacing: 0.08em;
  display: inline;
  line-height: 1.2em;
  font-family: Helvetica, sans-serif;
  padding: 0;
  font-size: 12px;
  color: #7a8da2;
}
.crunch body .footer .categLink:before,
.crunch body.microsite .footer .categLink:before {
  font-family: 'FontAwesome', sans-serif;
  position: initial;
  padding-right: 5px;
  font-size: 15px;
  vertical-align: 0;
  display: inline;
  color: #67b2ed;
}
.crunch body .footer .categLink > a,
.crunch body.microsite .footer .categLink > a {
  margin: 0;
  letter-spacing: 0.08em;
  display: inline;
  line-height: 1.2em;
  font-family: Helvetica, sans-serif;
  padding: 0;
  font-size: 12px;
  color: #7a8da2;
}
@media print {
  @page {
    size: A4;
    margin: 0.5cm;
  }
/*Prevent Blank White pages*/
  .print + .print {
    page-break-before: always !important;
  }
.crunch   * {
    -webkit-print-color-adjust: exact;
  }
.crunch   html,
  body {
    position: relative !important;
    color: black;
    margin: 0 0 0 0;
    size: letter;
    padding: 0 !important;
    min-height: inherit;
    height: auto !important;
    width: 100%;
    max-width: none !important;
    overflow: visible !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }
.crunch   .show-print {
    display: block !important;
  }
.crunch   .hidden-print {
    display: none !important;
  }
.crunch   .page-break-before {
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }
.crunch   .col-pad-none,
.crunch .page-section.col-pad-none {
    padding: 0 !important;
  }
.crunch   header {
    position: relative !important;
    height: 75px;
    border-top: 1px solid transparent !important;
    width: 100%;
    display: inline-block !important;
    page-break-inside: avoid !important;
  }
.crunch   header .logoLink {
    position: relative !important;
  }
.crunch   header .logoWrapper {
    opacity: 1 !important;
    width: 50%;
    display: inline-block !important;
  }
.crunch   header .logoWrapper .NCGlogo {
    margin: 0 !important;
    max-width: 200px !important;
  }
.crunch   header .mobile-call {
    display: none !important;
  }
.crunch   header .contactWrapper {
    float: right !important;
    padding: 10px !important;
  }
.crunch   header.microsite {
    border: none !important;
  }
.crunch   header.microsite .logo-desktop {
    display: inline-block !important;
  }
.crunch   .col-sm-1,
.crunch .col-sm-2,
.crunch .col-sm-3,
.crunch .col-sm-4,
.crunch .col-sm-5,
.crunch .col-sm-6,
.crunch .col-sm-7,
.crunch .col-sm-8,
.crunch .col-sm-9,
.crunch .col-sm-10,
.crunch .col-sm-11,
.crunch .col-sm-12 {
    float: left;
  }
.crunch   .col-sm-12 {
    width: 100%;
  }
.crunch   .col-sm-11 {
    width: 91.66666666666666%;
  }
.crunch   .col-sm-10 {
    width: 83.33333333333334%;
  }
.crunch   .col-sm-9 {
    width: 75%;
  }
.crunch   .col-sm-8 {
    width: 66.66666666666666%;
  }
.crunch   .col-sm-7 {
    width: 58.333333333333336%;
  }
.crunch   .col-sm-6 {
    width: 50%;
  }
.crunch   .col-sm-5 {
    width: 41.66666666666667%;
  }
.crunch   .col-sm-4 {
    width: 33.33333333333333%;
  }
.crunch   .col-sm-3 {
    width: 25%;
  }
.crunch   .col-sm-2 {
    width: 16.666666666666664%;
  }
.crunch   .col-sm-1 {
    width: 8.333333333333332%;
  }
/*Headers*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid  !important;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid  !important;
    /* or 'auto' */
  }
.crunch   body,
  h1,
  h2,
  h3,
  ol,
  ul,
  div {
    width: auto;
    border: 0;
    position: relative;
  }
.crunch   h1 {
    font-size: 36pt !important;
  }
.crunch   h2 {
    font-size: 24pt !important;
  }
.crunch   h3 {
    font-size: 18pt !important;
  }
.crunch   h4 {
    font-size: 14pt !important;
  }
.crunch   ul li {
    font-size: 12px !important;
  }
.crunch   p {
    font-size: 17px !important;
    position: relative !important;
    page-break-inside: avoid  !important;
  }
.crunch   p a[href^="#"]:after {
    display: none;
  }
.crunch   a:after {
    display: none;
  }
.crunch   p a:after {
    content: " " attr(href) ")";
  }
.crunch   p a {
    word-wrap: break-word;
  }
.crunch   h1,
.crunch h2,
.crunch h3,
.crunch h4,
.crunch h5,
.crunch p,
.crunch img,
.crunch .title,
.crunch .graphic--desktop {
    opacity: 1 !important;
    transition: none !important;
  }
.crunch   .mobile {
    display: none !important;
  }
.crunch   .splash {
    height: auto !important;
    display: block;
    margin-top: 0 !important;
    min-height: 0 !important;
  }
.crunch   .splash #page-title {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 70px !important;
  }
.crunch   .splash #page-title,
.crunch .splash #concept {
    top: 0 !important;
  }
.crunch   .splash-content {
    padding-top: 0 !important;
  }
.crunch   .splash-wrapper {
    margin: 10px auto !important;
  }
.crunch   .splash .fade-in {
    margin: 0 !important;
    float: none !important;
    display: block !important;
    width: 100% !important;
  }
.crunch   .splash-graphic {
    width: 100% !important;
    max-width: 600px;
    position: relative;
    z-index: -10 !important;
    left: 0;
    top: 30px;
    right: 0;
    height: auto;
    overflow: hidden;
  }
.crunch   .splash-graphic img {
    height: 100% !important;
    width: 100% !important;
    max-width: none !important;
  }
.crunch   .three-topics {
    padding: 0 !important;
    min-height: 0 !important;
    height: auto !important;
    margin-bottom: 130px;
  }
.crunch   .three-topics ul {
    margin: 30px 0 !important;
    float: left !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: none !important;
  }
.crunch   .three-topics ul .fade-in {
    opacity: 1 !important;
    justify-content: flex-start !important;
  }
.crunch   .three-topics ul .fade-in p {
    font-size: 24px !important;
    font-weight: bold !important;
  }
.crunch   .banner {
    height: 130px !important;
    padding: 20px 0;
  }
.crunch   .banner .bannerTitle {
    position: relative !important;
    padding: 10px 15px !important;
    color: #2b4787 !important;
    top: 30px !important;
    width: 100% !important;
    font-size: 36px !important;
  }
.crunch   .banner-sms h1 {
    font-size: 28px !important;
  }
.crunch   .Carousel.slide {
    display: block !important;
    height: 250px !important;
  }
.crunch   .Carousel.slide .carousel-control {
    display: none !important;
  }
.crunch   .Carousel.slide .carousel-caption {
    top: 0 !important;
  }
.crunch   .Carousel.slide .captionTxt {
    top: 0 !important;
  }
.crunch   .Carousel.slide .captionTitle {
    margin-top: 0;
  }
.crunch   .Carousel.slide p {
    font-size: 18px !important;
  }
.crunch   .breadcrumb-wrapper {
    box-shadow: none !important;
    border-bottom: 1px solid #eee !important;
  }
.crunch   .breadcrumb-wrapper .breadcrumb {
    margin: 0 !important;
    padding: 0 15px !important;
  }
.crunch   .featured {
    margin-bottom: 40px !important;
  }
.crunch   .page-wrap {
    padding-bottom: 0 !important;
    display: block !important;
    height: auto !important;
  }
.crunch   .page-section {
    padding-bottom: 20px!important;
    padding-top: 20px!important;
    display: block !important;
  }
.crunch   .page-section--striped {
    display: inline-block !important;
  }
.crunch   .page-section--bordered {
    display: inline-block !important;
  }
.crunch   .page-section--bordered:last-of-type {
    border: none;
  }
.crunch   .page-section .page-section {
    padding: 10px 0 !important;
  }
.crunch   .microsite .page-section {
    padding: 35px 0 !important;
  }
.crunch   .microsite .page-section .page-section {
    padding: 20px 0 !important;
  }
.crunch   .microsite .main-section {
    page-break-inside: avoid !important;
    page-break-before: always !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
.crunch   .microsite .main-section h2 {
    font-size: 40px;
  }
.crunch   .mainpage-wrapper {
    height: auto !important;
    padding: 0 !important;
    display: inline-block !important;
  }
.crunch   .mainpage-body {
    padding: 0 !important;
  }
.crunch   .primary_layout,
.crunch .secondary_layout {
    border-top: 5px solid #eee !important;
    display: block !important;
  }
.crunch   .sideBar,
.crunch .sideBar.relative,
.crunch .sideBar.fixed {
    position: relative !important;
    top: 0 !important;
    padding: 10px !important;
    margin-top: 15px;
    border-radius: 3px !important;
    border: 2px solid #ddd !important;
  }
.crunch   .sideBar .wrapper,
.crunch .sideBar.relative .wrapper,
.crunch .sideBar.fixed .wrapper {
    border: none !important;
  }
.crunch   .sideBar .list.disc li,
.crunch .sideBar.relative .list.disc li,
.crunch .sideBar.fixed .list.disc li {
    margin-left: 30px !important;
  }
.crunch   .title {
    display: inline-block !important;
    margin-left: 0 !important;
    float: none !important;
  }
.crunch   .tile-collection .tile {
    display: inline-block !important;
    float: left !important;
    vertical-align: middle;
    min-height: 200px;
  }
/*Images*/
  .graphic-wrap--icon {
    margin: 0 !important;
    margin-right: 10px !important;
  }
.crunch   .graphic-wrap--icon .fa {
    color: #2773bd !important;
  }
.crunch   .graphic-wrap--icon *,
.crunch .graphic-wrap--icon *:before,
.crunch .graphic-wrap--icon *:after {
    color: #2773bd !important;
  }
.crunch   .graphic--mobile {
    display: none !important;
  }
.crunch   .imgIcon:before {
    color: #c6d1d6 !important;
  }
.crunch   .icon {
    max-width: 65px !important;
    height: auto;
    margin: 0 auto;
  }
.crunch   .icon img {
    max-height: 65px !important;
    width: auto !important;
    height: auto;
  }
.crunch   .btn {
    white-space: normal;
    width: auto !important;
    display: inline-block !important;
  }
.crunch   a.btn-link {
    display: block !important;
    width: 100%;
    font-size: 15px !important;
    float: none !important;
  }
.crunch   .btn.btn-download,
.crunch .btn.btn-link {
    font-size: 14px !important;
  }
/*Google Map*/
  #googleMap {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
.crunch   #googleMap iframe.googleMaps {
    margin: 0;
  }
.crunch   #googleMap h5 a:after {
    content: '';
  }
/*Modals*/
  .modal-dialog {
    display: none !important;
  }
.crunch   .contactSidebar {
    display: block !important;
  }
.crunch   .contactSidebar li.header {
    width: 30% !important;
    border: none !important;
  }
.crunch   #back-top.anchorScroll {
    display: none !important;
  }
.crunch   .form {
    display: block;
    width: 100%;
  }
.crunch   .form-group {
    margin-bottom: 1rem;
    display: block;
    width: 100%;
  }
/*Capabilities*/
  .client-logo {
    margin-bottom: 20px;
    max-width: 200px !important;
  }
}
@media (max-width: 411px) {
  .crunch .carousel {
    height: 40vh;
  }
}
@media (max-width: 768px) {
  .crunch .carousel {
    height: 40vh;
  }
}
.crunch .carousel:before {
  content: '';
}
@media (max-width: 992px) {
  .crunch .carousel:before {
    position: absolute;
    width: 100%;
    height: 60px;
    top: 440px;
    z-index: 2;
  }
}
@media (max-width: 768px) {
  .crunch .carousel:before {
    top: 400px;
    height: 60px;
    display: block;
  }
}
.crunch .carousel:hover .playPause .fa-pause {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.crunch .carousel-fade .carousel-inner .item {
  transition: opacity 0.7s ease-in-out;
}
.crunch .carousel-fade .carousel-inner .item,
.crunch .carousel-fade .carousel-inner .active.left,
.crunch .carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.crunch .carousel-fade .carousel-inner .active,
.crunch .carousel-fade .carousel-inner .next.left,
.crunch .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.crunch .carousel-fade .carousel-inner .next,
.crunch .carousel-fade .carousel-inner .prev,
.crunch .carousel-fade .carousel-inner .active.left,
.crunch .carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.crunch .carousel-fade .carousel-control {
  z-index: 500;
}
.crunch .carousel .mainpage-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: auto;
  z-index: 1;
}
.crunch .carousel .playPause {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 170px;
  z-index: 5;
  height: 0;
  width: 992px;
  padding: 27px;
}
@media (max-width: 992px) {
  .crunch .carousel .playPause {
    width: 100%;
    margin: 0 auto;
    top: 80px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .playPause {
    display: none;
  }
}
.crunch .carousel .playPause .fa {
  top: 0;
  position: absolute;
  right: 0;
  font-size: 17px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 3px;
  background-color: transparent;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  color: rgba(11, 128, 185, 0.78);
}
.crunch .carousel .playPause .fa-pause {
  opacity: 0;
  padding-left: 2px;
  color: #fff;
  background-color: rgba(50, 149, 202, 0.26);
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 992px) {
  .crunch .carousel .playPause .fa-pause {
    margin-right: 15px;
    background-color: rgba(50, 149, 202, 0.79);
  }
}
.crunch .carousel .carousel-control {
  position: fixed;
  top: 200px;
  width: 60px;
  opacity: 0.3;
  z-index: 5;
  background-image: none;
  -webkit-transition: all 0.9s ease-in-out;
  -moz-transition: all 0.9s ease-in-out;
  -o-transition: all 0.9s ease-in-out;
  transition: all 0.9s ease-in-out;
}
.crunch .carousel .carousel-control:hover {
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.9s ease-in-out;
  -moz-transition: all 0.9s ease-in-out;
  -o-transition: all 0.9s ease-in-out;
  transition: all 0.9s ease-in-out;
}
@media (max-width: 992px) {
  .crunch .carousel .carousel-control {
    position: absolute;
    color: #2773bd;
    text-shadow: 1px 1px 1px #dce5fa;
    top: 355px;
    width: 40px;
    border-radius: 100px;
    display: block;
    opacity: 0.7;
    margin: 10px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-control {
    top: 75%;
    z-index: 50;
  }
}
.crunch .carousel .carousel-control .glyphicon {
  color: #00549a;
  font-size: 30px;
  top: 100px;
  display: block;
  text-shadow: none;
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-control .glyphicon {
    color: #fff;
    font-size: 30px;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    display: flex;
    text-shadow: none;
  }
}
.crunch .carousel .indicatorWrap {
  position: fixed;
  height: 20px;
  top: 650px;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  display: block;
  z-index: 5;
  background: rgba(233, 236, 238, 0);
  background: -moz-linear-gradient(top, rgba(233, 236, 238, 0) 0%, rgba(23, 133, 210, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(233, 236, 238, 0)), color-stop(100%, rgba(23, 133, 210, 0)));
  background: -webkit-linear-gradient(top, rgba(233, 236, 238, 0) 0%, rgba(23, 133, 210, 0) 100%);
  background: -o-linear-gradient(top, rgba(233, 236, 238, 0) 0%, rgba(23, 133, 210, 0) 100%);
  background: -ms-linear-gradient(top, rgba(233, 236, 238, 0) 0%, rgba(23, 133, 210, 0) 100%);
  background: linear-gradient(to bottom, rgba(233, 236, 238, 0) 60%, rgba(23, 133, 210, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9ecee', endColorstr='#012945', GradientType=0);
}
@media (max-width: 992px) {
  .crunch .carousel .indicatorWrap {
    position: absolute;
    bottom: 0;
    top: 465px;
    background: none;
    width: 300px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .indicatorWrap {
    top: 90%;
  }
}
.crunch .carousel .indicatorWrap .carousel-indicators {
  position: absolute;
  margin: 0 auto;
  height: 30px;
  left: 0;
  right: 0;
  width: 1000px;
  z-index: 5;
  text-align: right;
  padding: 0;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .indicatorWrap .carousel-indicators {
    width: auto;
    text-align: center;
    padding: 0;
    top: 0;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .indicatorWrap .carousel-indicators {
    width: 100%;
    text-align: center;
    position: static;
  }
}
@media (min-width: 768px) {
  .crunch .carousel .indicatorWrap .carousel-indicators.invert li {
    background-color: #00549a;
    opacity: 0.5;
    transition: background-color 1s;
  }
.crunch   .carousel .indicatorWrap .carousel-indicators.invert li.active {
    background-color: #00549a;
    opacity: 1;
    transition: background-color 1s;
  }
}
.crunch .carousel .indicatorWrap .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  background-color: #000 \9;
  background-color: rgba(76, 170, 214, 0.64);
  transition: background-color 1s;
}
@media (max-width: 992px) {
  .crunch .carousel .indicatorWrap .carousel-indicators li {
    background-color: #00549a;
    opacity: 0.5;
    margin: 5px;
  }
.crunch   .carousel .indicatorWrap .carousel-indicators li.active {
    background-color: #00549a;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .indicatorWrap .carousel-indicators li {
    background-color: #fff;
    opacity: 0.5;
    margin: 5px;
  }
.crunch   .carousel .indicatorWrap .carousel-indicators li.active {
    background-color: #fff;
  }
}
.crunch .carousel .indicatorWrap .carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #4caad6;
}
@media (max-width: 992px) {
  .crunch .carousel .indicatorWrap .carousel-indicators .active {
    background-color: #ffffff;
    opacity: 1;
    margin: 4px 5px;
  }
}
.crunch .carousel .carousel-inner {
  height: 500px;
  overflow: visible;
  padding: 0;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .carousel-inner {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-inner {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
.crunch   .carousel .carousel-inner .item {
    height: 350px;
    width: 100%;
  }
}
.crunch .carousel .carousel-inner .slide {
  height: 500px;
  transition: height 1s;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-inner .slide {
    height: 100vh;
    background-attachment: scroll !important;
  }
.crunch   .carousel .carousel-inner .slide:before {
    background-image: linear-gradient(to right, rgba(35, 77, 112, 0.92) 0%, rgba(6, 50, 88, 0.69) 40%, rgba(255, 255, 255, 0) 75%);
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    transition: height 1s;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .crunch .carousel .carousel-inner .slide:before {
    height: 100vh;
    background-image: linear-gradient(to top, rgba(21, 129, 215, 0.52) 0%, rgba(0, 65, 112, 0.96) 88%);
  }
}
.crunch .carousel .carousel-inner .slide:before {
  transition: height 1s;
}
.crunch .carousel .carousel-inner .slide .slide-background-graphic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-inner .slide .slide-background-graphic {
    min-width: unset;
    min-height: 100%;
    max-width: unset;
    top: 50px;
  }
}
@media (max-width: 992px) {
  .crunch div.carousel .carousel-inner .slide .slide-background-graphic {
    margin-top: unset
  }
}
@media (max-width: 1200px) {
  .crunch .carousel .carousel-inner .slide .slide-background-graphic {
    margin-top: 130px;
  }
}
.crunch .carousel .carousel-caption {
  top: 250px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  text-align: left;
  display: block;
  text-shadow: none;
}
@media (max-width: 992px) {
  .crunch .carousel .carousel-caption {
    top: 65px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption {
    top: 40%;
  margin: 0 auto;
    min-width: inherit;
    transform: translateY(-40%);
    max-width: 500px;
  }
}
.crunch .carousel .carousel-caption .captionTxt {
  width: 65%;
  padding: 0 15px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt {
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateX(0%);
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt {
    position: relative;
    border-radius: 0;
    padding: 30px 15px;
    width: 100%;
    top: 0px;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0;
    transform: none;
    min-width: inherit;
  }
}
.crunch .carousel .carousel-caption .captionTxt .captionTitle {
  text-shadow: none;
  margin-bottom: 10px;
  line-height: 1.3em;
  max-width: 500px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt .captionTitle {
    max-width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt .captionTitle {
    max-width: 100%;
    text-align: left;
    margin-top: 0px;
  }
}
.crunch .carousel .carousel-caption .captionTxt .captionTitle a {
  font-weight: 500;
  line-height: 1.3em;
  font-size: 38px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt .captionTitle a {
    font-weight: 500;
    text-shadow: 2px 2px 2px rgba(3, 41, 77, 0.72);
    font-size: 33px;
    letter-spacing: 0.06em;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt .captionTitle a {
    font-size: 20px;
    width: 100%;
    line-height: 1.4em;
    margin: 0;
    padding: 0;
    display: block;
    color: #fff;
    text-shadow: 1px 1px 1px #032646;
  }
}
.crunch .carousel .carousel-caption .captionTxt .captionTitle a:hover {
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt .captionTitle {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt.wide .captionTitle {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt.narrow .captionTitle {
    max-width: 400px;
  }
}
.crunch .carousel .carousel-caption .captionTxt h1 {
  color: #10649e;
  position: fixed;
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 42px;
}
@media screen and (max-width: 992px) {
  .crunch .carousel .carousel-caption .captionTxt h1 {
    top: 70px;
    left: 35px;
    font-size: 36px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt h1 {
    top: 70px;
    left: 15px;
    margin: 0;
    line-height: 1.3em;
    position: fixed;
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 2px #00365d;
  }
}
.crunch .carousel .carousel-caption .captionTxt h2 {
  line-height: 1.2em;
  max-width: 600px;
  color: #2773bd;
}
@media (max-width: 992px) {
  .crunch .carousel .carousel-caption .captionTxt h2 {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt h2 {
    font-size: 26px;
    color: #fff;
  }
}
.crunch .carousel .carousel-caption .captionTxt h4 {
  padding-top: 0;
  padding-bottom: 5px;
  font-weight: 400;
  color: #2d8adf;
  text-transform: none;
  margin: 0;
  line-height: 1.5em;
  font-size: 20px;
  max-width: 600px;
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt h4 {
    font-size: 16px;
    color: #fff;
  }
}
.crunch .carousel .carousel-caption .captionTxt p {
  width: 100%;
  max-width: 600px;
  line-height: 1.8em;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  text-shadow: none;
  font-size: 17px;
  color: #019fa1;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .crunch .carousel .carousel-caption .captionTxt p {
    font-weight: 300;
  }
}
@media (max-width: 992px) {
  .crunch .carousel .carousel-caption .captionTxt p {
    max-width: 650px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt p {
    max-width: 100%;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8em;
  }
}
.crunch .carousel .carousel-caption .captionTxt ul {
  margin-bottom: 10px;
}
.crunch .carousel .carousel-caption .captionTxt ul li {
  width: 100%;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 300;
  color: #7c818c;
  list-style-type: disc;
}
@media (max-width: 992px) {
  .crunch .carousel .carousel-caption .captionTxt ul li {
    color: #fff;
  }
}
@media (max-width: 768px) {
  .crunch .carousel .carousel-caption .captionTxt ul li {
    font-size: 16px;
  }
}
.crunch .carousel .carousel-caption .captionTxt .learn-more {
  padding: 0;
}
.crunch .carousel .carousel-caption .captionTxt button.btn.btn-info {
  margin-top: 15px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.15em;
  background-color: #1c88bb;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .crunch .carousel .carousel-caption .captionTxt button.btn.btn-info {
    font-weight: 400;
  }
}
@media (max-width: 480px) {
  .crunch .carousel .carousel-caption .captionTxt button.btn.btn-info {
    position: relative;
    margin-top: 20px;
    display: inline;
    float: none;
    font-size: 13px;
    padding: 10px;
    width: 130px;
  }
.crunch   .carousel .carousel-caption .captionTxt button.btn.btn-info .btn-info a:hover,
.crunch .carousel .carousel-caption .captionTxt button.btn.btn-info .btn-info a:focus {
    text-decoration: none;
  }
@-moz-document url-prefix() {
  .crunch   .carousel .carousel-caption .captionTxt button.btn.btn-info button.btn.btn-info {
      padding: 10px;
    }
}
.crunch   .carousel .carousel-caption .captionTxt button.btn.btn-info .btn-info:hover {
    border-color: #fff;
  }
}
@media (min-width: 992px) {
  .crunch .carousel--homepage.carousel {
    height: 750px;
  }
}
@media (max-width: 992px) and (min-width: 769px) {
  .crunch .carousel--homepage.carousel {
    height: 500px;
  }
}
@media (min-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-control {
    top: 270px;
  }
}
.crunch .carousel--homepage.carousel .carousel-control .glyphicon {
  color: #fff;
  transition: color 1s;
}
@media (min-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-control.invert .glyphicon {
    color: #00549a;
    transition: color 1s;
  }
}
@media (min-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-indicators {
    position: absolute;
    height: 70px;
    top: 60px;
    left: 0;
    right: 0;
    width: auto;
    text-align: left;
    padding: 0;
    max-width: 960px;
    opacity: 1;
    -webkit-transition: opacity 2s;
    transition: opacity 2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media (min-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.56);
  }
.crunch   .carousel--homepage.carousel .carousel-indicators li.active {
    background-color: #fff;
  }
}
@media (orientation: landscape) {
  .crunch .carousel--homepage.carousel .carousel-indicators {
    height: 70px;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner {
  height: 600px;
  max-width: 100%;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner {
    width: 100%;
    height: 100vh;
  }
.crunch   .carousel--homepage.carousel .carousel-inner .item {
    height: 350px;
    width: 100%;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner:before {
  display: none;
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide {
  height: 600px;
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide:before {
  background-image: linear-gradient(to right, rgba(5, 63, 111, 0.91) 0%, rgba(17, 70, 113, 0.87) 30%, rgba(5, 62, 111, 0.51) 50%, rgba(17, 107, 182, 0) 70%);
  content: '';
  position: absolute;
  top: 0;
  margin-top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
}
@media (max-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to right, rgba(5, 63, 111, 0.91) 0%, rgba(17, 70, 113, 0.87) 30%, rgba(5, 62, 111, 0.64) 50%, rgba(17, 107, 182, 0) 90%);
  }
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to top, rgba(45, 124, 187, 0.52) 0%, rgba(1, 72, 123, 0.84) 88%);
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted {
  background-color: #fff;
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted:before {
  display: none;
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted:before {
    display: block;
    background-image: linear-gradient(to top, rgba(45, 124, 187, 0.62) 0%, rgba(1, 72, 123, 0.94) 58%);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .slide-background img {
  top: 40px;
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .slide-background img {
    left: auto;
    right: 0;
    top: 0;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption h3 a,
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption p {
  text-shadow: none;
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption h3 a {
  color: #00549a;
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption h3 a {
    color: #fff;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption p {
  color: #5e85a1;
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide.inverted .carousel-caption p {
    color: #fff;
  }
}
@media (max-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide {
    height: 100vh;
    background-size: cover;
    background-position: center top;
  }
}
@media (orientation: landscape) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide {
    background-position: left top;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt .captionTitle a {
  color: #fff;
  text-shadow: 2px 2px 2px #092c58;
}
@media (max-width: 768px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt .captionTitle a {
    font-size: 25px;
  }
}
@media (min-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt h2 {
    margin-bottom: 0;
    color: #fff;
    text-shadow: 2px 2px 2px #003d69;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt p {
  color: #d1ebff;
}
@media (min-width: 992px) {
  .crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt p {
    font-size: 20px;
  }
}
.crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt p,
.crunch .carousel--homepage.carousel .carousel-inner .item .slide .captionTxt ul li {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.58);
}
.crunch .carousel--cyber.carousel .carousel-inner .item .slide {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: auto 100%;
  background-position: left 100px;
}
@media (max-width: 992px) {
  .crunch .carousel--cyber.carousel .carousel-inner .item .slide {
    background-size: cover;
    background-position: left top;
  }
}
@media (min-width: 769px) {
  .crunch .carousel--cyber.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to right, rgba(231, 237, 242, 0) 0%, rgba(255, 255, 255, 0.91) 50%);
    content: '';
    position: absolute;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    height: 600px;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .crunch .carousel--cyber.carousel .carousel-inner .item .slide .slide-background-graphic {
    right: auto;
    left: -10%;
  }
}
@media (min-width: 992px) {
  .crunch .carousel--cyber.carousel .captionTxt {
    margin-left: 25%;
  }
}
.crunch .carousel--orchestration.carousel .carousel-inner .item .slide {
  background-image: url('images/images/orchestration/slider/slide_one/one.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
@media (min-width: 769px) {
  .crunch .carousel--orchestration.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to right, rgba(231, 237, 242, 0.8) 0%, rgba(148, 210, 232, 0) 100%);
    content: '';
    position: absolute;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    height: 600px;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .crunch .carousel--orchestration.carousel .platform_logo {
    background-color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) {
  .crunch .carousel--orchestration.carousel .captionTxt h2 {
    color: #2773bd;
  }
}
@media (min-width: 769px) {
  .crunch .carousel--orchestration.carousel .captionTxt p {
    color: #5e85a1;
  }
}
@media (min-width: 769px) {
  .crunch .carousel--bank.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to right, rgba(231, 237, 242, 0.96) 0%, rgba(253, 253, 253, 0.86) 40%, rgba(148, 210, 232, 0) 100%);
    content: '';
    position: absolute;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    height: 600px;
    width: 100%;
    z-index: 1;
  }
}
@media (min-width: 769px) {
  .crunch .carousel--bank.carousel .captionTxt h2 {
    text-shadow: 2px 2px 2px #fff;
  }
}
@media (min-width: 768px) {
  .crunch .carousel--merger.carousel .carousel-control .glyphicon {
    color: #fff;
  }
}
@media (min-width: 768px) {
  .crunch .carousel--merger.carousel .carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.56);
  }
.crunch   .carousel--merger.carousel .carousel-indicators li.active {
    background-color: #fff;
  }
}
.crunch .carousel--merger.carousel .carousel-inner .item .slide {
  background-size: cover;
  background-position: left top;
}
@media (min-width: 769px) {
  .crunch .carousel--merger.carousel .carousel-inner .item .slide:before {
    background-image: linear-gradient(to right, rgba(0, 75, 129, 0.8) 27%, rgba(134, 218, 255, 0.47) 100%);
    content: '';
    position: absolute;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    height: 600px;
    width: 100%;
    z-index: 1;
  }
}
.crunch .carousel--merger.carousel .captionTxt {
  background-color: transparent;
  width: 100%;
}
@media (min-width: 768px) {
  .crunch .carousel--merger.carousel .captionTxt h2 {
    margin-bottom: 0;
    color: #fff;
    text-shadow: 2px 2px 2px #003d69;
  }
}
@media (min-width: 768px) {
  .crunch .carousel--merger.carousel .captionTxt p {
    color: #d2f1ff;
    text-shadow: none;
    font-size: 18px;
  }
}
.crunch .carousel--merger.carousel .captionTxt ul li {
  padding: 15px 0 0 6px;
  font-size: 18px;
  line-height: 1.8em;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #2870A2;
}
.crunch .carousel--merger.carousel .captionTxt .btn-info:hover {
  color: #fff;
}
.crunch .sidebar-nav {
  padding: 9px 0;
}
.crunch .dropdown {
  display: table;
  position: relative;
}
@media (max-width: 768px) {
  .crunch .dropdown {
    margin: 10px 0;
  }
}
.crunch .dropdown #adobe h5 {
  display: block;
  width: 100%;
  float: left;
  font-size: 12px;
  margin: 0 15px 10px 15px;
}
.crunch .dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  visibility: hidden;
  margin-left: 2px;
  width: auto;
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (max-width: 768px) {
  .crunch .dropdown-menu .dropdown-submenu .dropdown-menu {
    width: 100%;
    background-color: #13719C;
    border-radius: 0;
    border-bottom: 1px solid #66c8ff;
    cursor: pointer;
  }
}
@media (min-width: 768px) {
  .crunch .dropdown-menu .sub-menu {
    left: 100%;
    visibility: hidden;
    margin-left: 2px;
    background-color: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -40px;
  }
}
@media (max-width: 768px) {
  .crunch .dropdown-menu {
    width: 100%;
    background-color: rgba(25, 73, 115, 0.97);
    border-radius: 0;
    cursor: pointer;
    border: none;
    border-top: 2px solid #fff;
  }
}
.crunch .dropdown-menu {
  top: auto;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.crunch .dropdown-menu .divider {
  height: 1px;
  width: 100%;
  margin: 9px 0;
  display: block;
  overflow: hidden;
  background-color: #bfced9;
}
.crunch .dropdown-menu .dropdown-header {
  display: block;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #839eb2;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
}
.crunch .dropdown-menu > li {
  padding: 0;
}
.crunch .dropdown-menu > li > a {
  padding: 10px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
  font-size: 12px;
}
.crunch .nav-tabs .dropdown-menu,
.crunch .nav-pills .dropdown-menu,
.crunch .navbar .dropdown-menu {
  min-width: 250px;
}
.crunch .nav-tabs .dropdown-menu li,
.crunch .nav-pills .dropdown-menu li,
.crunch .navbar .dropdown-menu li {
  border: none;
  cursor: pointer;
}
.crunch .nav-tabs .dropdown-menu li a,
.crunch .nav-pills .dropdown-menu li a,
.crunch .navbar .dropdown-menu li a {
  text-align: left;
}
.crunch .nav-tabs .dropdown-menu li a i,
.crunch .nav-pills .dropdown-menu li a i,
.crunch .navbar .dropdown-menu li a i {
  position: absolute;
  right: 10px;
  padding-top: 3px;
  color: #1091cc;
}
@media (max-width: 768px) {
  .crunch .nav-tabs .dropdown-menu li a i,
.crunch .nav-pills .dropdown-menu li a i,
.crunch .navbar .dropdown-menu li a i {
    position: relative;
    right: 10px;
    float: right;
    padding-top: 3px;
    color: #FFFFFF;
  }
}
.crunch .nav-tabs .dropdown-menu li:hover,
.crunch .nav-pills .dropdown-menu li:hover,
.crunch .navbar .dropdown-menu li:hover {
  cursor: pointer;
}
.crunch .nav-tabs .dropdown-menu li:hover a,
.crunch .nav-pills .dropdown-menu li:hover a,
.crunch .navbar .dropdown-menu li:hover a {
  cursor: pointer;
}
.crunch .nav-tabs .dropdown-menu .active:hover > a:first-child,
.crunch .nav-pills .dropdown-menu .active:hover > a:first-child,
.crunch .navbar .dropdown-menu .active:hover > a:first-child {
  color: #1091cc;
  cursor: pointer;
}
.crunch .navbar .sub-menu:before {
  border-bottom: 7px solid transparent;
  border-left: none;
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 7px solid transparent;
  left: -7px;
  top: 10px;
}
.crunch .navbar .sub-menu:after {
  border-top: 6px solid transparent;
  border-left: none;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
  top: 11px;
  left: -6px;
}
.crunch .dropdown-menu > .active > a:first-child {
  background-color: rgba(87, 184, 230, 0.23);
  color: #1091cc;
}
.crunch .sideBar.careerSidebar .sidebar-wrapper-standard {
  padding: 40px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.crunch .sideBar .sidebar-wrapper-standard h5 {
  margin: 0px;
}
.crunch .sidebar-wrapper-standard .cell {
  display: inline-block;
}
.crunch .sidebar-wrapper-standard .btn {
  margin-top: 20px;
}
.crunch .sideBar,
.crunch .sideBar.relative {
  position: relative;
  display: inline-block;
  background-color: #f7f9fc;
  padding: 0;
  float: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
@media screen and (min-width: 992px) {
  .crunch .sideBar,
.crunch .sideBar.relative {
    max-width: 290px;
    min-width: 290px;
  }
}
@media screen and (max-width: 768px) {
  .crunch .sideBar,
.crunch .sideBar.relative {
    width: 100%;
    min-height: 100%;
    margin: 0;
  }
}
.crunch .sideBar .wrapper,
.crunch .sideBar.relative .wrapper {
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #ced8e2;
  display: table;
}
.crunch .sideBar .wrapper .divider,
.crunch .sideBar.relative .wrapper .divider {
  padding: 0;
}
@media (max-width: 992px) {
  .crunch .sideBar .wrapper,
.crunch .sideBar.relative .wrapper {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar .wrapper,
.crunch .sideBar.relative .wrapper {
    border: none;
    border-radius: 0;
  }
}
.crunch .sideBar .wrapper:last-of-type,
.crunch .sideBar.relative .wrapper:last-of-type {
  margin: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media (max-width: 992px) {
  .crunch .sideBar .wrapper:last-of-type,
.crunch .sideBar.relative .wrapper:last-of-type {
    border-radius: 0;
  }
  .crunch div.sideBar .list,
.crunch div.sideBar.relative .list {
  margin: unset;
  padding-left: 0px;
  padding-right: 0px;
}
.crunch #adobe {
  padding-left: 0px;
  padding-right: 0px;
}
}
.crunch .sideBar .list-wrapper,
.crunch .sideBar.relative .list-wrapper {
  padding: 0;
}
.crunch .sideBar .list,
.crunch .sideBar.relative .list {
  margin: 0px auto;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  width: fit-content;
  width: -moz-fit-content;
}

.crunch .sideBar .list.capa{
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .crunch .sideBar .list,
.crunch .sideBar.relative .list {
    border-bottom: none;
    margin-bottom: 10px;
  }
}
.crunch .sideBar .list li,
.crunch .sideBar.relative .list li {
  padding: 0px;
  margin-top: 10px;
  color: #6d8196;
  list-style-type: none;
  font-size: 13px;
}
.crunch .sideBar .list.bulleted {
    padding-left: 25px;
}
.crunch .sideBar .list.bulleted li {
    list-style-type: disc;
}
.crunch .sideBar .list li p,
.crunch .sideBar.relative .list li p {
  padding: 0;
  color: #6a90ae;
  font-size: 14px;
}
@media (max-width: 992px) {
  .crunch .sideBar .list li p,
.crunch .sideBar.relative .list li p {
    color: #467092;
  }
}
.crunch .sideBar .list a:after,
.crunch .sideBar.relative .list a:after {
  height: 10px;
  width: 10px;
}
.crunch .sideBar .list li a,
.crunch .sideBar.relative .list li a {
  padding: 0;
  padding-right: 20px;
  color: #42a1ec;
  position: relative;
  font-size: 13px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1.7em;
}
.crunch .sideBar .list li:last-of-type,
.crunch .sideBar.relative .list li:last-of-type {
  border: none;
}
.crunch .sideBar .list.disc li,
.crunch .sideBar.relative .list.disc li {
  list-style-type: disc;
  padding-left: 0;
  margin-left: 25px;
  text-align: left;
  width: auto;
  padding-right: 0px;
  max-width: 160px;
}
@media (max-width: 992px) {
  .crunch .sideBar .list.disc li,
.crunch .sideBar.relative .list.disc li {
    padding: 8px 0;
    margin-left: 20px;
  }
}
@media (max-width: 992px) {
  .crunch .sideBar .long-list.inner li,
.crunch .sideBar.relative .long-list.inner li {
    width: 50%;
    display: inline-block;
    float: left;
  }
}
.crunch .sideBar h4,
.crunch .sideBar.relative h4,
.crunch .sideBar h5,
.crunch .sideBar.relative h5 {
  margin: 10px 25px;
}
@media (max-width: 992px) {
  .crunch .sideBar h4,
.crunch .sideBar.relative h4,
.crunch .sideBar h5,
.crunch .sideBar.relative h5 {
    margin: 15px 0;
  }
}
.crunch .sideBar h4,
.crunch .sideBar.relative h4 {
  font-size: 22px;
  color: #788fa7;
  line-height: 1.4em;
  margin: 5px 25px;
}
@media (max-width: 992px) {
  .crunch .sideBar h4,
.crunch .sideBar.relative h4 {
    margin: 5px 0;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar h4,
.crunch .sideBar.relative h4 {
    margin: 0;
  }
}
.crunch .sideBar h5,
.crunch .sideBar.relative h5 {
  margin-bottom: 0;
}
.crunch .sideBar a,
.crunch .sideBar.relative a {
  color: #2773bd;
  padding: 0 25px;
}
.crunch .sideBar .btn-link,
.crunch .sideBar.relative .btn-link {
  font-size: 15px;
  margin-right: 10px;
  display: inline-block;
}
.crunch .sideBar p,
.crunch .sideBar.relative p {
  padding: 0;
  color: #73899a;
  font-size: 14px;
}
.crunch .sideBar .search,
.crunch .sideBar.relative .search {
  width: 100%;
  padding: 0;
}
.crunch .sideBar .inner,
.crunch .sideBar.relative .inner {
  padding: 0;
  width: 100%;
}
@media (max-width: 992px) {
  .crunch .sideBar .inner,
.crunch .sideBar.relative .inner {
    margin: 0;
  }
}
.crunch .sideBar .inner li,
.crunch .sideBar.relative .inner li {
  font-size: 12px;
  width: 100%;
  padding: 7px 25px;
  border: 2px solid transparent;
  border-top: none;
  border-bottom: 1px solid #d6dde4;
  list-style-type: none;
}
@media (max-width: 992px) {
  .crunch .sideBar .inner li,
.crunch .sideBar.relative .inner li {
    padding: 10px 5px 8px 0;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar .inner li,
.crunch .sideBar.relative .inner li {
    border: none;
  }
}
.crunch .sideBar .inner li a,
.crunch .sideBar.relative .inner li a {
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #748998;
}
.crunch .sideBar .inner li a:hover,
.crunch .sideBar.relative .inner li a:hover,
.crunch .sideBar .inner li a:focus,
.crunch .sideBar.relative .inner li a:focus {
  background-color: transparent;
  text-decoration: none;
}
@media (max-width: 992px) {
  .crunch .sideBar .inner li:hover,
.crunch .sideBar.relative .inner li:hover {
    background-color: transparent;
  }
}
@media (min-width: 992px) {
  .crunch .sideBar .inner li:last-of-type,
.crunch .sideBar.relative .inner li:last-of-type {
    border-bottom: 1px solid transparent;
  }
}
@media (min-width: 992px) {
  .crunch .sideBar .inner li.active,
.crunch .sideBar.relative .inner li.active {
    background-color: #fff;
    border-left: 2px solid #2a9fd4;
    border-right: 2px solid #ADD9F1;
    border-bottom: 1px solid #ADD9F1;
  }
.crunch   .sideBar .inner li.active a,
.crunch .sideBar.relative .inner li.active a {
    color: #2773bd;
  }
}
.crunch .sideBar .header,
.crunch .sideBar.relative .header {
  padding: 5px 15px;
  font-size: 22px;
  border: none;
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 2px solid #d8dde3;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
.crunch .sideBar .header h4,
.crunch .sideBar.relative .header h4,
.crunch .sideBar .header h5,
.crunch .sideBar.relative .header h5 {
  margin: 0;
}
.crunch .sideBar .header a,
.crunch .sideBar.relative .header a {
  padding: 0 20px;
  margin: 0;
}
@media (max-width: 992px) {
  .crunch .sideBar .header a,
.crunch .sideBar.relative .header a {
    padding: 0;
  }
}
.crunch .sideBar .header a:hover,
.crunch .sideBar.relative .header a:hover,
.crunch .sideBar .header a:focus,
.crunch .sideBar.relative .header a:focus {
  background-color: transparent;
}
@media (max-width: 992px) {
  .crunch .sideBar .header,
.crunch .sideBar.relative .header {
    width: auto;
    padding: 0;
    margin-right: 40px;
    display: inline-block;
    float: left;
    max-width: none;
    border-bottom: none;
    padding-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar .header,
.crunch .sideBar.relative .header {
    width: 100%;
    margin: 10px 0;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 2px solid #CBDDEC;
  }
}
.crunch .sideBar .header .header:last-of-type,
.crunch .sideBar.relative .header .header:last-of-type {
  border-bottom: none;
}
.crunch .sideBar .header .header.active,
.crunch .sideBar.relative .header .header.active {
  padding: 0;
}
.crunch .sideBar .header:last-of-type,
.crunch .sideBar.relative .header:last-of-type {
  border-bottom: 1px solid transparent;
}
.crunch .sideBar .header.active,
.crunch .sideBar.relative .header.active {
  opacity: 1;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
@media (max-width: 992px) {
  .crunch .sideBar .header.active,
.crunch .sideBar.relative .header.active {
    padding: 0 0 10px 0;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar .header.active,
.crunch .sideBar.relative .header.active {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
.crunch .sideBar .header.active a,
.crunch .sideBar.relative .header.active a {
  letter-spacing: 0.12em;
  padding: 0;
}
@media (max-width: 992px) {
  .crunch .sideBar .header.active a,
.crunch .sideBar.relative .header.active a {
    color: #007db7;
    letter-spacing: 0.08em;
  }
}
.crunch .sideBar li.tierOne,
.crunch .sideBar.relative li.tierOne {
  padding: 0;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 2s;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne,
.crunch .sideBar.relative li.tierOne {
    width: auto;
    padding: 5px 25px 0 0;
    border: none;
    display: inline-block;
    float: left;
    height: auto;
    max-height: none;
  }
}
.crunch .sideBar li.tierOne h4,
.crunch .sideBar.relative li.tierOne h4 {
  padding: 10px 8px 8px 8px;
  margin: 0 15px;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne h4,
.crunch .sideBar.relative li.tierOne h4 {
    padding: 0 0 10px 0;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar li.tierOne h4,
.crunch .sideBar.relative li.tierOne h4 {
    padding: 5px 0 0 0;
  }
}
.crunch .sideBar li.tierOne h4 a,
.crunch .sideBar.relative li.tierOne h4 a {
  font-size: 16px;
  font-weight: bold;
}
@media (min-width: 992px) {
  .crunch .sideBar li.tierOne li > a,
.crunch .sideBar.relative li.tierOne li > a {
    color: #748998;
  }
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne li > a,
.crunch .sideBar.relative li.tierOne li > a {
    color: #2773bd;
  }
}
.crunch .sideBar #first.tierOne,
.crunch .sideBar.relative #first.tierOne {
  border-radius: 0;
  margin-bottom: 30px;
  height: 40px;
  border-bottom: 1px solid #b4bcc5;
}
@media (max-width: 992px) {
  .crunch .sideBar #first.tierOne,
.crunch .sideBar.relative #first.tierOne {
    border: none;
  }
}
.crunch .sideBar #first.tierOne .tierTwo_item,
.crunch .sideBar.relative #first.tierOne .tierTwo_item {
  border-radius: 0;
  border-bottom: none;
}
.crunch .sideBar li.tierOne.stayActive,
.crunch .sideBar.relative li.tierOne.stayActive {
  max-height: 800px;
  overflow: visible;
  height: auto;
  border-bottom: 2px solid #c4cdd4;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne.stayActive,
.crunch .sideBar.relative li.tierOne.stayActive {
    border: none;
  }
}
.crunch .sideBar li.tierOne.active,
.crunch .sideBar.relative li.tierOne.active {
  background-color: transparent;
}
@media (min-width: 992px) {
  .crunch .sideBar li.tierOne.active,
.crunch .sideBar.relative li.tierOne.active {
    border-left: 2px solid #2a9fd4;
    border-right: 2px solid #ADD9F1;
    height: auto;
    max-height: 800px;
    -webkit-transition: border 0.8s ease, max-height 1.5s ease;
    -moz-transition: border 0.8s ease, max-height 1.5s ease;
    -o-transition: border 0.8s ease, max-height 1.5s ease;
    transition: border 0.8s ease, max-height 1.5s ease;
  }
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne.active,
.crunch .sideBar.relative li.tierOne.active {
    border: none;
  }
}
.crunch .sideBar li.tierOne.active h4 a,
.crunch .sideBar.relative li.tierOne.active h4 a {
  font-weight: bold;
}
.crunch .sideBar li.tierOne.active ul,
.crunch .sideBar.relative li.tierOne.active ul {
  visibility: inherit;
  height: auto;
  background-color: transparent;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
@media (min-width: 992px) {
  .crunch .sideBar li.tierOne.active ul li > a,
.crunch .sideBar.relative li.tierOne.active ul li > a {
    color: #748998;
  }
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierOne.active ul li > a,
.crunch .sideBar.relative li.tierOne.active ul li > a {
    color: #2773bd;
  }
}
.crunch .sideBar li.tierOne.active ul li.active > a,
.crunch .sideBar.relative li.tierOne.active ul li.active > a {
  color: #2773bd;
}
.crunch .sideBar li.tierOne.active ul li:last-of-type,
.crunch .sideBar.relative li.tierOne.active ul li:last-of-type {
  border: none;
}
.crunch .sideBar li.tierOne:last-of-type,
.crunch .sideBar.relative li.tierOne:last-of-type,
.crunch .sideBar .tierTwo_item:last-of-type,
.crunch .sideBar.relative .tierTwo_item:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.crunch .sideBar li.tierTwo,
.crunch .sideBar.relative li.tierTwo {
  padding: 0;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierTwo,
.crunch .sideBar.relative li.tierTwo {
    width: 10px;
    opacity: 0;
    -webkit-transition: width 1.2s ease;
    -moz-transition: width 1.2s ease;
    -o-transition: width 1.2s ease;
    transition: width 1.2s ease;
  }
.crunch   .sideBar li.tierTwo span,
.crunch .sideBar.relative li.tierTwo span {
    display: none;
  }
}
.crunch .sideBar li.tierTwo_item,
.crunch .sideBar.relative li.tierTwo_item {
  list-style-type: none;
  height: auto;
  padding: 10px 25px;
  border-right: none;
  border-left: none;
  -webkit-transition: height 0.8s ease;
  -moz-transition: height 0.8s ease;
  -o-transition: height 0.8s ease;
  transition: height 0.8s ease;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierTwo_item,
.crunch .sideBar.relative li.tierTwo_item {
    display: block;
    border: none;
    padding: 5px 0;
  }
}
.crunch .sideBar li.tierTwo_item a,
.crunch .sideBar.relative li.tierTwo_item a {
  padding: 0;
  font-size: 13px;
}
.crunch .sideBar li.tierTwo_item.active,
.crunch .sideBar.relative li.tierTwo_item.active {
  border-right: none;
  border-left: none;
  height: auto;
  -webkit-transition: height 0.8s ease;
  -moz-transition: height 0.8s ease;
  -o-transition: height 0.8s ease;
  transition: height 0.8s ease;
}
@media (max-width: 992px) {
  .crunch .sideBar li.tierTwo_item.active,
.crunch .sideBar.relative li.tierTwo_item.active {
    background-color: transparent !important;
  }
}
.crunch .sideBar li.tierTwo_item.active ul,
.crunch .sideBar.relative li.tierTwo_item.active ul {
  opacity: 1;
  padding: 0;
}
.crunch .sideBar li.tierTwo_item.active ul li,
.crunch .sideBar.relative li.tierTwo_item.active ul li {
  border-color: #e1e5e7;
}
.crunch .sideBar .btn-download:after,
.crunch .sideBar.relative .btn-download:after {
  color: #7a9891;
  margin: 0;
}
.crunch .sideBar a:hover,
.crunch .sideBar.relative a:hover,
.crunch .sideBar a:focus,
.crunch .sideBar.relative a:focus {
  background-color: transparent;
  text-decoration: none;
  color: #2773bd;
  cursor: pointer;
}
.crunch .sideBar.relative {
  position: relative;
}
.crunch .sideBar.fixed {
  position: fixed;
  top: 50px;
  bottom: 0;
  display: table;
}
@media (max-width: 992px) {
  .crunch .sideBar.fixed {
    min-width: 100%;
    width: 100%;
    background-color: #ebeef1;
    padding: 0 35px;
    z-index: 9;
    border-radius: 0;
  }
.crunch   .sideBar.fixed .inner {
    padding: 0;
  }
.crunch   .sideBar.fixed li,
.crunch .sideBar.fixed a {
    font-size: 12px;
  }
.crunch   .sideBar.fixed .header .inner > li {
    padding: 0;
  }
.crunch   .sideBar.fixed .header.active .inner > li.active {
    background-color: transparent;
    font-size: 12px;
  }
.crunch   .sideBar.fixed .header.active {
    padding: 0;
  }
.crunch   .sideBar.fixed #capaMenu {
    border: none;
  }
.crunch   .sideBar.fixed .cardWrapper {
    height: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: height 0.8s ease, opacity 1s ease;
    -moz-transition: height 0.8s ease, opacity 1s ease;
    -o-transition: height 0.8s ease, opacity 1s ease;
    transition: height 0.8s ease, opacity 1s ease;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .crunch .sideBar.fixed .header.active .inner > li.active {
    background-color: #fff;
  }
}
@media (max-width: 480px) {
  .crunch .sideBar.fixed {
    top: 46px;
    padding: 0 15px;
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
  }
}
.crunch #sidebar.sideBar.fixed.break {
  position: fixed;
  -webkit-transition: top 0s,  bottom 0s;
  transition: top 0s,  bottom 0s;
}
.crunch .midSpy {
  opacity: 0;
}
@media (min-width: 992px) {
  .crunch .midSpy {
    display: none;
  }
}
@media (max-width: 992px) {
  .crunch .midSpy {
    position: fixed;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    background-color: #eceeed;
    height: 43px;
    z-index: 45;
    overflow: visible;
    border-bottom: 2px solid #b0bbcb;
    top: 55px;
    padding: 10px 15px 10px 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
}
@media (max-width: 768px) {
  .crunch .midSpy {
    position: fixed;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    top: 55px;
    height: 40px;
    z-index: 45;
    overflow: visible;
    padding: 10px 15px;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
}
.crunch .midSpy h5 {
  display: none;
}
.crunch .midSpy img {
  visibility: hidden;
}
@media (max-width: 768px) {
  .crunch .midSpy .navbar-nav {
    position: absolute;
    top: 0;
    margin: 0;
  }
}
.crunch .midSpy .heading {
  padding: 0;
}
@media (min-width: 768px) {
  .crunch .midSpy .header {
    padding: 0;
    width: 100%;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .crunch .midSpy .header {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .crunch .midSpy .title {
    color: #7c818c;
    display: inline-block;
    margin: 0;
    padding-top: 7px;
    padding-left: 35px;
    width: auto;
    min-width: 250px;
    font-weight: bold;
  }
}
@media (max-width: 768px) {
  .crunch .midSpy .title {
    display: none;
  }
}
@media (min-width: 768px) {
  .crunch .midSpy .navbar-nav {
    overflow: visible;
  }
}
@media (min-width: 992px) {
  .crunch .midSpy .inner {
    width: 45%;
    display: inline-flex;
    align-items: flex-start;
    float: left;
    overflow: visible;
    margin-top: 7px;
    list-style-type: none;
  }
}
@media (max-width: 992px) {
  .crunch .midSpy .inner {
    list-style-type: none;
    padding: 0;
    height: 40px;
    overflow: hidden;
    position: relative;
    margin: 0;
    display: inline-block;
    width: 100%;
  }
}
.crunch .midSpy .inner li {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
  padding-left: 20px;
}
.crunch .midSpy .inner li a {
  height: 20px;
  display: block;
  position: relative;
  top: 20px;
  right: 0;
  width: 100%;
  opacity: 0;
  color: #7c818c;
}
.crunch .midSpy .inner li.active {
  background-color: transparent;
  border: none;
  max-width: 80%;
  padding-left: 20px;
}
@media (max-width: 768px) {
  .crunch .midSpy .inner li.active {
    padding-left: 0;
  }
}
.crunch .midSpy .inner li.active a {
  height: 20px;
  width: 100%;
  display: block;
  top: 12px;
  left: 0;
  opacity: 1;
  color: #2773bd;
}
@media (max-width: 992px) {
  .crunch .midSpy .dropdown {
    display: block;
    position: absolute;
    right: 0;
    top: 3px;
    z-index: 900;
    width: 100%;
    margin: 0;
  }
.crunch   .midSpy .dropdown .btn-link {
    padding: 0;
    margin: 0;
    float: right;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    width: 50px;
  }
.crunch   .midSpy .dropdown .btn-link .caret {
    opacity: 0;
  }
.crunch   .midSpy .dropdown .btn-link:before {
    font-family: "FontAwesome", serif;

    content: '\f13a';
    font-size: 23px;
    color: #2773bd;
    position: absolute;
    width: 50px;
    right: 0;
    left: 4px;
  }
.crunch   .midSpy .dropdown .btn-link:focus {
    text-decoration: none;
  }
.crunch   .midSpy .dropdown .btn-link:after {
    content: '';
  }
.crunch   .midSpy .dropdown h5 {
    display: block;
    font-size: small;
    text-transform: none;
    font-weight: 400;
    line-height: 1.5em;
  }
.crunch   .midSpy .dropdown .dropdown-menu {
    max-height: 0;
    overflow: hidden;
    display: block;
    top: 35px;
    width: 100%;
    background-color: #fff;
    border: none;
    -webkit-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
.crunch   .midSpy .dropdown .dropdown-menu li h5 {
    margin: 0;
  }
.crunch   .midSpy .dropdown .dropdown-menu li a {
    padding: 9px;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    white-space: nowrap;
    font-size: 12px;
    padding-left: 30px;
    border-bottom: 1px solid #eee;
  }
.crunch   .midSpy .dropdown .dropdown-menu li:last-of-type a {
    border: none;
  }
.crunch   .midSpy .dropdown .dropdown-menu li.active a {
    border-color: #c8ebfc;
  }
.crunch   .midSpy .dropdown .dropdown-menu li.active a h5 {
    color: #2773bd;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .crunch .midSpy .dropdown .dropdown-menu li a {
    padding-left: 35px;
  }
}
@media (max-width: 992px) and (max-width: 768px) {
  .crunch .midSpy .dropdown .dropdown-menu li a {
    padding-left: 15px;
  }
}
.crunch .midSpy .dropdown.open .dropdown-menu {
  position: inherit;
  border: 0;
  box-shadow: none;
  max-height: 800px;
  overflow-y: scroll;
  overflow: hidden;
  display: block;
  top: 35px;
  background-color: #fff;
  border-bottom: 2px solid #c4c4c4;
  border-radius: 0;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
}
.crunch .midSpy .dropdown.open .dropdown-menu .btn-link {
  display: block;
  top: 0;
}
.crunch .midSpy .dropdown.open .dropdown-menu h5 {
  margin: 0;
}
.crunch .midSpy.bar-down {
  opacity: 1;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
@media (max-width: 768px) {
  .crunch .midSpy.bar-down {
    display: block;
  }
}
.crunch .midSpy.nav-up {
  height: 40px;
  overflow: visible;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
}
@media (min-width: 768px) {
  .crunch .midSpy.nav-up {
    top: 54px;
  }
}
@media (max-width: 768px) {
  .crunch .midSpy.nav-up {
    top: 0;
  }
}
.crunch .midSpy.nav-down {
  top: 55px;
  height: 40px;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
}
.crunch .sideBar .navbar-nav {
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.crunch .sideBar .dropdown .dropdown-menu {
  overflow: hidden;
  display: block;
  border: none;
  background-color: #fff;
}
.crunch .sideBar .dropdown .dropdown-menu li {
  padding: 0;
  border-bottom: 1px solid #eee;
}
.crunch .sideBar .dropdown .dropdown-menu li a {
  padding: 10px 15px;
}
.crunch .sideBar .dropdown .dropdown-menu li:hover {
  background-color: #fff;
}
.crunch .sideBar .dropdown .dropdown-menu li:hover a {
  color: #2773bd;
  background-color: #fff;
}
.crunch .sideBar .dropdown.open .dropdown-menu {
  height: auto;
  overflow: hidden;
}
@media (max-width: 768px) {
  .crunch .sideBar .dropdown {
    display: table;
    position: relative;
    right: 0;
    z-index: 900;
    width: 100%;
  }
.crunch   .sideBar .dropdown .dropdown-menu {
    height: 0;
    overflow: hidden;
    display: block;
    border: none;
  }
.crunch   .sideBar .dropdown .dropdown-menu li a {
    padding: 10px 15px;
    white-space: inherit;
  }
.crunch   .sideBar .dropdown .dropdown-menu h2,
.crunch .sideBar .dropdown .dropdown-menu h3 {
    padding: 0 15px;
  }
.crunch   .sideBar .dropdown .btn-link {
    padding: 0;
    width: 100%;
    text-align: left;
    margin: 0;
  }
.crunch   .sideBar .dropdown .btn-link:after {
    display: none;
  }
.crunch   .sideBar .dropdown h5 {
    font-size: small;
    text-transform: none;
    font-weight: 400;
  }
.crunch   .sideBar .dropdown h4 {
    display: inline;
    width: auto;
    margin: 0;
    margin-right: 10px;
  }
}
.crunch .padZero {
  padding: 0;
}
.crunch .col-pad-none {
  padding: 0;
}
.crunch .pad-left-none {
  padding-left: 0;
}
.crunch .marginT {
  margin-top: 50px;
}
@media (max-width: 992px) {
  .crunch .marginT {
    margin-top: 0;
  }
}
.crunch .margin30 {
  margin-top: 30px;
}
@media (max-width: 992px) {
  .crunch .margin30 {
    margin-top: 10px;
  }
}
.crunch .margint {
  margin-top: 15px;
}
@media (max-width: 768px) {
  .crunch .margint {
    margin-top: 10px;
  }
}
.crunch .form-group {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.crunch .form-group.required label:after {
  content: '*';
  color: #e46461;
  font-size: 12px;
  padding-left: 5px;
  display: inline;
}
.crunch .form-control {
  color: #2d8adf;
}
.crunch .form-control-file {
  font-size: 13px;
  color: #666c79;
}
.crunch .form-check {
  display: inline;
  margin-right: 15px;
}
.crunch .form-check:hover {
  cursor: pointer;
}
.crunch .form-check label {
  font-weight: normal;
}
.crunch .help-text {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  padding-left: 10px;
  letter-spacing: normal;
  color: #8b9093;
}
@media (max-width: 768px) {
  .crunch .help-text {
    display: block;
  }
}
.crunch .fileType-message {
  display: none;
}
.crunch .fileType-message.active {
  display: block;
  padding-bottom: 5px;
}
.crunch .messages.alert-danger {
  text-align: center;
  width: 430px;
  line-height: 1.8em;
  margin: 0 auto 40px auto;
}
.crunch .alert.alert-success.alert-dismissable {
  background-color: #e8f4ff;
  border-color: #66c8ff;
  color: #00549a;
  text-align: center;
  line-height: 1.8em;
}
.crunch .captcha-message {
  display: none;
  color: #de5250;
  font-size: 12px;
}
.crunch .captcha-message.active {
  display: block;
}
.crunch .captcha {
  padding: 20px 0;
  display: inline-block;
}
@media (max-width: 768px) {
  .crunch .captcha {
    width: 100%;
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .crunch img {
    width: auto;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .crunch .graphic-wrap {
    margin: 0;
  }
}
.crunch .graphic-wrap--left {
  padding-right: 30px;
  float: left;
}
@media screen and (max-width: 768px) {
  .crunch .graphic-wrap--left {
    padding: 0 15px;
  }
}
.crunch .graphic-wrap--right {
  padding-left: 30px;
  float: right;
}
@media screen and (max-width: 768px) {
  .crunch .graphic-wrap--right {
    padding: 0 15px;
  }
}
.crunch .graphic-wrap--icon {
  max-width: 200px;
  margin: 20px 0;
  display: inline-block;
}
.crunch .graphic-wrap--icon-no-margin {
  margin: 0;
}
.crunch .graphic-wrap .graphic {
  height: auto;
  display: inline-block;
  border-radius: 3px;
}
.crunch .graphic-wrap .graphic img {
  border-radius: 3px;
  height: auto;
}
.crunch .graphic-wrap .graphic--mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .crunch .graphic-wrap .graphic--mobile {
    display: inline-block;
    width: 100%;
  }
}
.crunch .graphic-wrap .graphic--desktop {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .crunch .graphic-wrap .graphic--desktop {
    display: none;
  }
}
.crunch .graphic-wrap .graphic--caption {
  color: #7c818c;
  font-weight: 400;
  line-height: 1.5;
  width: 90%;
  margin: 20px auto;
  font-style: italic;
  display: block;
  font-size: 14px;
}
@media (max-width: 1000px) {
  .crunch .graphic-wrap .graphic--caption {
    width: 100%;
    font-size: 12px;
    margin-top: 0;
  }
}
.crunch .graphic-wrap .graphic--icon {
  max-width: 50px;
}
.crunch .page-section .graphic-wrap {
  margin-top: 25px;
}
.crunch .title .graphic-wrap--icon {
  width: 55px;
  margin: 0;
  text-align: left;
  padding: 0;
}
@media (max-width: 768px) {
  .crunch .title .graphic-wrap--icon {
    text-align: center;
  }
}
.crunch .imgAvatar {
  border-radius: 150px;
  float: left;
  background-color: #fff;
  border: 2px solid rgba(179, 194, 201, 0.43);
}
@media (max-width: 768px) {
  .crunch .imgAvatar {
    max-width: 80%;
    margin: 0;
    height: auto ;
  }
}
@media (max-width: 992px) {
  .crunch img.expand-image {
    max-height: 500px;
  }
}
@media (max-width: 768px) {
  .crunch img.expand-image {
    max-width: 500px;
    height: auto;
    width: 100%;
    margin: 30px 0;
    max-height: unset;
  }
  .crunch .expand-details {
    padding: 0;
    display: block;
    max-width: 500px;
    width: 100%;
}
}
@media (max-width: 576px) {
  .crunch img.expand-image {
    max-width: 100%;
  }
  .crunch .expand-details {
    max-width: 100%;
}
}
.crunch .expand-details {
  padding: 0;
  display: block;
}
.crunch .img-bordered {
  border: 2px solid #d1dee4;
}
.crunch .popover {
  color: #666c79;
  letter-spacing: 0.06em;
  min-width: 500px;
  padding: 10px;
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .crunch .popover {
    width: 100%;
    max-width: 300px;
  }
.crunch   .popover .popover-content {
    max-width: 300px;
  }
}
.crunch .btn .caret {
  margin: 0 0 0 10px;
}
.crunch .btn {
  display: inline-block;
  font-weight: normal;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 4px;
  letter-spacing: 0.08em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
}
@media (max-width: 768px) {
  .crunch .btn {
    font-size: 14px;
    word-wrap: break-word;
    margin: 0;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}
.crunch .btn:hover {
  cursor: pointer;
}
.crunch .btn.mobile {
  display: none;
}
@media (max-width: 768px) {
  .crunch .btn.mobile {
    display: inline-block;
  }
}
.crunch .btn-default {
  border: 1px solid #bfced9;
  color: #42a1ec;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  transition: background-color 0.5s, color 0.5s;
}
.crunch .btn-default:hover,
.crunch .btn-default:focus {
  border-color: #c2d9f0;
  color: #2773bd;
  background-color: #fff;
  transition: background-color 0.5s, color 0.5s;
}
.crunch .btn-default:hover a,
.crunch .btn-default:focus a {
  color: #40aada;
}
.crunch .btn-default .fa {
  color: #7c818c;
}
.crunch .btn-default a {
  color: #5e85a1;
  text-decoration: none;
}
.crunch .btn-primary {
  color: #fff;
  background-color: #1091cc;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 200;
  letter-spacing: 0.1em;
  padding: 5px 15px;
  margin: 5px 0;
  border-bottom: 3px solid #204d80;
  border-right: 3px solid #204d80;
}
@media (max-width: 768px) {
  .crunch .btn-primary {
    font-size: 16px;
  }
}
.crunch .btn-primary:hover,
.crunch .btn-primary:focus {
  background-color: #2773bd;
  border-color: #204d80;
}
.crunch .btn-primary.active {
  color: #fff;
  background-color: #2773bd;
  border-color: #204d80;
}
.crunch .btn-primary.active:focus,
.crunch .btn-primary.active:hover {
  color: #fff;
  background-color: #15629e;
  border-color: #09518a;
}
.crunch .btn-primary h5 {
  color: #fff;
  font-size: 14px;
  margin: 3px 0 0 0;
}
.crunch .btn-info {
  background-color: transparent;
  color: #2ba5df;
  border: 2px solid #57b8e6;
  font-size: 13px;
  padding: 8px 10px 8px;
  border-radius: 0;
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: 0.08em;
  margin-right: 15px;
  text-transform: uppercase;
  margin-top: 10px;
  width: auto;
  display: inline-block;
}
.crunch .btn-info a {
  color: #33b6ff;
}
@media (max-width: 768px) {
  .crunch .btn-info {
    text-align: left;
  }
}
.crunch .btn-info:hover {
  background-color: #57b8e6;
  text-decoration: none;
  color: #fff;
}
.crunch .btn-info:hover a {
  color: #fff;
  text-decoration: none;
}
.crunch .btn-info:focus {
  color: #fff;
}
.crunch .btn-info:focus a {
  color: #fff;
  text-decoration: none;
}
.crunch .btn-info:active:focus {
  color: #fff;
  background-color: #1e92c8;
  border-color: #15688f;
}
.crunch .btn-info:active:hover {
  color: #fff;
}
.crunch .btn-info:active:hover,
.crunch .btn-info.active:hover,
.crunch .open > .dropdown-toggle.btn-info:hover,
.crunch .btn-info:active:focus,
.crunch .btn-info.active:focus,
.crunch .open > .dropdown-toggle.btn-info:focus,
.crunch .btn-info:active.focus,
.crunch .btn-info.active.focus,
.crunch .open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #1e92c8;
  border-color: #15688f;
}
.crunch .btn-service {
  min-height: 80px;
  background-color: transparent;
  color: #5e85a1;
  border: 4px solid #b4c3cb;
  letter-spacing: 0.08em;
  font-weight: 300;
  white-space: normal;
}
@media (max-width: 480px) {
  .crunch .btn-service {
    font-size: 14px;
    padding: 10px 12px;
  }
}
.crunch .btn-service:hover {
  background-color: #fff;
  text-decoration: none;
  color: #1091cc;
}
@media (max-width: 480px) {
  .crunch .btn-service:hover {
    border-color: #66c8ff;
  }
}
.crunch .btn-service:focus {
  background-color: #fff;
  text-decoration: none;
}
@media (max-width: 480px) {
  .crunch .btn-service:focus {
    border-color: #66c8ff;
  }
}
.crunch .pane-link :before {
  font-family: "FontAwesome", serif;
  content: '\f137';
  font-size: 20px;
  color: green;
}
.crunch .btn-link-back,
.crunch .btn-link,
.crunch .btn-download {
  font-size: 17px;
  color: #40aada;
  font-weight: 500;
  padding: 0;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-align: left;
  margin: 10px 0 0;
  white-space: inherit;
}
.crunch .btn-link-back:hover,
.crunch .btn-link:hover,
.crunch .btn-download:hover,
.crunch .btn-link:focus,
.crunch .btn-download:focus {
  color: #1091cc;
}
@media (max-width: 768px) {
  .crunch .btn-link-back
  .btn-link,
.crunch .btn-download {
    font-size: medium;
    display: block;
    text-align: left;
    color: #1292c2;
    white-space: pre-wrap;
    /* css-3 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
  }
}
.crunch .btn-download:after {
  font-family: "FontAwesome", serif;
  font-size: 22px;
  padding-left: 10px;
  vertical-align: -2px;
  color: #12b892;
} 
.crunch a.btn-link-back,
.crunch a.btn-link {
  font-size: 15px;
  color: #2773bd;
  font-weight: 500;
  padding: 0;
  display: inline-block;
  margin: 10px 0 0;
  float: left;
}
.crunch a.btn-link-back:hover,
.crunch a.btn-link:hover {
  text-decoration: none;
}
.crunch .btn-link:hover,
.crunch .btn-download:hover {
  text-decoration: none;
}
.crunch button.btn-expand {
  background-color: transparent;
  display: block;
  margin: 5px auto;
}
@media (max-width: 992px) {
  .crunch button.btn-expand {
    background-color: transparent;
    display: block;
  }
}
@media (max-width: 768px) {
  .crunch button.btn-expand {
    display: none;
  }
}
.crunch button.btn-expand p {
  border-radius: 5px;
  padding: 0 10px;
}
.crunch p {
  position: relative;
  display: inline-block;
  font-size: 1.05em;
  font-family: Helvetica, sans-serif;
  color: #666c79;
  line-height: 1.875em;
  max-width: 770px;
  font-weight: 400;
  letter-spacing: 0.06em;
  padding: 5px 0 10px 0;
  margin: 0;
  z-index: 5;
}
@media (max-width: 768px) {
  .crunch p {
    font-weight: normal;
    color: #547186;
    line-height: 1.8em;
  }
}
.crunch p a {
  font-weight: 400;
}
.crunch p,
.crunch a,
.crunch li,
.crunch span {
  letter-spacing: 0.08em;
}
.crunch label {
  color: #5a606b;
}
.crunch .highlight {
  color: #1091cc;
  font-weight: 400;
}
.crunch .center {
  text-align: center;
}
.crunch .address--wrap h5 {
  font-size: 14px;
  margin: 5px 0;
}
@media screen and (max-width: 768px) {
  .crunch .address--wrap {
    margin-bottom: 40px;
  }
.crunch   .address--wrap:last-of-type {
    margin-bottom: 0;
  }
}
.crunch .with-errors ul li {
  padding: 0;
  color: #e46461;
  font-size: 12px;
}
.crunch .error {
  padding: 0;
  color: #e46461;
  font-size: 12px;
}
.crunch ul,
.crunch ol {
  margin-bottom: 20px;
  padding-left: 20px;
  list-style-type: disc;
  position: relative;
}
@media (max-width: 768px) {
  .crunch ul,
  .crunch ol {
    padding-left: 10px;
    margin-left: 10px;
    display: table;
    width: 100%;
  }
}
.crunch ul.list_striped,
.crunch ol.list_striped {
  margin: 0;
}
.crunch ul.list_striped li:nth-of-type(odd),
.crunch ol.list_striped li:nth-of-type(odd) {
  background-color: rgba(235, 241, 247, 0.82);
  border-bottom: 1px solid rgba(221, 221, 221, 0.62);
}
.crunch ul.list_striped li:nth-of-type(even),
.crunch ol.list_striped li:nth-of-type(even) {
  background-color: #fff;
  border-bottom: 1px solid rgba(221, 221, 221, 0.62);
}
.crunch ul.address,
.crunch ol.address {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1.1em;
}
.crunch ul.address li,
.crunch ol.address li {
  width: 100%;
  list-style-type: none;
  padding: 3px 0;
  color: #7c818c;
  font-size: 13px;
  display: inline-block;
}
.crunch ul.address li .address--label,
.crunch ol.address li .address--label {
  padding: 0;
  padding-right: 10px;
  font-weight: bold;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .crunch ul.address li .address--label,
  ol.address li .address--label {
    text-align: left;
    padding-bottom: 5px;
  }
}
.crunch ul.address li .address--info,
.crunch ol.address li .address--info {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: #019fa1;
}
.crunch ul li,
.crunch ol li {
  padding: 10px 0 0 0;
  color: #7c818c;
  z-index: 10;
  font-size: 15px;
  position: relative;
}
@media (max-width: 768px) {
  .crunch ul li,
  ol li {
    font-size: small;
  }
}
.crunch .page-section ul li {
  font-weight: 400;
}
.crunch .page-section .sideBar ul li {
  font-weight: 400;
}
.crunch h1,
.crunch h2,
.crunch h3,
.crunch h4,
.crunch h5,
.crunch h6 {
  position: relative;
  z-index: 5;
  font-family: Helvetica, sans-serif;
  line-height: 1.6em;
  letter-spacing: 0.08em;
}
.crunch h1 {
  font-size: 3em;
  color:#2773bd;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.2em;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch h1 {
    font-size: 2.5em;
    line-height: 1.125em;
  }
}
@media (max-width: 768px) {
  .crunch h1 {
    font-size: 2em;
    line-height: 1.25em;
  }
}
.crunch h2 {
  font-size: 2.0em;
  color: #1091cc;
  line-height: 1.25em;
  margin-bottom: 10px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch h2 {
    font-size: 2em;
    line-height: 1.25em;
  }
}
@media (max-width: 480px) {
  .crunch .microsite-sms h2 {
    font-size: 1.6em!important;
  }
}
@media (max-width: 768px) {
  .crunch h2 {
    font-size: 1.625em;
  }
}
.crunch h3 {
  font-size: 1.75em;
  color: #40aada;
  line-height: 1.25em;
  margin-top: 20px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch h3 {
    font-size: 1.5em;
    line-height: 1.25em;
  }
}
@media (max-width: 768px) {
  .crunch h3 {
    font-size: 1.375em;
    line-height: 1.4;
  }
}
.crunch h4 {
  font-size: 1.125em;
  color: #5e85a1;
  margin: 20px 0 0 0;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch h4 {
    font-size: 1.125em;
    line-height: 1.22222222em;
  }
}
@media (max-width: 768px) {
  .crunch h4 {
    font-size: 1.125em;
    line-height: 1.11111111em;
  }
}
.crunch h5 {
  font-size: 1.1em;
  color: #727887;
  font-weight: bold;
  line-height: 1.5em;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 25px;
}
@media (max-width: 768px) {
  .crunch h5 {
    font-size: 15px;
    line-height: 1.5em;
  }
}
.crunch h1 small,
.crunch h2 small,
.crunch h3 small,
.crunch h4 small,
.crunch h5 small,
.crunch h6 small,
.crunch .h1 small,
.crunch .h2 small,
.crunch .h3 small,
.crunch .h4 small,
.crunch .h5 small,
.crunch .h6 small,
.crunch h1 .small,
.crunch h2 .small,
.crunch h3 .small,
.crunch h4 .small,
.crunch h5 .small,
.crunch h6 .small,
.crunch .h1 .small,
.crunch .h2 .small,
.crunch .h3 .small,
.crunch .h4 .small,
.crunch .h5 .small,
.crunch .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #82b2d4;
  letter-spacing: 0.06em;
}
.crunch .headings--over {
  margin-bottom: 5px;
  font-size: 2.5em;
  line-height: 1.125em;
  color: #2773bd;
  margin-top: 30px;
}
.crunch .headings--under {
  margin: 5px 0;
  padding-top: 0;
  display: inline-block;
  color: #1091cc;
}
.crunch .anchorScroll {
  width: auto;
  padding: 0;
}
@media (max-width: 768px) {
  .crunch .anchorScroll {
    width: 50px;
  }
}
.crunch #back-top {
  position: fixed;
  bottom: 200px;
  right: 20px;
  z-index: 10;
}
.crunch #back-top span {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 7px;
  background: rgba(64, 170, 218, 0.5);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 5px;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  transition: background 1s;
}
.crunch #back-top span:hover {
  background: rgba(64, 170, 218, 0.9);
}
@media (max-width: 768px) {
  .crunch #back-top span {
    background: rgba(64, 170, 218, 0.7);
    margin: 0;
    width: 100%;
    border-radius: 5px;
  }
}
@media screen and (max-width: 992px) {
  .crunch body #back-top {
    bottom: 250px;
  }
}
.crunch #back-top.fixed span {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 7px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 5px;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  transition: background 1s;
}
@media (max-width: 768px) {
  .crunch #back-top.fixed span {
    border-radius: 5px;
  }
}
.crunch #back-top.bottom {
  position: absolute;
  bottom: 0;
  top: auto;
  -webkit-transition: bottom 0.35s ease-in-out;
  -moz-transition: bottom 0.35s ease-in-out;
  -o-transition: bottom 0.35s ease-in-out;
  transition: bottom 0.35s ease-in-out;
}
@media (max-width: 768px) {
  .crunch #back-top.bottom {
    position: relative;
    height: 50px;
    margin: 0;
    width: 100%;
    display: block;
    left: 0;
    right: 0;
    -webkit-transition: bottom 0s ease-in-out;
    -moz-transition: bottom 0s ease-in-out;
    -o-transition: bottom 0s ease-in-out;
    transition: bottom 0s ease-in-out;
  }
.crunch   #back-top.bottom span {
    border-radius: 0;
  }
}
@media (max-width: 768px) {
  .crunch #back-top.fixed.bottom span {
    margin: 0;
    background: #0d93de;
    width: 100%;
    border-radius: 0;
  }
}
.crunch #back-top:hover {
  cursor: pointer;
}
.crunch #back-top:hover a {
  color: #439ac1;
}
@media (max-width: 768px) {
  .crunch #back-top:hover a {
    color: transparent;
  }
}
.crunch #back-top:hover:before {
  color: #fff;
}
.crunch #back-top a {
  width: 100px;
  display: table;
  padding: 0;
  text-align: center;
  font: 11px/100% Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: transparent;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
  z-index: 900;
}
@media (max-width: 768px) {
  .crunch #back-top a {
    width: 100%;
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
  }
}
.crunch #back-top a:after {
  font-family: "FontAwesome", serif;
  content: '\f102';
  font-size: 35px;
  display: block;
  color: #fff;
  position: absolute;
  top: 18px;
  right: 0;
  left: 0;
  padding: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
}
@media (max-width: 768px) {
  .crunch #back-top a:after {
    z-index: 2;
    width: 25px;
    margin: 0 auto;
  }
}
.crunch .breadcrumb-wrapper {
  position: relative;
  z-index: 10;
  padding: 0;
  width: 100%;
  display: inline-block;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  box-shadow: 2px 2px 2px rgba(0, 63, 97, 0.22);
  background: #dfeaf0;
  background: -moz-linear-gradient(right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%);
  background: -webkit-gradient(right top, right top, color-stop(0%, rgba(72, 197, 253, 0.11)), color-stop(100%, rgba(75, 145, 206, 0.37)));
  background: -webkit-linear-gradient(right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%);
  background: -o-linear-gradient(right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%);
  background: -ms-linear-gradient(right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%);
  background: linear-gradient(to right, rgba(72, 197, 253, 0.11) 0%, rgba(75, 145, 206, 0.37) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfeaf0', endColorstr='#edf9ff', GradientType=1);
}
@media (max-width: 768px) {
  .crunch .breadcrumb-wrapper {
    padding: 0;
    background-color: #fff;
    z-index: 10;
    position: relative;
  }
}
.crunch .breadcrumb > li + li:before {
  content: "/\00a0";
  padding: 0 5px;
  color: #89acbf;
  font-size: 15px;
}
.crunch .breadcrumb {
  max-width: 992px;
  padding: 2px 15px;
  margin: 0 auto;
  list-style: none;
  border-radius: 0;
  width: 100%;
  background-color: transparent;
}
@media (max-width: 992px) {
  .crunch .breadcrumb {
    padding: 0 15px;
  }
}
@media (max-width: 768px) {
  .crunch .breadcrumb {
    padding: 2px 30px 0 15px;
  }
.crunch   .breadcrumb li {
    font-size: 12px;
  }
}
.crunch .breadcrumb a {
  font-weight: 500;
  font-size: 13px;
}
.crunch .breadcrumb li {
  font-weight: 400;
  padding: 5px 0;
}
.crunch .breadcrumb > .active {
  color: #75a4c6;
  letter-spacing: 0.08em;
  font-size: 13px;
}
.crunch .panel-group .panel {
  margin: 20px 0 50px 0;
  border-radius: 4px;
}
.crunch .panel-group .panel .page-section div {
  padding: 0;
}
.crunch .panel-group .panel .page-section h4 {
  margin: 0;
}
.crunch .panel-group .panel .topDetails.page-section {
  background-color: #f3faff;
  padding: 20px 25px 0;
  margin-bottom: 20px;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #ddd;
  display: block;
  width: 100%;
}
@media (max-width: 992px) {
  .crunch .panel-group .panel .topDetails.page-section {
    padding: 20px 25px;
  }
}
.crunch .panel-group .panel .topDetails.page-section h5 {
  margin: 0;
  color: #729ebe;
}
.crunch .panel-group .panel .topDetails.page-section h4 {
  margin: 0;
  font-weight: bold;
  color: #2773bd;
  font-size: 18px;
}
.crunch .panel-group .panel .topDetails.page-section ul li {
  color: #7099b6;
  padding-top: 5px;
}
.crunch .panel-group .panel .topDetails.page-section p {
  font-style: italic;
  padding-top: 0;
  line-height: 1.5em;
  color: #729ebe;
  font-weight: 400;
}
.crunch .panel-group .panel:last-of-type {
  margin-bottom: 10px;
}
.crunch .panel-default > .panel-heading {
  padding: 10px 15px 5px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #4e8bb4;
  position: relative;
}
@media (max-width: 768px) {
  .crunch .panel-default > .panel-heading {
    display: flex;
    flex-direction: column-reverse;
  }
}
.crunch .panel-default > .panel-heading .panel-title {
  margin: 5px 0;
  display: inline-flex;
  width: 80%;
  font-size: 22px;
  line-height: 1.4em;
  padding: 3px 10px 5px 5px;
  color: #ffffff;
  text-shadow: 1px 1px 1px #174869;
}
@media (max-width: 768px) {
  .crunch .panel-default > .panel-heading .panel-title {
    width: 100%;
    margin: 0;
  }
}
.crunch .panel-default > .panel-heading .addInfo {
  position: relative;
  display: inline-block;
  float: right;
  width: 20%;
  height: 30px;
}
@media (max-width: 768px) {
  .crunch .panel-default > .panel-heading .addInfo {
    position: relative;
    width: 100%;
    z-index: 9000;
    text-align: left;
  }
}
.crunch .panel-default > .panel-heading .addInfo p {
  position: absolute;
  right: 0;
  padding: 0;
  top: 5px;
  bottom: 0;
  text-align: right;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3em;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .crunch .panel-default > .panel-heading .addInfo p {
    background-color: transparent;
    line-height: 1.3em;
    right: 10px;
    text-align: left;
    position: relative;
    margin: 0 20px;
    font-size: 16px;
    padding-top: 5px;
  }
}
.crunch .panel-body {
  padding: 10px 25px;
  display: block;
  width: 100%;
}
@media (max-width: 768px) {
  .crunch .panel-body {
    padding: 10px 15px;
  }
.crunch .panel-body .graphic-wrap--right {
    padding: 0;
  }
}
.crunch .panel-body .page-section {
  width: 100%;
  padding: 0;
  padding-top: 10px;
}
.crunch .reveal-pane {
  background: #f7f9fc;
  padding: 20px;
  border-right: 3px solid #fff;
  visibility: hidden;
  overflow-y: scroll;
}
.crunch .reveal-pane.open {
  visibility: visible;
}
.crunch .reveal-pane--serviceCards {
  position: fixed;
  width: 250px;
  transition: all 300ms ease;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1049;
}
.crunch .reveal-pane--serviceCards h2 {
  font-size: 22px;
}
.crunch .reveal-pane--serviceCards .headings--over {
  font-size: 22px;
  color: #0e77d9;
}
.crunch .reveal-pane--about h3 {
  text-align: center;
}
.crunch .reveal-pane--about.team-certs h5 {
  font-size: 13px;
  margin: 0;
  text-align: center;
}
.crunch .reveal-pane--about.team-certs .team-certs--list {
  display: inline-block;
  border-bottom: 1px solid #ddd;
  border-top: 2px solid #fff;
  padding: 10px 0 0 0;
}
.crunch .reveal-pane--about.team-certs .team-certs--list:first-of-type {
  border-top: none;
}
.crunch .reveal-pane--about.team-certs .team-certs--list:last-of-type {
  border-bottom: none;
}
.crunch .reveal-pane--about.team-certs .team-certs--list ul li {
  font-size: 12px;
}
.crunch .reveal-pane--list {
  background-color: transparent;
  padding: 0;
  margin-top: 20px;
}
.crunch .reveal-pane--list li {
  margin: 5px 0 0 10px;
  font-size: 12px;
  list-style-type: disc;
}
.crunch .reveal-pane h2 {
  margin: 0;
  line-height: 1.2em;
}
.crunch .reveal-pane h3 {
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 18px;
  color: #4f7591;
}
.crunch .reveal-pane p {
  color: #5e85a1;
  padding-top: 0;
  padding-bottom: 30px;
}
.crunch .tile-collection-wrap {
  display: inline-block;
  margin: 50px 0px 30px 0px;
}
.crunch .tile-collection-wrap .tile-collection {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}
.crunch .tile-collection-wrap .tile-collection .tile {
  margin-bottom: 15px;
  padding: 0 10px;
}
@media (max-width: 768px) {
  .crunch .tile-collection-wrap .tile-collection .tile {
    padding: 0;
    margin: 0;
  }
}
.crunch .tile-collection-wrap .tile-collection .tile.tile--shadow .tile-wrap {
  box-shadow: 2px 2px 2px rgba(26, 39, 54, 0.14);
  border: 2px solid rgba(175, 186, 203, 0.5);
}
.crunch .tile-collection-wrap .tile-collection .tile.col-pad-none {
  padding: 0;
}
.crunch .tile-collection-wrap .tile-collection .tile .tile-wrap {
  position: relative;
  display: inline-block;
  float: left;
  height: 100%;
  border: 2px solid #b0bbcb;
  margin: 5px 0;
  padding: 15px;
  background-color: #fff;
  z-index: 5;
}
@media (max-width: 768px) {
  .crunch .page-section--site-layout .tile-collection-wrap .tile-collection .tile .tile-wrap {
    padding: 8px;
    margin: 0;
  }
}
.crunch .tile-collection-wrap .tile-collection .tile--title {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.crunch .tile-collection-wrap .tile-collection .tile--title .header {
  font-size: 20px;
  font-weight: 300;
  color: #939ba8;
}
@media (max-width: 768px) {
  .crunch .tile-collection-wrap .tile-collection .tile--title .header {
    font-size: 28px;
  }
}
.crunch .tile-collection-wrap .tile-collection .tile--title .tile--graphic {
  margin-right: 20px;
  padding-top: 10px;
}
.crunch .tile-collection-wrap .tile-collection .tile--title .tile--graphic img {
  width: auto;
  max-width: 60px;
  max-height: 50px;
  margin-bottom: 10px;
}
.crunch .tile-collection-wrap .tile-collection .tile--title .tile--graphic .fa {
  font-size: 40px;
  color: #1f73bb;
}
.crunch .tile-collection-wrap .tile-collection .tile--body {
  padding-bottom: 65px;
}
.crunch .tile-collection-wrap .tile-collection .tile--body h3 {
  font-size: 22px;
  font-weight: bold;
  color: #536a87;
  text-align: left;
  margin: 10px 0;
}
@media (max-width: 768px) {
  .crunch .tile-collection-wrap .tile-collection .tile--body h3 {
    min-height: fit-content;
  }
}
.crunch .tile-collection-wrap .tile-collection .tile--learn-more {
  position: absolute;
  bottom: 20px;
}
.crunch .tile-collection-wrap .tile-collection .tile.tile-serviceCard .tile-wrap {
  margin-top: 15px;
  margin-bottom: 0;
  background-color: #edf3fb;
  padding: 20px 15px;
  border-radius: 4px;
  border: 2px solid #fff;
  text-align: center;
  height: auto;
}
@media (max-width: 768px) {
  .crunch .tile-collection-wrap .tile-collection .tile.tile-serviceCard .tile-wrap {
    padding: 25px;
    border-radius: 0;
    margin: 10px 0;
  }
}
.crunch .tile-collection-wrap .tile-collection .tile.tile-serviceCard h5 {
  color: #3c7699;
  margin-top: 0;
}
.crunch .tile-collection-wrap .tile-collection .tile.tile-serviceCard #adobe {
  display: inline-block;
  vertical-align: middle;
}
.tile-collection-wrap .tile-collection .tile.tile-serviceCard .wrapper {
  padding: 0;
  border: none;
}
.crunch .tile-collection-wrap .tile-collection .tile.tile-serviceCard a {
  padding: 0;
}
.crunch #sidebar.sideBar.capaSidemenu .cardWrapper,
#sidebar.sideBar.capaSidemenu.relative .cardWrapper {
  padding: 15px 20px;
  background-color: #e0e4e9;
  border-top: 2px solid #fff;
  display: table;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu .cardWrapper,
  #sidebar.sideBar.capaSidemenu.relative .cardWrapper {
    opacity: 1;
    background-color: transparent;
    border: none;
    padding: 15px 35px;
    overflow: hidden;
    -webkit-transition: height 0.8s ease;
    -moz-transition: height 0.8s ease;
    -o-transition: height 0.8s ease;
    transition: height 0.8s ease;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.sideBar.capaSidemenu .cardWrapper,
  #sidebar.sideBar.capaSidemenu.relative .cardWrapper {
    overflow: visible;
    padding: 0;
  }
}
.crunch #sidebar.sideBar.capaSidemenu .cardWrapper .btn,
#sidebar.sideBar.capaSidemenu.relative .cardWrapper .btn {
  padding: 10px 15px 8px 15px;
  width: auto;
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  margin: 0;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu .cardWrapper .btn,
  #sidebar.sideBar.capaSidemenu.relative .cardWrapper .btn {
    padding: 8px 10px 6px 10px;
    font-size: 13px;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed {
  padding: 0;
  top: 53px;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed {
    min-width: 100%;
    max-width: 800px;
    width: 100%;
    background-color: #ebeef1;
    padding: 0 35px;
    z-index: 9;
    border-radius: 0;
    box-shadow: 2px 2px 2px rgba(45, 83, 109, 0.35);
    height: auto;
    overflow: visible;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .heading h4 {
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    color: #5b7e97;
    display: block;
    float: left;
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header h5 {
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
    color: #5b7e97;
    margin: 0;
    margin-top: 7px;
    display: inline;
    float: left;
    margin-right: 20px;
    font-weight: 500;
    letter-spacing: 0.15em;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header,
  #sidebar.sideBar.capaSidemenu.fixed .header.active {
    margin-top: 0;
    height: auto;
    overflow: visible;
    padding: 0;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header .inner,
#sidebar.sideBar.capaSidemenu.fixed .header.active .inner {
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header .inner,
  #sidebar.sideBar.capaSidemenu.fixed .header.active .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header li,
#sidebar.sideBar.capaSidemenu.fixed .header.active li {
  padding: 0;
  border-left: 2px solid #ebeef1;
  width: 100%;
  background-color: transparent;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header li,
  #sidebar.sideBar.capaSidemenu.fixed .header.active li {
    max-width: 50%;
    margin: 0;
    display: list-item;
    height: auto;
    padding: 8px 0;
    border: none;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header li:hover a h5,
#sidebar.sideBar.capaSidemenu.fixed .header.active li:hover a h5 {
  color: #2773bd;
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header li a,
#sidebar.sideBar.capaSidemenu.fixed .header.active li a {
  font-size: 11px;
  color: #556b84;
  display: flex;
  height: 40px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  align-items: center;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header li a,
  #sidebar.sideBar.capaSidemenu.fixed .header.active li a {
    display: flex;
    align-items: center;
    height: auto;
    margin: 0 auto;
    width: 100%;
    font-size: 12px;
    padding: 0;
    font-weight: 400;
    color: #2773bd;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header h5,
#sidebar.sideBar.capaSidemenu.fixed .header.active h5 {
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  margin: 0;
  color: #748998;
  letter-spacing: 0.12em;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header h5,
  #sidebar.sideBar.capaSidemenu.fixed .header.active h5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 9px;
    line-height: 1.2em;
    width: 30%;
    margin-right: 0;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header li.active,
#sidebar.sideBar.capaSidemenu.fixed .header.active li.active {
  opacity: 1;
  background-color: #fff;
  border-left: 2px solid #2a9fd4;
  border-right: 2px solid #ADD9F1;
  border-bottom: 1px solid #ADD9F1;
}
.crunch #sidebar.sideBar.capaSidemenu.fixed .header li.active a h5,
#sidebar.sideBar.capaSidemenu.fixed .header.active li.active a h5 {
  color: #2773bd;
}
@media (max-width: 992px) {
  .crunch #sidebar.sideBar.capaSidemenu.fixed .header li.active a h5,
  #sidebar.sideBar.capaSidemenu.fixed .header.active li.active a h5 {
    visibility: visible;
    font-size: 13px;
    color: #1694ce;
    font-weight: bold;
    padding-top: 5px;
  }
}
.crunch #sidebar.sideBar.capaSidemenu.fixed #capaMenu {
  border: none;
}
.crunch #sidebar.sideBar.capaSidemenu.fixed #career {
  display: none;
}
.crunch .midSpy.capaMidSpy.bar-down .inner {
  width: 90%;
}
.crunch #sidebar.capaSidemenu .cardWrapper {
  display: none;
}
@media (max-width: 768px) {
  .crunch #sidebar.capaSidemenu #capaMenu {
    display: none;
  }
}
.crunch #sidebar.capaSidemenu .midSpy .inner {
  width: 50%;
}
@media (max-width: 768px) {
  .crunch #sidebar.capaSidemenu .midSpy .inner {
    width: 100%;
  }
}
.crunch #sidebar.capaSidemenu .midSpy .inner .li h5 {
  width: 30%;
}
@media (max-width: 768px) {
  .crunch #sidebar.capaSidemenu .midSpy .inner .li h5 {
    width: 100%;
  }
}
.crunch #sidebar.capaSidemenu .midSpy .inner .dropdown.open .dropdown-menu {
  height: 295px;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}
.crunch #sidebar.capaSidemenu.bar-down.nav-up .header.midSpy {
  top: -80px;
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
}
.crunch #sidebar.capaSidemenu.bar-down.nav-down .header.midSpy {
  -webkit-transition: top 1.5s;
  transition: top 1.5s;
}
@media (max-width: 768px) {
  .crunch #sidebar.capaSidemenu.bar-down.nav-down .header.midSpy {
    top: 53px;
  }
}
@media (max-width: 480px) {
  .crunch #sidebar.capaSidemenu.bar-down.nav-down .header.midSpy {
    top: 47px;
  }
}
.crunch .capaMain .page-section {
  padding-left: 0;
  padding-right: 0;
}
.crunch .capaMain .container:last-of-type {
  border: none;
}
.crunch .capaMain .mobile-dropdown-cards h3.headings--under {
  text-transform: uppercase;
  color: grey;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .crunch .capaMain .mobile-dropdown-cards .dropdown-menu li {
    list-style-type: disc;
  }
.crunch   .capaMain .mobile-dropdown-cards .dropdown-menu li a {
    padding: 10px 15px;
    white-space: inherit;
  }
}
.crunch .capaMain .tool {
  position: relative;
  border: 2px solid #eaedef;
  padding: 10px 20px;
  margin: 20px 0 20px 0;
  background-color: #fff;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool {
    padding: 0;
    margin: 10px 0;
    border: none;
    background-color: transparent;
  }
}
.crunch .capaMain .tool .title {
  margin: 25px 0 20px 0;
  height: auto;
  display: inline;
  padding: 0;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool .title {
    margin: 10px 0;
    display: block;
  }
}
.crunch .capaMain .tool .GearFramework {
  position: absolute;
  right: 15px;
  top: 10px;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool .GearFramework {
    position: relative;
  }
}
.crunch .capaMain .tool .GearFramework .gear {
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool .GearFramework .gear {
    width: 100%;
    padding: 0 15px;
    height: auto;
    background-color: transparent;
  }
}
.crunch .capaMain .tool .GearFramework .gear img {
  margin: 10px 0 5px 0;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool .GearFramework .gear img {
    display: none;
  }
}
.crunch .capaMain .tool .GearFramework .gear p {
  font-size: 15px;
  text-align: right;
  line-height: 1.3em;
  color: #939ba8;
}
@media (max-width: 768px) {
  .crunch .capaMain .tool .GearFramework .gear p {
    font-size: 22px;
    margin: 5px 0;
    text-align: left;
  }
}
.crunch .capaMain .orchLogo {
  max-width: 400px;
  margin: 30px 0 10px 0;
}
.crunch .markets_sideBar {
  padding: 0 !important;
}
@media (max-width: 992px) {
  .crunch .markets_sideBar {
    width: 100%;
  }
}
.crunch .markets_sideBar ul.marketCategories {
  float: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}
@media (max-width: 992px) {
  .crunch .markets_sideBar ul.marketCategories {
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media (max-width: 480px) {
  .crunch .markets_sideBar ul.marketCategories {
    display: table !important;
  }
}
.crunch .markets_sideBar ul.marketCategories .staticHead {
  width: 100%;
  padding: 10px 20px;
  font-size: 22px;
  border: none;
  height: auto;
  position: relative;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 1px solid #bcc9cd;
  display: flex;
  color: #4589a8;
  align-items: center;
  background-color: transparent;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .crunch .markets_sideBar ul.marketCategories .staticHead {
    border-bottom: none;
    border-right: 1px solid #bcc9cd;
    justify-content: inherit;
    padding-left: 35px;
  }
}
@media (max-width: 768px) {
  .crunch .markets_sideBar ul.marketCategories .staticHead {
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #bcc9cd;
  }
}
.crunch .markets_sideBar ul.marketCategories .staticHead img {
  max-width: 80px;
}
@media (max-width: 992px) {
  .crunch .markets_sideBar ul.marketCategories .staticHead img {
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .crunch .markets_sideBar ul.marketCategories .staticHead img {
    display: flex;
    margin: 0;
    margin-right: 15px;
  }
}
.crunch .markets_sideBar ul.marketCategories .staticHead a {
  color: #2773bd;
}
.crunch .markets_sideBar ul.marketCategories .staticHead:hover {
  cursor: pointer;
  color: #2773bd;
  background-color: #fff;
  border-left: 2px solid #2a9fd4;
  border-right: 2px solid #ADD9F1;
  border-bottom: 1px solid #ADD9F1;
}
.crunch .markets_sideBar ul.marketCategories .option {
  width: auto;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #ebeef1;
  justify-content: space-between;
  padding-left: 20px;
}
.crunch .markets_sideBar ul.marketCategories .option h5 {
  padding: 0;
  margin: 0;
  color: #2773bd;
}
.crunch .markets_sideBar ul.marketCategories .option img {
  width: 80px;
  margin: 20px auto;
  margin-right: 30px;
  margin-bottom: 10px;
}
.crunch .markets_sideBar ul.marketCategories .option:last-of-type {
  border: none;
}
.crunch .markets_sideBar ul.marketCategories .option:hover {
  background-color: #fff;
  cursor: pointer;
}
@media (min-width: 992px) {
  .crunch .markets_main {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .crunch #sidebar.aboutSidebar .wrapper,
  #sidebar.aboutSidebar.relative .wrapper {
    padding-bottom: 0;
  }
}
.crunch #sidebar.aboutSidebar .header.active,
#sidebar.aboutSidebar.relative .header.active {
  height: 274px;
  overflow: hidden;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .header.active,
  #sidebar.aboutSidebar.relative .header.active {
    height: auto;
    overflow: visible;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .header .inner > li a,
  #sidebar.aboutSidebar.relative .header .inner > li a {
    display: flex;
    flex-direction: column;
  }
}
.crunch #sidebar.aboutSidebar .header .inner > li a:focus,
#sidebar.aboutSidebar.relative .header .inner > li a:focus,
#sidebar.aboutSidebar .header .inner > li a:hover,
#sidebar.aboutSidebar.relative .header .inner > li a:hover {
  text-decoration: none;
}
.crunch #sidebar.aboutSidebar h4,
#sidebar.aboutSidebar.relative h4,
#sidebar.aboutSidebar h5,
#sidebar.aboutSidebar.relative h5 {
  margin: 10px 25px;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar h4,
  #sidebar.aboutSidebar.relative h4,
  #sidebar.aboutSidebar h5,
  #sidebar.aboutSidebar.relative h5 {
    margin: 15px 0;
  }
}
.crunch #sidebar.aboutSidebar h4,
#sidebar.aboutSidebar.relative h4 {
  font-size: 22px;
  color: #788fa7;
  line-height: 1.4em;
  margin: 5px 25px;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar h4,
  #sidebar.aboutSidebar.relative h4 {
    margin: 5px 0;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar h4,
  #sidebar.aboutSidebar.relative h4 {
    margin: 0;
  }
}
.crunch #sidebar.aboutSidebar a,
#sidebar.aboutSidebar.relative a {
  color: #2773bd;
  padding: 0 25px;
}
.crunch #sidebar.aboutSidebar .btn-link,
#sidebar.aboutSidebar.relative .btn-link {
  font-size: 15px;
  margin-right: 10px;
  display: inline-block;
}
.crunch #sidebar.aboutSidebar p,
#sidebar.aboutSidebar.relative p {
  padding: 0;
  color: #73899a;
  font-size: 14px;
}
.crunch #sidebar.aboutSidebar #career.header,
#sidebar.aboutSidebar.relative #career.header {
  opacity: 0;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #career.header,
  #sidebar.aboutSidebar.relative #career.header {
    height: auto;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar #career.header,
  #sidebar.aboutSidebar.relative #career.header {
    padding: 0;
    display: table;
    height: auto;
    margin-bottom: 30px;
  }
}
.crunch #sidebar.aboutSidebar #career.header #careerLink > h4,
#sidebar.aboutSidebar.relative #career.header #careerLink > h4 {
  color: #2773bd;
  padding: 0;
}
.crunch #sidebar.aboutSidebar #career.header .teamTitle,
#sidebar.aboutSidebar.relative #career.header .teamTitle {
  padding: 0;
  height: auto;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  color: #8399b2;
  margin-top: 20px;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #career.header .teamTitle,
  #sidebar.aboutSidebar.relative #career.header .teamTitle {
    display: block;
    width: 100%;
  }
}
.crunch #sidebar.aboutSidebar #career.header li,
#sidebar.aboutSidebar.relative #career.header li {
  padding-top: 2px;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #career.header li,
  #sidebar.aboutSidebar.relative #career.header li {
    display: inline;
    border: none;
  }
}
.crunch #sidebar.aboutSidebar #career.header li > a,
#sidebar.aboutSidebar.relative #career.header li > a {
  display: inline;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #career.header li > a,
  #sidebar.aboutSidebar.relative #career.header li > a {
    padding-right: 20px;
  }
}
.crunch #sidebar.aboutSidebar #career.header.active,
#sidebar.aboutSidebar.relative #career.header.active {
  height: 300px;
  padding: 5px 20px;
  opacity: 1;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #career.header.active,
  #sidebar.aboutSidebar.relative #career.header.active {
    height: auto;
    padding: 5px 0;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar #career.header.active,
  #sidebar.aboutSidebar.relative #career.header.active {
    margin-bottom: 0px;
    padding: 5px 0;
  }
}
.crunch #sidebar.aboutSidebar #team.header.active,
#sidebar.aboutSidebar.relative #team.header.active {
  height: 520px;
  padding: 0;
  margin-top: -30px;
  -webkit-transition: height 1s;
  transition: height 1s;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header.active,
  #sidebar.aboutSidebar.relative #team.header.active {
    margin-top: 0;
    height: auto;
    overflow: visible;
  }
}
.crunch #sidebar.aboutSidebar #team.header,
#sidebar.aboutSidebar.relative #team.header {
  padding: 0;
  width: 100%;
}
.crunch #sidebar.aboutSidebar #team.header h4,
#sidebar.aboutSidebar.relative #team.header h4 {
  margin: 0 15px 10px 15px;
}
@media (max-width: 992px) and (min-height: 1025px) {
  .crunch #sidebar.aboutSidebar #team.header,
  #sidebar.aboutSidebar.relative #team.header {
    margin-top: 40px;
    height: auto;
    padding: 0;
  }
.crunch   #sidebar.aboutSidebar #team.header h4,
  #sidebar.aboutSidebar.relative #team.header h4 {
    margin: 0;
  }
}
.crunch #sidebar.aboutSidebar #team.header #consultTeam,
#sidebar.aboutSidebar.relative #team.header #consultTeam {
  padding: 0;
  padding-top: 20px;
  text-align: center;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header #consultTeam,
  #sidebar.aboutSidebar.relative #team.header #consultTeam {
    padding: 0 !important;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member,
#sidebar.aboutSidebar.relative #team.header .member {
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  border: 2px solid transparent;
  border-bottom: 1px solid #d8dde3;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header .member,
  #sidebar.aboutSidebar.relative #team.header .member {
    padding: 10px 0;
    margin: 0;
    margin-bottom: 5px;
    display: inline-block;
    width: 30%;
    border: none;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header .member,
  #sidebar.aboutSidebar.relative #team.header .member {
    max-width: 100%;
    padding: 10px 0;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member img,
#sidebar.aboutSidebar.relative #team.header .member img {
  width: 70px;
  max-width: 30%;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header .member img,
  #sidebar.aboutSidebar.relative #team.header .member img {
    opacity: 0;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #team.header .member img,
  #sidebar.aboutSidebar.relative #team.header .member img {
    width: 100%;
    height: 0;
    max-width: 100%;
    border: none;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member a,
#sidebar.aboutSidebar.relative #team.header .member a {
  width: 100%;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #team.header .member a,
  #sidebar.aboutSidebar.relative #team.header .member a {
    margin: 0;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member a > h5,
#sidebar.aboutSidebar.relative #team.header .member a > h5 {
  font-size: 11px;
  padding-left: 10px;
  display: inline-block;
  width: 70%;
  font-weight: bold;
  color: #348fb8;
  line-height: 1.6;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #team.header .member a > h5,
  #sidebar.aboutSidebar.relative #team.header .member a > h5 {
    display: block;
    padding: 0;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member a > h5 b,
#sidebar.aboutSidebar.relative #team.header .member a > h5 b {
  font-size: 11px;
  color: #929bad;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #team.header .member a > h5 b,
  #sidebar.aboutSidebar.relative #team.header .member a > h5 b {
    font-size: 10px;
  }
}
.crunch #sidebar.aboutSidebar #team.header .member:last-of-type,
#sidebar.aboutSidebar.relative #team.header .member:last-of-type {
  border-bottom: none;
}
.crunch #sidebar.aboutSidebar #team.header .member.active,
#sidebar.aboutSidebar.relative #team.header .member.active {
  opacity: 1;
  background-color: #fff;
  border-left: 2px solid #2a9fd4;
  border-right: 2px solid #ADD9F1;
  border-bottom: 1px solid #ADD9F1;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar #team.header .member.active,
  #sidebar.aboutSidebar.relative #team.header .member.active {
    background-color: #fff;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar,
  #sidebar.aboutSidebar.relative {
    height: auto;
    overflow: visible;
  }
.crunch   #sidebar.aboutSidebar #team .member,
  #sidebar.aboutSidebar.relative #team .member {
    max-width: 15%;
    margin: 0;
    margin-top: 3px;
    display: inline-block;
    height: 20px;
    border: 2px solid #ebeef1;
    border-radius: 3px;
    padding: 0;
  }
.crunch   #sidebar.aboutSidebar #team .member h5,
  #sidebar.aboutSidebar.relative #team .member h5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 0;
    top: 9px;
    line-height: 1.2em;
    width: 30%;
  }
.crunch   #sidebar.aboutSidebar #team .member img,
  #sidebar.aboutSidebar.relative #team .member img {
    width: 100%;
    height: 20px;
    max-width: 70px;
  }
.crunch   #sidebar.aboutSidebar #team .member:hover,
  #sidebar.aboutSidebar.relative #team .member:hover {
    cursor: pointer;
  }
.crunch   #sidebar.aboutSidebar #team .member.active,
  #sidebar.aboutSidebar.relative #team .member.active {
    background-color: #fff;
  }
.crunch   #sidebar.aboutSidebar #team .member.active h5,
  #sidebar.aboutSidebar.relative #team .member.active h5 {
    visibility: visible;
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar #team.header.active .inner,
  #sidebar.aboutSidebar.relative #team.header.active .inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    margin-bottom: 5px;
    height: 20px;
  }
.crunch   #sidebar.aboutSidebar #career,
  #sidebar.aboutSidebar.relative #career {
    height: 0;
    margin: 0;
  }
.crunch   #sidebar.aboutSidebar h5,
  #sidebar.aboutSidebar.relative h5 {
    font-size: 13px;
    margin-top: 0;
  }
.crunch   #sidebar.aboutSidebar #team .member,
  #sidebar.aboutSidebar.relative #team .member {
    height: 30px;
  }
.crunch   #sidebar.aboutSidebar #team .member h5,
  #sidebar.aboutSidebar.relative #team .member h5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 2px;
    line-height: 1.2em;
    width: 35%;
  }
.crunch   #sidebar.aboutSidebar #team .member h5 b,
  #sidebar.aboutSidebar.relative #team .member h5 b {
    font-size: 8px;
    margin-top: 5px;
    display: table;
  }
.crunch   #sidebar.aboutSidebar #team .member.active h5,
  #sidebar.aboutSidebar.relative #team .member.active h5 {
    font-size: 10px;
  }
}
.crunch #sidebar.aboutSidebar .teamInner li,
#sidebar.aboutSidebar.relative .teamInner li {
  padding: 0;
}
.crunch #sidebar.aboutSidebar .header,
#sidebar.aboutSidebar.relative .header {
  font-size: 22px;
  border: none;
  position: relative;
  overflow: hidden;
  height: 0;
  margin: 0;
  min-height: 0;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
@media (max-width: 992px) and (min-height: 1025px) {
  .crunch #sidebar.aboutSidebar .header,
  #sidebar.aboutSidebar.relative .header {
    opacity: 1;
    height: 200px;
    overflow: visible;
  }
}
.crunch #sidebar.aboutSidebar .header h5,
#sidebar.aboutSidebar.relative .header h5 {
  margin: 0;
  letter-spacing: 0.15em;
  font-size: 15px;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .header h5,
  #sidebar.aboutSidebar.relative .header h5 {
    font-size: 15px;
    text-align: left;
    margin: 0;
    display: block;
    width: 100%;
    float: left;
    margin-right: 20px;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .header h5,
  #sidebar.aboutSidebar.relative .header h5 {
    display: inline;
    width: auto;
  }
}
.crunch #sidebar.aboutSidebar .header#team h5,
#sidebar.aboutSidebar.relative .header#team h5 {
  padding: 0 15px;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .crunch #sidebar.aboutSidebar .header .inner > li,
  #sidebar.aboutSidebar.relative .header .inner > li {
    opacity: 0.6;
    font-size: 12px;
    border-bottom: 1px solid #d8dde3;
    width: 100%;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .header .inner > li,
  #sidebar.aboutSidebar.relative .header .inner > li {
    opacity: 1;
  }
}
.crunch #sidebar.aboutSidebar .header .inner > li h5,
#sidebar.aboutSidebar.relative .header .inner > li h5 {
  margin: 0;
}
.crunch #sidebar.aboutSidebar .header .inner > li a,
#sidebar.aboutSidebar.relative .header .inner > li a {
  padding: 0;
  font-weight: 300;
}
.crunch #sidebar.aboutSidebar .header .inner > li a:hover,
#sidebar.aboutSidebar.relative .header .inner > li a:hover,
#sidebar.aboutSidebar .header .inner > li a:focus,
#sidebar.aboutSidebar.relative .header .inner > li a:focus {
  background-color: transparent !important;
}
@media (min-width: 992px) {
  .crunch #sidebar.aboutSidebar .header .inner > li:last-of-type,
  #sidebar.aboutSidebar.relative .header .inner > li:last-of-type {
    border: none;
  }
}
.crunch #sidebar.aboutSidebar .header > a:hover,
#sidebar.aboutSidebar.relative .header > a:hover,
#sidebar.aboutSidebar a:focus,
#sidebar.aboutSidebar.relative a:focus {
  background-color: transparent;
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .inner,
  #sidebar.aboutSidebar.relative .inner {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .crunch #sidebar.aboutSidebar .inner li,
  #sidebar.aboutSidebar.relative .inner li {
    border: 2px solid transparent;
    opacity: 0.5;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 992px) {
  .crunch #sidebar.aboutSidebar .inner li,
  #sidebar.aboutSidebar.relative .inner li {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .crunch #sidebar.aboutSidebar .inner li.active,
  #sidebar.aboutSidebar.relative .inner li.active {
    opacity: 1;
    background-color: white;
    border: 2px solid rgba(110, 186, 221, 0.84);
  }
}
.crunch #sidebar.aboutSidebar ul.teamInner.inner,
#sidebar.aboutSidebar.relative ul.teamInner.inner {
  margin-top: 10px;
}
.crunch #sidebar.aboutSidebar ul.teamInner.inner li,
#sidebar.aboutSidebar.relative ul.teamInner.inner li {
  border: none;
  opacity: 1;
}
.crunch #sidebar.aboutSidebar ul.teamInner.inner li .btn-link:after,
#sidebar.aboutSidebar.relative ul.teamInner.inner li .btn-link:after {
  display: none;
}
.crunch #sidebar.aboutSidebar ul.teamInner.inner li .btn-link:before,
#sidebar.aboutSidebar.relative ul.teamInner.inner li .btn-link:before {
  font-family: "FontAwesome", serif;
  content: '\f137';
  font-size: 20px;
  color: rgba(131, 153, 178, 0.49);
  padding-right: 10px;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar ul.teamInner.inner li .btn-link:before,
  #sidebar.aboutSidebar.relative ul.teamInner.inner li .btn-link:before {
    content: '\f05a';
  }
}
.crunch #sidebar.aboutSidebar.fixed,
#sidebar.aboutSidebar.fixed {
  top: 55px;
}
.crunch .midSpy.aboutMidSpy .nav {
  margin: 0;
  width: 100%;
}
.crunch .midSpy.aboutMidSpy .square.active h5 {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.crunch .midSpy.aboutMidSpy .inner {
  width: 100%;
}
.crunch .midSpy.aboutMidSpy .highlight {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .crunch .midSpy.aboutMidSpy .square.active a {
    display: inline;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
  }
.crunch   .midSpy.aboutMidSpy .square h5 {
    opacity: 0;
    top: 13px;
    height: 40px;
    left: 0;
    width: auto;
    display: inline;
  }
.crunch   .midSpy.aboutMidSpy .square h5 .highlight {
    display: none;
  }
.crunch   .midSpy.aboutMidSpy .dropdown .dropdown-menu h5 .highlight {
    font-weight: bold;
    margin: 0;
  }
}
.crunch #sidebar.aboutSidebar.bar-down.nav-up .header.midSpy {
  top: -80px;
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
}
.crunch #sidebar.aboutSidebar.bar-down.nav-down .header.midSpy {
  -webkit-transition: top 1.5s;
  transition: top 1.5s;
}
@media (max-width: 768px) {
  .crunch #sidebar.aboutSidebar.bar-down.nav-down .header.midSpy {
    top: 53px;
  }
}
@media (max-width: 480px) {
  .crunch #sidebar.aboutSidebar.bar-down.nav-down .header.midSpy {
    top: 47px;
  }
}
.crunch .aboutMain .highlight {
  font-size: 18px;
}
.crunch .aboutMain .container:last-of-type {
  border-bottom: none;
  padding-bottom: 30px;
}
.crunch .aboutMain .title-wrap {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0;
}
.crunch .aboutMain .title-wrap img {
  border: 2px solid #b0bbcb;
  border-radius: 3px;
  margin: 0;
  margin-right: 10px;
  max-width: 100px;
  width: auto;
}
.crunch .codes-table .codes-table--wrapper h5 {
  color: #40aada;
}
.crunch .codes-table .codes-table--wrapper .codes {
  padding: 0;
}
.crunch .codes-table .codes-table--wrapper .codes li {
  list-style-type: none;
  padding: 10px;
  font-size: 12px;
  display: flex;
}
.crunch .codes-table .codes-table--wrapper .codes li:last-of-type {
  border: none;
}
.crunch .codes-table .codes-table--wrapper .codes li b {
  padding-right: 10px;
}
.crunch .sideBar.contactSidebar .address {
  margin-bottom: 30px;
}
.crunch .sideBar.contactSidebar .address:last-of-type {
  margin-bottom: 0;
}
.crunch .sideBar.contactSidebar .address li {
  line-height: 1.2em;
  border: none;
  padding: 6px 25px;
}
@media (max-width: 992px) {
  .crunch .sideBar.contactSidebar .address li {
    padding: 6px 0;
  }
}
.crunch iframe.googleMaps {
  float: left;
  border: none;
  height: 250px;
  width: 100%;
  border-radius: 5px;
  margin: 0 0 25px 0;
}
@media (max-width: 992px) {
  .crunch iframe.googleMaps {
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .crunch iframe.googleMaps {
    height: 250px;
  }
}
@media (max-width: 768px) {
  .crunch .emailButton {
    margin: 40px 0;
  }
}
.crunch .emailButton a .fa {
  font-size: 50px;
  display: block;
  margin: 0 auto;
}
.crunch .emailButton a:hover {
  text-decoration: none;
}
.crunch .modal-dialog {
  width: 100%;
  max-width: 900px;
}
@media (max-width: 768px) {
  .crunch .modal-dialog {
    margin: 0;
  }
}
.crunch .modal-header .title {
  width: auto;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-weight: 400;
}
@media (max-width: 768px) {
  .crunch .modal-header .title {
    width: 70%;
    text-align: left;
    margin: 0;
  }
}
.crunch .modal-body p {
  line-height: 1.55em;
}
.crunch .modal-body img {
  float: none;
  margin: 0 auto;
}
.crunch .modal-title {
  text-align: center;
  width: 100%;
  color: #5e626c;
  margin: 5px 0;
}
.crunch .modal-body {
  padding: 20px 40px;
}
@media (max-width: 768px) {
  .crunch .modal-body {
    padding: 10px 15px;
  }
}
@media (max-width: 768px) {
  .crunch .drupalMain #drupalLogo img {
    height: auto;
  }
}
.crunch .drupalMain #organBadge {
  margin: 80px auto;
  display: block;
  float: none;
}
@media (max-width: 768px) {
  .crunch .drupalMain #organBadge {
    margin: 10px 0;
    max-width: 80%;
  }
}
.crunch .drupalMain #porterImg a {
  width: 100%;
}
@media (max-width: 768px) {
  .crunch .drupalMain #porterImg a {
    width: 80%;
    margin: 0;
  }
}
.crunch .drupalMain #customers {
  color: #789cb4;
}
.crunch .drupalMain #intro h5 {
  color: #2599cb;
}
.crunch .drupalMain #drupalTraining h4,
.crunch .drupalMain #trainingClasses h4,
.crunch .drupalMain #drupalConsulting h4,
.crunch .drupalMain #drupalTraining h5,
.crunch .drupalMain #trainingClasses h5,
.crunch .drupalMain #drupalConsulting h5 {
  display: inline;
  padding-right: 10px;
}
.crunch .drupalMain #prepImage {
  width: 100%;
  display: inline-block;
  float: right;
  vertical-align: top;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .crunch .drupalMain #prepImage {
    float: left;
    max-width: 60%;
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .crunch .drupalMain #prepImage {
    max-width: 100%;
  }
}
.crunch .drupalMain #trainDay.flex-container {
  align-items: flex-start;
  padding: 0;
}
.crunch .drupalMain #trainDay > .flex-container {
  justify-content: space-between;
}
@media (max-width: 768px) {
  .crunch .drupalMain #trainDay > .flex-container {
    flex-direction: column;
  }
}
.crunch .drupalMain .dayOne {
  padding-top: 0;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-top: -40px;
}
@media (max-width: 992px) {
  .crunch .drupalMain .dayOne {
    margin-top: 10px;
  }
}
.crunch .drupalMain .dayOne h4,
.crunch .drupalMain .dayOne h5 {
  margin: 0;
}
.crunch .drupalMain .dayOne h4 {
  font-size: 17px;
  color: #2ea0d4;
}
.crunch .drupalMain .dayOne #basics {
  margin-left: 0;
}
.crunch .drupalMain .trainDay h4,
.crunch .drupalMain .trainDay h5 {
  margin: 0;
}
.crunch .drupalMain .trainDay h4 {
  font-size: 17px;
  color: #2ea0d4;
  line-height: 1.3em;
}
.crunch .drupalMain .consulting-container .panel-heading {
  background-color: #799bb2;
  position: relative;
}
.crunch .drupalMain .consulting-container .panel-heading .panel-title {
  width: 60%;
}
.crunch .drupalMain .consulting-container .panel-heading .days {
  width: 40%;
}
@media (max-width: 768px) {
  .crunch .drupalMain .consulting-container .panel-heading .days {
    position: absolute;
    display: inline-block;
    float: right;
    width: 10px;
    height: 30px;
    right: 0;
    top: 5px;
    border-radius: 0;
  }
}
.crunch .drupalMain .consulting-container .panel-heading .days p {
  background-color: #476e88;
  color: #e1f2fe;
}
@media (max-width: 768px) {
  .crunch .drupalMain .consulting-container .panel-heading .days p {
    font-size: 10px;
    border-radius: 0;
    background-color: transparent;
    text-align: center;
    line-height: 1.3em;
  }
}
.crunch .drupalMain .consulting-container #consultingIMG {
  margin: 10px 0;
  margin-left: 20px;
  width: 200px;
}
@media (max-width: 768px) {
  .crunch .drupalMain .consulting-container #consultingIMG {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .crunch .sideBar.releaseSlider .header.active,
.crunch .releaseSlider.relative .header.active {
    height: auto;
    opacity: 1;
    -webkit-transition: height 0.8s ease, opacity 0.8s ease;
    -moz-transition: height 0.8s ease, opacity 0.8s ease;
    -o-transition: height 0.8s ease, opacity 0.8s ease;
    transition: height 0.8s ease, opacity 0.8s ease;
  }
.crunch   .sideBar.releaseSlider .header.active .inner > li,
.crunch .releaseSlider.relative .header.active .inner > li {
    padding: 10px 30px;
    -webkit-transition: width 0.8s ease, opacity 0.8s ease;
    -moz-transition: width 0.8s ease, opacity 0.8s ease;
    -o-transition: width 0.8s ease, opacity 0.8s ease;
    transition: width 0.8s ease, opacity 0.8s ease;
  }
.crunch   .sideBar.releaseSlider li.active > .monthContain,
.crunch .releaseSlider.relative li.active > .monthContain {
    display: block;
  }
}
@media (max-width: 992px) {
  .crunch .sideBar.releaseSlider,
.crunch .releaseSlider.relative {
    height: auto;
    border: none;
    display: inline-block;
    margin: 0;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar.releaseSlider,
.crunch .releaseSlider.relative {
    padding: 10px 15px;
  }
.crunch   .sideBar.releaseSlider .header.active .inner .yearContain.active,
.crunch .releaseSlider.relative .header.active .inner .yearContain.active {
    width: auto;
    padding: 5px 25px 0 0;
    border: none;
    display: inline-block;
  }
.crunch   .sideBar.releaseSlider .header.active .inner > li.yearContain,
.crunch .releaseSlider.relative .header.active .inner > li.yearContain {
    width: auto;
    padding: 5px 25px 0 0;
    border: none;
    display: inline-block;
  }
.crunch   .sideBar.releaseSlider .header.active .inner .yearContain.active ul,
.crunch .releaseSlider.relative .header.active .inner .yearContain.active ul {
    display: none;
  }
}
.crunch .sideBar.releaseSlider .wrapper,
.crunch .releaseSlider.relative .wrapper {
  padding-bottom: 0;
}
.crunch .sideBar.releaseSlider .inner,
.crunch .releaseSlider.relative .inner {
  margin: 0;
  width: 100%;
  height: auto;
  padding: 0;
  -webkit-transition: height 0.8s ease, opacity 0.8s ease;
  -moz-transition: height 0.8s ease, opacity 0.8s ease;
  -o-transition: height 0.8s ease, opacity 0.8s ease;
  transition: height 0.8s ease, opacity 0.8s ease;
}
@media (max-width: 992px) {
  .crunch .sideBar.releaseSlider .inner,
.crunch .releaseSlider.relative .inner {
    padding-top: 0;
    min-height: unset;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
}
.crunch .midSpy.pressMidSpy ul.monthContain.inner {
  position: absolute;
  top: 0;
}
.crunch .midSpy.pressMidSpy .month {
  opacity: 0;
  visibility: hidden;
}
.crunch .midSpy.pressMidSpy .month.active {
  opacity: 1;
  visibility: visible;
}
.crunch .midSpy.pressMidSpy .month.active a {
  left: 30px;
  font-style: italic;
}
@media (max-width: 768px) {
  .crunch .midSpy.pressMidSpy .month.active a {
    left: 50px;
  }
}
.crunch .pressPage .page-section.release {
  border-top: 10px solid #1091cc;
  padding-top: 0;
}
@media (max-width: 992px) and (min-width: 768px) {
  .crunch .pressPage .page-section.release {
    padding: 40px 20px;
  }
}
.crunch .pressPage .page-section.release .releaseTxt {
  padding: 40px 15px;
  border-bottom: 1px solid rgba(187, 197, 204, 0.7);
}
@media (max-width: 768px) {
  .crunch .pressPage .page-section.release .releaseTxt {
    display: table;
  }
}
.crunch .pressPage .page-section.release .releaseTxt:last-of-type {
  border-bottom: none;
}
.crunch .pressPage .page-section.release .releaseTxt h5 {
  color: #1091cc;
  font-weight: bold;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  margin: 0;
}
@media (max-width: 768px) {
  .crunch .pressPage .page-section.release .releaseTxt h5 {
    width: 100%;
    line-height: 1.8em;
    max-width: 100%;
  }
}
.crunch .pressRelease .press-release-info {
  padding-left: 20px;
}
.crunch .pressPage .page-section.release .releaseTxt .date,
.crunch .blogPage .page-section .blogPost .date,
.crunch .pressRelease .press-release-info .date {
  width: auto;
  font-style: italic;
  color: #98a6b0;
  display: block;
  position: relative;
}
.crunch .pressRelease .press-release-info .date,
.crunch .pressRelease .press-release-info a,
.crunch .pressRelease .press-release-info p {
  line-height: 1.7em;
}
.crunch .pressRelease .press-release-info a {
  display: block;
  margin-left: 0px;
  float: none;
}
.crunch .pressRelease .press-release-info a:before {
  display: none;
}
.crunch .pressRelease .press-release-info p {
  width: auto;
  color: #98a6b0;
  display: block;
  position: relative;
  text-transform: uppercase;
  padding: 0px;
}
.crunch .blogPage .page-section .blogPost .date,
.crunch .pressRelease .press-release-info .date {
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  .crunch .blogPage .page-section .blogPost .date,
.crunch .pressRelease .press-release-info.date {
    text-align: left;
    width: fit-content;
    width: -moz-fit-content;
  }
}
.crunch .pressPage .page-section.release .releaseTxt .dwnFull {
  font-size: 14px;
  letter-spacing: 0.08em;
}
@media (max-width: 768px) {
  .crunch .pressPage .page-section.release .releaseTxt .dwnFull {
    position: relative;
    margin: 20px 0;
  }
}
.crunch .pressPage .page-section.release .releaseTxt .dwnFull .fa {
  font-size: 20px;
  padding-right: 10px;
  vertical-align: -2px;
  color: #479ac8;
}
.crunch .pressPage .page-section.release .releaseTxt:nth-of-type(even) {
  background-color: #f7f9fb;
}
.crunch .pressPage .container.release:first-of-type {
  border-top: none;
  padding-top: 70px;
}
.crunch .pressPage .releaseTxt:last-of-type {
  border: none;
}
.crunch .pressPage .year {
  color: #00549a;
  font-size: 23px;
  margin: 0;
  margin-bottom: 10px;
}
.crunch .toolLogo {
  margin: 30px 0 10px 0;
}
.crunch #cloudCompute {
  position: absolute;
  bottom: 0;
  width: 40%;
  max-width: 600px;
  right: 0;
}
.crunch .knowContainer {
  width: 100%;
}
.crunch .knowContainer #knowList {
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 5px 0;
  padding: 0;
  list-style-type: none;
  margin-top: 20px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .crunch .knowContainer #knowList {
    display: inline-block;
    width: 40%;
  }
}
.crunch .knowContainer #knowList li {
  width: auto;
  font-size: 18px;
  color: #259cd2;
}
.crunch .knowContainer .flex-container {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .crunch .knowContainer .flex-container {
    display: inline-block;
    width: 55%;
    vertical-align: top;
  }
}
.crunch .knowContainer .flex-container .tile {
  border: 2px solid #b7d0e4;
  border-right: 1px solid #ffffff;
}
@media (max-width: 768px) {
  .crunch .knowContainer .flex-container .tile {
    border: 1px solid #b7d0e4;
  }
}
.crunch .knowContainer .flex-container .tile p {
  color: #8ca5b8;
  font-weight: 400;
}
.crunch .knowContainer .flex-container .tile:last-of-type {
  border-right: 2px solid #b7d0e4;
}
.crunch .keyAreas h5 {
  max-width: 70%;
  margin: 20px 0;
}
.crunch .keyAreas .topic {
  padding-left: 0;
}
.crunch .keyAreas {
  display: table;
}
.crunch #securityDifference {
  padding-bottom: 70px;
}
@media (max-width: 992px) {
  .crunch #securityDifference {
    border-top: 1px solid #ddd;
    padding-bottom: 50px;
  }
}
.crunch #chain {
  position: absolute;
  margin-top: -10px;
}
.crunch #chainImg {
  max-height: 600px;
  width: auto;
  position: absolute;
  top: -60px;
}
@media (max-width: 992px) {
  .crunch #chainImg {
    max-height: 400px;
    position: absolute;
    top: -40px;
  }
}
@media (max-width: 768px) {
  .crunch #chainImg {
    position: relative;
    top: 0;
    width: 100%;
    max-width: none;
    height: auto;
  }
}
.crunch #nist {
  max-width: 250px;
}
@media (max-width: 992px) {
  .crunch #critSlider {
    margin-top: 20px;
  }
}
.crunch .projectSidebar .inner > .client {
  overflow: hidden;
  height: 40px;
  display: inline-grid;
}
.crunch .projectSidebar .inner > .client img {
  opacity: 0;
  width: 100%;
  margin: 10px 0;
  background-color: #fff;
  padding: 10px 20px 0 0;
  border-radius: 5px;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}
.crunch .projectSidebar .inner > .client.active {
  height: auto;
  overflow: hidden;
}
.crunch .projectSidebar .inner > .client.active img {
  opacity: 1;
  width: 100%;
  margin: 10px 0;
  background-color: #fff;
  padding: 10px 20px 0 0;
  border-radius: 5px;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}
.crunch .projectSidebar .inner > .client.active #portlandImg {
  max-width: 150px;
}
.crunch .sideBar.careerSidebar #applyHeader.header .inner > li {
  border: none;
  font-size: 13px;
  line-height: 0.5em;
}
.crunch .sideBar.careerSidebar #values {
  border-bottom: 1px solid #ddd;
}
@media (max-width: 992px) {
  .crunch .sideBar.careerSidebar #values {
    border: none;
  }
}
.crunch .sideBar.careerSidebar #values .inner li {
  border: none;
}
.crunch .sideBar.careerSidebar .btn-primary:after {
  font-family: "FontAwesome", serif;
  content: '\f003';
  font-size: 18px;
  color: #fff;
  padding-left: 10px;
}
@media (max-width: 992px) {
  .crunch .sideBar.careerSidebar ul.listContainer {
    display: inline-block;
    width: 40%;
    float: left;
  }
.crunch   .sideBar.careerSidebar ul.listContainer .header {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .crunch .sideBar.careerSidebar ul.listContainer {
    width: 100%;
    margin: 0;
  }
}
.crunch .page-wrap--commercial .graphic-wrap--icon {
  width: 30%;
  float: right;
}
@media screen and (max-width: 768px) {
  .crunch .page-wrap--commercial .graphic-wrap--icon {
    width: auto;
    float: none;
  }
}
.crunch .page-wrap--commercial .graphic-wrap--icon .fa {
  font-size: 90px;
  right: 30px;
  position: relative;
  text-align: right;
  width: 150px;
  margin: 0 auto;
  height: 100px;
  vertical-align: middle;
  float: none;
  top: 40px;
  display: block;
  color: #b9cad0 !important;
}
@media screen and (max-width: 768px) {
  .crunch .page-wrap--commercial .graphic-wrap--icon .fa {
    display: block;
    width: 100%;
    text-align: left;
    left: 0;
    top: 0;
  }
}
.crunch .page-wrap--commercial .graphic-wrap--icon:before {
  font-size: 90px;
  color: #bacad0;
  right: 30px;
  position: relative;
  text-align: right;
  width: 150px;
  margin: 0 auto;
  height: 100px;
  vertical-align: middle;
  float: none;
  top: 40px;
  display: block;
}
@media (max-width: 768px) {
  .crunch .page-wrap--commercial .graphic-wrap--icon:before {
    display: block;
    margin: 0 25px;
    text-align: left;
    margin-bottom: 20px;
  }
}
.crunch .page-wrap--commercial #agriculture img {
  width: auto;
  height: auto;
  max-width: 110px !important;
  padding: 0;
  display: block;
  margin: 0 auto;
  margin-right: 10%;
  float: none;
}
@media (max-width: 768px) {
  .crunch .page-wrap--commercial #agriculture img {
    display: block;
    margin: 0;
    text-align: left;
    margin-bottom: 20px;
  }
}
.crunch body.microsite .title {
  position: relative;
  display: block;
}
.crunch body.microsite #intro.page-section {
  padding-top: 0;
}
 #intro.page-section .col-lg-8 {
  padding-top: 50px;
}
.crunch body.microsite .page-section .page-section {
  padding: 40px 0;
}
@media (max-width: 768px) {
.microsite #intro.page-section .col-lg-8 {
    padding-top: 0;
  }
.crunch   body.microsite .page-section .page-section {
    padding: 20px 0;
  }
}
.crunch body.microsite .page-section--mini.page-section {
  padding: 20px 0;
}
.crunch body.microsite .sideBar {
  float: right;
  max-width: 290px;
}
@media (min-width: 992px) {
  .crunch .drupalSidebar .inner li.active,
.crunch .drupalSidebar.relative .innerli.active {
    background-color: #eceeed;
    border: 2px solid transparent;
    border-bottom: 1px solid #ADD9F1;
  }
.crunch   .drupalSidebar .inner li.active a,
.crunch .drupalSidebar.relative .inner li.active a {
    color: #2773bd;
  }
.crunch   .drupalSidebar .inner.tierTwo li.active,
.crunch .drupalSidebar.relative .inner.tierTwo li.active {
    background-color: #fff;
    border: 2px solid transparent;
    border-bottom: 1px solid #ADD9F1;
  }
}
.crunch header.microsite {
  position: fixed;
  display: inline-block;
  width: 100%;
  box-shadow: none;
  padding: 0;
  height: 60px;
  overflow-y: visible;
}
.crunch header.microsite .logoWrapper {
  position: relative;
  width: auto;
}
@media (max-width: 768px) {
  .crunch header.microsite {
    height: 55px;
  }
.crunch   header.microsite .logoWrapper {
    top: 0;
    width: 150px;
    height: 52px;
    display: inline;
    background-size: contain;
  }
.crunch body.microsite .sideBar {
    float: none;
    max-width: none;
    width: 100%;
    margin: 20px 0;
  }
.crunch   header.microsite .logoWrapper .logoLink {
    display: inline-block;
    position: absolute;
    z-index: 100;
    padding: 0;
  }
.crunch   .microsite header.microsite .logoWrapper .logoLink img {
    height: auto;
    max-width: 155px;
  }
}
.crunch header.microsite .logoWrapper .logoLink {
  padding-top: 0;
}
.crunch header.microsite .logoWrapper .logoLink img {
  max-width: 175px;
  width: auto;
}
.crunch body.microsite header.microsite.smaller .logoWrapper {
  opacity: 1;
}
.crunch header.microsite .navbar.navbar-inverse {
  box-shadow: none;
  position: relative;
  width: 100%;
}
.crunch header.microsite .navbar.navbar-inverse .navbar-collapse.collapse {
  float: right;
  background: none;
  border: none;
  box-shadow: none;
}
.crunch header.microsite .navbar.navbar-inverse .navbar-nav {
  margin: 0;
  justify-content: flex-end;
}
.crunch header.microsite .navbar.navbar-inverse .navbar-nav li {
  border: none;
  width: auto;
  padding-top: 0;
}
@media (min-width: 769px) {
  .crunch header.microsite .navbar.navbar-inverse .navbar-nav li {
    border-bottom: 5px solid transparent;
  }
}
.crunch header.microsite .navbar.navbar-inverse .navbar-nav li:hover a {
  background-color: transparent;
}
.crunch header.microsite .navbar.navbar-inverse .navbar-nav li a {
  width: auto;
  font-size: 14px;
  line-height: 1.8em;
}
@media (min-width: 769px) {
  .crunch header.microsite .navbar.navbar-inverse .navbar-nav li a {
    padding: 20px;
    padding-bottom: 15px;
  }
}
.crunch #request.modal .address--wrap:nth-of-type(2) .address--wrap-inner {
  padding-left: 30px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .crunch #request.modal .address--wrap:nth-of-type(2) .address--wrap-inner {
    padding: 0;
  }
}
.crunch #request.modal .well {
  margin: 0;
  padding: 0 35px;
}
@media (max-width: 768px) {
  .crunch #request.modal .well {
    padding: 0;
  }
}
.crunch #request.modal .well h3 {
  padding: 0;
  color: #00549a;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
@media (max-width: 768px) {
  .crunch #request.modal .well h3 {
    padding-left: 15px;
  }
}
.crunch #request.modal .well .flex-wrap {
  margin: 10px 0 20px 0;
}
@media (max-width: 768px) {
  .crunch #request.modal .well .flex-wrap {
    padding: 0 15px;
  }
}
@media (min-width: 768px) {
  .crunch body.microsite.microsite-banking #project.page-section .graphic--mobile {
    margin-top: 75px;
  }
}
.crunch body.microsite.microsite-banking #cybersecurity.page-section .graphic {
  border-left: 2px solid #939ba8;
  border-radius: 0;
}
@media (max-width: 768px) {
  .crunch body.microsite.microsite-banking #cybersecurity.page-section .graphic {
    border: none;
  }
}
.crunch body.microsite.microsite-cyber #services {
  padding-left: 35px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .crunch body.microsite.microsite-cyber #services {
    padding: 0 15px;
  }
}
.crunch body.microsite.microsite-cyber #systems {
  min-height: 400px;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber #systems {
    background-image: none;
    min-height: inherit;
  }
}
.crunch body.microsite.microsite-cyber #systems:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 31%;
  min-height: 400px;
  height: 100%;
  background-image: url(images/images/cyber/sections/system.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber #systems:before {
    display: none;
  }
}
.crunch body.microsite.microsite-cyber #legal {
  background-image: url(images/images/cyber/sections/legal-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber #legal {
    background-image: none;
  }
}
.crunch body.microsite.microsite-cyber #legal:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(229, 244, 255, 0) 0%, #ffffff 40%, rgba(255, 255, 255, 0.53) 95%, rgba(148, 210, 232, 0) 100%);
}
.crunch body.microsite.microsite-cyber #disaster:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  min-height: 400px;
  height: 100%;
  background-image: url(images/images/cyber/sections/bcp.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber #disaster {
    display: none;
  }
}
.crunch body.microsite.microsite-cyber #nist {
  border: none;
  margin: 20px auto;
  margin-bottom: 0;
  display: block;
  float: none;
  width: 100%;
}
@media (max-width: 768px) {
  .crunch body.microsite.microsite-cyber #nist {
    width: 100%;
    max-width: none;
    margin: 0;
  }
}
.crunch body.microsite.microsite-cyber .industries {
  float: right;
  display: block;
  width: 100%;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber .industries {
    padding: 0;
    float: none;
  }
}
.crunch body.microsite.microsite-cyber .industries ul {
  margin-bottom: 0;
  width: 100%;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber .industries ul {
    padding: 0;
    margin: 0;
  }
}
.crunch body.microsite.microsite-cyber .industries ul li {
  width: 100%;
}
@media (max-width: 992px) {
  .crunch body.microsite.microsite-cyber .industries ul li {
    width: 35%;
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .crunch body.microsite.microsite-cyber .industries ul li {
    width: 47%;
    display: inline-block;
  }
}
@media (max-width: 480px) {
  .crunch body.microsite.microsite-cyber .industries ul li {
    width: 100%;
    display: block;
  }
}
.crunch .title-text h2 {
  font-size: 2.5em;
  line-height: 1.125em;
  color: #2773bd;
  margin-top: 30px;
  margin-bottom: 10px;
}
.crunch .microsite-sms {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.crunch .microsite-sms h1,
.crunch .microsite-sms h2,
.crunch .microsite-sms h3,
.crunch .microsite-sms h4,
.crunch .microsite-sms h5 {
  letter-spacing: 0.04em;
}
.crunch .microsite-sms h1, .microsite-sms div.sub-title h2 {
  color: #092c58;
  margin-bottom: 30px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 35px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms h1 {
    font-size: 30px;
  }
}
.crunch .microsite-sms h3 {
  color: #5e626c;
  font-size: 22px;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.crunch .microsite-sms p {
  max-width: 700px;
  font-weight: normal;
  padding-bottom: 15px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms p {
    max-width: 100%;
  }
}
.crunch .microsite-sms a:hover {
  cursor: pointer;
}
.crunch .image-pane {
  display: inline-block;
  margin-top: 5px;
}
.crunch .image-pane .image-pane-graphic {
  border: 2px solid #d2dae2;
}
.crunch .image-pane .image-pane-graphic--approach {
  border-bottom: 5px solid #4eacfe;
}
.crunch .image-pane--caption {
  position: relative;
  font-size: 13px;
  display: inline-block;
  font-style: italic;
  background-color: #f3f7fb;
  padding: 10px;
}
.crunch .image-pane--caption p {
  line-height: 1.55em;
  color: #6e7e8a;
  padding: 5px 0 5px 0;
}
.crunch .image-pane--caption p:last-of-type {
  padding-bottom: 0;
}
.crunch header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(1).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(1).active {
  border-bottom: 5px solid #4eacfe;
}
.crunch header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(2).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(2).active {
  border-bottom: 5px solid #819bff;
}
.crunch header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(3).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(3).active {
  border-bottom: 5px solid #ee9344;
}
.crunch header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(4).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(4).active {
  border-bottom: 5px solid #2ab69a;
}
@media screen and (max-width: 768px) {
  .crunch header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(1).active,
  .crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(1).active {
    background-image: linear-gradient(to right,#4eacfe,#a2c7eb);
  }
.crunch   header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(2).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(2).active {
    background-image: linear-gradient(to right,#819bff,#a2c7eb);
  }
.crunch   header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(3).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(3).active {
    background-image: linear-gradient(to right,#ee9344,#a2c7eb);
  }
.crunch   header.microsite-sms.microsite .navbar-inverse .navbar-nav li:nth-of-type(4).active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .navbar-nav li:nth-of-type(4).active {
    background-image: linear-gradient(to right,#2ab69a,#a2c7eb);
  }
.crunch   header.microsite-sms.microsite .navbar-inverse .nav.navbar-nav li.active,
.crunch header.microsite-sms.microsite.smaller .navbar-inverse .nav.navbar-nav li.active {
    border-bottom: none;
  }
}
@media screen and (max-width: 992px) {
  .crunch header.microsite.microsite-new .navbar.navbar-inverse .navbar-nav li a {
    padding: 20px 15px 15px 15px;
  }
}
.crunch .microsite-sms .page-section h2 {
  color: #00549a;
}
.crunch .microsite-sms .page-section .site-layout-title {
  display: flex;
  justify-content: baseline;
  align-items: center;
  padding: 50px 15px;
  margin-bottom: 25px;
}
.crunch .microsite-sms .page-section .site-layout-title .site-layout--graphic {
  height: auto;
  width: 55px;
  display: inline-block;
  margin-right: 10px;
}
.crunch .microsite-sms .page-section .site-layout-title .site-layout--graphic .graphic-wrap {
  margin: 0;
}
.crunch .microsite-sms .page-section .site-layout-title h2 {
  margin: 0;
  font-size: 30px;
  color: #939ba8;
  font-weight: 300;
  padding-left: 5px;
}
.crunch .microsite-sms .page-section.page-section--approach {
  border-top: 5px solid #4eacfe;
}
.crunch .microsite-sms .page-section.page-section--approach .mainpage-wrapper {
  padding-bottom: 60px;
}
.crunch .microsite-sms .page-section.page-section--approach .mainpage-wrapper--last {
  padding-top: 75px;
}
.crunch .microsite-sms .page-section.page-section--approach-FAA {
  background-image: url(images/images/microsite/sms/approach/background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  margin: 50px 0;
  padding: 70px 0;
}
.crunch .microsite-sms .page-section.page-section--approach-FAA:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(216, 239, 255, 0.93) 0%, rgba(143, 209, 255, 0.64) 90%);
  z-index: 1;
  height: 100%;
  width: 100%;
}
.crunch .microsite-sms .page-section.page-section--approach-FAA .mainpage-wrapper {
  z-index: 5;
}
.crunch .microsite-sms .page-section.page-section--approach-FAA .mainpage-wrapper h2 {
  text-align: center;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.93);
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--approach-FAA .mainpage-wrapper h1 {
    text-align: left;
  }
}
.crunch .microsite-sms .page-section.page-section--approach h2 {
  font-weight: 500;
  max-width: 700px;
}
.crunch .microsite-sms .page-section.page-section--approach .white-box--trans {
  margin: 0 auto;
  display: block;
  width: 70%;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--approach .white-box--trans {
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--approach .white-box--trans p {
  display: block;
  margin: 0 auto;
  float: none;
}
.crunch .microsite-sms .page-section.page-section--approach #one.image-pane .image-pane-graphic {
  background-image: url(images/images/microsite/sms/approach/approach.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left -160px;
  display: inline-block;
  float: right;
  height: 250px;
  padding: 0;
  width: 100%;
}
.crunch .microsite-sms .page-section.page-section--approach #two.image-pane .image-pane-graphic {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: inline-block;
  height: 310px;
  padding: 0;
  margin-right: 15px;
  width: 100%;
  margin-bottom: 35px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--approach #two.image-pane .image-pane-graphic {
    margin: 15px 0;
  }
}
.crunch .microsite-sms .banner-sms {
  padding: 25px 0;
  background-color: #dfe2e4;
  background-image: linear-gradient(to bottom, #e3eef6, #d0dbe3);
  border-top: 3px solid #ffffff;
  width: 100%;
}
.crunch .microsite-sms .banner-sms h2 {
  text-align: left;
  font-size: 32px;
  width: 85%;
  display: inline-block;
  color: #415d81;
  font-weight: 300;
  line-height: 1.4em;
  vertical-align: middle;
  margin: 0;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .banner-sms h1 {
    width: 100%;
    display: block;
    font-size: 22px;
    text-align: center;
    padding: 5px 10px;
  }
}
.crunch .microsite-sms .banner-sms .banner-sms--graphic {
  height: 100px;
  display: inline-block;
  width: 10%;
  vertical-align: middle;
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .banner-sms .banner-sms--graphic {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .banner-sms .banner-sms--graphic .graphic-wrap .graphic {
    display: block;
    margin-bottom: 10px;
  }
}
.crunch .microsite-sms .page-section--experience {
  border-top: 5px solid rgba(156, 154, 233, 0.41);
}
.crunch .microsite-sms .page-section--experience .site-layout-title {
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin-bottom: 25px;
  padding: 0 15px;
}
.crunch .microsite-sms .page-section--experience .site-layout-title .site-layout--graphic {
  position: relative;
  height: auto;
  width: 55px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.crunch .microsite-sms .page-section--experience .image-pane {
  float: right;
  padding: 40px 0;
}
@media (max-width: 900px) {
  .crunch .microsite-sms .page-section--experience .image-pane {
    padding: 0;
  }
}
.crunch .microsite-sms .page-section--experience .image-pane--flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  align-items: flex-end;
}
@media (max-width: 900px) {
  .crunch .microsite-sms .page-section--experience .image-pane--flex {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 15px;
  }
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section--experience .image-pane--flex {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
}
.crunch .microsite-sms .page-section--experience .client-logo {
  max-width: 200px;
  margin-bottom: 120px;
  float: right;
}
.crunch .microsite-sms .page-section--experience .client-logo:last-of-type {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section--experience .client-logo {
    margin-bottom: 20px;
  }
}
.crunch .microsite-sms .page-section--experience .graphic-wrap {
  padding: 0;
  margin: 20px auto;
}
.crunch .microsite-sms .page-section--experience .graphic-wrap img {
  max-width: 60px;
  margin: 0 auto;
  display: block;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section--experience .graphic-wrap img {
    max-width: 65px;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing {
  border-top: 3px solid #dbdbdb;
}
.crunch .microsite-sms .page-section.page-section--pricing .site-layout-title {
  justify-content: center;
}
.crunch .microsite-sms .page-section.page-section--pricing .site-layout-title h1 {
  width: auto;
}
.crunch .microsite-sms .page-section.page-section--pricing div.pricing-content h2 {
  text-align: center;
  width: 650px;
  margin: 0 auto 40px auto;
  font-weight: 500;
}
@media (max-width: 480px) {
  .crunch .microsite-sms .page-section.page-section--pricing div.pricing-content h2 {
    text-align: center;
    width: auto;
    margin: 0 10px 0 10px;
    font-weight: 500;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing div.pricing-title h2 {
  text-align: center;
  font-weight: 500;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing h1 {
    width: 100%;
    font-size: 25px;
    padding: 0 15px;
    margin-top: 0;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-wrapper {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper {
  width: 50%;
  display: inherit;
  border: 2px solid #acd9d5;
  border-bottom: none;
  border-right: none;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper:nth-of-type(1) {
  border-right: 2px solid #acd9d5;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper:nth-of-type(2) {
  border-right: 2px solid #acd9d5;
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper:nth-of-type(3) {
  border-bottom: 2px solid #acd9d5;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper:nth-of-type(3) {
  border-right: 2px solid #acd9d5;
    border-bottom: none;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper:nth-of-type(4) {
  border-right: 2px solid #acd9d5;
  border-bottom: 2px solid #acd9d5;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile-inner--wrapper {
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile {
  margin: 10px;
  min-height: 320px;
  position: relative;
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile {
    max-width: 100%;
    padding: 15px;
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile .graphic-wrap {
  display: inline-block;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile .graphic-wrap {
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile .graphic-wrap .graphic {
  width: 50px;
  height: auto;
}
.crunch .microsite-sms .page-section.page-section--pricing .tile .graphic-wrap .graphic--large {
  width: 60px;
  height: auto;
}
.crunch .microsite-sms .page-section.page-section--pricing .tile .tile-title {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile .tile-title {
    padding: 0;
    text-align: center;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile h3 {
  display: inline-block;
  font-size: 24px;
  width: auto;
  margin-top: 0;
  padding-left: 10px;
  margin-bottom: 20px;
  color: #7c818c;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile h3 {
    text-align: center;
    padding: 0;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile h4 {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.5em;
  margin: 20px auto;
  color: #666c79;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile h4 {
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile p {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile p {
    width: 100%;
    margin: 10px 0;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile {
    width: 100%;
    margin: 10px 0;
    height: auto;
    min-height: inherit;
  }
.crunch   .microsite-sms .page-section.page-section--pricing .tile:last-of-type {
    margin-bottom: 0;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile-title {
  min-height: 40px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--pricing .tile-title {
    min-height: 0;
    height: auto;
  }
}
.crunch .microsite-sms .page-section.page-section--pricing .tile b {
  color: #019fa1;
}
.crunch .microsite-sms .page-section.page-section--pricing .tile .btn.btn-default:hover {
  cursor: pointer;
}
.crunch .microsite-sms .page-section.page-section--pricing #oneTile.tile .btn.btn-primary:hover {
  background-color: #019fa1;
  border: none;
}
.crunch .microsite-sms .page-section.page-section--pricing #twoTile.tile .btn.btn-primary:hover {
  background-color: #2d8adf;
  border: none;
}
.crunch .microsite-sms .page-section.page-section--pricing #threeTile.tile .btn.btn-primary:hover {
  background-color: #017a7c;
  border: none;
}
.crunch .microsite-sms .page-section.page-section--pricing #fourTile.tile .btn.btn-primary:hover {
  background-color: #2773bd;
  border: none;
}
.crunch .microsite-sms .page-section.page-section--services {
  border-top: 3px solid #dbdbdb;
}
.crunch .microsite-sms .page-section.page-section--services h2 {
  color: #7c818c;
  width: 100%;
  margin: 0 0 20px 0;
  font-weight: 500;
  max-width: 900px;
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--services h2 {
    width: 100%;
    font-size: 22px;
    padding: 10px;
    max-width: none;
  }
}
.crunch .microsite-sms .page-section.page-section--services .tile-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 15px;
  align-items: stretch;
  justify-content: space-between;
}
.crunch .microsite-sms .page-section.page-section--services .tile {
  width: 33%;
  padding: 0;
  border: 2px solid #e9dfc4;
  margin-bottom: 5px;
  position: relative;
}
@media (max-width: 900px) {
  .crunch .microsite-sms .page-section.page-section--services .tile {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section.page-section--services .tile {
    max-width: 100%;
    width: 100%;
  }
}
.crunch .microsite-sms .page-section.page-section--services .tile .tile-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px 10px 20px;
}
@media screen and (max-width: 992px) {
  .crunch .microsite-sms .page-section.page-section--services .tile .tile-title {
    justify-content: start;
  }
}
.crunch .microsite-sms .page-section.page-section--services .tile h3 {
  display: inline-block;
  font-size: 20px;
  width: 200px;
  margin: 0;
  color: #298ecc;
  padding: 0 15px;
}
@media (max-width: 992px) {
  .crunch .microsite-sms .page-section.page-section--services .tile h3 {
    width: auto;
  }
}
.crunch .microsite-sms .page-section.page-section--services .tile p {
  padding: 10px 25px 25px 25px;
}
.crunch .microsite-sms .page-section.page-section--services .tile .graphic-wrap {
  height: 70px;
  width: 70px;
  display: inline-block;
  float: left;
}
.crunch .microsite-sms .page-section.page-section--services .tile .graphic-wrap--plane {
  height: 30px;
  width: 40px;
  display: inline-block;
  float: left;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
}
.crunch .microsite-sms .page-section--site-layout {
  background-image: url('images/images/microsite/sms/site_layout/sky-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: relative;
  padding: 0 0 25px 0;
}
.crunch .microsite-sms .page-section--site-layout:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.53) 1%, transparent 60%, rgba(218, 244, 255, 0.62) 90%);
}
@media (max-width: 768px) {
  .crunch .microsite-sms .page-section--site-layout {
    padding: 0;
  }
}
.crunch .microsite-sms .page-section--site-layout .tile#tileApproach .tile-wrap {
  border-top: 5px solid #51a5ed;
}
.crunch .microsite-sms .page-section--site-layout .tile#tileExperience .tile-wrap {
  border-right: 1px solid #d8e3eb;
  border-top: 5px solid #8096ea;
}
.crunch .microsite-sms .page-section--site-layout .tile#tileExperience .tile-wrap .site-layout--title .site-layout--graphic {
  width: 45px;
}
.crunch .microsite-sms .page-section--site-layout .tile#tileServices .tile-wrap {
  border-top: 5px solid #eaa66a;
  border-bottom: 1px solid #d8e3eb;
}
.crunch .microsite-sms .page-section--site-layout .tile#tileServices .tile-wrap .site-layout--title .site-layout--graphic {
  width: 40px;
}
.crunch .microsite-sms .page-section--site-layout .tile#tilePricing .tile-wrap {
  border-top: 5px solid #10a9b2;
  border-bottom: 1px solid #d8e3eb;
  border-right: 1px solid #d8e3eb;
}
.crunch .microsite-sms .page-section--site-layout .tile#tilePricing .tile-wrap .site-layout--graphic {
  max-width: 40px;
}
.crunch .microsite-sms .splash {
  display: inline-block;
  width: 100%;
  height: 540px;
  background-image: url('images/images/microsite/sms/splash/splash.jpg');
  background-position: center -30px;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .splash {
    margin-top: 0;
    background-position: 70%;
    background-size: auto 100%;
  }
}
.crunch .microsite-sms .splash-wrapper {
  max-width: 992px;
  margin: 90px auto;
  display: flex;
  float: none;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .splash-wrapper {
    margin-top: 0;
  }
}
.crunch .microsite-sms .splash h1 {
  font-size: 54px;
  margin-top: 15px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .splash h1 {
    width: 100%;
    font-size: 35px;
    margin-top: 70px;
  }
}
.crunch .microsite-sms .splash h4 {
  line-height: 1.6em;
  color: #2773bd;
  font-size: 30px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .crunch .microsite-sms .splash h4 {
    font-size: 25px;
    text-shadow: 1px 1px 1px #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.46), rgba(231, 247, 255, 0.76));
    padding: 15px;
  }
}
@-moz-document url-prefix() {
  .crunch .microsite-sms .splash {
    background-position: center -60px;
  }
}
.crunch body.microsite.microsite-devops {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.crunch body.microsite.microsite-devops h1,
.crunch body.microsite.microsite-devops h2,
.crunch body.microsite.microsite-devops h3,
.crunch body.microsite.microsite-devops h4,
.crunch body.microsite.microsite-devops h5 {
  letter-spacing: 0.04em;
}
.crunch body.microsite.microsite-devops h1 {
  color: #595959;
  font-weight: 600;
}
@media (max-width: 768px) {
  .crunch body.microsite.microsite-devops h1 {
    font-size: 2.2em;
    text-align: left;
  }
}
.crunch body.microsite.microsite-devops h2 {
  font-weight: 600;
  max-width: 700px;
  color: #666c79;
}
.crunch body.microsite.microsite-devops h3 {
  color: #7c818c;
}
.crunch body.microsite.microsite-devops h4 {
  color: #666c79;
  font-weight: bold;
  line-height: 1.3em;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .crunch header.microsite.microsite-devops.white-nav .navbar.navbar-inverse.navbar-microsite-devops .navbar-nav li a {
    max-width: none;
  }
}
@media screen and (max-width: 992px) {
  .crunch header.microsite.microsite-devops.white-nav .navbar.navbar-inverse .navbar-nav li a {
    max-width: 110px;
  }
}
.crunch body.microsite.microsite-devops .main-section {
  background-image: url('images/images/microsite/devops/sections/section-bg.jpg');
  background-size: cover;
  background-position: center;
  display: inline-block;
  width: 100%;
  padding: 25px 0;
  height: auto;
  border-top: 3px solid #ffffff;
}
.crunch body.microsite.microsite-devops .main-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-image: linear-gradient(to right, rgba(95, 109, 118, 0.76) 70%, rgba(225, 225, 225, 0.71) 100%);
  z-index: 0;
  height: 100%;
}
.crunch body.microsite.microsite-devops .main-section .mainpage-wrapper h2 {
  color: #fff;
  max-width: none;
  padding: 0 15px;
  margin: 0;
  text-shadow: 2px 2px 2px rgba(24, 37, 43, 0.77);
}
@media screen and (min-width: 768px) {
  .crunch body.microsite.microsite-devops .main-section .mainpage-wrapper h2 {
    font-size: 38px;
  }
}
.crunch body.microsite.microsite-devops .tile--25 {
  background-color: transparent;
  border: none;
  padding: 0;
}
.crunch .three-topics {
  background-image: url(images/images/microsite/devops/splash/splash-two.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 1000px;
  display: inline-block;
  padding: 70px 15px;
}
@media screen and (max-width: 768px) {
  .crunch .three-topics {
    padding: 40px 0;
    background-position: center;
    background-attachment: scroll;
    height: auto;
    min-height: 0;
  }
}
.crunch .three-topics.active .fade-in {
  opacity: 1;
  transition: opacity 0.5s;
}
.crunch .three-topics.active .fade-in:nth-of-type(2) {
  transition-delay: 2s;
}
.crunch .three-topics.active .fade-in:nth-of-type(3) {
  transition-delay: 4s;
}
.crunch .three-topics .graphic-wrap {
  background-color: #fff;
  padding: 20px;
  width: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border: 2px solid #aeccd6;
  border-right: 0;
}
.crunch .three-topics ul {
  margin: 0;
  padding: 0;
  margin-top: 70px;
  max-width: 600px;
  float: right;
}
@media screen and (max-width: 768px) {
  .crunch .three-topics ul {
    margin: 0;
  }
}
.crunch .three-topics .fade-in {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .crunch .three-topics .fade-in {
    opacity: 1;
    background-color: rgba(2, 49, 86, 0.71);
  }
}
.crunch .three-topics .fade-in-text {
  display: flex;
  align-items: center;
}
.crunch .three-topics .fade-in p {
  padding: 15px 5px;
  color: #fff;
  font-size: 22px;
  line-height: 1.45em;
  font-weight: 400;
  text-shadow: 2px 2px 2px #0b2c50;
}
@media screen and (max-width: 768px) {
  .crunch .three-topics .fade-in p {
    font-size: 20px;
  }
}
.crunch body.microsite-devops .splash-devops {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('images/images/microsite/devops/splash/splash-v2.jpg');
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: inline-block;
  padding: 0;
  border-bottom: 15px solid #00549a;
}
.crunch body.microsite-devops .splash-devops:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.34) 100%);
}
@media (min-width: 768px) {
  .crunch body.microsite-devops .splash-devops {
    min-height: 600px;
  }
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .splash-devops {
    background-image: url('images/images/microsite/devops/splash/mobile/splash.jpg');
    background-attachment: scroll;
    background-position: top;
  }
.crunch   body.microsite-devops .splash-devops:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.34) 100%);
  }
}
.crunch body.microsite-devops .splash-devops .splash-content {
  padding-top: 100px;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .splash-devops .splash-content {
    width: 100%;
    display: inline-block;
    padding-top: 75px;
  }
}
.crunch body.microsite-devops .splash-devops .splash-content #page-title {
  position: relative;
  font-size: 70px;
  color: #2773bd;
  text-shadow: 2px 2px 2px #fff;
  font-weight: 400;
  text-align: left;
  line-height: 1.2em;
  max-width: 670px;
  transition: opacity 4s;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .splash-devops .splash-content #page-title {
    width: 100%;
    font-size: 35px;
    opacity: 1;
    margin: 0;
  }
}
.crunch body.microsite-devops .splash-devops .splash-content #concept {
  color: #4f7591;
  text-shadow: 5px 5px 20px #fff;
  position: relative;
  font-weight: 400;
  font-size: 30px;
  max-width: 800px;
  line-height: 1.4em;
  margin-top: 20px;
  transition: opacity 4s;
  transition-delay: 1s;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .splash-devops .splash-content #concept {
    text-shadow: none;
    margin: 30px 0;
    font-size: 19px;
    line-height: 1.4em;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 3px;
  }
}
.crunch body.microsite-devops .splash-devops .splash-content.active #concept {
  opacity: 1;
  transition: left 1.5s, opacity 2s;
  transition-delay: 1.4s;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 {
  padding: 35px 0;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-rectangle {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  background-color: #dee3e9;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-rectangle {
    display: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-rectangle .tile {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .tile-collection-wrap {
  margin: 0;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .tile {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .icon-element {
    padding: 0;
    background-color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: -2px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .icon-element .icon {
  margin-bottom: 15px;
  height: 100px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .icon-element .icon {
    margin: 0 auto;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .icon-element .icon img {
  margin: 0 auto;
  max-width: 70px;
  max-height: 70px;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .icon-element .icon img {
    width: auto;
    height: auto;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .box-element {
  background-color: #fff;
  display: block;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.17);
  position: relative;
  border: 2px solid #b0bbcb;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .box-element {
    padding: 10px 20px;
    box-shadow: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .box-element .arrow-right {
  position: absolute;
  right: -20px;
  top: -2px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #b0bbcb;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-15 .tile-collection .box-element .arrow-right {
    border-top: 20px solid #b0bbcb;
    border-bottom: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    bottom: 0;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    float: none;
    margin: 0 auto;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-17 .title {
  max-width: 600px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .title {
  max-width: 700px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .title h1 {
    text-align: center;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 p {
  width: 100%;
  margin: 0;
  max-width: 100%;
  font-style: italic;
  font-size: 16px;
  color: #7c818c;
}
@media (min-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 p {
    text-align: center;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop {
  display: block;
  width: 100%;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-top .circle-graphic {
  display: block;
  background: #dee3e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 10px auto;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-top .circle-graphic:nth-of-type(2) {
  background: #bfc8d5;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-top .circle-graphic:nth-of-type(3) {
  background: #b0bbcb;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-bottom .circle-graphic {
  display: block;
  background: #b0bbcb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 10px auto;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-bottom .circle-graphic:nth-of-type(2) {
  background: #bfc8d5;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .circle-bottom .circle-graphic:nth-of-type(3) {
  background: #dee3e9;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection {
    flex-direction: column;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .track--arrow {
  position: absolute;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .track--arrow-left {
  right: 0;
  width: 55px;
  top: 8px;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(90deg);
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .track--arrow-down {
  width: 55px;
  left: -6px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .track--arrow-right {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: 20%;
  width: 55px;
  top: 8px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .track--bg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  z-index: -1;
  background-color: #b0bbcb;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--one .track--arrow {
  top: 110px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--two .track--arrow {
  top: 20px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--two .track--bg {
  height: 150px;
  top: 0;
  width: 45px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three {
  justify-content: flex-end;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three .track--arrow {
  left: 60%;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three .track--bg-one {
  width: 45%;
  margin: 0;
  right: 12%;
  margin-right: 2px;
  left: auto;
  top: -100px;
  height: 45px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three .track--bg-one {
    display: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three .track--bg-two {
  width: 45px;
  height: 255px;
  left: 75%;
  top: -100px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--three .track--bg-two {
    left: 0;
    height: 685px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four {
    flex-direction: column-reverse;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four .track--arrow-left {
  top: -8px;
  left: 30%;
  right: 0;
  width: 55px;
  height: 55px;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(90deg);
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four .track--arrow-left:nth-of-type(2) {
  left: 55%;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four .track--arrow-left {
    left: 0;
    top: 80px;
    right: 0;
    margin: 0 auto;
    width: 65px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(0deg);
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--four .track--bg {
  width: 90%;
  height: 40px;
  top: 30px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five {
  justify-content: flex-start;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five .track--bg-one {
  width: 45px;
  margin: 0;
  height: 100px;
  top: -50px;
  left: 10%;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five .track--bg-one {
    display: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five .track--bg-two {
  width: 40%;
  margin: 0;
  left: 10%;
  top: 30px;
  height: 45px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five .track--bg-two {
    display: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--five .track--bg-two .track--arrow {
  left: 50%;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--six .tile {
  min-width: 300px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--six .track--arrow {
  top: 50px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--six .track--bg {
  height: 120px;
  width: 45px;
  top: -95px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--six .track--bg {
    display: none;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection {
    flex-direction: row;
  }
.crunch   body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .tile {
    width: 50%;
    margin: 0;
    min-width: 0;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-one-top {
  height: 320px;
  width: 45px;
  top: -100px;
  left: -170px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-one-top {
    height: 250px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-one-top .track--arrow {
  top: 50px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-one-top .track--arrow:nth-of-type(2) {
  top: 200px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-one-top .track--arrow:nth-of-type(2) {
  top: 180px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-two-top {
  height: 320px;
  width: 45px;
  top: -100px;
  left: 180px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-two-top {
    height: 250px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-two-top .track--arrow {
  top: 50px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-two-top .track--arrow:nth-of-type(2) {
  top: 200px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--seven.tile-collection .track--bg-two-top .track--arrow:nth-of-type(2) {
  top: 180px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--eight {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection--eight {
    margin-top: 50px;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile {
  position: relative;
  width: 100%;
  max-width: 230px;
  margin: 10px;
  border: 2px solid #fff;
  padding: 10px;
  margin-bottom: 40px;
  font-size: 14px;
  border-radius: 3px;
  background-color: #6a869c;
  background-image: linear-gradient(to bottom, #6b9ac2 0%, #407aa8 100%);
  min-height: 75px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile {
    min-width: 100%;
    width: 100%;
    margin: 30px 0;
  }
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile:before {
  color: #f4f7fa;
  transition: color 0.5s;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile:hover {
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.5s;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile:hover:after {
  color: #fff;
  transition: color 0.5s;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile:hover:before {
  color: #fff;
  transition: color 0.5s;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--today {
  background-color: #FFA500;
  background-image: none;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--today:hover {
  background-color: #FFA500;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--today:before {
  content: '';
  position: absolute;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--today span {
  font-size: 22px;
  color: #fff;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--target span {
  font-size: 22px;
  color: #fff;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--two {
  margin-top: 100px;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--target {
  background-color: #019fa1;
  background-image: none;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--target:hover {
  background-color: #019fa1;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--target:before {
  content: '';
  position: absolute;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile--target a {
  font-size: 20px;
  color: #fff;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile span {
  font-weight: 500;
  padding: 0 10px;
  color: #fff;
  text-align: center;
}
.crunch body.microsite-devops .page-section.page-section--approach .slide-20 .tile-collection--desktop .tile-collection .tile span:hover {
  text-decoration: none;
}
.crunch body.microsite-devops .page-section.page-section--services {
  background-image: url(images/images/microsite/devops/services/implement-bg.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left 70%;
}
.crunch body.microsite-devops .page-section.page-section--services .mainpage-wrapper {
  z-index: 10;
}
.crunch body.microsite-devops .page-section.page-section--services .tile-collection .tile .title .graphic-wrap--icon .fa {
  color: #2773bd;
  font-size: 50px;
}
.crunch body.microsite-devops .page-section.page-section--services .tile-collection .tile .title h3 {
  color: #666c79;
  padding-left: 20px;
  margin: 8px 0;
}
@media (max-width: 768px) {
  .crunch body.microsite-devops .page-section.page-section--services .tile-collection .tile .title h3 {
    padding: 0;
  }
}
.crunch body.microsite-devops .page-section.page-section--devops {
  background-image: url('images/images/microsite/devops/devops/devops-bg.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.crunch body.microsite-devops .page-section.page-section--devops .slide-10 .title {
  max-width: 700px;
}
.crunch body.microsite-devops .page-section.page-section--devops .slide-12 .tile h5 {
  font-weight: bold;
  color: #019fa1;
  margin: 0;
  margin-bottom: 10px;
}
.crunch body.microsite-devops .page-section.page-section--devops .slide-12 .tile p {
  color: #7c818c;
  font-size: 15px;
  padding: 0;
}
.crunch body.microsite-devops .page-section.page-section--devops .slide-12 .tile--50 h5 {
  text-align: center;
}
.crunch .heading-h2{
   font-size: 23px;
   color:#40aada;
 }
.crunch .heading-h3{
  font-size: 28px;
  color:#1091cc;
}
.crunch .sub-heading-h2{
  font-size: 21px;
  color:#40aada;
}
.crunch .contactSidebar.sideBar .wrapper{
  width: 100%;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #ced8e2;
  display: table;
}
.crunch .header-social-media {
  margin-right: 20px;
  margin-top: 10px;
}
.crunch .header-social-media img,
.crunch .footer-social-media img {
  margin-left: 3px;
  margin-right: 3px;
  height: 25px;
  width: 25px;
}
.crunch .footer-social-media {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.crunch .footer-social-media a {
  display: inline-block;
}
@media screen and (max-width: 576px) {
  .crunch .foot-panel {
    padding: 0px;
  }
.crunch   .footer div.row-bottom {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (max-width: 992px) {
  .crunch .footer-social-media {
    margin-top: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 400px;
  }
.crunch   .footer-social-media a {
    margin-top: 5px;
  }
}
.crunch .privacyPolicy h4,
.crunch .termsofUse h4,
.crunch .cookiePolicy h4 {
  font-weight: 600;
}
.crunch .privacyPolicy p {
  display: block;
}
.crunch .privacyPolicy.mainpage-wrapper,
.crunch .cookiePolicy.mainpage-wrapper,
.crunch .termsofUse.mainpage-wrapper {
  padding-left: 20px;
}
.crunch body footer ul.emailLink,
.crunch body footer ul.careerOps {
  margin-left: 10px;
}
.crunch span.bold {
  font-weight: 600;
}
.crunch #internships a.internship-docs {
  display: block;
  padding: 5px 0px;
}
.crunch body .footer ul.legal-docs,
.crunch body.microsite .footer ul.legal-docs {
  display: inline-block;
  padding-left: 20px;
}
.crunch .footer .legal-docs li {
  font-size: 12px;
  display: inline-block;
  padding-left: 0px;
}
.crunch body .footer ul.legal-docs a,
.crunch body.microsite .footer ul.legal-docs a {
  color: white;
  display: inline-block;
  padding-right: 20px;
}
.crunch body .footer .copyRight p.ncg-copyright {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.crunch body .footer .copyRight .mainpage-wrapper {
  position: relative;
}
@media screen and (max-width: 768px) {
  .crunch body footer.footer ul.legal-docs,
  body.microsite footer.footer ul.legal-docs,
  body footer.footer .copyRight p.ncg-copyright {
    display: block;
    height: fit-content;
    margin: 0px auto;
    width: fit-content;
    width: -moz-fit-content;
  }
.crunch   body footer.footer ul.legal-docs,
  body.microsite footer.footer ul.legal-docs {
    min-height: unset;
    margin-bottom: 10px;
    margin-top: 5px;
  }
.crunch   body footer.footer .copyRight p.ncg-copyright,
  body.microsite footer.footer .copyRight p.ncg-copyright {
    position: relative;
    right: unset;
    top: unset;
    transform: unset;
  }
.crunch   body .footer .copyRight .ncg-copyright .rights,
  body.microsite .footer .copyRight .ncg-copyright .rights {
    text-align: center;
  }
.crunch   body footer.footer ul,
  body.microsite footer.footer ul {
    width: fit-content;
    width: -moz-fit-content;
  }
.crunch   body footer.footer .address ul {
    height: 100%;
    padding-bottom: 40px;
  }
}
/*BLOG PAGE*/
.crunch div.banner--blog h1.bannerTitle,
.crunch .banner--press-release h1.bannerTitle {
  font-size: 40px;
  position: absolute;
  bottom: 10px;
  top: unset;
}
.crunch .blogPost .cmmc-logo-container,
.crunch .pressReleasePost .logo-container {
  float: none;
  display: block;
  margin-top: 30px;
  padding-left: 0px;
}
.crunch .pressReleasePost .logo-container.float-right {
  float: right;
}
.crunch .blogPost h2,
.crunch .pressReleasePost h2 {
  max-width: 6500px;
}
.crunch .blogPost .linkWrapper,
.crunch .pressReleasePost .linkWrapper {
  padding: 20px 10px;
  display: block;
  text-align: center;
  margin: 0px auto;
}
.crunch .blogPost .linkWrapper a,
.crunch .pressReleasePost .linkWrapper a {
  display: block;
  margin: 0px auto;
  padding-bottom: 5px;
}
.crunch .blogPost .linkWrapper p,
.crunch .pressReleasePost .linkWrapper p {
  padding-top: 20px;
}
.crunch .blogPost .linkWrapper p a,
.crunch .pressReleasePost .linkWrapper p a {
  padding: 0px;
}
.crunch .mainpage-wrapper.blogPage,
.crunch .mainpage-wrapper.pressRelease {
  padding-top: 30px;
}
.crunch .blogPage a.microsite-link,
.crunch .pressRelease a.microsite-link {
  padding-top: 30px;
}
.crunch .blogPage .btn-link-back,
.crunch .pressRelease .btn-link-back {
  margin-top: 0px;
  margin-left: 20px;
}
.crunch .blogPage ul,
.crunch .pressRelease ul {
  padding-left: 50px;
}
.crunch .blogPage ul li,
.crunch .pressRelease ul li {
  color: #0b3e6f;
  font-weight: 600;
}
.crunch .blogPage .ncg-logo-container,
.crunch .pressRelease .ncg-logo-container {
  float: none;
  margin: 0px auto;
  padding-bottom: 20px;
}
.crunch .blogPage .ncg-logo-container img,
.crunch .pressRelease .ncg-logo-container img {
  margin: 0px auto;
}
.crunch a.blog-link {
  max-width: 500px;
  word-wrap: break-word;
  display: block;
}
.crunch a.blog-link.federal {
  padding-left: 20px;
  max-width: 600px;
}
@media screen and (max-width: 498px) {
  .crunch body .banner--blog.banner,
  body .banner--blog.banner:before,
  body .banner--press-release.banner,
  body .banner--press-release.banner:before {
    height: 320px;
  }
}
@media screen and (max-width: 768px) {
  .crunch .banner--blog.banner,
.crunch .banner--blog.banner:before,
.crunch .banner--press-release.banner,
.crunch .banner--press-release.banner:before {
    height: 280px;
  }
}
@media screen and (max-width: 992px) {
  .crunch .blogPage .btn-link-back,
.crunch .pressRelease .btn-link-back {
    margin-bottom: 30px;
  }
}
/*PRESS RELEASE*/
.press-release {
  padding: 0px;
  bottom: 0px;
  z-index: 50;
  margin: 0px auto;
  display: block;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #d5e1eb;
  background: #40aada;
  background: -moz-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #40aada), color-stop(100%, #1091cc));
  background: -webkit-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -o-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: -ms-linear-gradient(left, #40aada 0%, #1091cc 100%);
  background: linear-gradient(to right, #40aada 0%, #1091cc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7cacd0', endColorstr='#47739d', GradientType=1);
}
.crunch .press-release.mobile {
  display: none;
}
.crunch .press-release.pr-slider {
  padding: 0px;
  right: 0px;
  width: fit-content;
  width: -moz-fit-content;
  position: absolute;
  bottom: 0px;
  z-index: 50;
  margin: 0px auto;
  border-bottom: none;
  border-radius: 40px 0px 0px 0px;
  background: rgba(3, 66, 155, 0.68);
}
@media screen and (max-width: 768px) {
  .crunch .press-release.pr-slider  {
    display: none;
  }
.crunch   .press-release.mobile {
    display: block;
  }
}
.crunch .press-release.pr-slider .mainpage-wrapper {
  position: relative;
  right: unset;
  top: unset;
  left: unset;
  bottom: unset;
  z-index: 200;
}
.crunch .press-release-wrapper {
  display: flex;
  flex-direction: row;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0px auto;
  padding: 60px 40px;
  align-items: center;
  justify-content: flex-end;
}
.crunch .pr-slider .press-release-wrapper {
  padding: 40px 30px 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: unset;
}
.crunch .pr-slider .pr-label {
  text-align: left;
  max-width: unset;
  padding-left: 50px;
  margin-right: unset;
}
.crunch .pr-slider .pr-link {
  padding-top: 10px;
}
@media screen and (max-width: 992px) {
  .crunch .pr-slider .press-release-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px 30px 40px;
  }
.crunch   .press-release.pr-slider {
    bottom: 60px;
    border-radius: 40px 0px 0px 40px;
  }
.crunch   .pr-link {
    padding-top: 10px;
  }
.crunch   .press-release.pr-slider a {
    font-size: 12px;
  }
}
.crunch .mobile .press-release-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: unset;
  margin-left: auto;
}
.crunch .pr-label {
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: -moz-fit-content;
  position: relative;
  margin-right: 30px;
  padding-right: 50px;
  text-align: right;
}
.crunch .mobile .pr-label {
  margin-right: 30px;
}
.crunch .pr-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.crunch .press-release h4 {
  text-transform: uppercase;
  position: relative;
  margin-top: 0px;
  color: #ffffff;
  line-height: 23px;
  font-size: 20px;
}
.crunch .press-release .pr-label:after {
  content: '';
  background-image: url('images/images/index/page/press-release-icon-white-3.png');
  display: inline-block;
  height: 45px;
  width: 45px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  right: -10px;
  top: -2px;
}
.crunch .press-release.pr-slider .pr-label:after {
  height: 40px;
  width: 40px;
  left: -2px;
}
@media screen and (max-width: 768px) {
  .crunch .press-release .pr-label:after {
    left: unset;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
  }
.crunch   .press-release-wrapper .pr-link {
    padding-top: 0px;
  }
.crunch   .mobile .pr-label,
.crunch .press-release .pr-label {
    margin-right: 30px;
    padding-right: 40px;
  }
.crunch   .mobile .press-release-wrapper,
.crunch .press-release .press-release-wrapper {
    margin: 0px auto;
  }
.crunch   .press-release .pr-link a {
    max-width: 300px;
  }
.crunch   .press-release p.date,
.crunch .press-release.mobile p.date {
    font-size: 12px;
    line-height: normal;
  }
.crunch   .press-release.mobile .pr-label h4,
.crunch .press-release .press-release-wrapper .pr-label h4 {
    font-size: 1.125em;
    line-height: 1.11111111em;
  }
}
.crunch .press-release-wrapper p.date {
  display: block;
  padding: 0px;
  line-height: normal;
  font-weight: 100;
  padding-top: 3px;
  font-size: 14px;
}
.crunch .press-release.mobile p.date {
  display: block;
  line-height: normal;
  font-weight: 100;
  padding-top: 3px;
  font-size: 12px;
  color: #ffffff;
}
.crunch .press-release p.date {
  display: block;
  line-height: normal;
  font-weight: 100;
  font-size: 12px;
  color: #ffffff;
}
.crunch .cmmc .press-release p.date {
  font-size: 12px;
  line-height: normal;
}
.crunch .press-release a {
  max-width: 400px;
  display: block;
  color: white;
  padding: 0px;
}
.crunch .press-release.pr-slider a {
  padding: 0px;
  max-width: 300px;
  display: block;
  color: #ffffff;
}
@media screen and (max-width: 480px) {
  .crunch body .press-release {
    margin-top: 0px;
  }
.crunch   body div.press-release h3:before {
    display: block;
    position: relative;
    margin: 0px auto;
    margin-bottom: 10px;
    left: unset;
  }
.crunch   body div.press-release-wrapper,
  body .mobile div.press-release-wrapper  {
    display: block;
    text-align: center;
  }
.crunch   div.press-release-wrapper .pr-link {
    padding-top: 15px;
  }
.crunch   .mobile .press-release-wrapper .pr-label, 
  .press-release .press-release-wrapper .pr-label {
    text-align: center;
    margin: 0px auto;
    padding-right: unset;
    padding-top: 40px;
  }
.crunch   div.press-release .pr-label:after {
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }
}
/*ACADEMIC PARTNERSHIP*/
.nmu-partnership .banner,
.crunch .nmu-partnership .banner:before {
  height: 500px;
  padding-top: 144px;
}
.crunch .nmu-partnership .banner .wrapper {
  position: relative;
  align-items: center;
  clear: both;
  display: flex;
}
.crunch .nmu-partnership .logo-wrapper {
  align-items: center;
  display: flex;
  height: inherit;
}
.crunch .nmu-partnership .logo-wrapper img {
  max-width: 120px;
  margin-left: auto;
}
.crunch .nmu-partnership .banner:before {
  background-color: rgba(48, 81, 130, 0.59);
}
.crunch .banner--nmu-partnership {
  background-image: url('images/images/nmu-partnership/nmu-splash.png');
  background-position: left -100px;
  background-size: cover;
}
.crunch .nmu-partnership .banner .text-wrapper-overlay {
  position: fixed;
  width: auto;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
}
.crunch .nmu-partnership .banner .text-wrapper-overlay h1 {
  color: white;
  text-shadow: none;
  font-size: 45px;
  left: unset;
  position: relative;
  top: unset;
  bottom: 0px;
  width: unset;
}
.crunch .academic-partnership-about .section-header-wrapper {
  display: flex;
  align-items: center;
}
.crunch .academic-partnership-about .logo-container {
  align-items: center;
}
.crunch .academic-partnership-about .logo-container img {
  display: inline-block;
}
.crunch .nmu-partnership .photo-description {
  font-size: 10px;
  display: block;
}
.crunch .nmu-partnership .intro-wrapper {
  display: flex;
  margin-bottom: 70px;
  align-items: center;
}
.crunch .left-intro-column {
  padding-left: 0px;
}
.crunch .left-intro-column img {
  display: block;
  margin-left: auto;
  max-width: 400px;
  position: relative;
}
.crunch .right-intro-column {
  padding-left: 80px;
}
.crunch .right-intro-column h2 {
  max-width: 800px;
  display: block;
  margin-right: auto;
  float: none;
}
.crunch .internship-overview-intro .overview-breakdown {
  margin-top: 20px;
}
.crunch .overview-breakdown-wrapper {
  margin-top: 100px;
  text-align: center;
}
.crunch .overview-breakdown-wrapper .btn-link {
  margin: 0px auto;
  float: none;
}
.crunch .overview-breakdown {
  margin-top: 80px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 40px;
}
.crunch .overview-breakdown h3 {
  margin-bottom: 80px;
}
.crunch .overview-breakdown .overview-tile {
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .crunch .overview-breakdown .overview-tile p {
    max-width: 200px;
  }
.crunch   .page-section.internship-overview .polygon-divider {
    clip-path: polygon(100% 0, 0 0, 100% 65%);
  }
}
.crunch .overview-breakdown .overview-tile .graphic-container {
  margin-bottom: 30px;
}
.crunch .overview-breakdown .overview-tile .graphic-container img {
  max-height: 100px;
  margin: 0px auto;
}
.crunch .focus-areas .header {
  text-align: center;
  background-color: rgba(15, 81, 144, 0.78);
  padding: 20px 10px;
}
.crunch .focus-areas .header h3 {
  color: white;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}
.crunch .focus-areas h4 {
  text-align: center;
  margin-top: 40px;
}
.crunch .focus-areas-tiles-container {
  margin-top: 50px;
  margin-bottom: 40px;
  padding-left: 50px;
}
.crunch .focus-areas-tiles-container .row-wrapper {
  justify-content: space-around;
  display: flex;
  float: none;
  clear: both;
}
.crunch .focus-area-tile {
  padding-left: 80px;
  margin-bottom: 50px;
}
.crunch .focus-area-tile:before {
  content: '';
  display: inline-block;
  height: 60px;
  width: 60px;
  position: absolute;
  top: 2px;
  left: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.crunch .focus-area-tile.cybersecurity-essentials:before {
  background-image: url('images/images/nmu-partnership/practice-areas-essentials.png');
}
.crunch .focus-area-tile.cyberrisk-assessments:before {
  background-image: url('images/images/nmu-partnership/practice-areas-ra.png');
}
.crunch .focus-area-tile.cyberrisk-management-programs:before {
  background-image: url('images/images/nmu-partnership/practice-areas-rm-programs.png');
  height: 90px;
  width: 90px;
  left: -20px;
}
.crunch .focus-area-tile.system-hardening:before {
  background-image: url('images/images/nmu-partnership/practice-areas-system-hardening.png');
}
.crunch .video-section {
  display: flex;
  align-items: center;
}
.crunch .video-section div {
  float: none;
}
.crunch .video-column {
  display: flex;
  align-items: center;
}
.crunch .video-wrapper {
  position: "relative";
  width: "100%";
  height: 0;
  padding-bottom: 56.25%;
}
.crunch .video-wrapper .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .crunch .video-section {
    flex-flow: column-reverse;
  }
.crunch   .video-column {
    margin-bottom: 15px;
    padding: 0px;
  }
.crunch   #intro {
    padding-top: 0px;
  }
}
/* MARQUETTE INTERNSHIPS & CAREERS PAGE */
.crunch .mq-internships .mq-internships-sidebar.sideBar,
.crunch .mq-careers .mq-careers-sidebar.sideBar {
  background-color: #f7f9fc;
}
.crunch .sidebar-wrapper {
  padding: 40px;
  min-height: 550px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .crunch .sideBar .sidebar-wrapper {
    min-height: unset;
  }
.crunch   .page-section .focus-area.row {
    flex-direction: column;
    align-items: center;
  }
.crunch   .focus-area .internship-area-tile {
    max-width: unset;
  }
}
.crunch .sidebar-wrapper h5 {
  padding-bottom: 20px;
}
.crunch .focus-area.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.crunch .internship-area-tile {
  padding: 30px;
  display: flex;
  position: relative;
  max-width: 380px;
}
.crunch .internship-area-tile h3 {
  max-width: 160px;
}
.crunch .internship-area-tile .header-wrapper {
  position: relative;
}
.crunch .internship-area-tile .header-wrapper:before {
  content: '';
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -110px;
}
.crunch .internship-area-tile.cybersecurity-essentials .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-essentials.png');
  height: 70px;
  width: 80px;
}
.crunch .internship-area-tile.cyber-risk-assessments .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-ra.png');
  height: 75px;
  width: 75px;
}
.crunch .internship-area-tile.system-hardening .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-system-hardening.png');
}
.crunch .internship-area-tile.cyber-risk-management .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-rm-programs.png');
  height: 100px;
  width: 100px;
  left: -120px;
}
.crunch .internship-area-tile.compliance-program-ops .header-wrapper:before {
  background-image: url('images/images/careers/internships/compliance.png');
}
.crunch .internship-area-tile.mergers-acquisitions .header-wrapper:before {
  background-image: url('images/images/careers/internships/mergers-acquisitions.png');
}
.crunch .internship-area-tile.risk-management .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-rm-programs.png');
  height: 100px;
  width: 100px;
  left: -120px;
}
.crunch .internship-area-tile.assessments-auditing .header-wrapper:before {
  background-image: url('images/images/careers/internships/practice-areas-ra.png');
  height: 75px;
  width: 75px;
}
.crunch .internship-area-tile .text-wrapper {
  padding-left: 100px;
}
.crunch .current-internship,
.crunch .current-position  {
  padding: 20px 0px;
  max-width: 730px;
  position: relative;
}
.crunch #internship-focus-areas .download-link {
  text-align: center;
  padding-bottom: 20px;
  width: 100%;
  display: inline-block;
}
.crunch .internship-info,
.crunch .career-info {
  display: flex;
  flex-direction: row;
}
.crunch .hexagon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
}
.crunch .cybersecurity-engineer-analyst .hexagon-wrapper {
  align-items: flex-end;
}
.crunch .cybersecurity-engineer-analyst .hex-image:after {
  height: 250px;
  width: 300px;
  background-color: #003E7C;
  opacity: .86;
  mix-blend-mode: soft-light;
}
.crunch .cybersecurity-privacy-analyst .hexagon-wrapper {
  align-items: center;
  margin-right: 35px;
}
.crunch .cybersecurity-privacy-analyst .hex-image:after {
  height: 170px;
  width: 220px;
  background-color: #67B9FF;
  opacity: .8;
  mix-blend-mode: soft-light;
}
.crunch .senior-program-manager-consultant .hexagon-wrapper {
  align-items: flex-end;
}
.crunch .senior-program-manager-consultant .hex-image {
  justify-content: flex-end;
}
.crunch .senior-program-manager-consultant .hex-image:after {
  height: 350px;
  width: 400px;
  background-color: #529DE8;
  opacity: .85;
  mix-blend-mode: soft-light;
}
.crunch .senior-program-bus-analyst .hexagon-wrapper {
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}
.crunch .senior-program-bus-analyst .hex-image:after {
  height: 250px;
  width: 300px;
  background-color: #3D66D5;
  opacity: .85;
  mix-blend-mode: soft-light;
}
.crunch .operations-internship .hexagon-wrapper {
  align-items: center;
  justify-content: flex-start;
}
.crunch .hex-image {
  position: relative;
  content: '';
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  z-index: 100;
  display: flex;
  justify-content: center;
}
.crunch .hex-image.large {
  height: 350px;
  width: 400px;
  margin-right: -50px;
}
.crunch .hex-image.medium {
  height: 250px;
  width: 300px;
}
.crunch .hex-image.small {
  height: 170px;
  width: 220px;
}
.crunch .hex-image img {
  height: 100%;
  width: auto;
  max-width: unset;
}
.crunch .hex-image:after {
  content: '';
  position: absolute;
  display: inline-block;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  z-index: 100;
}
.crunch .hex {
  display: flex;
  position: absolute;
  background-color: #9AB9CE;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  mix-blend-mode: darken;
}
.crunch .hex:after {
  content: '';
  display: inline-block;
  background-color: #ffffff;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
.crunch .hex.xlarge {
  height: 350px;
  width: 400px;
}
.crunch .hex.xlarge:after {
  height: 345px;
  width: 395px;
}
.crunch .hex.large {
  height: 290px;
  width: 340px;
}
.crunch .hex.large:after {
  height: 285px;
  width: 335px;
}
.crunch .hex.medium {
  height: 230px;
  width: 280px;
}
.crunch .hex.medium:after {
  height: 225px;
  width: 275px;
}
.crunch .hex.small {
  height: 190px;
  width: 230px;
}
.crunch .hex.small:after {
  height: 185px;
  width: 225px;
}
.crunch .hex.xsmall {
  height: 110px;
  width: 130px;
}
.crunch .hex.xsmall:after {
  height: 105px;
  width: 125px;
}
.crunch .cybersecurity-engineer-analyst .hex.small {
  top: 40px;
  right: -20px;
  opacity: .6;
}
@media screen and (max-width: 992px) {
  .crunch .currently-recruiting {
    padding-left: 20px;
  }
.crunch .career-info .hex {
    display: none;
  }
.crunch .career-info .hexagon-wrapper {
    align-items: center;
  }
.crunch div.career-info .hex-image,
.crunch div.hexagon-wrapper .hex-image.large {
    height: 250px;
    width: 300px;
    overflow: hidden;
  }
.crunch div.career-info .hex-image:after,
.crunch div.hexagon-wrapper .hex-image.large:after {
    height: 250px;
    width: 300px;
  }
.crunch .career-info.senior-program-bus-analyst .hexagon-wrapper {
    margin-top: 0px;
    justify-content: center;
  }
.crunch .currently-recruiting .cybersecurity-privacy-analyst .hexagon-wrapper {
    margin-right: unset;
  }
.crunch .currently-recruiting .hex-image.large {
    margin-right: unset;
  }
}
@media screen and (max-width: 768px) {
  .crunch .currently-recruiting div.career-info .hex-image {
    height: 170px;
    width: 200px;
  }
.crunch .currently-recruiting div.career-info .hex-image:after {
    height: 170px;
    width: 200px;
  }
.crunch   div.career-info {
    flex-flow: column-reverse;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 460px;
    margin: 0px auto;
    text-align: center;
  }
.crunch   .currently-recruiting h2 {
    text-align: center;
  }
}
.crunch .cybersecurity-engineer-analyst .hex.medium {
  bottom: 10px;
  left: 142px;
  opacity: .5;
}
.crunch .cybersecurity-engineer-analyst .hex.xsmall {
  bottom: -74px;
  right: 94px;
  transform: rotate(59deg);
  opacity: .6;
}
.crunch .cybersecurity-privacy-analyst .hex.small.second {
  bottom: 220px;
  right: 77px;
  height: 170px;
  width: 220px;
  opacity: .6;
}
.crunch .cybersecurity-privacy-analyst .hex.small.second:after {
  height: 165px;
  width: 215px;
}
.crunch .senior-program-manager-consultant .hex.large {
  top: -9px;
  right: -119px;
  height: 350px;
  width: 400px;
  opacity: .5;
}
.crunch .senior-program-manager-consultant .hex.large:after {
  height: 345px;
  width: 395px;
}
.crunch .senior-program-manager-consultant .hex.xsmall {
  bottom: 5px;
  right: 100px;
  transform: rotate(59deg);
  opacity: .6;
}
.crunch .senior-program-manager-consultant .hex.xsmall.first {
  top: -175px;
  right: 158px;
  height: 100px;
  width: 120px;
  transform: unset;
  opacity: .5;
}
.crunch .senior-program-manager-consultant .hex.xsmall.first:after {
  height: 95px;
  width: 115px;
}
.crunch .senior-program-manager-consultant .hex.medium {
  bottom: 52px;
  right: 100px;
  opacity: .6;
}
.crunch .senior-program-bus-analyst .hex.medium {
  top: -58px;
  left: 55px;
  opacity: .5;
}
.crunch .senior-program-bus-analyst .hex.xsmall {
  bottom: 80px;
  left: 120px;
  transform: rotate(59deg);
  opacity: .6;
}
.crunch .italic {
  font-style: italic;
}
.crunch .add-padding {
  padding-top: 40px;
}
.crunch .download-link {
  padding-top: 20px;
}
.crunch .column-wrapper {
  display: flex;
  flex-direction: column;
}
.crunch .foot-panel {
  flex-direction: row;
}
.crunch .row {
  flex-direction: row;
}
.crunch .mailto-link {
  padding-top: 20px;
  display: block;
}
@media screen and (max-width: 768px) {
  .crunch div.currently-recruiting {
    padding-right: 30px;
  }
.crunch   .focus-areas,
.crunch .mq-internships .intro {
    padding-top: 30px;
    padding-bottom: 30px;
  }
.crunch   .mq-careers .intro {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 992px) {
  .crunch .mailto-link {
    padding-top: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .crunch .currently-recruiting {
    padding-left: 30px;
  }
.crunch .mq-careers .intro,
.crunch .mq-internships .intro,
.crunch .focus-areas {
    padding-left: 15px;
    padding-right: 15px;
  }
.crunch .hexagon-wrapper .hex-image.large {
    margin-right: 0px;
    height: 280px;
    width: 330px;
  }
  .crunch .senior-program-manager-consultant .hex-image:after {
        height: 280px;
    width: 330px;
  }
.crunch body .cybersecurity-engineer-analyst .hex.small {
    top: 48px;
    right: 146px;
  }
.crunch body .cybersecurity-engineer-analyst .hex.medium {
    left: 163px;
  }
.crunch body .cybersecurity-engineer-analyst .hexagon-wrapper {
    align-items: center;
  }
.crunch .cybersecurity-engineer-analyst .hex.xsmall {
    bottom: -74px;
    right: 95px;
  }
.crunch .career-info.senior-program-manager-consultant .hex.large {
    left: 40px;
    right: unset;
    top: 45px;
  }
  .crunch div.senior-program-manager-consultant .hex.medium {
    right: 115px;
    bottom: 63px;
  }
  .crunch div.senior-program-manager-consultant .hex.xsmall {
    bottom: -29px;
    right: 138px;
  }
  .crunch div.senior-program-bus-analyst .hex.xsmall {
    bottom: 101px;
  }
  .crunch div.senior-program-bus-analyst .hex.medium {
    left: 48px;
  }
  .crunch div.cybersecurity-privacy-analyst .hex.small.second {
    right: 69px;
  }
  .crunch div.cybersecurity-engineer-analyst .hex.medium {
    bottom: 16px;
    left: 130px;
  }
}

.img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.graphic--mobile, .graphic--desktop {
  display: none;
}

#orchestrationTool.img-responsive {
  width: 70%;
}  

 