
.imp-micro .microsite-imp .mainpage-wrapper {
  max-width: 1200px;
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .mainpage-wrapper {
    max-width: 100%;
    width: 100%;
  }
.imp-micro .microsite .page-wrap {
    overflow-x: unset;
  }
}
/*CAROUSEL*/
.imp-micro body.microsite-imp .carousel .carousel-control {
  position: absolute;
  top: unset;
  width: 60px;
  opacity: .3;
  background-image: none;
  transition: all 0.9s ease-in-out;
  align-items: center;
  display: flex;
  height: 100%;
  padding-top: 60px;
}
.imp-micro body.microsite-imp .carousel .playPause {
  position: absolute;
  width: 100%;
  left: unset;
  right:  unset;
  top: unset;
  text-align: right;
  height: 40px;
  padding: 0px 10%;
  bottom: 7%;
  z-index: 25;
}
.imp-micro body.microsite-imp .carousel .playPause .fa {
  line-height: 40px;
  position: absolute;
  top: unset;
  right: unset;
}
.imp-micro body.microsite-imp .carousel:hover .playPause .fa {
  position: relative;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}
.imp-micro body.microsite-imp .carousel:hover .playPause .fa-pause {
  opacity: .5;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
}
.imp-micro .carousel .arrow-container .carousel-control .glyphicon {
  top: unset;
}
.imp-micro .carousel .carousel-inner .slide {
  height: fit-content;
  height: -moz-fit-content;
  display: grid;
  align-items: center;
  max-width: 1800px;
  margin: 0px auto;
}
@media screen and (max-width: 768px) {
  .imp-micro body.microsite-imp.microsite .carousel-control .glyphicon.glyphicon-chevron-right {
    margin-right: 10%;
    margin-top: 40px;
  }
.imp-micro body.microsite-imp.microsite .carousel-control .glyphicon.glyphicon-chevron-left {
    margin-left: 10%;
    margin-top: 40px;
  }
.imp-micro .microsite.microsite-imp .carousel .carousel-control {
    width: 30%;
    align-items: flex-end;
    padding-bottom: 4%;
  }
.imp-micro .microsite.microsite-imp .carousel:hover .carousel-control .glyphicon {
    color: #4c4c4c;
    opacity: .6;
  }
.imp-micro   .microsite.microsite-imp .carousel .right.carousel-control {
  padding-right: 20%;
  text-align: right;
  }
.imp-micro   .microsite.microsite-imp .carousel .playPause {
    display: inline-block;
    padding: 0px 5%;
    bottom: 80px;
    right: 50px;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro body.microsite-imp .carousel-control .glyphicon.glyphicon-chevron-right {
    margin-right: -20px;
    margin-top: 40px;
  }
.imp-micro   body.microsite-imp .carousel-control .glyphicon.glyphicon-chevron-left {
    margin-left: -20px;
    margin-top: 40px;
  }
.imp-micro   body.microsite-imp .carousel .carousel-inner:hover .playPause .fa-pause {
    opacity: .5;
    margin-right: 0px;
  }
.imp-micro   body.microsite-imp .carousel .carousel-inner .playPause {
    height: 30px;
    line-height: 30px;
  }
.imp-micro   body.microsite-imp .carousel .carousel-inner .playPause .fa {
    height: 30px;
    width: 30px;
    font-size: 10px;
    line-height: 30px;
  }
.imp-micro   body.microsite-imp .carousel .carousel-inner:hover .playPause .fa {
    line-height: 30px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro body.microsite-imp .carousel-control .glyphicon-chevron-right {
    margin-right: -15px;
    margin-top: 20px;
  }
.imp-micro   body.microsite-imp .carousel-control .glyphicon-chevron-left {
    margin-left: -15px;
    margin-top: 20px;
  }
}
/*CAROUSEL SLIDER*/
.imp-micro .carousel--imp.carousel {
  height: fit-content;
  height: -moz-fit-content;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  z-index: 180;
  padding-bottom: 30px;
}
.imp-micro .carousel--imp.carousel .indicatorWrap {
  bottom: 50px;
  top: unset;
}
.imp-micro .carousel--imp.carousel .indicatorWrap .carousel-indicators {
  height: fit-content;
  height: -moz-fit-content;
  width: auto;
  text-align: center;
  padding: 0;
  max-width: 960px;
  opacity: 1;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: inline-block;
}
.imp-micro .carousel--imp.carousel .carousel-indicators li {
  background-color: #c6c9cb;
}
.imp-micro .carousel--imp.carousel .carousel-indicators li.active {
  background-color: #1978b4;
}
.imp-micro .carousel--imp.carousel .carousel-inner {
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .carousel .carousel-inner .slide:before {
  transition: height 1s;
  display: none;
}
.imp-micro .carousel--imp.carousel .carousel-inner:before {
  display: none;
}
.imp-micro .carousel--imp.carousel .carousel-inner .slider-text-wrapper {
  height: fit-content;
  height: -moz-fit-content;
  padding-right: 0px;
  padding-left: 0px;
  display: block;
}
.imp-micro body .carousel--imp .title-wrapper {
  float: right;
  margin-right: 120px;
}
.imp-micro body.microsite-imp .carousel-inner .slider-text {
  max-width: 100%;
  display: inline-flex;
  position: absolute;
  height: 100%;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 40px;
  align-items: center;
}
.imp-micro .microsite-imp .carousel-inner .slider-text.title {
  max-width: 100%;
  display: block;
  position: absolute;
  height: 100%;
  flex-direction: row;
  top: 8%;
  width: 100%;
}
.imp-micro .microsite-imp .carousel--imp .first-paragraph {
  margin-left: 300px;
}
.imp-micro .carousel--imp.carousel .carousel-inner .slider-text h4 {
  font-size: 31px;
  margin-top: 0px;
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 0px;
  color: #00549a;
}
.imp-micro .carousel--imp .slider-text .first-paragraph div h4:nth-of-type(2) {
  font-size: 22px;
  margin-top: 0px;
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 0px;
  color: #666c79;
  margin-left: 50px;
}
.imp-micro .carousel--imp.carousel .carousel-inner .item .slide img {
  margin: 0px auto;
  width: 100%;
}
.imp-micro body.microsite-imp .carousel--imp img.image-large {
  display: block;
}
.imp-micro body.microsite-imp .carousel--imp img.image-small,
.imp-micro body.microsite-imp .carousel.carousel--imp .piggy-bank img.image-mobile,
.imp-micro body.microsite-imp .carousel.carousel--imp .piggy-bank-mobile.slide {
  display: none;
}
@media screen and (max-width: 320px), (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .imp-micro .microsite.microsite-imp .slide.carousel--imp.carousel.Carousel .carousel-inner .slide.piggy-bank-mobile img {
    margin-top: 340px;
  }
.imp-micro   .microsite.microsite-imp .slide.carousel-fade.carousel--imp.carousel.Carousel {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 480px), (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .imp-micro .microsite.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .slide.piggy-bank-mobile img {
    margin-top: 400px;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px), (min-device-width: 320px) and
(max-device-width: 640px) and (orientation: landscape) {
  .imp-micro .microsite.microsite-imp .carousel--imp.carousel.Carousel {
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 360px) and (max-width: 640px) and
(min-device-width: 360px) and (max-device-width: 640px) and (orientation : landscape) {
  .imp-micro .microsite.microsite-imp .carousel.carousel--imp .carousel-inner .title-wrapper {
    margin-right: 0px;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel .carousel-inner .title.slider-text {
    top: 4%;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp.carousel .carousel-inner {
    min-height: 500px;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .slide.piggy-bank-mobile {
    margin-top: -100px;
  }
}
@media screen and (max-width: 360px) and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
  .imp-micro .microsite.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .slide.piggy-bank-mobile img {
    margin-top: 460px;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp.carousel.Carousel {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 480px) {
  .imp-micro body.microsite-imp.microsite .carousel.carousel--imp .carousel-inner .slider-text.title h1 {
    font-size: 2.4em;
  }
}
@media screen and (max-width: 640px) {
  .imp-micro .carousel--imp.carousel {
    box-shadow: none;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel.carousel .carousel-inner .slider-text .row h4 {
    font-size: 1.2em;
  }
.imp-micro   .microsite.microsite-imp .site-title .mainpage-wrapper h1 {
    font-size: 1.4em;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel .carousel-inner .title.slider-text {
    top: 5%;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp.carousel .carousel-inner .slider-text-wrapper {
    top: 100px;
    padding-top: 0px;
    position: absolute;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .slide.piggy-bank-mobile img {
    align-items: flex-end;
    margin-top: 340px;
    display: block;
  }
.imp-micro   .microsite.microsite-imp .carousel.carousel--imp .piggy-bank-mobile.slide {
    display: inline-flex;
  }
.imp-micro   body.microsite-imp .Carousel.carousel.carousel--imp .carousel-inner .title-wrapper {
    margin-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .Carousel.carousel--imp.carousel.slide .indicatorWrap,
  .microsite.microsite-imp .carousel .playPause,
  .microsite.microsite-imp .carousel .arrow-container,
  body.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .item .slide img.image-small,
  body.microsite-imp .carousel--imp.carousel.Carousel .carousel-inner .item .slide img.image-large {
    display: none;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel .carousel-inner .slide-1,
  body.microsite-imp .carousel--imp.carousel .carousel-inner .slide-2,
  body.microsite-imp .carousel--imp.carousel .carousel-inner .slide-3,
  body.microsite-imp .carousel--imp.carousel .carousel-inner .slide-4,
  body.microsite-imp .carousel--imp.carousel .carousel-inner .item {
    display: none;
  }
.imp-micro   body.microsite-imp.carousel .carousel-inner .slide:before {
    transition: none;
    display: none;
  }
}
@media screen and (min-width: 641px) and (max-width: 765px) {
  .imp-micro body.microsite-imp .carousel.carousel--imp .carousel-inner .title-wrapper {
    margin-right: 0px;
    max-width: 70%;
    float: none;
    margin-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .imp-micro body.carousel--imp.carousel .carousel-inner .item .slide-background {
    height: fit-content;
    height: -moz-fit-content;
  }
.imp-micro   body.microsite-imp .carousel .carousel-inner .item {
    height: fit-content;
    height: -moz-fit-content;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel {
    padding-top: 0px;
    padding-bottom: 0px;
  }
.imp-micro   body.microsite-imp .carousel--imp .slide img.image-large {
    display: none;
  }
.imp-micro   body.microsite-imp .carousel--imp .slide img.image-small {
    display: block;
  }
.imp-micro   .carousel .carousel-inner .slide {
    height: 350px;
    position: relative;
  }
}
@media screen and (min-width: 761px) and (max-width: 992px) {
  .imp-micro body.microsite-imp .carousel .carousel-inner .slide {
    height: fit-content;
    height: -moz-fit-content;
    min-height: 400px;
    display: inline-flex;
    align-items: center;
  }
.imp-micro   body.microsite-imp.carousel--imp.carousel .carousel-inner {
    min-height: 300px;
  }
}
@media screen and (max-width: 840px) {
  .imp-micro body.microsite-imp .carousel.carousel--imp .carousel-inner .slider-text.title h1 {
    font-size: 2.7em;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro body.microsite-imp .carousel--imp .first-paragraph,
  .microsite.microsite-imp .Carousel.carousel--imp.carousel.slide .indicatorWrap,
  .microsite.microsite-imp .carousel .playPause,
  .microsite.microsite-imp .carousel .arrow-container {
    display: none;
  }
.imp-micro   body.microsite-imp .carousel-inner .slider-text.title {
    display: flex;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro body.microsite-imp .carousel--imp.carousel .carousel-inner .slide {
    max-height: 400px;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel.slide .carousel-inner {
    max-height: 400px;
  }
.imp-micro   .microsite-imp .carousel--imp.carousel .carousel-inner .title.slider-text {
    top: 0px;
  }
.imp-micro   .microsite-imp .carousel--imp.carousel .carousel-inner .slider-text-wrapper {
    padding-top: 50px;
  }
.imp-micro   .microsite-imp .carousel--imp.carousel .carousel-inner .first-paragraph div {
    padding: 0px;

  }
.imp-micro   .microsite-imp .carousel--imp.carousel .carousel-inner .first-paragraph div h4 {
    line-height: normal;
  }
.imp-micro   .microsite-imp .carousel--imp.carousel .indicatorWrap {
    bottom: 30px;
  }
}
@media screen and (max-width: 1300px) {
  .imp-micro body.microsite-imp .carousel .carousel-inner .slide {
    height: fit-content;
    height: -moz-fit-content;
    min-height: 600px;
    display: inline-flex;
    align-items: center;
  }
.imp-micro   body.microsite-imp .carousel--imp.carousel .carousel-inner {
    min-height: 600px;
  }
.imp-micro   body.microsite-imp .carousel.carousel--imp .slider-text-wrapper .row.first-paragraph {
    margin-top: 20%;
  }
.imp-micro   body.microsite-imp.microsite .carousel .carousel-inner .slider-text h4:nth-of-type(1) {
  font-size: 1.7em;
  }
}
@media screen and (max-width: 1405px) {
  .imp-micro body.microsite-imp .carousel .carousel-inner .slider-text.title h1 {
    font-size: 3em;
  }
.imp-micro   body.microsite-imp .carousel.carousel--imp .title-wrapper {
    padding: 0px;
  }
.imp-micro   .microsite.microsite-imp .carousel.carousel--imp .slider-text-wrapper .first-paragraph {
    margin-left: 200px;
    margin-top: 200px;
  }
}
@media screen and (max-width: 1500px) {
  .imp-micro body.microsite-imp .carousel--imp .title-wrapper {
    padding: 0px;
  }
}
@media screen and (max-width: 1600px) {
  .imp-micro body.microsite-imp .carousel .carousel-inner .slider-text h4:nth-of-type(1) {
  font-size: 2em;
  }
.imp-micro   .carousel--imp .carousel-inner .slider-text .first-paragraph div h4:nth-of-type(2) {
  display: none;
  }
.imp-micro   body.microsite-imp.microsite .carousel.carousel--imp .first-paragraph {
    margin-top: 240px;
  }
}
@media screen and (max-width: 1700px) {
  .imp-micro .microsite-imp .carousel.carousel--imp .slider-text .first-paragraph div h4:nth-of-type(2) {
  margin-top: 10px;
  }
}
@media screen and (min-width: 1800px) {
  .imp-micro .microsite.microsite-imp .carousel--imp .first-paragraph {
    margin-left: 400px;
    margin-top: 200px;
  }
}
@media screen and (max-width: 1800px) {
  .imp-micro .microsite.microsite-imp .carousel--imp .first-paragraph {
    margin-left: 250px;
  }
}
@media screen and (max-width: 2100px) {
  .imp-micro body.microsite-imp .carousel.carousel--imp .first-paragraph {
    margin-top: 190px;
  }
.imp-micro   .carousel.carousel--imp .slider-text .first-paragraph div h4:nth-of-type(2) {
  margin-top: 30px;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .slider-text-wrapper {
    width: 60%;
  }
}
@media screen and (min-width: 2100px) {
  .imp-micro body.microsite-imp .carousel.carousel--imp .first-paragraph {
    margin-top: 190px;
  }
.imp-micro   .carousel.carousel--imp .slider-text .first-paragraph div h4:nth-of-type(2) {
  margin-top: 40px;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .slider-text-wrapper {
    width: 60%;
  }
.imp-micro   body.microsite-imp .carousel--imp .title-wrapper {
    margin-top: 50px;
  }
}
@media screen and (min-width: 2400px) {
  .imp-micro .microsite.microsite-imp .carousel--imp .slibody.microsite-imp .carousel--imp .slide-3 .row.first-paragraphder-text-wrapper .first-paragraph {
    margin-left: 540px;
    margin-top: 200px;
  }
.imp-micro   body.microsite-imp .carousel--imp .title-wrapper {
    margin-right: 200px;
  }
}
@media screen and (min-width: 2800px) {
  .imp-micro .microsite.microsite-imp .carousel.carousel--imp .slider-text-wrapper .first-paragraph {
    margin-left: 850px;
  }
.imp-micro   body.microsite-imp .carousel--imp .title-wrapper {
    margin-right: 0px;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .title .slider-text-wrapper h1 {
    font-size: 3.8em;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp .slider-text-wrapper .first-paragraph {
    margin-top: 170px;
  }
.imp-micro   body.microsite-imp.microsite .carousel .carousel-inner .slider-text h4:nth-of-type(2) {
  font-size: 1.8em;
    margin-top: 30px;
    margin-left: 90px
  }
}
@media screen and (min-width: 3100px) {
  .imp-micro .microsite.microsite-imp .carousel.carousel--imp .slider-text-wrapper .first-paragraph {
    margin-left: 900px;
  }
.imp-micro   body.microsite-imp .carousel--imp .title-wrapper {
    padding-left: 120px;
  }
.imp-micro   .microsite-imp .carousel-inner .slider-text.title {
    width: 70%;
    top: 10%;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .title .slider-text-wrapper {
    width: 70%;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .title .slider-text-wrapper h1 {
    font-size: 4.3em;
  }
.imp-micro   .carousel.carousel--imp .carousel-inner .slider-text-wrapper {
    width: 60%;
  }
.imp-micro   body.microsite-imp .carousel.carousel--imp .first-paragraph {
    margin-top: 190px;
  }
.imp-micro   body.microsite-imp.microsite .carousel .carousel-inner .slider-text h4:nth-of-type(1) {
  font-size: 2.6em;
  }
.imp-micro   body.microsite-imp.microsite .carousel .carousel-inner .slider-text h4:nth-of-type(2) {
  font-size: 2em;
  }
.imp-micro   .microsite.microsite-imp .carousel--imp .slider-text-wrapper .first-paragraph {
    margin-top: 270px;
  }
}
@media screen and (min-width: 3700px) {
  .imp-micro .carousel.carousel--imp .carousel-inner .slider-text-wrapper {
    width: 66%;
  }
.imp-micro   .microsite.microsite-imp .carousel.carousel--imp .slider-text-wrapper .first-paragraph {
    margin-left: 940px;
  }
}
@media screen and (min-width: 1px) {
  .imp-micro body.microsite-imp .carousel .indicatorWrap .carousel-indicators li.active {
    margin: 3px;
  }
}
/*GENERAL STYLING*/
.imp-micro .microsite.microsite-imp .page-section {
  padding-top: 20px;
  padding-bottom: 0px;
}
.imp-micro .microsite-imp h4 {
  font-size: 22px;
  color: #6d7377;
  line-height: 1.7em;
}
.imp-micro .microsite.microsite-imp p {
  padding-bottom: 15px;
}
.imp-micro .microsite.microsite-imp a:hover {
  cursor: pointer;
}
/*PAGE SECTION CONTAINERS*/
.imp-micro .microsite.microsite-imp .page-section--prepare h3 {
  margin: 40px 0px;
  font-size: 40px;
  line-height: 1.2em;
}
.imp-micro .microsite.microsite-imp span.indent {
  margin-left: 5em;
}
.imp-micro .microsite.microsite-imp h3 span.indent {
  margin-left: 5em;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration h3 span.indent {
  margin-right: -5em;
}
.imp-micro .microsite.microsite-imp .page-section--prepare,
.imp-micro .microsite.microsite-imp .page-section--tools,
.imp-micro .microsite.microsite-imp .page-section--bcp-dr {
  -webkit-box-shadow: 0px -6px 18px -2px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px -6px 18px -2px rgba(0,0,0,0.45);
  box-shadow: 0px -6px 18px -2px rgba(0,0,0,0.45);
  padding-bottom: 9px;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  scroll-padding-top: 0px;
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--prepare {
  scroll-padding: 100px;
    box-shadow: none;
}
.imp-micro .microsite.microsite-imp .page-section .page-section--respond {
  padding-bottom: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--manage {
  -webkit-box-shadow: 0px -10px 18px -9px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px -10px 18px -9px rgba(0,0,0,0.45);
  box-shadow: 0px -10px 18px -9px rgba(0,0,0,0.45);
  padding-bottom: 9px;
  z-index: 200;
  scroll-padding: 100px;
}
@media screen and (max-width: 360px) and (min-aspect-ratio: 4/3) {
  .imp-micro .microsite.microsite-imp .page-section .page-section--prepare .page-section--respond .prepare-your-team {
    padding: 0px;
  }
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section .site-layout-title .site-layout--graphic {
    margin-right: 0px;
  }
.imp-micro   .microsite.microsite-new .page-section--manage .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 57px;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--prepare {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--prepare h3 span.indent {
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--prepare .page-section--respond .prepare-your-team {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    margin-top: 0px;
  }
}
.imp-micro .microsite.microsite-imp .site-layout-title .graphic-wrap .graphic {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section .site-layout-title .graphic-wrap .graphic {
    width: 40px;
  }
.imp-micro   .microsite.microsite-imp .page-section .site-layout-title .site-layout--graphic {
    margin-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section .site-layout-title .site-layout--graphic.circle {
    height: 50px;
    width: 50px;
    margin-left: 3%;
  }
.imp-micro   .microsite.microsite-imp .page-section .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 50px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .site-layout-title .site-layout--graphic .graphic-wrap {
    line-height: 44px;
  }
}
.imp-micro body.microsite-imp .banner {
  display: inline-block;
  width: 100%;
  margin-top: 60px;
  height: 550px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  z-index: 40;
}
/*PREPARE SECTION*/
.imp-micro .microsite.microsite-imp .page-section--proactive {
  height: fit-content;
  margin-bottom: 160px;
}
.imp-micro .microsite.microsite-imp .page-section--prepare .prepare-your-team {
  margin-top: 50px;
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--prepare .prepare-respond-collab {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 100;
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}
.imp-micro .microsite.microsite-imp .page-section--prepare .prepare-respond-collab img {
  float: right;
}
.imp-micro .microsite-imp .people-working {
  position: absolute;
  top: 130px;
  right: 0px;
  display: inline-block;
  width: 900px;
  height: 640px;
  background-image:
          linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 36%, rgba(255,255,255,0) 100%),
          url(images/images/microsite/imp/prepare/people-working.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.imp-micro .microsite.microsite-imp .page-section--prepare .people-working:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) .4%, rgba(255,255,255,0) 100%);
}
.imp-micro .microsite.microsite-imp .blue-banner {
   width: 100%;
   min-height: 200px;
   height: fit-content;
   height: -moz-fit-content;
   text-align: right;
   background: rgb(9,44,88);
   background: linear-gradient(90deg, rgba(9,44,88,0.7357317927170868) 0%, rgba(75,151,198,0.8869922969187675) 42%, rgba(75,151,198,0.9402135854341737) 66%, rgba(75,150,201,0.9822303921568627) 100%);
   position: relative;
   display: flex;
   align-items: center;
   top: 10px;
   z-index: 10;
   justify-content: flex-end;
   padding-top: 60px;
   padding-bottom: 60px;
 }
.imp-micro  .microsite.microsite-imp .purple-banner {
    width: 100%;
    min-height: 200px;
    height: fit-content;
    height: -moz-fit-content;
    text-align: right;
    background-color: #555b8a;
    position: relative;
    display: flex;
    align-items: center;
    top: 10px;
    z-index: 10;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .imp-micro  .microsite.microsite-imp .purple-banner p {
    color: #ffffff;
    text-align: left;
  }
.imp-micro .microsite.microsite-imp .blue-banner h4 {
  color: #ffffff;
  text-align: left;
  margin-top: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--prepare .blue-banner .banner-text h4 {
  text-align: left;
  max-width: 400px;
  float: left;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .be-proactive {
  float: right;
  margin-top: 4%;
  margin-bottom: 90px;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .be-proactive .text-wrapper {
  max-width: 500px;
  float: left;
  margin-top: 0px;
  width: fit-content;
  margin-bottom: 50px;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .be-proactive h4 {
   margin: 0px;
   color: #666c79;
   font-size: 4em;
}
.imp-micro .microsite.microsite-imp .page-section--proactive p {
  margin-left: 70px;
}
.imp-micro .microsite.microsite-imp .be-proactive span.prepare-blue {
  color: #4b97c6;
  margin-left: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .proactive-graphic {
  top: 60px;
  display: inline-block;
  height: fit-content;
  height: -moz-fit-content;
  z-index: 100;
  padding-left: 0px;
  margin-bottom: -470px;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .proactive-graphic img {
  width: 100%;
  height: auto;
}
.imp-micro .microsite.microsite-imp .page-section--proactive .blue-banner {
  height: fit-content;
  height: -moz-fit-content;
  background: none #4b97c6;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.45);
  display: flex;
  width: 100%;
  align-items: center;
  float: none;
  top: 180px;
}
.imp-micro .microsite.microsite-imp .middle-of-incident {
  float: right;
  text-align: left;
  height: fit-content;
  height: -moz-fit-content;
  padding-right: 15%;
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--proactive .be-proactive .text-wrapper {
    margin-bottom: 20px;
  }
.imp-micro   .microsite.microsite-imp .prepare-your-team h3 span.indent {
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--proactive .be-proactive .text-wrapper h4 {
    font-size: 2.8em;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--proactive .be-proactive {
    position: relative;
    float: none;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--prepare .prepare-respond-collab {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
.imp-micro   .microsite.microsite-imp .page-section--prepare .prepare-respond-collab img {
    max-width: 800px;
    float: none;
  }
.imp-micro   .microsite.microsite-imp .page-section .page-section.page-section--proactive .be-proactive .text-wrapper {
    margin-left: 0%;
  }
  .imp-micro .microsite.microsite-imp .page-section .page-section--state-assessment {
    display: flex;
    flex-flow: column;
  }
  .imp-micro .microsite.microsite-imp .page-section.page-section--state-assessment div.state-assessment-info-wrapper {
    padding: 100px 40px 0px 40px;
    text-align: center;
  }
  .imp-micro .page-section--state-assessment .assessment-infographic img.image-small {
    width: 100%;
    max-width: inherit;
    padding-right: 45px;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section--proactive .proactive-graphic {
    top: 0px;
    margin-bottom: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--proactive .be-proactive {
    position: relative;
    float: none;
    margin: 0px auto;
    margin-bottom: 80px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--proactive .be-proactive .text-wrapper {
    margin-top: 90px;
    margin-bottom: 0px;
    margin-left: 30%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--assess-improve {
    padding-top: 50px;
    padding-bottom: 50px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--state-assessment .state-assessment-info-wrapper {
    padding-left: 40px;
    padding-right: 30px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--state-assessment .state-assessment-info-wrapper .state-assessment-info h3 {
    font-size: 1.6em;
    margin-bottom: 20px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--state-assessment .state-assessment-info-wrapper .state-assessment-info h4 {
    font-size: 1em;
    margin-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--prepare .blue-banner .middle-of-incident {
    float: none;
    margin: 0px auto;
    display: inline-block;
  }
.imp-micro 
  .microsite.microsite-imp .page-section.page-section--prepare .people-working {
    display: none;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--proactive .blue-banner {
    top: 80px;
  }
.imp-micro   .microsite.microsite-imp .page-section .page-section--proactive {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp h1 span.indent {
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--proactive .proactive-graphic {
    top: 20px;
  }
.imp-micro body.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection {
    display: block;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--state-assessment .state-assessment-info h3 {
    font-size: 2em;
    margin-bottom: 30px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--state-assessment .state-assessment-info h4 {
    font-size: 1.3em;
    margin-top: 10px;
  }
.imp-micro   .microsite.microsite-imp .page-section--prepare .people-working {
    background-image:
            linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8673844537815126) 25%, rgba(255,255,255,0.7245273109243697) 49%, rgba(255,255,255,0) 100%),
            url(images/images/microsite/imp/prepare/people-working.png);
    width: 700px;
    height: 440px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--prepare .middle-of-incident {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: 1800px) {
  .imp-micro .microsite.microsite-imp .page-section--prepare .middle-of-incident {
    padding-right: 10%;
  }
}
@media screen and (min-width: 2000px) {
  .imp-micro .microsite.microsite-imp .people-working {
    width: 1200px;
    height: 740px;
    top: 150px;
  }
}
@media screen and (min-width: 2500px) {
  .imp-micro .microsite.microsite-imp .people-working {
    width: 1500px;
    height: 1040px;
    top: 170px;
  }
.imp-micro   .microsite.microsite-imp .page-section--proactive .blue-banner h4 {
    font-size: 1.8em;
  }
}
@media screen and (min-width: 3200px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--proactive .blue-banner {
    top: -60px;
  }
}
/*ASSESS & IMPROVE SECTION*/
.imp-micro .microsite.microsite-imp .page-section--assess-improve .mainpage-wrapper {
  text-align: center;
  padding: 50px 0px;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve {
  width: 100%;
  background-image: url(images/images/microsite/imp/prepare/roads-background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0px auto;
  padding-top: 100px;
  padding-bottom: 100px;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-container {
  display: inline-block;
  margin: 0px auto;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color: #ffffff;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap {
  opacity: 1;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile {
  margin: 0px 20px;
  display: inline-block;
  opacity: 1;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-wrap {
  width: 465px;
  height: auto;
  padding: 25px;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile--graphic {
  height: 183px;
  margin-top: 20px;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile--graphic img {
  max-width: 261px;
  width: auto;
  max-height: 183px;
  margin-bottom: 20px;
}
.imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile--body {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--assess-improve .tile-collection-wrap .tile-collection .tile-wrap {
    width: 280px;
    height: auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--assess-improve .tile-collection-wrap .tile-collection .tile--graphic img {
    max-width: 200px;
    height: auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--assess-improve .tile-collection-wrap .tile-collection .tile--graphic {
    height: auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--assess-improve .tile-collection-wrap .tile-collection .tile--body {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
.imp-micro   body.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile .tile-wrap {
    padding: 20px 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px), (min-device-width: 600px) and
(max-device-width: 960px) and (orientation: landscape) {
  .imp-micro body.microsite-imp .page-section--assess-improve .tile-container .tile-collection-wrap .tile-collection .tile-wrap {
    margin: 15px 0px;
  }
}
@media (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--assess-improve .tile-container .tile-collection-wrap .tile-collection .tile {
    margin: 20px 0px;
  }
}
@media screen and (max-width: 845px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--assess-improve .tile-collection-wrap .tile-collection .tile {
    margin: 20px 15px;
  }
}
@media screen and (max-width: 996px) {
  .imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile {
    display: block;
  }
.imp-micro   body.microsite-imp .page-section--assess-improve .tile-collection-wrap .tile-collection .tile-wrap {
    max-width: 370px;
    height: auto;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-wrap {
    margin: 20px 0px 20px 0px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--assess-improve .tile-wrap {
    margin: 20px 0 20px 0;
  }
}
/*CURRENT STATE ASSESSMENT SECTION*/
.imp-micro .microsite.microsite-imp .page-section--state-assessment {
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.75);
  margin: auto 0px;
  text-align: center;
  z-index: 100;
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
  display: inline-flex;
  flex-flow: row-reverse;
}
.imp-micro .microsite.microsite-imp h3 {
  line-height: 1.7em;
  display: inline-block;
  color: #4b97c6;
  margin: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info h3 {
  font-size: 2.25em;
  line-height: 1.25em;
  margin-top: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info p {
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info-wrapper {
  padding-left: 60px;
  text-align: left;

}
.imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info {
  display: inline-block;
  max-width: 325px;
}
.imp-micro .microsite.microsite-imp .page-section--state-assessment .assessment-infographic {
    padding: 80px 0px;
}
.page-section--state-assessment .assessment-infographic img.image-small {
  display: none;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 1400px) {
  .imp-micro .microsite.microsite-imp .page-section--state-assessment .assessment-infographic img.image-large {
    display: none;
  }
  .imp-micro .microsite.microsite-imp .page-section--state-assessment .assessment-infographic img.image-small {
    display: block;
  }
  .imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info-wrapper {
    padding-left: 40px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 2900px) {
  .imp-micro .microsite.microsite-imp .page-section--state-assessment .state-assessment-info {
    padding-left: 200px;
  }
}

/*IMPROVEMENT ROADMAP SECTION */
.imp-micro .microsite.microsite-imp .page-section--improvement-roadmap {
  width: 100%;
  height: 660px;
  background-image: url(images/images/microsite/imp/prepare/bridge-road.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 60% 100%;
  display: block;
  margin-bottom: -15px;
  border-bottom: 5px solid #ffffff;
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap {
    height: 730px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--improvement-roadmap .improvement-roadmap-info {
    padding: 5%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--improvement-roadmap .improvement-roadmap-info p {
    font-size: 1.1em
  }
}
@media screen and (max-width: 760px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap {
    background-size: auto 100%;
    margin-bottom: -18px;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap .gradient {
    background: linear-gradient(90deg, rgba(255,255,255,0.8799894957983193) 0%, rgba(255,255,255,0.8183648459383753) 51%, rgba(255,255,255,0.8715861344537815) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--improvement-roadmap .improvement-roadmap-info {
    margin: 0px auto;
    max-width: 450px;
  }
}
@media screen and (min-width: 761px) and (max-width: 833px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .improvement-roadmap-info {
    padding-right: 4%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap {
    background-size: auto 100%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap .gradient {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 18%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
  }
}
@media screen and (min-width: 834px) and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .improvement-roadmap-info {
    padding-right: 4%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap {
    background-size: auto 100%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap .gradient {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 15%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .improvement-roadmap-info {
    padding-right: 2%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap {
    background-size: 90% 100%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap .gradient {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 28%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }
}
@media screen and (min-width: 1201px) and (max-width: 1920px) {
  .imp-micro .microsite.microsite-imp .page-section--improvement-roadmap {
    background-size: 70% 100%;
  }
.imp-micro   .microsite.microsite-imp .page-section--improvement-roadmap .gradient {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 28%, rgba(255,255,255,1) 56%, rgba(255,255,255,1) 100%);
  }
}
.imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .mainpage-wrapper {
  height: fit-content;
  height: -moz-fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .improvement-roadmap-info {
  text-align: left;
  max-width: 500px;
}
.imp-micro .microsite.microsite-imp .page-section--improvement-roadmap .improvement-roadmap-info h3 {
  margin-bottom: 20px;
  font-size: 2.25em;
  margin-top: 0px;
}
.imp-micro .microsite.microsite-imp .gradient {
  width: 100%;
  height: 100%;
  text-align: right;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 28%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
  position: absolute;
  top: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax p {
  max-width: none;
}
.imp-micro .microsite.microsite-imp .page-section--manage h3 {
  color: #00549a;
}
.imp-micro .microsite-imp .parallax {
  background-image: url(images/images/microsite/imp/manage/mountain-compressed.png);
  height: fit-content;
  background-attachment: fixed;
  background-position: 0px bottom;
  background-repeat: no-repeat;
  background-size: 1200px auto;
  overflow-x: hidden;
  padding-top: 0px;
}
.imp-micro .microsite.microsite-imp .parallax.page-section {
  padding-top: 0px;
}
.imp-micro .microsite-imp .parallax.mountain {
  background-image: url(images/images/microsite/imp/manage/mountain-compressed.png);
  background-position: 0px 90%;
  background-size: 1400px auto;
}
.imp-micro .microsite-imp .parallax.arrows {
  background-image: url(images/images/microsite/imp/manage/incident-support-compressed.png);
  background-position: right bottom;
  padding-bottom: 0px;
}
.imp-micro .microsite.microsite-imp .page-section .parallax {
  padding-bottom: 0px;
}
.imp-micro .microsite-imp .parallax.roles {
  background-image: url(images/images/microsite/imp/manage/roles.png);
  background-size: 1200px auto;
  background-position: 0px bottom;
  height: 75vh;
  max-height: 1000px;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax h3 {
  font-weight: 600;
  color: #00549a;
  font-size: 38px;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.arrows .section-one p.wrap-indent {
  padding: 2px 0;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax span {
  line-height: normal;
  display: inline-block;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .float-right {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section {
  display: inline-block;
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-roles {
  height: 100%;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-management,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-roles {
  text-align: right;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-management,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-support {
  height: fit-content;
  padding-bottom: 7%;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-support,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles h2 {
  text-align: left;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.arrows .section-one {
  padding-top: 7%;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.arrows .section-two {
  padding-top: 2%;
}
.imp-micro .microsite.microsite-imp .parallax.mountain .section-three {
  padding-top: 4%;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two {
  scroll-snap-align: start;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.46122198879551823) 17%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax h4 {
  color: #4b97c6;
  margin: 0px;
  font-size: 2.25em;
  line-height: 1.25em;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .events p {
  display: block;
  float: none;
  font-weight: normal;
  margin-left: auto;
}
.imp-micro .microsite.microsite-imp .page-section .big {
  font-weight: 700;
  margin: 30px 0px;
  color: #00549a;
  font-size: 40px;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one .mainpage-wrapper div {
  float: right;
}
.imp-micro .microsite.microsite-imp .parallax .purple-banner {
  margin-top: 40px;
  opacity: .7;
  margin-bottom: 90px;
}
.imp-micro .microsite.microsite-imp .parallax .purple-banner h5 {
  color: #ffffff;
  text-align: left;
  line-height: 1.7em;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 22px;
  text-transform: none;
  font-weight: normal;
}
.imp-micro .parallax.mountain .section-three p {
  width: 75%;
  text-align: left;
}
.imp-micro .parallax.mountain .section-three h1,
.parallax.roles .section-one h1 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two .events {
  height: auto;
  vertical-align: middle;
  display: inline-block;
}
.imp-micro .microsite.microsite-imp .parallax.arrows .section-one,
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.arrows .section-two {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8673844537815126) 48%, rgba(255,255,255,0) 74%);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.arrows .section-two p {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6152836134453781) 40%, rgba(255,255,255,0.9094012605042017) 66%, rgba(255,255,255,0.9822303921568627) 100%);
  display: flex;
  align-items: center;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .float-right {
  margin-top: -10%;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .list-container {
  position: relative;
  display: inline-block;
  padding: 20px 0;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .list-container h5 {
  margin-top: 0px;
  font-weight: 600;
  color: #00549a;
  line-height: 1.7em;
  font-size: 22px;
  text-transform: none;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .list-container p {
  padding-bottom: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax .page-section--incident-roles.section-two .icon {
  background-image: url(images/images/microsite/imp/manage/define-roles.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-right: 30px;
  vertical-align: middle;
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .roles-responsibilities .icon {
  background-image: url(images/images/microsite/imp/manage/define-roles.png);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .escalation .icon {
  background-image: url(images/images/microsite/imp/manage/escalation-process.png);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .communication .icon {
  background-image: url(images/images/microsite/imp/manage/communication-procedures.png);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .restore .icon {
  background-image: url(images/images/microsite/imp/manage/managed-process.png);
}
.imp-micro .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .list-container div {
  display: inline-block;
  max-width: 550px;
  text-align: left;
  vertical-align: middle;
}
@media screen and (max-width: 360px),
(min-device-width: 320px) and (max-device-width: 480px) and (orientation : portrait),
screen and (max-width: 360px),
(min-device-width: 360px) and (max-device-width: 640px) and (orientation : portrait) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows {
    background-size: 370px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain,
  .microsite.microsite-imp .page-section--manage .parallax.roles {
    background-size: 500px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two {
    height: fit-content;
    height: -moz-fit-content;
    padding-bottom: 13%;
    padding-top: 13%;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows .page-section--incident-support.section-one {
    padding: 15px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.6460959383753502) 0%, rgba(255,255,255,0.6685049019607843) 44%, rgba(255,255,255,0.8141631652661064) 65%, rgba(255,255,255,0.8729866946778712) 100%);
  }
.imp-micro   .parallax.mountain .section-three p {
    max-width: 410px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three .events {
    margin-top: 0px;
    margin-bottom: 100px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two,
  .microsite.microsite-imp .page-section--manage .parallax.roles .section-two {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.8841911764705882) 0%, rgba(255,255,255,0.8365721288515406) 51%, rgba(255,255,255,0.7805497198879552) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows {
    background-size: 900px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows .page-section--incident-support.section-one .mainpage-wrapper span {
    padding-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-one .mainpage-wrapper div {
    padding-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one .mainpage-wrapper div {
    padding-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two .float-right {
  margin-top: 0px;  
  margin-right: 0px;
    padding: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax .page-section--incident-roles.section-two .list-container .icon {
    display: block;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px), (min-device-width: 320px) and
(max-device-width: 480px) and (orientation: landscape),
screen and (min-width: 568px) and (max-height: 568px), (min-device-width: 320px) and
  (max-device-width: 568px) and (orientation : landscape) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one,
  .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two {
    height: fit-content;
    height: -moz-fit-content;
    padding-bottom: 13%;
    padding-top: 13%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles {
    height: 100vh;
    background-size: 400px auto;
    background-position: 0px center;
    padding-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .page-section.parallax h3 {
    line-height: 1.2em;
  }
.imp-micro   .microsite.microsite-imp .page-section.parallax {
    padding-top: 0px;
  }
}
@media screen and (max-width: 600px),
(min-device-width: 600px) and (max-device-width: 960px) and (orientation : portrait) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one,
  .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6096813725490196) 0%, rgba(255, 255, 255, 0.7357317927170868) 53%, rgba(255, 255, 255, 0.8561799719887955) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three .events {
    margin-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two .list-container div {
    max-width: 450px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax .big {
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two .purple-banner span {
    padding-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one {
    padding-right: 30px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.7693452380952381) 0%, rgba(255,255,255,0.7861519607843137) 44%, rgba(255,255,255,0.8141631652661064) 65%, rgba(255,255,255,0.8897934173669468) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.roles {
    background-position: 0px 300px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows {
    background-size: 960px auto;
    background-position: 60% 80%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two .float-right .list-container {
    padding-right: 0px;
  }
}
@media screen and (max-width: 738px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one span p.wrap-indent {
    margin: 0 15px;
    text-indent: -15px;
  }
}
@media screen and (max-height: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.mountain .section-three,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-one,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two {
    height: fit-content;
    height: -moz-fit-content;
    padding-bottom: 13%;
    padding-top: 13%;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section .page-section.page-section--manage .parallax.mountain {
    background-size: 1200px auto;
    background-position: left 600px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows {
    background-size: 1000px auto;
    background-position: left bottom;
  }
.imp-micro   .microsite.microsite-imp .parallax.roles {
    background-size: 900px auto;
    background-position: left bottom;
  }
.imp-micro   body.microsite-imp .page-section--manage .parallax.roles .section-two {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.6460959383753502) 0%, rgba(255,255,255,0.6685049019607843) 44%, rgba(255,255,255,0.8141631652661064) 65%, rgba(255,255,255,0.8729866946778712) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows .section-one {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8225665266106442) 31%, rgba(255,255,255,0.6348914565826331) 67%, rgba(255,255,255,0) 100%);
    padding-top: 30%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax .page-section--incident-roles.section-two .list-container .icon {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax .page-section--incident-roles.section-two .icon {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one .mainpage-wrapper div {
    padding-right: 80px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows .section-one .mainpage-wrapper div {
    padding-left: 80px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.arrows .section-two .purple-banner span {
    padding-left: 50px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two .events p {
    max-width: 80%;
    margin-right: 40px;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.mountain {
    background-size: 1200px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.roles .section-two .list-container h5 {
    font-size: 1.3em;
  }
.imp-micro   .microsite.microsite-imp .page-section--manage .parallax.roles .section-one .float-right {
    margin-right: 10%;
  }
.imp-micro   .microsite-imp .page-section--manage .parallax.roles .section-two {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6152836134453781) 10%, rgba(255,255,255,0.9094012605042017) 46%, rgba(255,255,255,0.9822303921568627) 100%);
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax .page-section--incident-roles.section-two .icon {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1500px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-two,
  .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows .section-one {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.8799894957983193) 0%, rgba(255, 255, 255, 0.8183648459383753) 51%, rgba(255, 255, 255, 0.8715861344537815) 100%);
  }
.imp-micro   body.microsite-imp .parallax.roles {
    background-size: 1200px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles .section-two .list-container {
    padding-right: 40px;
  }
}
@media screen and (max-width: 1800px) {
  .imp-micro .microsite.microsite-imp .page-section--manage .parallax.mountain .section-one,
  .microsite.microsite-imp .page-section--manage .parallax.mountain .section-two {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6096813725490196) 0%, rgba(255, 255, 255, 0.7357317927170868) 53%, rgba(255, 255, 255, 0.8561799719887955) 100%);
  }
}
@media screen and (min-width: 2000px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .parallax.arrows {
    background-size: 1700px auto;
    background-position: bottom right;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--manage .parallax.roles {
    background-size: 1400px auto;
  }
}
/*CONNECTING SECTION*/
.imp-micro .microsite.microsite-imp .page-section--connect,
.imp-micro .microsite.microsite-imp .page-section--evolve {
  padding-top: 80px;
  background-color: #ffffff;
  padding-bottom: 90px;
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.75);
  scroll-snap-align: center;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square,
.imp-micro .microsite.microsite-imp .page-section--evolve .square {
  background-color: rgba(85, 92, 139, 0.68);
  padding: 30px;
  height: fit-content;
  height: -moz-fit-content;
  display: inline-block;
  opacity: .9;
  text-align: center;
  position: relative;
  scroll-snap-align: center;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square {
  width: 100%;
}
.imp-micro .microsite.microsite-imp .page-section--connect .right-section {
  width: 55%;
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--connect .left-section {
  width: 51%;
  margin-top: -11%;
  display: inline-block;
}
.imp-micro .microsite.microsite-imp .page-section--connect .first-connector,
.imp-micro .microsite.microsite-imp .page-section--connect .second-connector,
.imp-micro .microsite.microsite-imp .page-section--connect .last-connector {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  position: relative;
  z-index: 20;
}
.imp-micro .microsite.microsite-imp .page-section--connect .first-connector img,
.imp-micro .microsite.microsite-imp .page-section--connect .second-connector img,
.imp-micro .microsite.microsite-imp .page-section--connect .last-connector img {
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--connect .first-connector {
  position: absolute;
  left: 36%;
  margin-top: -30px;
}
.imp-micro .microsite.microsite-imp .page-section--connect .first-connector img {
  max-height: 600px;
  height: 100%;
  width: auto;
}
.imp-micro .microsite.microsite-imp .page-section--connect .second-connector.mobile {
  display: none;
}
.imp-micro .microsite.microsite-imp .page-section--connect .second-connector {
  margin-left: 8%;
  margin-top: -7%;
  margin-bottom: 3%;
}
.imp-micro .microsite.microsite-imp .page-section--connect .last-connector {
  float: right;
  margin-right: 80px;
  margin-top: -40px;
}
@media screen and (max-width: 320px),
(min-device-width: 320px) and (max-device-height: 480px) and (orientation: portrait) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .first-connector img {
    max-height: 400px;
    height: 100%;
    width: auto;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .first-connector {
    left: -1%;
    bottom: 55%;
    padding: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .left-section .second-connector.mobile {
    display: inline-block;
    float: right;
    margin-right: -54%;
    margin-top: -214%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector .large {
    display: none;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .last-connector {
    display: none;
  }
}
@media screen and (max-width: 480px),
(min-device-width: 320px) and (max-device-height: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .page-section.page-section--connect .first-connector {
    left: 0;
    bottom: 55%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .first-connector img {
    max-height: 500px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .last-connector {
    margin-top: -18%;
  }
}
@media screen and (max-width: 360px), (min-device-width: 360px) and (max-device-height: 568px) and (orientation: portrait)  {
  .microsite.microsite-imp .page-section--manage .page-section.page-section--connect .left-section {
    margin-bottom: -9%;
    margin-top: -1%;
  }
}
@media screen and (min-width: 361px) and (max-width: 479px){
.imp-micro .microsite.microsite-imp .page-section--connect .second-connector {
  opacity: 0;
}
}
@media screen and (max-width: 360px), (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait)  {
  .microsite.microsite-imp .page-section.page-section--connect .first-connector img {
    max-height: 400px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .first-connector {
    left: 0px;
    bottom: 55%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .left-section .second-connector.mobile {
    display: inline-block;
    float: right;
    margin-right: -54%;
    margin-top: -200%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .second-connector {
    display: none;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 70px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .left-section {
    margin-bottom: -9%;
    margin-top: -9%;
  }
}
@media screen and (min-device-width: 600px) and (max-device-height: 960px) and (orientation: portrait) {
  .imp-micro .microsite.microsite-imp .page-section--manage .page-section.page-section--connect .first-connector {
    left: 0;
    bottom: 56%;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 768px) and (max-device-height: 1024px) and (orientation : portrait) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .first-connector {
    left: 12%;
    bottom: 55%;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector {
    left: -2%;
    bottom: 56%;
    padding: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector {
    margin-left: 6%;
    margin-top: -5%;
    float: right;
    margin-bottom: 8%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 23%;
    margin-top: -6%;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--evolve {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector img {
    max-height: 500px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .first-connector {
    left: 17%;
    bottom: 55%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector {
    margin-left: 6%;
    margin-top: -5%;
    float: right;
    margin-bottom: 8%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 23%;
    margin-top: -6%;
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector img {
    max-height: 400px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .first-connector {
    left: 35%;
    margin-top: -30px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector {
    margin-left: 6%;
    margin-top: -1%;
    margin-bottom: -7%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 7%;
    margin-top: 6%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector img {
    max-height: 500px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .first-connector {
    left: 35%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector {
    margin-left: 7%;
    margin-top: -8%;
    margin-bottom: 0%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 8%;
    margin-top: -4%;
  }
}
@media screen and (min-width: 1601px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .first-connector {
    left: 35%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .last-connector {
    margin-right: 8%;
    margin-top: -4%;
  }
}
@media screen and (max-width: 1400px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector .image-large {
    display: none;
  }
}
@media screen and (min-width: 1401px) {
  .imp-micro .microsite.microsite-imp .page-section--connect .first-connector {
    left: 33%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .first-connector .image-small {
    display: none;
  }
}
@media screen and (min-width: 1801px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .page-section.page-section--connect .first-connector {
    left: 36%;
    margin-top: 152px;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .second-connector {
    margin-top: -3%;
    margin-bottom: -9%;
    transform: rotate(-5deg);
    margin-left: 6%;
  }
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .square.right.last {
    margin-top: -9px;
  }
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .left-section {
    margin-top: -39px;
  }
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .last-connector {
    margin-right: 6%;
    margin-top: -27px;
  }
}
@media screen and (min-width: 2100px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .page-section.page-section--connect .first-connector {
    left: 38%;
  }
}
@media screen and (min-width: 2800px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--manage .page-section.page-section--connect .first-connector {
    left: 42%;
  }
.imp-micro   .microsite.microsite-imp .page-section--connect .right-section .second-connector {
    margin-left: 6%;
    margin-top: -7%;
    margin-bottom: 6%;
  }
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.right,
.imp-micro .microsite.microsite-imp .page-section--connect .square.right.last {
  float: right;
  width: 100%;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.right {
  z-index: 10;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.right.last {
  margin-top: -210px;
  z-index: 8;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.left {
  margin-top: -60px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .square h4 {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #4b97c6;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square h3 {
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square p {
  color: #ffffff;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square .column {
  max-width: 600px;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square .column,
.imp-micro .microsite.microsite-imp .page-section--evolve .square .column {
  height: fit-content;
  height: -moz-fit-content;
  display: inline-block;
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square .left.column,
.imp-micro .microsite.microsite-imp .page-section--evolve .left.column {
  text-align: right;
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square .left.column {
  padding-right: 12%;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .left.column {
  padding-right: 9%;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square .right.column {
  text-align: left;
  padding-left: 11%;
  float: left;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .square .right.column {
  text-align: left;
  padding-left: 10%;
  float: left;
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.bridging {
  vertical-align: middle
}
.imp-micro .microsite.microsite-imp .page-section--connect .square.data-management {
  margin-top: 70px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve {
  padding-top: 25px;
  padding-bottom: 25px;
  box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.75);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8869922969187675) 29%, rgba(255,255,255,0.335171568627451) 67%, rgba(255,255,255,0.1026785714285714) 100%);
}
.imp-micro .microsite.microsite-imp .page-section--evolve .mainpage-wrapper .big {
display: inline-block;
padding-bottom: 60px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .content-container .text-wrapper {
  margin: 0px auto;
  float: none;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .content-container {
  margin-top: 100px;
  margin-bottom: 130px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .square {
  z-index: 15;
  background-color: #ffffff;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .left-section {
  z-index: 14;
  margin-top: -60%;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .right-section,
.imp-micro .microsite.microsite-imp .page-section--evolve .left-section {
  display: inline-block;
  position: relative;
  width: 55%;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .right-section {
  z-index: 13;
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-left,
.imp-micro .microsite.microsite-imp .page-section--evolve .background-right {
  display: inline-block;
  height: fit-content;
  height: -moz-fit-content;
  bottom: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-left {
  float: right;
  margin-right: 9%;
  bottom: 65px;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-left.mobile {
  display: none;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-right {
  margin-left: 9%;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-right .measure-effectiveness {
  float: left;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .background-left .improve-graphic {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .square.right {
  width: 100%;
  -webkit-box-shadow: -5px -1px 8px 1px rgba(0,0,0,0.29);
  -moz-box-shadow: -5px -1px 8px 1px rgba(0,0,0,0.29);
  box-shadow: -5px -1px 8px 1px rgba(0,0,0,0.29);
  z-index: 9;
}
.imp-micro .microsite.microsite-imp .page-section--evolve .square.left {
  margin-top: -70px;
  -webkit-box-shadow: 5px -1px 8px 1px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 5px -1px 8px 1px rgba(0, 0, 0, 0.29);
  box-shadow: 5px -1px 8px 1px rgba(0, 0, 0, 0.29);
}
@media screen and (max-width: 320px),
(min-device-width: 320px) and (max-device-width: 480px) and (orientation : portrait),
screen and (max-width: 360px),
(min-device-width: 360px) and (max-device-width: 640px) and (orientation : portrait) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--evolve .right-section,
  .microsite.microsite-imp .page-section.page-section--evolve .left-section {
    width: 100%;
    margin-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--evolve .background-right {
    margin: 0px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--evolve .square.left {
    margin-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--evolve .background-left {
    display: none;
  }
.imp-micro   .microsite.microsite-imp .page-section--evolve .background-left.mobile {
    display: inline-block;
    float: none;
    bottom: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .column {
    display: block;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .full-incident-reporting .vector-graphic {
    margin-bottom: 30px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .background.left,
  .microsite.microsite-imp .page-section.page-section--functions .background.right {
    background-repeat: repeat-y;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .background.left {
    background-position: right top;
    padding-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .left.column {
    height: 550px;
    float: none;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .simple-incident-reporting .vector-graphic.mobile {
    display: block;
    top: -30px;
    float: none;
    margin: 0px auto;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--functions .simple-incident-reporting .vector-graphic {
    display: none;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--evolve .content-container {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--connect .right-section {
    width: 65%;
    float: right;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .left-section {
    width: 61%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--connect .square.left,
  .microsite.microsite-imp .page-section.page-section--connect .square.right.last {
    margin-top: -40px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--evolve h3 {
    margin-bottom: 10%;
  }
}
.imp-micro   /*IMPORTAL SECTION*/
.imp-micro .microsite.microsite-imp .page-section--importal {
  padding-top: 70px;
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--importal .text-wrapper {
  float: right;
  padding-right: 40px;
}
.imp-micro .microsite.microsite-imp .page-section--importal .importal-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--importal .mainpage-wrapper {
  display: block;
  text-align: right;
  margin-bottom: 100px;
}
.imp-micro .microsite.microsite-imp .page-section--importal .mainpage-wrapper h4 {
   line-height: 1.7em;
   margin-top: 0px;
   display: inline-block;
  padding-right: 0px;
  float: right;
 }
.imp-micro .microsite.microsite-imp .page-section--importal .mainpage-wrapper h4 .smaller {
  font-size: .8em;
}
.imp-micro .microsite.microsite-imp .page-section--importal .imp-devices {
  position: relative;
  left: 0px;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-top: 40px;
  float: none;
}
.imp-micro .microsite.microsite-imp .page-section--importal .imp-devices img {
  margin: 0px auto;
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section--importal .mainpage-wrapper {
    padding-right: 0px;
  }
}
/*ORCHESTRATION SECTION*/
.imp-micro .microsite.microsite-imp .page-section--orchestration {
  height: fit-content;
  height: -moz-fit-content;
  clear: both;
  content:'';
  display: block;
  padding-left: 0px;
  padding-right: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration:after {
  clear: all;
  height: fit-content;
  height: -moz-fit-content;
  content:'';
  display: block;
}
.imp-micro .clearfix:before,
.imp-micro .clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper {
  display: block;
  clear: both;
  content:'';
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper:after {
  display: block;
  clear: both;
  content:'';
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h1 {
  margin-top: -10px;
  margin-bottom: 15px;
  margin-right: -35px;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h3 {
  line-height: 1.5em;
  margin-top: 0px;
  margin-right: 100px;
  display: block;
  text-align: right;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .orchestration-wrapper {
  text-align: right;
  margin-left: 7%;
  margin-bottom: 20px;
  display: block;
  clear: right;
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .text-wrapper {
  float: right;
  display: block;
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner {
  background-color: rgba(88, 173, 173, 0.91);
  width: 100%;
  height: 350px;
  padding-top: 40px;
  margin-top: -40px;
  margin-bottom: 300px;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner:after {
  clear: both;
  content:'';
  display:block;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner:before {
  height: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-text {
  margin-left: 100px;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-text h3 {
  font-weight: 600;
  color: #ffffff;
  margin-right: 0px;
  text-align: left;
}
.imp-micro .microsite.microsite-imp .banner-text p {
  color: #ffffff;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-photos li h5 {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 300px;
  text-transform: none;
  color: #5e85a1;
  font-size: 1.125em;
  line-height: 1.6em;
  margin: 20px 0 0 0;
  font-weight: normal;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-photos {
  float: right;
  display: inline-flex;
  list-style-type: none;
  margin-top: 10px;
  justify-content: space-between;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-photos li {
  width: auto;
  text-align: center;
  display: inline-block;
}
.imp-micro .microsite.microsite-imp .page-section--orchestration .banner-photos li .photo {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0px auto;
  max-width: 300px;
  max-height: 200px;
}
@media screen and (max-width: 480px) {
.imp-micro   .microsite.microsite-imp .page-section--orchestration .orchestration-wrapper {
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h3,
  .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h5 {
    margin-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .banner-text {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration ul.banner-photos li h5 {
    padding-left: 1%;
    PADDING-RIGHT: 1%;
    color: #ffffff;
  }
}
@media screen and (max-width: 600px) {
  .imp-micro .microsite.microsite-imp .page-section--orchestration ul.banner-photos {
    float: none;
    display: inline-block;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration ul.banner-photos li {
    display: block;
    padding-bottom: 30px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration ul.banner-photos li .photo {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
.imp-micro   .microsite.microsite-imp .page-section .page-section--orchestration ul.banner-photos li h5 {
    display: block;
    padding-top: 4%;
    margin: 0px auto;
    padding-left: 0px;
    padding-right: 0px;
    color: #ffffff;
  }
.imp-micro   .microsite.microsite-imp .page-section--tools.page-section .page-section--orchestration .banner {
    height: fit-content;
    height: -moz-fit-content;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--orchestration ul.banner-photos li h5 {
    padding-left: 1%;
    PADDING-RIGHT: 1%;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .orchestration-wrapper {
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h3,
  .microsite.microsite-imp .page-section--orchestration .mainpage-wrapper h5 {
    margin-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .banner-text {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--orchestration .banner {
    margin-bottom: 260px;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section--orchestration ul.banner-photos li h5 {
    padding-left: 1%;
    PADDING-RIGHT: 1%;
  }
}
/*FUNCTIONS SECTION*/
.imp-micro .microsite.microsite-imp .page-section--functions {
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column {
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}
.imp-micro .microsite.microsite-imp .page-section--functions .right.column {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7749474789915967) 33%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 100%);
  float: right;
  text-align: right;
  padding-right: 50px;
  height: fit-content;
  height: -moz-fit-content;
  justify-content: flex-end;
}
.imp-micro .microsite.microsite-imp .page-section--functions .left.column {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 46%, rgba(255,255,255,0.7749474789915967) 76%, rgba(255,255,255,0) 100%);
  float: left;
  text-align: left;
  padding-left: 50px;
  justify-content: flex-start;
  height: 360px;
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--functions .right.column {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7749474789915967) 16%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 100%);
  }
}
.imp-micro .microsite.microsite-imp .page-section--functions .right.column.automation-functions {
  padding-right: 30px;
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column .text-wrapper {
  display: inline-block;
  position: relative;
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting .text-wrapper {
  float: right;
  padding-left: 0px;
  padding-right: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .right.column.automation-functions .text-wrapper {
  max-width: 600px;
  text-align: center;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column .vector-graphic {
  position: relative;
  display: inline-block;
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting .vector-graphic,
.imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting .vector-graphic img {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting .vector-graphic {
  margin-top: 25px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .simple-incident-reporting .vector-graphic,
.imp-micro .microsite.microsite-imp .page-section--functions .simple-incident-reporting .vector-graphic img {
  float: left;
}
.imp-micro .microsite.microsite-imp .page-section--functions .simple-incident-reporting .vector-graphic {
  margin-left: -22%;
  top: 30px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .simple-incident-reporting .vector-graphic.mobile {
  display: none;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column h3 {
  display: inline-block;
  color: #4b97c6;
  letter-spacing: .04em;
  margin-bottom: 0px;
  line-height: 1.25em;
  font-size: 2.25em;
}
.imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting.column p {
  margin-left: -10px;
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--functions .right.column ul {
    padding-left: 50px;
  }
.imp-micro   .microsite.microsite-imp .page-section--functions .simple-incident-reporting .vector-graphic {
    top: 55px;
  }
}
@media screen and (max-width: 900px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--functions .full-incident-reporting.column p {
    margin-left: -80px;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--functions .full-incident-reporting.column .text-wrapper p {
    margin-left: 0px;
    text-align: right;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting.column p {
    margin-left: -45px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 993px) {
  .imp-micro .microsite.microsite-imp .page-section--functions .full-incident-reporting.column p {
    margin-left: -85px;
  }
}
.imp-micro .microsite.microsite-imp .page-section--functions .column ul {
  text-align: left;
  display: inline-block;
  height: fit-content;
  height: -moz-fit-content;
  margin-bottom: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .right.column ul {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column ul.automation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .column ul.automation li {
  background: url(images/images/microsite/imp/tools/checkmark.png) no-repeat left center;
  line-height: 20px;
  padding-left: 44px;
  display: block;
  padding-bottom: 10px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .background {
   background-image: url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp.png);
   background-position: left center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   width: 100%;
   height: 500px;
   z-index: 5;
   min-width: 100%;
   margin-top: 30px;
   align-items: center;
    display: block;
 }
.imp-micro .microsite.microsite-imp .page-section--functions .automation .list-container {
  float: right;
  height: fit-content;
  height: -moz-fit-content;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6937149859943977) 68%, rgba(255,255,255,1) 69%, rgba(255,255,255,1) 100%);
  margin-top: -2px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .background.left {
  background-image: url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp-flipped.png),
                    url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp-flipped.png);
  background-position: right center,
                      74% center;
  background-repeat: no-repeat,
                    no-repeat;
  background-size: contain;
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--functions .background.right {
  background-image: url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  height: fit-content;
  height: -moz-fit-content;
  padding-right: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--functions .background.right.automation {
  background-image: url(images/images/microsite/imp/tools/automation-functions-abstract-comp.jpg),
                    url(images/images/microsite/imp/tools/automation-functions-abstract-comp.jpg);
  background-position: left center,
                        1056px center;
  background-repeat: no-repeat,
                     no-repeat;
  background-size: cover;
  height: fit-content;
  height: -moz-fit-content;
  z-index: 5;
  margin-bottom: -9px;
}
@media screen and (min-width: 1900px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--functions .background.right {
    background-image: url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp.png),
    url(images/images/microsite/imp/tools/incident-reporting-data-abstract-comp.png);
    background-position: left center,
    600px center;
    background-repeat: no-repeat,
    no-repeat;
    background-size: contain;
    height: fit-content;
    height: -moz-fit-content;
    padding-right: 0px;
  }
}
/*BCP/DR SECTION*/
.imp-micro .microsite.microsite-imp .page-section--bcp-dr {
  z-index: 201;
  position: relative;
  height: fit-content;
  height: -moz-fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro {
  height: fit-content;
  height: -moz-fit-content;
  margin-bottom: -10px;
  padding-top: 40px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .intro-wrapper {
  margin-left: 40px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr h3 {
  color: #00549a;
  font-size: 38px;
  margin-top: 40px;
  font-weight: 500;
  margin-bottom: 30px;
  letter-spacing: .04em;
  line-height: 1.2em;
  display: block;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .intro-wrapper p {
  max-width: 770px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .column {
  position: relative;
  align-items: center;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .business-threat-toolkit {
  float: none;
  margin: 0px auto;
}
.imp-micro body.microsite-imp .page-section--bcp-dr .business-threat-toolkit img {
  margin: 0px auto;
  max-width: 1000px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr {
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .business-continuity,
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .disaster-recovery {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 5%;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .disaster-recovery {
  margin-bottom: 40px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .blue-banner {
  padding: 50px 0px;
  text-align: right;
  margin-top: 30px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .bc-dr .blue-banner {
  padding: 50px 0px;
  text-align: right;
  margin-top: 30px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .blue-banner .text-wrapper {
  float: right;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .blue-banner .text-wrapper h4 {
  margin: 0px;
}
@media screen and (max-width: 500px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section.page-section--intro .bc-dr .disaster-recovery {
    padding-bottom: 5%;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .business-threat-toolkit img {
    max-width: 700px;
  }
}
@media screen and (max-width: 600px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro .bc-dr .business-continuity,
  .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro .bc-dr .disaster-recovery {
    float: none;
    padding-top: 20px;
    padding-left: 4%;
    display: block;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro .bc-dr .disaster-recovery {
    padding-bottom: 3%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro .intro-wrapper {
    margin-left: 20px;
    margin-right: 10px;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--bcp-dr .blue-banner .text-wrapper {
    padding-left: 12%;
    padding-right: 8%;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro {
    margin-bottom: 0%;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr .business-continuity {
    padding-right: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr .disaster-recovery {
    margin-left: 4%;
    padding-bottom: 5%;
    padding-right: 0px;
    padding-top: 0px;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .blue-banner .text-wrapper {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (max-width: 992px) {
  .imp-micro .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section--intro .left.column .business-threat-toolkit {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
.imp-micro   body.microsite-imp .page-section--bcp-dr .business-threat-toolkit img {
    max-width: 992px;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .business-threat-toolkit {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr .business-continuity,
  .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr .disaster-recovery {
    padding-right: 2%;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--intro .bc-dr .business-continuity {
  margin-left: 4%;
  }
.imp-micro   body.microsite-imp .page-section--bcp-dr .business-threat-toolkit img {
    max-width: 992px;
  }
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .sub-section {
  width: 100%;
  height: 720px;
  display: block;
  scroll-snap-align: start;
  position: relative;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .sub-section.bridging-gap {
  background-image: url(images/images/microsite/imp/bcp-dr/gap-crossing-comp.svg);
  background-position: 0px top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #002C41;
  height: fit-content;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .sub-section.four-steps {
  background: rgb(25,120,180);
  background: linear-gradient(90deg, rgba(25,120,180,0.8533788515406162) 0%, rgba(25,120,180,0.8701855742296919) 36%, rgba(25,120,180,0.8729866946778712) 61%, rgba(25,120,180,0.8897934173669468) 100%);
  display: flex;
  scroll-snap-align: end;
  scroll-snap-stop: always;
  text-align: center;
  height: fit-content;
  height: -moz-fit-content;
  padding-top: 140px;
  padding-bottom: 140px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .gradient {
  width: 100%;
  text-align: left;
  background: rgb(25,120,180);
  background: linear-gradient(90deg, rgba(25,120,180,0.5872724089635855) 8%, rgba(25,120,180,0.7637429971988796) 30%, rgba(25,120,180,0.8729866946778712) 50%, rgba(25,120,180,0.8953956582633054) 100%);
  top: 0px;
  z-index: 10;
  height: inherit;
  padding-bottom: 25px;
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .banner {
  height: fit-content;
  height: -moz-fit-content;
  margin-top: -2px;
  display: flex;
  position: -webkit-sticky;
  z-index: 300;
  padding-top: 60px;
  padding-bottom: 80px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .banner::before {
  display: none;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .banner .header {
  margin: 0px auto;
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  padding-left: 50px;
  float: left;
  position: relative;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .banner .header p {
  display: block;
  padding-bottom: 0px;
  max-width: 900px;
}
.imp-micro .microsite.microsite-imp .shield-container {
  float: none;
  display: inline-block;
  height: auto;
  position: absolute;
  bottom: -167px;
  right: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .gradient .mainpage-wrapper {
  height: fit-content;
  height: -moz-fit-content;
  padding-top: 80px;
  padding-bottom: 80px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column {
  float: none;
  margin-left: auto;
  height: fit-content;
  height: -moz-fit-content;
  padding: 100px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .column p {
  line-height: 1.7em;
  margin-bottom: 0px;
  letter-spacing: .04em;
  display: inline-block;
  text-transform: none;
  color: #ffffff;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps {
  float: none;
  padding: 40px 10px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul {
  display: block;
  margin: 0px auto;
  list-style: none;
  padding: 0px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps h4 {
  margin-bottom: 0px;
  color: #ffffff;
  text-transform: none;
  line-height: 1.25em;
  font-weight: normal;
  padding-bottom: 30px;
  font-weight: bold;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul p {
  color: #ffffff;
  padding-top: 30px;
}
.imp-micro .microsite-imp .page-section--bcp-dr .four-steps li {
  display: inline-block;
  text-align: center;
  padding: 30px;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li .graphic {
  background-image: url(images/images/microsite/imp/bcp-dr/bcp-dr-design.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 110px;
  height: 100px;
  margin: 0px auto;
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li.design .graphic {
  background-image: url(images/images/microsite/imp/bcp-dr/bcp-dr-design.svg);
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li.maintain .graphic {
  background-image: url(images/images/microsite/imp/bcp-dr/bcp-dr-maintain.svg);
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li.test .graphic {
  background-image: url(images/images/microsite/imp/bcp-dr/bcp-dr-test.svg);
}
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li.activate .graphic {
  background-image: url(images/images/microsite/imp/bcp-dr/bcp-dr-activate.svg);
}
@media screen and (max-width: 320px),
(min-device-width: 320px) and (max-device-width: 480px) and (orientation : portrait),
screen and (min-width: 320px) and (max-width: 480px),
(min-device-width: 320px) and (max-device-width: 480px) and (orientation : landscape) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column .first.paragraph h4 {
    font-size: 1.5em;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--services .second.paragraph h5 {
    font-size: 1.1em;
    line-height: normal;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section.page-section--services .banner .mainpage-wrapper .shield-container {
    margin-bottom: -24%;
  }
}
@media screen and (max-width: 380px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section.page-section--services .gradient .mainpage-wrapper {
    margin-top: 0;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section.page-section--services .sub-section.bridging-gap {
    height: 600px;
  }
}
@media screen and (max-width: 480px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .gradient .mainpage-wrapper {
    margin-top: -39%;
  }
.imp-micro   .microsite.microsite-imp .page-section.page-section--bcp-dr .page-section.page-section--services .banner .shield-container {
    margin-bottom: -22%;
  }
}
@media screen and (max-width: 553px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column {
    margin-top: 35px;
  }
.imp-micro   .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li {
    max-width: 90%;
  }
}
@media screen and (min-width: 320px) and (max-width: 568px),
(min-device-width: 320px) and (max-device-width: 568px) and (orientation : landscape) {
  .imp-micro body.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul li {
    max-width: 70%;
  }
}
@media screen and (max-width: 768px), (min-device-width: 768px) and (max-device-height: 1024px) and (orientation: portrait) {
  .imp-micro body.microsite-imp .page-section--bcp-dr .four-steps ul li {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column,
  .microsite.microsite-imp .page-section--bcp-dr .page-section--services .column .second.paragraph {
    margin-right: 0px;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column {
    max-width: 60%;
    padding: 40px 60px 60px 40px;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .banner .shield-container {
    display: none;
  }
.imp-micro .microsite.microsite-imp .page-section.page-section--services {
    padding-top: 0px;
  }
}
@media screen and (min-width: 769px) and (max-width: 996px)  {
  .microsite.microsite-imp .page-section.page-section--services .sub-section.four-steps ul li .graphic {
    height: 130px;
    width: 130px;
  }
.imp-micro .microsite.microsite-imp .page-section.page-section--services .sub-section.four-steps ul p {
    font-size: 1em;
  }
}
@media screen and (max-width: 996px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .shield-container {
    bottom: -147px;
  }
.imp-micro .microsite.microsite-imp .page-section.page-section--services .sub-section.four-steps ul h5 {
    font-size: 1.6em;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .sub-section.four-steps {
    padding-top: 80px;
    padding-bottom: 80px;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column h4 {
    font-size: 1.8em;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .right.column h5 {
    font-size: 18px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .shield-container {
    bottom: -159px;
  }
.imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul h5 {
    font-size: 1.7em;
  }
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul {
    display: flex;
    flex-wrap: wrap;
  }
  .imp-micro .microsite-imp .page-section--bcp-dr .four-steps li {
    max-width: 49%;
  }
  .imp-micro .microsite.microsite-imp .page-section--bcp-dr .page-section--services .four-steps ul p {
    max-width: 300px;
  }
}