.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navLinkMenu>li.active {
  color: #fff;
  background-color: rgba(15, 81, 144, 0.78);
}

.scroll {
  color: #fff;
  background-color: #13407c;
  -webkit-box-shadow: inset 0px 3px 17px 0px #0b2544;
  box-shadow: inset 0px 3px 17px 0px #0b2544;
  background-image: none;
}

.scroll > a {
  color: #fff;
}
.scroll > a:hover {
  color: #fff !important;
}

.navbar-nav li>a {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}